/**
 * @generated SignedSource<<b5fe87a6d74922f43932def6e8dbfbec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type invitedUserEmailData_GetInvitedUserEmailDataListQuery$variables = {
  emails: ReadonlyArray<string>;
};
export type invitedUserEmailData_GetInvitedUserEmailDataListQuery$data = {
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_password: boolean;
        readonly email: string | null | undefined;
        readonly name: string;
        readonly pk: string;
        readonly role: string;
        readonly subcontractor_employee: {
          readonly subcontractor_id: string;
        } | null | undefined;
        readonly worker: {
          readonly subcontractor: {
            readonly id: string;
            readonly name: string;
          } | null | undefined;
        } | null | undefined;
      };
    }>;
  };
};
export type invitedUserEmailData_GetInvitedUserEmailDataListQuery = {
  response: invitedUserEmailData_GetInvitedUserEmailDataListQuery$data;
  variables: invitedUserEmailData_GetInvitedUserEmailDataListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "emails"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_in",
            "variableName": "emails"
          }
        ],
        "kind": "ObjectValue",
        "name": "email"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_password",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "invitedUserEmailData_GetInvitedUserEmailDataListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor_employee",
                    "kind": "LinkedField",
                    "name": "subcontractor_employee",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "invitedUserEmailData_GetInvitedUserEmailDataListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor_employee",
                    "kind": "LinkedField",
                    "name": "subcontractor_employee",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ed19c3e31c581671c5d631cbcecf20e",
    "id": null,
    "metadata": {},
    "name": "invitedUserEmailData_GetInvitedUserEmailDataListQuery",
    "operationKind": "query",
    "text": "query invitedUserEmailData_GetInvitedUserEmailDataListQuery(\n  $emails: [String!]!\n) {\n  user_connection(where: {email: {_in: $emails}}) {\n    edges {\n      node {\n        pk: id\n        email\n        name\n        role\n        created_password\n        subcontractor_employee {\n          subcontractor_id\n          id\n        }\n        worker {\n          subcontractor {\n            id\n            name\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7ddc8eb0f4cbb2a856e54fe26de78bf";

export default node;

/**
 * @generated SignedSource<<e2a1dbb1f28dfa4c11d48730bfbfd334>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SiteOrientationInPersonWorkerStepsResidenceQuery$variables = {
  cityId: string;
  stateCode: string;
  zipCode: string;
};
export type SiteOrientationInPersonWorkerStepsResidenceQuery$data = {
  readonly cityAssociatedZipCodes: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly city_id: string;
        readonly pk: string;
        readonly zip_code: string;
      };
    }>;
  };
  readonly city_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly pk: string;
      };
    }>;
  };
  readonly city_zip_code_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly city: {
          readonly name: string;
          readonly state: {
            readonly name: string;
          };
          readonly state_code: string;
        };
        readonly id: string;
        readonly pk: string;
        readonly zip_code: string;
      };
    }>;
  };
  readonly state_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly code: string;
        readonly name: string;
      };
    }>;
  };
};
export type SiteOrientationInPersonWorkerStepsResidenceQuery = {
  response: SiteOrientationInPersonWorkerStepsResidenceQuery$data;
  variables: SiteOrientationInPersonWorkerStepsResidenceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "stateCode"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "zipCode"
},
v3 = {
  "name": "asc"
},
v4 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "city": (v3/*: any*/)
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "zipCode"
          }
        ],
        "kind": "ObjectValue",
        "name": "zip_code"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zip_code",
  "storageKey": null
},
v7 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state_code",
  "storageKey": null
},
v10 = {
  "kind": "Literal",
  "name": "order_by",
  "value": (v3/*: any*/)
},
v11 = [
  (v10/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v13 = [
  (v10/*: any*/),
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "stateCode"
          }
        ],
        "kind": "ObjectValue",
        "name": "state_code"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v14 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "zip_code": "asc"
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "cityId"
          }
        ],
        "kind": "ObjectValue",
        "name": "city_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city_id",
  "storageKey": null
},
v16 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SiteOrientationInPersonWorkerStepsResidenceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "city_zip_codeConnection",
        "kind": "LinkedField",
        "name": "city_zip_code_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "city_zip_codeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "city_zip_code",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "city",
                    "kind": "LinkedField",
                    "name": "city",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "state",
                        "kind": "LinkedField",
                        "name": "state",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "stateConnection",
        "kind": "LinkedField",
        "name": "state_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "stateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "state",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "state_connection(order_by:{\"name\":\"asc\"})"
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "cityConnection",
        "kind": "LinkedField",
        "name": "city_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "cityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "city",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "cityAssociatedZipCodes",
        "args": (v14/*: any*/),
        "concreteType": "city_zip_codeConnection",
        "kind": "LinkedField",
        "name": "city_zip_code_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "city_zip_codeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "city_zip_code",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v6/*: any*/),
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SiteOrientationInPersonWorkerStepsResidenceQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "city_zip_codeConnection",
        "kind": "LinkedField",
        "name": "city_zip_code_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "city_zip_codeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "city_zip_code",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "city",
                    "kind": "LinkedField",
                    "name": "city",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "state",
                        "kind": "LinkedField",
                        "name": "state",
                        "plural": false,
                        "selections": [
                          (v8/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "stateConnection",
        "kind": "LinkedField",
        "name": "state_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "stateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "state",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v12/*: any*/),
                  (v8/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "state_connection(order_by:{\"name\":\"asc\"})"
      },
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "cityConnection",
        "kind": "LinkedField",
        "name": "city_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "cityEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "city",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "cityAssociatedZipCodes",
        "args": (v14/*: any*/),
        "concreteType": "city_zip_codeConnection",
        "kind": "LinkedField",
        "name": "city_zip_code_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "city_zip_codeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "city_zip_code",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v6/*: any*/),
                  (v15/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ef104c7ae827c4fe41c4a5271f218b0",
    "id": null,
    "metadata": {},
    "name": "SiteOrientationInPersonWorkerStepsResidenceQuery",
    "operationKind": "query",
    "text": "query SiteOrientationInPersonWorkerStepsResidenceQuery(\n  $stateCode: String!\n  $zipCode: String!\n  $cityId: uuid!\n) {\n  city_zip_code_connection(where: {zip_code: {_eq: $zipCode}}, order_by: {city: {name: asc}}) {\n    edges {\n      node {\n        id\n        zip_code\n        pk: id\n        city {\n          name\n          state_code\n          state {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n  state_connection(order_by: {name: asc}) {\n    edges {\n      node {\n        code\n        name\n        id\n      }\n    }\n  }\n  city_connection(where: {state_code: {_eq: $stateCode}}, order_by: {name: asc}) {\n    edges {\n      node {\n        name\n        pk: id\n        id\n      }\n    }\n  }\n  cityAssociatedZipCodes: city_zip_code_connection(where: {city_id: {_eq: $cityId}}, order_by: {zip_code: asc}) {\n    edges {\n      node {\n        pk: id\n        zip_code\n        city_id\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b16b743547f3af2a9830fdc7bbd1c3aa";

export default node;

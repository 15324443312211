/**
 * @generated SignedSource<<f0112ef9535315e52e01ca38aa31dd0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TasksTable_task$data = {
  readonly task_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly accepted_at_from_creation_request: string | null | undefined;
        readonly accepted_by_uid_from_creation_request: string | null | undefined;
        readonly archived_at: string | null | undefined;
        readonly archived_by_uid: string | null | undefined;
        readonly created_at: string;
        readonly created_by_user: {
          readonly name: string;
        } | null | undefined;
        readonly creation_request_id: string | null | undefined;
        readonly description: {
          readonly en: string;
        };
        readonly document: {
          readonly url: string;
        } | null | undefined;
        readonly email_requests: ReadonlyArray<{
          readonly created_at: string;
          readonly type: string | null | undefined;
        }>;
        readonly first_used?: ReadonlyArray<{
          readonly created_at: string;
        }>;
        readonly flagged_at: string | null | undefined;
        readonly id: string;
        readonly is_pending_acceptance: boolean | null | undefined;
        readonly last_used?: ReadonlyArray<{
          readonly created_at: string;
        }>;
        readonly pk: string;
        readonly project_tasks?: ReadonlyArray<{
          readonly id: string;
          readonly pk: string;
          readonly project_id: string | null | undefined;
        }>;
        readonly request_state: string | null | undefined;
        readonly reviewed_at: string | null | undefined;
        readonly reviewed_by_user: {
          readonly name: string;
        } | null | undefined;
        readonly show_type: string;
        readonly subcontractor: {
          readonly name: string;
        } | null | undefined;
        readonly subcontractor_id: string | null | undefined;
        readonly task_admins: ReadonlyArray<{
          readonly general_contractor_employee: {
            readonly employee_title: {
              readonly name: {
                readonly en: string;
              };
            } | null | undefined;
            readonly uid: string;
            readonly user: {
              readonly name: string;
            };
          };
        }>;
        readonly task_creation_request: {
          readonly attached_files: ReadonlyArray<{
            readonly url: string;
          }>;
          readonly id: string;
          readonly pk: string;
          readonly subcontractor: {
            readonly name: string;
          };
          readonly subcontractor_id: string;
          readonly tasks: ReadonlyArray<{
            readonly show_type: string;
          }>;
          readonly type: string;
        } | null | undefined;
        readonly task_edit?: ReadonlyArray<{
          readonly edit_type: string;
          readonly edited_at: string;
          readonly edited_by_user: {
            readonly name: string;
          } | null | undefined;
          readonly task_patch: any | null | undefined;
        }>;
        readonly task_permit_types_aggregate?: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly task_ppe_types_aggregate?: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly task_signatures?: ReadonlyArray<{
          readonly created_at: string;
          readonly signature_image_id: string | null | undefined;
          readonly user: {
            readonly employee: {
              readonly employee_title: {
                readonly employee_title_category: {
                  readonly name: {
                    readonly en: string;
                  };
                } | null | undefined;
              } | null | undefined;
            } | null | undefined;
            readonly name: string;
            readonly role: string;
          } | null | undefined;
        }>;
        readonly task_steps: ReadonlyArray<{
          readonly id: string;
          readonly task_step_hazards: ReadonlyArray<{
            readonly id: string;
          }>;
        }>;
        readonly task_steps_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly updated_at: string | null | undefined;
        readonly updated_by_user: {
          readonly name: string;
        } | null | undefined;
      };
    }>;
  };
  readonly " $fragmentType": "TasksTable_task";
};
export type TasksTable_task$key = {
  readonly " $data"?: TasksTable_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TasksTable_task">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "task_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v5 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_type",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "en",
    "storageKey": null
  }
],
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": (v11/*: any*/),
    "storageKey": null
  }
],
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v14 = {
  "kind": "Literal",
  "name": "limit",
  "value": 1
},
v15 = {
  "kind": "Literal",
  "name": "order_by",
  "value": {
    "created_at": "desc"
  }
},
v16 = [
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeFirstUsed"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeLastUsed"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includePermitsCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includePpeCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeProjectTaskCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeTaskEdit"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./TasksTableRefetchableQuery.graphql')
    }
  },
  "name": "TasksTable_task",
  "selections": [
    {
      "alias": "task_connection",
      "args": [
        {
          "kind": "Variable",
          "name": "order_by",
          "variableName": "order_by"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "taskConnection",
      "kind": "LinkedField",
      "name": "__TasksTable_task_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "taskEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "task",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "email_request",
                  "kind": "LinkedField",
                  "name": "email_requests",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "creation_request_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "add_task_request",
                  "kind": "LinkedField",
                  "name": "task_creation_request",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "document",
                      "kind": "LinkedField",
                      "name": "attached_files",
                      "plural": true,
                      "selections": (v4/*: any*/),
                      "storageKey": null
                    },
                    (v5/*: any*/),
                    (v6/*: any*/),
                    (v9/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "task",
                      "kind": "LinkedField",
                      "name": "tasks",
                      "plural": true,
                      "selections": [
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "task_admin",
                  "kind": "LinkedField",
                  "name": "task_admins",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "general_contractor_employee",
                      "kind": "LinkedField",
                      "name": "general_contractor_employee",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "uid",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "employee_title",
                          "kind": "LinkedField",
                          "name": "employee_title",
                          "plural": false,
                          "selections": (v12/*: any*/),
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "user",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": (v8/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updated_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "updated_by_user",
                  "plural": false,
                  "selections": (v8/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "document",
                  "kind": "LinkedField",
                  "name": "document",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/),
                (v9/*: any*/),
                (v3/*: any*/),
                (v5/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "text_translation",
                  "kind": "LinkedField",
                  "name": "description",
                  "plural": false,
                  "selections": (v11/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "task_step_aggregate",
                  "kind": "LinkedField",
                  "name": "task_steps_aggregate",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "task_step_aggregate_fields",
                      "kind": "LinkedField",
                      "name": "aggregate",
                      "plural": false,
                      "selections": (v13/*: any*/),
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "is_pending_acceptance",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "request_state",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archived_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "archived_by_uid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "flagged_at",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "created_by_user",
                  "plural": false,
                  "selections": (v8/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reviewed_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "reviewed_by_user",
                  "plural": false,
                  "selections": (v8/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "task_step",
                  "kind": "LinkedField",
                  "name": "task_steps",
                  "plural": true,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "task_step_hazard",
                      "kind": "LinkedField",
                      "name": "task_step_hazards",
                      "plural": true,
                      "selections": [
                        (v3/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v10/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accepted_at_from_creation_request",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "accepted_by_uid_from_creation_request",
                  "storageKey": null
                },
                {
                  "condition": "includeTaskEdit",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        (v14/*: any*/),
                        {
                          "kind": "Literal",
                          "name": "order_by",
                          "value": {
                            "edited_at": "desc"
                          }
                        },
                        {
                          "kind": "Literal",
                          "name": "where",
                          "value": {
                            "task_patch": {
                              "_neq": []
                            }
                          }
                        }
                      ],
                      "concreteType": "task_edit",
                      "kind": "LinkedField",
                      "name": "task_edit",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "edited_at",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "edit_type",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "task_patch",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "user",
                          "kind": "LinkedField",
                          "name": "edited_by_user",
                          "plural": false,
                          "selections": (v8/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": "task_edit(limit:1,order_by:{\"edited_at\":\"desc\"},where:{\"task_patch\":{\"_neq\":[]}})"
                    },
                    {
                      "alias": null,
                      "args": [
                        (v15/*: any*/),
                        {
                          "kind": "Literal",
                          "name": "where",
                          "value": {
                            "is_active": {
                              "_eq": true
                            }
                          }
                        }
                      ],
                      "concreteType": "task_signature",
                      "kind": "LinkedField",
                      "name": "task_signatures",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "signature_image_id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "user",
                          "kind": "LinkedField",
                          "name": "user",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "role",
                              "storageKey": null
                            },
                            (v7/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "general_contractor_employee",
                              "kind": "LinkedField",
                              "name": "employee",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "employee_title",
                                  "kind": "LinkedField",
                                  "name": "employee_title",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "employee_title_category",
                                      "kind": "LinkedField",
                                      "name": "employee_title_category",
                                      "plural": false,
                                      "selections": (v12/*: any*/),
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "task_signatures(order_by:{\"created_at\":\"desc\"},where:{\"is_active\":{\"_eq\":true}})"
                    }
                  ]
                },
                {
                  "condition": "includeLastUsed",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": "last_used",
                      "args": [
                        (v14/*: any*/),
                        (v15/*: any*/)
                      ],
                      "concreteType": "task",
                      "kind": "LinkedField",
                      "name": "report_tasks",
                      "plural": true,
                      "selections": (v16/*: any*/),
                      "storageKey": "report_tasks(limit:1,order_by:{\"created_at\":\"desc\"})"
                    }
                  ]
                },
                {
                  "condition": "includeFirstUsed",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": "first_used",
                      "args": [
                        (v14/*: any*/),
                        {
                          "kind": "Literal",
                          "name": "order_by",
                          "value": {
                            "created_at": "asc"
                          }
                        }
                      ],
                      "concreteType": "task",
                      "kind": "LinkedField",
                      "name": "report_tasks",
                      "plural": true,
                      "selections": (v16/*: any*/),
                      "storageKey": "report_tasks(limit:1,order_by:{\"created_at\":\"asc\"})"
                    }
                  ]
                },
                {
                  "condition": "includeProjectTaskCount",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "task",
                      "kind": "LinkedField",
                      "name": "project_tasks",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "project_id",
                          "storageKey": null
                        },
                        (v3/*: any*/),
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "includePpeCount",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "task_ppe_type_aggregate",
                      "kind": "LinkedField",
                      "name": "task_ppe_types_aggregate",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "task_ppe_type_aggregate_fields",
                          "kind": "LinkedField",
                          "name": "aggregate",
                          "plural": false,
                          "selections": (v13/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                },
                {
                  "condition": "includePermitsCount",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "task_permit_type_aggregate",
                      "kind": "LinkedField",
                      "name": "task_permit_types_aggregate",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "task_permit_type_aggregate_fields",
                          "kind": "LinkedField",
                          "name": "aggregate",
                          "plural": false,
                          "selections": (v13/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "e7fd713927f29c8e1615b4fcd4954fa5";

export default node;

/**
 * @generated SignedSource<<94ab1c0128293f92df644d9df979382c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_crew_onsite_update_insert_input, project_subcontractor_crew_report_settings_insert_input, project_subcontractor_onsite_update_insert_input, project_subcontractor_report_settings_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCDashAppReportSettingsOnDateInsertMutation$variables = {
  ProjSubCrewOnsiteUpdateObjects: ReadonlyArray<project_subcontractor_crew_onsite_update_insert_input>;
  ProjSubCrewReportSettingsObjects: ReadonlyArray<project_subcontractor_crew_report_settings_insert_input>;
  ProjSubOnsiteUpdateObjects: ReadonlyArray<project_subcontractor_onsite_update_insert_input>;
  ProjSubReportSettingsObjects: ReadonlyArray<project_subcontractor_report_settings_insert_input>;
};
export type GCDashAppReportSettingsOnDateInsertMutation$data = {
  readonly insert_project_subcontractor_crew_onsite_update: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_project_subcontractor_crew_report_settings: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_project_subcontractor_onsite_update: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly insert_project_subcontractor_report_settings: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type GCDashAppReportSettingsOnDateInsertMutation = {
  response: GCDashAppReportSettingsOnDateInsertMutation$data;
  variables: GCDashAppReportSettingsOnDateInsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ProjSubCrewOnsiteUpdateObjects"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ProjSubCrewReportSettingsObjects"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ProjSubOnsiteUpdateObjects"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ProjSubReportSettingsObjects"
},
v4 = [
  "type"
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v6 = [
  "safety_reports_required",
  "toolbox_talks_required",
  "daily_reports_required"
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "ProjSubOnsiteUpdateObjects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "project_subcontractor_onsite_update_project_id_subcontractor_id",
          "update_columns": (v4/*: any*/)
        }
      }
    ],
    "concreteType": "project_subcontractor_onsite_update_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_onsite_update",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "ProjSubCrewOnsiteUpdateObjects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "project_subcontractor_crew_onsite_update_project_crew_id_onsite",
          "update_columns": (v4/*: any*/)
        }
      }
    ],
    "concreteType": "project_subcontractor_crew_onsite_update_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_crew_onsite_update",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "ProjSubReportSettingsObjects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "project_subcontractor_report_settings_project_id_subcontractor_",
          "update_columns": (v6/*: any*/)
        }
      }
    ],
    "concreteType": "project_subcontractor_report_settings_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_report_settings",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "ProjSubCrewReportSettingsObjects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "project_subcontractor_crew_report_settings_project_crew_id_chan",
          "update_columns": (v6/*: any*/)
        }
      }
    ],
    "concreteType": "project_subcontractor_crew_report_settings_mutation_response",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_crew_report_settings",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCDashAppReportSettingsOnDateInsertMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCDashAppReportSettingsOnDateInsertMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "89a7727ae2f983a5d21219e6c6bc7e5c",
    "id": null,
    "metadata": {},
    "name": "GCDashAppReportSettingsOnDateInsertMutation",
    "operationKind": "mutation",
    "text": "mutation GCDashAppReportSettingsOnDateInsertMutation(\n  $ProjSubOnsiteUpdateObjects: [project_subcontractor_onsite_update_insert_input!]!\n  $ProjSubReportSettingsObjects: [project_subcontractor_report_settings_insert_input!]!\n  $ProjSubCrewOnsiteUpdateObjects: [project_subcontractor_crew_onsite_update_insert_input!]!\n  $ProjSubCrewReportSettingsObjects: [project_subcontractor_crew_report_settings_insert_input!]!\n) {\n  insert_project_subcontractor_onsite_update(objects: $ProjSubOnsiteUpdateObjects, on_conflict: {constraint: project_subcontractor_onsite_update_project_id_subcontractor_id, update_columns: [type]}) {\n    affected_rows\n  }\n  insert_project_subcontractor_crew_onsite_update(objects: $ProjSubCrewOnsiteUpdateObjects, on_conflict: {constraint: project_subcontractor_crew_onsite_update_project_crew_id_onsite, update_columns: [type]}) {\n    affected_rows\n  }\n  insert_project_subcontractor_report_settings(objects: $ProjSubReportSettingsObjects, on_conflict: {constraint: project_subcontractor_report_settings_project_id_subcontractor_, update_columns: [safety_reports_required, toolbox_talks_required, daily_reports_required]}) {\n    affected_rows\n  }\n  insert_project_subcontractor_crew_report_settings(objects: $ProjSubCrewReportSettingsObjects, on_conflict: {constraint: project_subcontractor_crew_report_settings_project_crew_id_chan, update_columns: [safety_reports_required, toolbox_talks_required, daily_reports_required]}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "132f7479b908b283f388d398ac267646";

export default node;

/**
 * @generated SignedSource<<d9166d53a0615aa1a590913bec612a34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_delivery_sub_block_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation$variables = {
  object: project_delivery_sub_block_insert_input;
};
export type SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation$data = {
  readonly insert_project_delivery_sub_block_one: {
    readonly calendars: ReadonlyArray<{
      readonly calendar: {
        readonly color_hex: string;
        readonly id: string;
        readonly name: {
          readonly en: string;
        } | null | undefined;
        readonly pk: string;
      } | null | undefined;
    }>;
    readonly end_time: string;
    readonly id: string;
    readonly pk: string;
    readonly start_time: string;
    readonly subcontractors: ReadonlyArray<{
      readonly subcontractor: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      } | null | undefined;
    }>;
    readonly weekday: number;
  } | null | undefined;
};
export type SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation = {
  response: SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation$data;
  variables: SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start_time",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end_time",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weekday",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_delivery_sub_block",
        "kind": "LinkedField",
        "name": "insert_project_delivery_sub_block_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_subcontractor",
            "kind": "LinkedField",
            "name": "subcontractors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_delivery_sub_block",
        "kind": "LinkedField",
        "name": "insert_project_delivery_sub_block_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "sub_block_subcontractor",
            "kind": "LinkedField",
            "name": "subcontractors",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "de1cc4a5d5728509573151477ef99287",
    "id": null,
    "metadata": {},
    "name": "SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation",
    "operationKind": "mutation",
    "text": "mutation SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation(\n  $object: project_delivery_sub_block_insert_input!\n) {\n  insert_project_delivery_sub_block_one(object: $object) {\n    id\n    pk: id\n    start_time\n    end_time\n    weekday\n    calendars {\n      calendar {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n        color_hex\n      }\n      id\n    }\n    subcontractors {\n      subcontractor {\n        id\n        pk: id\n        name\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6300482bb7599d036db8c052adf30cdb";

export default node;

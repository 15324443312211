import { FC, useMemo } from "react";
import {
  useGetProjectInfoForIncidentQuery,
  useUpdateIncidentMutation,
  Incident_Set_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSearchParams } from "react-router-dom";
import { Button, Card } from "antd";
import IncidentSelectField from "./basic/IncidentSelectField";
import createIncidentPatch from "../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../utils/IncidentTypeProps";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";

const IncidentProjectInfo: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();

  const { data: projectInfo } = useGetProjectInfoForIncidentQuery({
    variables: {
      projectId: incident.project_id,
    },
    fetchPolicy: "cache-first",
  });
  const projectAddressObject = projectInfo?.project.at(0)?.address;

  let projectAddress = "";
  if (projectAddressObject) {
    if (projectAddressObject.line_1) {
      projectAddress = projectAddressObject.line_1;
    }
    if (projectAddressObject.line_2) {
      projectAddress = `${projectAddress}, ${projectAddressObject.line_2}`;
    }
    if (projectAddressObject.zip_code) {
      projectAddress = `${projectAddress}, ${projectAddressObject.zip_code}`;
    }
    if (projectAddressObject.city) {
      projectAddress = `${projectAddress}, ${projectAddressObject.city}`;
    }
    if (projectAddressObject.state_code) {
      projectAddress = `${projectAddress}, ${projectAddressObject.state_code}`;
    }
  }
  const projectEmployeeOptions = useMemo(() => {
    const projectEmployees = projectInfo?.project
      .at(0)
      ?.project_employees.map((pe) => ({
        value: pe.employee.uid,
        label: pe.employee.user.name,
      }));

    return projectEmployees || [];
  }, [projectInfo]);

  const [updateIncident] = useUpdateIncidentMutation();

  const updateIncidentProjectInfo = async (
    _set: Omit<
      Incident_Set_Input,
      "id" | "project_id" | "created_at" | "description_id"
    >,
    comment: string,
  ) => {
    const updatedIncident = { ...incident, ..._set };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateIncident({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: {
          patch: patch,
          comment: comment,
          edited_by_uid: authUser.uid,
          edit_type: "project-info-edit",
          incident_id: incident.id,
        },
      },
      optimisticResponse: {
        update_incident_by_pk: {
          ...incident,
          ..._set,
          id: incident.id,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  return (
    <>
      <div className="absolute left-24 top-2 text-2">Project Details</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <div className="flex flex-col flex-1">
          <div className="top-5 flex flex-col">
            <Card className="w-4/5">
              <div className="flex gap-1 items-center">
                <span className="w-10">Project Name</span>
                <span className="w-full">
                  {projectInfo?.project.at(0)?.name}
                </span>
              </div>
            </Card>

            <Card className="w-4/5">
              <div className="flex gap-1 items-center">
                <span className="w-10">Project Address</span>
                <span className="w-full">{projectAddress}</span>
              </div>
            </Card>

            <Card className="w-4/5">
              <IncidentSelectField
                title="Project Executive"
                onChange={(option) => {
                  if (typeof option.value === "string")
                    updateIncidentProjectInfo(
                      { project_executive_id: option.value },
                      `Updated Project Executive to ${option.label}`,
                    );
                }}
                options={projectEmployeeOptions}
                value={incident?.project_executive?.uid}
              />
            </Card>

            <Card className="w-4/5">
              <IncidentSelectField
                title="EH&S Area Supervisor"
                onChange={(option) => {
                  if (typeof option.value === "string")
                    updateIncidentProjectInfo(
                      { area_supervisor_id: option.value },
                      `Updated EH&S Area Supervisor to ${option.label}`,
                    );
                }}
                options={projectEmployeeOptions}
                value={incident?.area_supervisor?.uid}
              />
            </Card>

            <Card className="w-4/5">
              <IncidentSelectField
                title="Project Superintendent"
                onChange={(option) => {
                  if (typeof option.value === "string")
                    updateIncidentProjectInfo(
                      { project_superintendent_id: option.value },
                      `Updated Project Superintendent to ${option.label}`,
                    );
                }}
                options={projectEmployeeOptions}
                value={incident?.project_superintendent?.uid}
              />
            </Card>

            <Card className="w-4/5">
              <IncidentSelectField
                title="Project Safety Manager"
                onChange={(option) => {
                  if (typeof option.value === "string")
                    updateIncidentProjectInfo(
                      { project_safety_manager_id: option.value },
                      `Updated Project Safety manager to ${option.label}`,
                    );
                }}
                options={projectEmployeeOptions}
                value={incident?.project_safety_manager?.uid}
              />
            </Card>

            <div className="flex w-4/5 justify-between mt-2">
              <Button
                className="w-6"
                type="primary"
                htmlType="submit"
                onClick={() => setSearchParams({ subview: "incident_detail" })}
              >
                Save
              </Button>
              <Button
                className="w-6"
                type="primary"
                htmlType="submit"
                onClick={() => setSearchParams({ subview: "incident_detail" })}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withCustomSuspense(IncidentProjectInfo);

import { useMemo, useState } from "react";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import { GCAdminRadioFilterProps } from "../component/filter-inputs/GCAdminRadioFilter";
import {
  GetAdminObservationOptionsQuery,
  GetIncidentReportAndChartOptionsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import { GCAdminSelectFilterProps } from "../component/filter-inputs/GCAdminSelectFilter";

const useGetGCAdminCommonFilters = (
  options:
    | GetAdminObservationOptionsQuery
    | GetIncidentReportAndChartOptionsQuery
    | undefined,
) => {
  const [projectStatusFilter, setProjectStatus] = useState<string | undefined>(
    "all",
  );
  const { userData } = useUserData();

  const [projectsFilter, handleProjectChange, setProjectsFilter] =
    useSelectedIndicies<string>();
  const [officesFilter, handleOfficeChange, setOfficesFilter] =
    useSelectedIndicies<string>();
  const [divisionsFilter, handleDivisionChange, setDivisionsFilter] =
    useSelectedIndicies<string>();
  const [
    businessUnitsFilter,
    handleBusinessUnitChange,
    setBusinessUnitsFilter,
  ] = useSelectedIndicies<string>();

  const filteredProjOptions = useMemo(
    () =>
      (options?.project || []).filter(
        (proj) =>
          !projectStatusFilter ||
          (projectStatusFilter === "active" && !proj.completed) ||
          (projectStatusFilter === "completed" && proj.completed) ||
          projectStatusFilter === "all",
      ),
    [options?.project, projectStatusFilter],
  );
  const emp = userData.employee;
  if (!emp) throw new Error("Employee not found");
  const gc = emp.general_contractor;
  const radioFilters: GCAdminRadioFilterProps[] = [
    {
      defaultVal: "all",
      selectedItem: projectStatusFilter,
      handleItemChange: setProjectStatus,
      title: "Project Status",
      options: [
        { label: "All", value: "all" },
        { label: "Active", value: "active" },
        { label: "Completed", value: "completed" },
      ],
    },
  ];
  const divisions = options?.gc_division || [];
  const businessUnits = options?.gc_business_unit || [];
  const offices = options?.gc_office || [];
  const selectFilters: GCAdminSelectFilterProps[] = [
    {
      options: filteredProjOptions.map((p) => ({
        value: p.id,
        label: p.name,
      })),
      title: "Projects",
      placeholder: "All Projects Included",
      selectedItems: projectsFilter,
      handleItemChange: handleProjectChange,
      setSelectedItems: setProjectsFilter,
    },
  ];
  if (divisions.length && gc.hierarchy_division_name) {
    selectFilters.push({
      options: divisions.map((p) => ({
        value: p.id,
        label: p.name,
      })),
      title: gc.hierarchy_division_name,
      placeholder: `All ${gc.hierarchy_division_name} Included`,
      selectedItems: divisionsFilter,
      handleItemChange: handleDivisionChange,
      setSelectedItems: setDivisionsFilter,
    });
  }
  if (businessUnits.length && gc.hierarchy_business_unit_name) {
    selectFilters.push({
      options: businessUnits.map((p) => ({
        value: p.id,
        label: p.name,
      })),
      title: gc.hierarchy_business_unit_name,
      placeholder: `All ${gc.hierarchy_business_unit_name} Included`,
      selectedItems: businessUnitsFilter,
      handleItemChange: handleBusinessUnitChange,
      setSelectedItems: setBusinessUnitsFilter,
    });
  }
  if (offices.length && gc.hierarchy_office_name) {
    selectFilters.push({
      options: offices.map((p) => ({
        value: p.id,
        label: p.name,
      })),
      title: gc.hierarchy_office_name,
      placeholder: `All ${gc.hierarchy_office_name} Included`,
      selectedItems: officesFilter,
      handleItemChange: handleOfficeChange,
      setSelectedItems: setOfficesFilter,
    });
  }

  return {
    selectFilters,
    radioFilters,
    projectStatusFilter,
    projectsFilter,
    officesFilter,
    divisionsFilter,
    businessUnitsFilter,
  };
};
export default useGetGCAdminCommonFilters;

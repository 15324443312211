/**
 * @generated SignedSource<<4bee276eeb9383d9a506e8805980f5ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type GCProjectsQuery$variables = {
  uid: string;
};
export type GCProjectsQuery$data = {
  readonly general_contractor_employee_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly employee_projects: ReadonlyArray<{
          readonly project: {
            readonly completed: boolean;
            readonly name: string;
            readonly pk: string;
          };
        }>;
      };
    }>;
  };
};
export type GCProjectsQuery = {
  response: GCProjectsQuery$data;
  variables: GCProjectsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "uid"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "uid"
          }
        ],
        "kind": "ObjectValue",
        "name": "uid"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "general_contractor_employeeConnection",
        "kind": "LinkedField",
        "name": "general_contractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_employee",
                    "kind": "LinkedField",
                    "name": "employee_projects",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "pk",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__id_pk",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "general_contractor_employeeConnection",
        "kind": "LinkedField",
        "name": "general_contractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_employee",
                    "kind": "LinkedField",
                    "name": "employee_projects",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": [
                          {
                            "alias": "pk",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": "pk",
                            "args": null,
                            "filters": null,
                            "handle": "pk",
                            "key": "",
                            "kind": "ScalarHandle",
                            "name": "id"
                          },
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "011a22c304025943e9acec5674778997",
    "id": null,
    "metadata": {},
    "name": "GCProjectsQuery",
    "operationKind": "query",
    "text": "query GCProjectsQuery(\n  $uid: uuid!\n) {\n  general_contractor_employee_connection(where: {uid: {_eq: $uid}}) {\n    edges {\n      node {\n        employee_projects {\n          project {\n            pk: id\n            completed\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ca0227b2d92353b3445fe3e160bb1fb";

export default node;

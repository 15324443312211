/**
 * @generated SignedSource<<46af60d37c843d5319f6af8c20e9c190>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { orientation_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectOrientationDetail_DeleteOrientation_Mutation$variables = {
  _set: orientation_set_input;
  orientationId: string;
};
export type GCProjectOrientationDetail_DeleteOrientation_Mutation$data = {
  readonly update_orientation_by_pk: {
    readonly deleted_at: string | null | undefined;
    readonly id: string;
    readonly pk: string;
  } | null | undefined;
};
export type GCProjectOrientationDetail_DeleteOrientation_Mutation = {
  response: GCProjectOrientationDetail_DeleteOrientation_Mutation$data;
  variables: GCProjectOrientationDetail_DeleteOrientation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orientationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "orientationId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted_at",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectOrientationDetail_DeleteOrientation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "orientation",
        "kind": "LinkedField",
        "name": "update_orientation_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectOrientationDetail_DeleteOrientation_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "orientation",
        "kind": "LinkedField",
        "name": "update_orientation_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3bb3c93524d00d2b6e768f98a8ed645f",
    "id": null,
    "metadata": {},
    "name": "GCProjectOrientationDetail_DeleteOrientation_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectOrientationDetail_DeleteOrientation_Mutation(\n  $orientationId: uuid!\n  $_set: orientation_set_input!\n) {\n  update_orientation_by_pk(pk_columns: {id: $orientationId}, _set: $_set) {\n    id\n    pk: id\n    deleted_at\n  }\n}\n"
  }
};
})();

(node as any).hash = "2b0f4ffb56fa31b752b21f6680883aa9";

export default node;

const filterOnProjectAndHierarchy = (
  project: {
    id: string;
    completed: boolean;
    gc_office_id?: string | null;
    gc_business_unit_id?: string | null;
    gc_division_id?: string | null;
  },
  currentFilters: {
    projectStatusFilter?: string | undefined;
    projectsFilter?: Set<string>;
    businessUnitsFilter?: Set<string>;
    divisionsFilter?: Set<string>;
    officesFilter?: Set<string>;
  },
) => {
  const projStatusFiltering =
    !currentFilters.projectStatusFilter ||
    (currentFilters.projectStatusFilter === "active" && !project.completed) ||
    (currentFilters.projectStatusFilter === "completed" && project.completed) ||
    currentFilters.projectStatusFilter === "all";
  return (
    projStatusFiltering &&
    (!currentFilters.officesFilter?.size ||
      (project.gc_office_id &&
        currentFilters.officesFilter.has(project.gc_office_id))) &&
    (!currentFilters.divisionsFilter?.size ||
      (project.gc_division_id &&
        currentFilters.divisionsFilter.has(project.gc_division_id))) &&
    (!currentFilters.businessUnitsFilter?.size ||
      (project.gc_business_unit_id &&
        currentFilters.businessUnitsFilter.has(project.gc_business_unit_id))) &&
    (!currentFilters.projectsFilter?.size ||
      currentFilters.projectsFilter.has(project.id))
  );
};
export default filterOnProjectAndHierarchy;

import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  notification,
  Select,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import TextareaAutosize from "react-textarea-autosize";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import * as uuid from "uuid";
import {
  EmailReceipentsInput,
  EmailRequestType,
  GcUserType,
  useEmailFlaggedJhaForEditMutation,
  useEmailJhaForReviewMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import useFlagOrUnflagTask from "src/common/api/relay/mutationHooks/useFlagOrUnflagTask";
import { GetJHAApprovalModalDataQuery$data } from "src/common/types/generated/relay/GetJHAApprovalModalDataQuery.graphql";
import {
  SendTaskForApprovalModalMutation,
  SendTaskForApprovalModalMutation$data,
} from "src/common/types/generated/relay/SendTaskForApprovalModalMutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import CustomButton from "src/common/components/general/Button";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

type UserType = Array<{
  name: string;
  email: string;
  id?: string;
  anonymousId?: number;
}>;

interface Props {
  taskId: string | undefined;
  isNotTurnersProject: boolean;
  visible: boolean;
  onClose: () => void;
  data: GetJHAApprovalModalDataQuery$data;
  type: "flag-jha-request" | "approval-request" | "review-request";
}

const insertTaskEditMutation = graphql`
  mutation SendTaskForApprovalModalMutation(
    $object: [task_edit_insert_input!]!
  ) {
    insert_task_edit(objects: $object) {
      returning {
        id
        task_id
        comment
        edited_by_uid
      }
    }
  }
`;

const SendTaskForApprovalModal: React.FC<Props> = ({
  taskId,
  isNotTurnersProject,
  visible,
  data,
  onClose,
  type,
}) => {
  const [subAdminOptions, setSubAdminOptions] = useState<
    Array<{
      id?: string;
      anonymousId?: number;
      name: string;
      email: string;
      role: "worker" | "subAdmin";
    }>
  >([]);
  const [form] = Form.useForm();

  const [insertTaskEdit] = useAsyncMutation<SendTaskForApprovalModalMutation>(
    insertTaskEditMutation,
  );

  const [flagOrUnflagJHA] = useFlagOrUnflagTask();

  const [emailJHAForReview, { loading: emailingJHAForReview }] =
    useEmailJhaForReviewMutation();

  const [emailFlaggedJHAForEdit, { loading: emailingFlaggedJHAForEdit }] =
    useEmailFlaggedJhaForEditMutation();

  const onRequestSubmit = async () => {
    const selectedUsers = form.getFieldsValue().subsToReview;
    const comment = form.getFieldsValue().comment;
    let emailRecipients: Array<EmailReceipentsInput> = [];
    selectedUsers.forEach((user: string | number) => {
      if (type !== "review-request") {
        subAdminOptions.forEach((option) => {
          if (user === option.id || user === option.anonymousId) {
            emailRecipients.push({
              email: option.email,
              userId: option.id ? option.id : null,
              requestId: uuid.v4(),
            });
          }
        });
      } else {
        gcUsers.forEach((option) => {
          if (
            user == option.node.employee.user.pk &&
            option.node.employee.user.email
          ) {
            emailRecipients.push({
              email: option.node.employee.user.email,
              userId: option.node.employee.user.pk,
              gcUserType:
                option.node.employee.employee_title?.employee_title_category
                  ?.name.en === "Safety"
                  ? GcUserType.Safety
                  : GcUserType.Operations,
              requestId: uuid.v4(),
            });
          }
        });
      }
    });

    if (typeof taskId === "string") {
      try {
        console.log("EMAIL RECIPIENTS", emailRecipients);
        if (type === "flag-jha-request") {
          await flagOrUnflagJHA(taskId, "flag");
        }

        await insertTaskEdit({
          variables: {
            object: [
              {
                task_id: taskId,
                edit_type:
                  type === "flag-jha-request"
                    ? "Flagged for action"
                    : type === "review-request"
                    ? "Requested Team Review"
                    : "Requested for approval",
                edited_by_uid: auth.currentUser?.uid,
                comment: comment,
              },
            ],
          },
          updater: (
            store: RecordSourceSelectorProxy<SendTaskForApprovalModalMutation$data>,
          ) => {
            const insertTaskEdit = store.getRootField("insert_task_edit");
            const flaggedEditConn = ConnectionHandler.getConnection(
              store.getRoot(),
              "ViewAndEditJha_flaggedEdit",
            );
            if (flaggedEditConn) {
              insertTaskEdit.getLinkedRecords("returning").forEach((c) => {
                const edge = store.create(uuid.v4(), "edge");
                edge.setLinkedRecord(c, "node");
                ConnectionHandler.insertEdgeBefore(flaggedEditConn, edge);
              });
            }
          },
        });

        if (type === "flag-jha-request") {
          await emailFlaggedJHAForEdit({
            variables: {
              input: {
                comment: comment,
                isTurner: !isNotTurnersProject,
                taskId: taskId,
                emailRecipients: emailRecipients,
              },
            },
          });
        } else {
          console.log("EMAIL RECIPIENTS", emailRecipients);
          await emailJHAForReview({
            variables: {
              input: {
                comment: comment,
                requestType: EmailRequestType.ApprovalRequest,
                isTurner: !isNotTurnersProject,
                taskId: taskId,
                emailRecipients: emailRecipients,
              },
            },
          })
            .then((data) => console.log(".......DATA....", data))
            .catch((err) => console.log(".......ERROR....", err));
        }
        message.success("Email Sent");
      } catch (err) {
        notification.error({
          message: "Couldn't send request",
          description: `ERROR: ${
            err instanceof Error ? err.message : JSON.stringify(err)
          }`,
        });
      }
      onClose();
    }
    form.resetFields();
  };

  const subAdmins = data.subcontractor_employee_connection.edges;
  const workers = data.project_worker_connection.edges;
  const prevEmailReq = data.email_request_connection.edges;

  const prevEmailReqFiltering = (
    type: "flag-jha-request" | "approval-request" | "review-request",
  ) => {
    if (type === "review-request")
      return prevEmailReq.filter(
        (req) =>
          req.node.type === "operations-review-request" ||
          req.node.type === "safety-review-request",
      );
    else return prevEmailReq.filter((req) => req.node.type === type);
  };
  const gcUsers = data.project_employee_connection.edges;

  let cnt = 0;

  const distinctInitialVal: UserType = [];

  prevEmailReq.forEach((req) => {
    if (
      subAdminOptions.findIndex(
        (val) => val.email === req.node.email || val.id === req.node.user_id,
      ) === -1
    ) {
      if (req.node.user_id) {
        distinctInitialVal.push({
          id: req.node.user_id,
          name: req.node.user?.name ?? req.node.email,
          email: req.node.email,
        });

        subAdminOptions.push({
          id: req.node.user_id,
          name: req.node.user?.name ?? req.node.email,
          email: req.node.email,
          role: "subAdmin",
        });
      } else {
        distinctInitialVal.push({
          anonymousId: ++cnt,
          name: req.node.user?.name ?? req.node.email,
          email: req.node.email,
        });

        subAdminOptions.push({
          anonymousId: cnt,
          name: req.node.user?.name ?? req.node.email,
          email: req.node.email,
          role: "subAdmin",
        });
      }
    }
  });

  subAdmins.forEach((sub) => {
    if (
      subAdminOptions.findIndex(
        (option) =>
          option.email === sub.node.user.email ||
          option.id === sub.node.user_id,
      ) === -1 &&
      sub.node.user.email
    ) {
      subAdminOptions.push({
        id: sub.node.user_id,
        email: sub.node.user.email,
        name: sub.node.user.name,
        role: "subAdmin",
      });
    }
  });

  workers?.forEach((worker) => {
    if (
      worker &&
      worker.node.user?.email &&
      subAdminOptions.findIndex(
        (option) =>
          option.email == worker.node.user?.email ||
          option.id === worker.node.worker_id,
      ) == -1
    ) {
      subAdminOptions.push({
        id: worker.node.worker_id,
        email: worker.node.user.email,
        name: worker.node.user.name,
        role: "worker",
      });
    }
  });

  subAdminOptions.sort((a, b) => {
    if (a.role == b.role) return a.name.localeCompare(b.name);
    return a.role.localeCompare(b.role);
  });

  const [newEmail, setNewEmail] = useState("");

  const [anonymousEmail, setAnonymousEmail] = useState(0);

  const onAddSub = () => {
    if (newEmail && newEmail.length > 0) {
      const anonymousUser: (typeof subAdminOptions)[0] = {
        anonymousId: anonymousEmail,
        email: newEmail,
        name: newEmail,
        role: "subAdmin",
      };
      setAnonymousEmail(anonymousEmail + 1);

      setSubAdminOptions([anonymousUser, ...subAdminOptions]);
    }
    setNewEmail("");
  };
  return (
    <Modal
      title={
        type === "flag-jha-request"
          ? "Flagged for Action: Request Edits"
          : type === "approval-request"
          ? "Request Subcontractor(s) to Approve this JHA"
          : "Request Team Member(s) to Review this JHA"
      }
      open={visible}
      okText="Send"
      onCancel={() => {
        onClose();
        form.resetFields();
      }}
      onOk={() => {
        onRequestSubmit();
      }}
      destroyOnClose
    >
      {prevEmailReqFiltering(type).length > 0 && (
        <div className="mb-1">
          <CustomButton
            tiny
            label="Sent Previously to"
            onClick={() => console.log("")}
            hint={
              <div className="w-14">
                {prevEmailReqFiltering(type).map((req, i) => (
                  <div className="mt-0.25 " key={i}>
                    <div className="inline-flex">
                      <div className="min-w-8 max-w-8">
                        {req.node.user?.name}
                      </div>
                      <div className="ml-3">
                        <CustomButton
                          label="Resend"
                          tiny
                          onClick={async () => {
                            try {
                              message.loading({
                                content: "Sending Email",
                                duration: 0,
                              });

                              if (type === "flag-jha-request") {
                                await emailFlaggedJHAForEdit({
                                  variables: {
                                    input: {
                                      isTurner: !isNotTurnersProject,
                                      taskId: taskId!,
                                      comment: "",
                                      emailRecipients: [
                                        {
                                          email: req.node.email,
                                          requestId: uuid.v4(),
                                          userId: req.node.user_id,
                                        },
                                      ],
                                    },
                                  },
                                });
                              } else {
                                await emailJHAForReview({
                                  variables: {
                                    input: {
                                      isTurner: !isNotTurnersProject,
                                      taskId: taskId!,
                                      comment: "",
                                      requestType:
                                        type === "review-request"
                                          ? EmailRequestType.ReviewRequest
                                          : EmailRequestType.ApprovalRequest,
                                      emailRecipients: [
                                        {
                                          email: req.node.email,
                                          requestId: uuid.v4(),
                                          userId: req.node.user_id,
                                        },
                                      ],
                                    },
                                  },
                                });
                              }
                              message.destroy();
                              message.success("Email Sent");
                            } catch (err) {
                              message.error({
                                content: "Error while inserting email" + err,
                              });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
          />
        </div>
      )}

      <Form form={form} layout="vertical" name="form_in_modal">
        <Form.Item
          name="subsToReview"
          label={
            type === "flag-jha-request"
              ? "Select who to send this email to"
              : type === "approval-request"
              ? "Select who to send this JHA Approval Request to"
              : "Select who to send this JHA Review Request to"
          }
          initialValue={
            type === "flag-jha-request"
              ? distinctInitialVal.map((val) => val.id ?? val.anonymousId)
              : []
          }
        >
          <Select
            mode={"multiple"}
            placeholder={
              type === "review-request"
                ? "Select team members for Review"
                : "Select Subcontractor Admins and/or Workers"
            }
            className="w-70"
            showSearch
            filterOption={getNormalSelectOptionsFilter}
          >
            {type === "review-request" ? (
              gcUsers.map((user) => (
                <Select.Option
                  key={user.node.pk}
                  value={user.node.employee.user.pk}
                  label={user.node.employee.user.name}
                >
                  {user.node.employee.user.name}
                </Select.Option>
              ))
            ) : (
              <Select.OptGroup key={0} label={"Sub Admins"}>
                {subAdminOptions
                  .filter((user) => user.role === "subAdmin")
                  .map((s) => (
                    <Select.Option
                      key={s.id ?? s.anonymousId}
                      value={s.id ? s.id : s.anonymousId!}
                      label={s.name}
                    >
                      <div className="flex flex-col">{s.name}</div>
                    </Select.Option>
                  ))}
              </Select.OptGroup>
            )}

            {type !== "review-request" && (
              <Select.OptGroup key={1} label={"Workers"}>
                {subAdminOptions
                  .filter((user) => user.role === "worker")
                  .map((s) => (
                    <Select.Option
                      key={s.id ?? s.anonymousId}
                      value={s.id ?? s.anonymousId!}
                      label={s.name}
                    >
                      {s.name}
                    </Select.Option>
                  ))}
              </Select.OptGroup>
            )}
          </Select>
        </Form.Item>

        {type !== "review-request" && (
          <div className="ml-2">
            <div className="text-sm text-grey-500">
              Not listed above? Enter their email address and select Add
            </div>

            <div className="flex items-center">
              <Input
                placeholder="Enter an email address"
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                className="w-55 h-30 mt-0.5"
                onKeyDown={(e) => {
                  if (e.key === "Enter") onAddSub();
                }}
              />
              <Button
                type="primary"
                onClick={onAddSub}
                className="ml-1 h-90 leading-90 rounded-2"
              >
                Add
              </Button>
            </div>

            <p className="text-lightgrey">
              After adding, be sure to select their email address from the list
              above
            </p>
          </div>
        )}

        <Form.Item
          className="mt-2"
          name="comment"
          label="Add a message to include in the Review Request email"
        >
          <TextareaAutosize
            placeholder={
              type === "flag-jha-request"
                ? `List reason(s) this JHA was Flagged for Action
Example: "This JHA is missing critical steps to safely complete the work.
Please update it to clearly identify the scope of work and all hazards/controls to perform the work safely. Thanks!"`
                : type === "review-request"
                ? `Include context or information that will help review this JHA. 
For example: “Please review this JHA to make sure it includes the info we discussed with the sub”`
                : `Any context or information to help them approve this JHA,
For example... "please review and approve this JHA, I added a step for unloading and staging materials"`
            }
            className="p-1 w-full min-h-8 text-gray-500 border-suplementary-2 outline-none border-0.125 flex-1"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default withCustomSuspense(SendTaskForApprovalModal);

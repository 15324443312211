/**
 * @generated SignedSource<<1168197a25fc6b06f8618f94da366986>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SubProjectMobilizationStepJhaQuery$variables = {
  projectId: string;
  subcontractorId: string;
};
export type SubProjectMobilizationStepJhaQuery$data = {
  readonly permit_type_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
  };
  readonly ppe_type_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
  };
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly general_contractor: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
        readonly name: string;
      };
    }>;
  };
  readonly project_subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly project: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
      };
    }>;
  };
  readonly task_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly task_edit: ReadonlyArray<{
          readonly edited_at: string;
          readonly task_patch: any | null | undefined;
        }>;
        readonly task_signatures: ReadonlyArray<{
          readonly created_at: string;
          readonly signature_image_id: string | null | undefined;
        }>;
      };
    }>;
  };
};
export type SubProjectMobilizationStepJhaQuery = {
  response: SubProjectMobilizationStepJhaQuery$data;
  variables: SubProjectMobilizationStepJhaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subcontractorId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "name": {
        "en": "asc"
      }
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v4/*: any*/)
    ],
    "storageKey": null
  }
],
v6 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "projectId"
  }
],
v7 = [
  {
    "fields": [
      {
        "fields": (v6/*: any*/),
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "subcontractorId"
    }
  ],
  "kind": "ObjectValue",
  "name": "subcontractor_id"
},
v10 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "project": {
        "name": "asc"
      }
    }
  },
  {
    "fields": [
      (v9/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v11 = {
  "_is_null": true
},
v12 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "deleted_at",
        "value": (v11/*: any*/)
      },
      {
        "fields": (v6/*: any*/),
        "kind": "ObjectValue",
        "name": "project_id"
      },
      {
        "kind": "Literal",
        "name": "report_id",
        "value": (v11/*: any*/)
      },
      (v9/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v13 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "edited_at": "desc"
    }
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "task_patch",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "edited_at",
  "storageKey": null
},
v16 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "created_at": "desc"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "is_active": {
        "_eq": true
      }
    }
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signature_image_id",
  "storageKey": null
},
v19 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v20 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v21 = [
  (v2/*: any*/),
  (v19/*: any*/),
  (v20/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubProjectMobilizationStepJhaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ppe_typeConnection",
        "kind": "LinkedField",
        "name": "ppe_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ppe_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ppe_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "ppe_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor",
                    "kind": "LinkedField",
                    "name": "general_contractor",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "permit_typeConnection",
        "kind": "LinkedField",
        "name": "permit_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "permit_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "permit_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "permit_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "concreteType": "task_edit",
                    "kind": "LinkedField",
                    "name": "task_edit",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": "task_edit(order_by:{\"edited_at\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": (v16/*: any*/),
                    "concreteType": "task_signature",
                    "kind": "LinkedField",
                    "name": "task_signatures",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      (v18/*: any*/)
                    ],
                    "storageKey": "task_signatures(order_by:{\"created_at\":\"desc\"},where:{\"is_active\":{\"_eq\":true}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubProjectMobilizationStepJhaQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ppe_typeConnection",
        "kind": "LinkedField",
        "name": "ppe_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ppe_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ppe_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v21/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "ppe_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "projectConnection",
        "kind": "LinkedField",
        "name": "project_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "projectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor",
                    "kind": "LinkedField",
                    "name": "general_contractor",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "permit_typeConnection",
        "kind": "LinkedField",
        "name": "permit_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "permit_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "permit_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v21/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "permit_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v2/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "concreteType": "task_edit",
                    "kind": "LinkedField",
                    "name": "task_edit",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": "task_edit(order_by:{\"edited_at\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": (v16/*: any*/),
                    "concreteType": "task_signature",
                    "kind": "LinkedField",
                    "name": "task_signatures",
                    "plural": true,
                    "selections": [
                      (v17/*: any*/),
                      (v18/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": "task_signatures(order_by:{\"created_at\":\"desc\"},where:{\"is_active\":{\"_eq\":true}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b5f87f360b247c999644dc96b503b9a9",
    "id": null,
    "metadata": {},
    "name": "SubProjectMobilizationStepJhaQuery",
    "operationKind": "query",
    "text": "query SubProjectMobilizationStepJhaQuery(\n  $projectId: uuid!\n  $subcontractorId: uuid!\n) {\n  ppe_type_connection(order_by: {name: {en: asc}}) {\n    edges {\n      node {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n      }\n    }\n  }\n  project_connection(where: {id: {_eq: $projectId}}) {\n    edges {\n      node {\n        name\n        general_contractor {\n          id\n          pk: id\n          name\n        }\n        id\n      }\n    }\n  }\n  permit_type_connection(order_by: {name: {en: asc}}) {\n    edges {\n      node {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n      }\n    }\n  }\n  project_subcontractor_connection(where: {subcontractor_id: {_eq: $subcontractorId}}, order_by: {project: {name: asc}}) {\n    edges {\n      node {\n        project {\n          name\n          id\n          pk: id\n        }\n        id\n      }\n    }\n  }\n  task_connection(where: {project_id: {_eq: $projectId}, subcontractor_id: {_eq: $subcontractorId}, deleted_at: {_is_null: true}, report_id: {_is_null: true}}) {\n    edges {\n      node {\n        id\n        pk: id\n        task_edit(order_by: {edited_at: desc}) {\n          task_patch\n          edited_at\n          id\n        }\n        task_signatures(where: {is_active: {_eq: true}}, order_by: {created_at: desc}) {\n          created_at\n          signature_image_id\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0269e17c06e1f19c3df46b159a8154d8";

export default node;

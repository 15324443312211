/**
 * @generated SignedSource<<867b3f2bec81f5f4155bfda51f6cf257>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SelectWhoWorkerCanAssignTodoMutation$variables = {
  projectEmployeesAssignTrue: ReadonlyArray<string>;
  projectId: string;
};
export type SelectWhoWorkerCanAssignTodoMutation$data = {
  readonly setFalse: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly worker_can_assign_todo: boolean;
    }>;
  } | null | undefined;
  readonly setTrue: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly worker_can_assign_todo: boolean;
    }>;
  } | null | undefined;
};
export type SelectWhoWorkerCanAssignTodoMutation = {
  response: SelectWhoWorkerCanAssignTodoMutation$data;
  variables: SelectWhoWorkerCanAssignTodoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectEmployeesAssignTrue"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "project_employee",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "worker_can_assign_todo",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = [
  {
    "alias": "setTrue",
    "args": [
      {
        "kind": "Literal",
        "name": "_set",
        "value": {
          "worker_can_assign_todo": true
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "projectEmployeesAssignTrue"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_employee_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_employee",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  },
  {
    "alias": "setFalse",
    "args": [
      {
        "kind": "Literal",
        "name": "_set",
        "value": {
          "worker_can_assign_todo": false
        }
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_nin",
                "variableName": "projectEmployeesAssignTrue"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "projectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "project_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_employee_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_employee",
    "plural": false,
    "selections": (v1/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectWhoWorkerCanAssignTodoMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectWhoWorkerCanAssignTodoMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "81d57d8102309be31565d6646a7790fd",
    "id": null,
    "metadata": {},
    "name": "SelectWhoWorkerCanAssignTodoMutation",
    "operationKind": "mutation",
    "text": "mutation SelectWhoWorkerCanAssignTodoMutation(\n  $projectEmployeesAssignTrue: [uuid!]!\n  $projectId: uuid!\n) {\n  setTrue: update_project_employee(where: {id: {_in: $projectEmployeesAssignTrue}}, _set: {worker_can_assign_todo: true}) {\n    returning {\n      id\n      worker_can_assign_todo\n    }\n  }\n  setFalse: update_project_employee(where: {id: {_nin: $projectEmployeesAssignTrue}, project_id: {_eq: $projectId}}, _set: {worker_can_assign_todo: false}) {\n    returning {\n      id\n      worker_can_assign_todo\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b0d48112afc31249f972c95192a30826";

export default node;

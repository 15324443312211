/**
 * @generated SignedSource<<1963f899673005c5c2ca84f1f8aa27f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { document_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddNewPlanDocModal_InsertNewDoc_Mutation$variables = {
  objects: ReadonlyArray<document_insert_input>;
};
export type AddNewPlanDocModal_InsertNewDoc_Mutation$data = {
  readonly insert_document: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null | undefined;
      readonly pk: string;
      readonly sort_index: number;
      readonly url: string;
    }>;
  } | null | undefined;
};
export type AddNewPlanDocModal_InsertNewDoc_Mutation = {
  response: AddNewPlanDocModal_InsertNewDoc_Mutation$data;
  variables: AddNewPlanDocModal_InsertNewDoc_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sort_index",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNewPlanDocModal_InsertNewDoc_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "document_mutation_response",
        "kind": "LinkedField",
        "name": "insert_document",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "document",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddNewPlanDocModal_InsertNewDoc_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "document_mutation_response",
        "kind": "LinkedField",
        "name": "insert_document",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "document",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0999ae3869ffa487dd7634f4106f262b",
    "id": null,
    "metadata": {},
    "name": "AddNewPlanDocModal_InsertNewDoc_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddNewPlanDocModal_InsertNewDoc_Mutation(\n  $objects: [document_insert_input!]!\n) {\n  insert_document(objects: $objects) {\n    returning {\n      url\n      pk: id\n      id\n      sort_index\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4f15764a9c2d5d534fc11d5b360c2748";

export default node;

import React, { useEffect, useRef, useState } from "react";
import { InspectionTemplateType } from "../tables/InspectionTemplateTable";
import { message, notification } from "antd";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import Tag from "src/common/components/general/Tag";
import {
  InspectionChecklistItemProps,
  InspectionInsertValues,
  OptionType,
} from "../../utils/siteInspectionTypes";
import AddInspectionChecklistItemModal from "../modals/AddInspectionChecklistItemModal";
import { generalChecklistType } from "../../utils/generalChecklistType";
import noop from "src/common/functions/noop";
import { reorderArrayInt } from "src/common/functions/reorderRecords";
import InspectionChecklistItemTypeTable from "../tables/InspectionChecklistItemTypeTable";
import * as uuid from "uuid";
import Button from "src/common/components/general/Button";
import useCreateInspectionInstanceFromTemplate from "../../utils/useCreateInspectionInstanceFromTemplate";
import { IconPencil, IconPlus } from "@tabler/icons";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import { graphql } from "babel-plugin-relay/macro";
import EditInspectionFields from "../EditInspectionFields";
import dayjs from "dayjs";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  InspectionTemplateEditMutation,
} from "src/common/types/generated/relay/InspectionTemplateEditMutation.graphql";
import {
  inspection_checklist_item_type_insert_input,
  inspection_template_checklist_item_insert_input,
  inspection_label_insert_input,
} from "src/common/types/generated/relay/types";

import compareTwoLists from "src/common/components/ComparingTwoLists";
import { getCommomChecklistItemObject } from "../../utils/getCommonChecklistItemObject";
import capitalize from "src/common/functions/capitalize";
import { ConnectionHandler } from "relay-runtime";

interface InspectionTemplateProps {
  data: InspectionTemplateType;
  visible: boolean;
  labelOptions: Array<OptionType>;
  projectId: string;
  onClose: () => void;
}

const mutation = graphql`
  mutation InspectionTemplateEditMutation(
    $nameIdsToUpdate: [uuid!]!
    $newName: String!
    $now: timestamptz!
    $templateId: uuid!
    $toInsertInspectionChecklistItemTypes: [inspection_checklist_item_type_insert_input!]!
    $templateSet: inspection_template_set_input!
    $instanceSet: inspection_instance_set_input!
    $toDeleteLabels: [uuid!]!
    $addLabelObjects: [inspection_label_insert_input!]!
    $toDeleteTemplateChecklistItemIds: [uuid!]!
    $toInsertTemplateChecklistItemObjects: [inspection_template_checklist_item_insert_input!]!
  ) {
    update_text_translation(
      where: { id: { _in: $nameIdsToUpdate } }
      _set: { original: $newName, en: $newName }
    ) {
      returning {
        en
        id
      }
    }

    update_inspection_instance(
      where: {
        inspection_template_id: { _eq: $templateId }
        deleted_at: { _is_null: true }
      }
      _set: $instanceSet
    ) {
      returning {
        id
        model_number
        model_number_required
        image_required
        pk: id @__clientField(handle: "pk")
      }
    }
    insert_inspection_checklist_item_type(
      objects: $toInsertInspectionChecklistItemTypes
      on_conflict: {
        constraint: inspection_checklist_item_type_pkey
        update_columns: []
      }
    ) {
      returning {
        id
        text {
          en
        }
      }
    }
    insert_inspection_label(
      objects: $addLabelObjects
      on_conflict: {
        constraint: inspection_label_inspection_instance_id_label_id_key
        update_columns: []
      }
    ) {
      returning {
        id
        label_id
      }
    }
    delete_inspection_label(
      where: {
        label_id: { _in: $toDeleteLabels }
        _or: [
          { inspection_template_id: { _eq: $templateId } }
          {
            inspection_instance: {
              deleted_at: { _is_null: true }
              inspection_template_id: { _eq: $templateId }
            }
          }
        ]
      }
    ) {
      returning {
        id
      }
    }
    update_inspection_template_checklist_item(
      where: { id: { _in: $toDeleteTemplateChecklistItemIds } }
      _set: { deleted_at: $now }
    ) {
      returning {
        id
        deleted_at
      }
    }
    insert_inspection_template_checklist_item(
      objects: $toInsertTemplateChecklistItemObjects
    ) {
      returning {
        ...InspectionTemplateChecklistItemFrag @relay(mask: false)
      }
    }
    update_inspection_instance_checklist_item(
      where: {
        deleted_at: { _is_null: true }
        inspection_template_checklist_item_id: {
          _in: $toDeleteTemplateChecklistItemIds
        }
      }
      _set: { deleted_at: $now }
    ) {
      returning {
        id
        deleted_at
      }
    }
    update_inspection_template_by_pk(
      pk_columns: { id: $templateId }
      _set: $templateSet
    ) {
      ...InspectionTemplateFrag @relay(mask: false)
    }
  }
`;

interface TemplateItemType extends InspectionChecklistItemProps {
  inspection_instance_checklist_items: InspectionTemplateType["inspection_template_checklist_items"][number]["inspection_instance_checklist_items"];
}

const setChecklistItems = (data: InspectionTemplateType) => {
  const returnObj: {
    [type: string]: {
      [id: string]: TemplateItemType;
    };
  } = {};
  data.inspection_template_checklist_items.forEach((cli) => {
    const typeKey = cli.inspection_checklist_item_type
      ? cli.inspection_checklist_item_type.text.en
      : generalChecklistType;
    const obj: TemplateItemType = {
      id: cli.pk,
      item_id: cli.item_id,
      sort_index: cli.sort_index,
      description: cli.description.en,
      correct_answer: cli.correct_answer === "yes" ? "yes" : "no",
      notify_on_deficient: cli.notify_on_deficient,
      item_type_label: cli.inspection_checklist_item_type?.text?.en,
      type: typeKey,
      show_na: cli.show_na,
      require_photo: cli.require_photo,
      inspection_instance_checklist_items:
        cli.inspection_instance_checklist_items,
    };

    returnObj[typeKey] = {
      ...(returnObj[typeKey] ?? {}),
      [cli.pk]: obj,
    };
  });
  return returnObj;
};
const InspectionTemplate: React.FC<InspectionTemplateProps> = ({
  data,
  visible,
  projectId,
  labelOptions,
  onClose,
}) => {
  const [currentTemplateChecklistItems, setCurrentTemplateChecklistItems] =
    useState<{
      [type: string]: { [id: string]: InspectionChecklistItemProps };
    }>({});
  const initialTemplateChecklistItems = setChecklistItems(data);
  const companyTemplate = !!data.general_contractor_id;
  const [newChecklistItem, setNewChecklistItem] = useState(false);
  const [editInspection] =
    useAsyncMutation<InspectionTemplateEditMutation>(mutation);
  const [loading, setLoading] = useState(false);

  const [editing, setEditing] = useState(false);
  const modal = useRef<FModalRef<InspectionInsertValues>>(null);
  useEffect(() => {
    setCurrentTemplateChecklistItems(setChecklistItems(data));
  }, [data]);
  const [insertInspection, isInserting] =
    useCreateInspectionInstanceFromTemplate(projectId);
  const [allowChecklistItemPhoto, setAllowChecklistItemPhoto] = useState(
    data.allow_photo_to_checklist_item,
  );
  useEffect(() => {
    if (modal.current && editing) {
      setAllowChecklistItemPhoto(
        !!modal.current.form.getFieldValue("allow_photo_to_checklist_item"),
      );
    } else if (!editing) {
      setAllowChecklistItemPhoto(data.allow_photo_to_checklist_item);
    }
  }, [
    modal.current?.form.getFieldValue("allow_photo_to_checklist_item"),
    editing,
    data,
  ]);
  const onSave = async () => {
    if (editing) {
      setLoading(true);
      try {
        const values = await modal.current?.form.validateFields();
        if (!values?.name) {
          throw new Error("Name not found");
        }
        if (values.model_number && values.model_number_required)
          throw new Error(
            "You can only either enter a defauly model number or require inspector to enter,not both, please try again",
          );
        const checklistItemTypeToIdMap: { [key: string]: string } = {};
        data.inspection_template_checklist_items.forEach((item) => {
          if (item.inspection_checklist_item_type)
            checklistItemTypeToIdMap[
              item.inspection_checklist_item_type.text.en
            ] = item.inspection_checklist_item_type.pk;
          item.inspection_instance_checklist_items.map((inst) => {
            if (item.inspection_checklist_item_type)
              checklistItemTypeToIdMap[
                item.inspection_checklist_item_type.text.en +
                  "_" +
                  inst.inspection_instance_id
              ] = item.inspection_checklist_item_type.pk;
          });
        });
        const [toInsertLabels, toDeleteLabels] = compareTwoLists(
          values.labels ?? [],
          data.inspection_labels.map((p) => p.label_id),
        );
        const toInsertInspectionChecklistItemTypes: inspection_checklist_item_type_insert_input[] =
          [];
        const toInsertTemplateChecklistItemObjects: Array<inspection_template_checklist_item_insert_input> =
          [];
        const toDeleteTemplateChecklistItemIds: Array<string> = [];
        if (
          JSON.stringify(currentTemplateChecklistItems) !==
          JSON.stringify(initialTemplateChecklistItems)
        ) {
          Object.entries(currentTemplateChecklistItems).forEach(
            ([type, checklistItems]) => {
              if (
                type !== generalChecklistType &&
                !checklistItemTypeToIdMap[type]
              ) {
                checklistItemTypeToIdMap[type] = uuid.v4();
                data.inspection_instances.forEach((inst) => {
                  if (!checklistItemTypeToIdMap[type + "_" + inst.pk])
                    checklistItemTypeToIdMap[type + "_" + inst.pk] = uuid.v4();
                });
              }
              const checklistItemTypeId =
                type !== generalChecklistType
                  ? checklistItemTypeToIdMap[type]
                  : null;
              const toInsertTemplate: (input: {
                checklistItem: InspectionChecklistItemProps;
                inspInstanceItems?: InspectionTemplateType["inspection_template_checklist_items"][number]["inspection_instance_checklist_items"];
                inspInstances?: InspectionTemplateType["inspection_instances"];
              }) => inspection_template_checklist_item_insert_input = ({
                checklistItem,
                inspInstanceItems,
                inspInstances,
              }) => ({
                ...getCommomChecklistItemObject(checklistItem, true),
                item_id: checklistItem.item_id ?? uuid.v4(),
                inspection_template_id: data.pk,
                ...(checklistItemTypeId
                  ? {
                      inspection_checklist_item_type_id: checklistItemTypeId,
                    }
                  : {}),
                inspection_instance_checklist_items: {
                  data: inspInstances
                    ? inspInstances.map((inst) => ({
                        ...getCommomChecklistItemObject(checklistItem),
                        item_id: uuid.v4(),
                        inspection_instance_id: inst.pk,
                        ...(checklistItemTypeId
                          ? {
                              inspection_checklist_item_type_id:
                                checklistItemTypeToIdMap[type + "_" + inst.pk],
                            }
                          : {}),
                      }))
                    : (inspInstanceItems || []).map((inst) => ({
                        ...getCommomChecklistItemObject(checklistItem),
                        item_id: inst.item_id,
                        inspection_instance_id: inst.inspection_instance_id,
                        inspection_checklist_emails: {
                          data: inst.inspection_checklist_emails.map((u) => ({
                            user_id: u.user_id,
                          })),
                        },
                        ...(checklistItemTypeId
                          ? {
                              inspection_checklist_item_type_id:
                                checklistItemTypeToIdMap[
                                  type + "_" + inst.inspection_instance_id
                                ],
                            }
                          : {}),
                      })),
                },
              });
              if (!initialTemplateChecklistItems[type]) {
                Object.values(checklistItems).forEach((checklistItem) => {
                  toInsertTemplateChecklistItemObjects.push(
                    toInsertTemplate({
                      checklistItem,
                      inspInstances: data.inspection_instances,
                    }),
                  );
                });
              } else {
                Object.entries(checklistItems).forEach(
                  ([id, currentChecklistItem]) => {
                    const initialChecklistItem =
                      initialTemplateChecklistItems[type][id];
                    if (!initialChecklistItem?.id) {
                      toInsertTemplateChecklistItemObjects.push(
                        toInsertTemplate({
                          checklistItem: currentChecklistItem,
                          inspInstances: data.inspection_instances,
                        }),
                      );
                    } else {
                      if (
                        initialChecklistItem.description !==
                          currentChecklistItem.description ||
                        initialChecklistItem.correct_answer !==
                          currentChecklistItem.correct_answer ||
                        initialChecklistItem.require_photo !==
                          currentChecklistItem.require_photo ||
                        initialChecklistItem.notify_on_deficient !==
                          currentChecklistItem.notify_on_deficient ||
                        initialChecklistItem.show_na !==
                          currentChecklistItem.show_na ||
                        (initialChecklistItem.notifyees || [])
                          .sort((a, b) => a.localeCompare(b))
                          .join(", ") !==
                          (currentChecklistItem.notifyees || [])
                            .sort((a, b) => a.localeCompare(b))
                            .join(", ")
                      ) {
                        toInsertTemplateChecklistItemObjects.push(
                          toInsertTemplate({
                            checklistItem: currentChecklistItem,
                            inspInstanceItems:
                              initialChecklistItem.inspection_instance_checklist_items,
                          }),
                        );
                        toDeleteTemplateChecklistItemIds.push(
                          initialChecklistItem.id,
                        );
                      }
                    }
                  },
                );
              }
            },
          );
          Object.entries(initialTemplateChecklistItems).forEach(
            ([type, checklistItems]) => {
              if (!currentTemplateChecklistItems[type]) {
                Object.keys(checklistItems).forEach((id) =>
                  toDeleteTemplateChecklistItemIds.push(id),
                );
              } else {
                Object.keys(checklistItems).forEach((id) => {
                  if (!currentTemplateChecklistItems[type][id]) {
                    toDeleteTemplateChecklistItemIds.push(id);
                  }
                });
              }
            },
          );

          Object.keys(currentTemplateChecklistItems)
            .filter((type) => type !== generalChecklistType)
            .forEach((type) => {
              toInsertInspectionChecklistItemTypes.push({
                id: checklistItemTypeToIdMap[type],
                text: {
                  data: {
                    original: capitalize(type),
                    en: capitalize(type),
                  },
                },
              });
              data.inspection_instances.forEach((inst) => {
                toInsertInspectionChecklistItemTypes.push({
                  id: checklistItemTypeToIdMap[type + "_" + inst.pk],
                  text: {
                    data: {
                      original: capitalize(type),
                      en: capitalize(type),
                    },
                  },
                });
              });
            });
        }
        const addLabelObjects: inspection_label_insert_input[] = [];
        toInsertLabels.forEach((labelId) => {
          addLabelObjects.push({
            inspection_template_id: data.pk,
            label_id: labelId,
          });
          data.inspection_instances.forEach((inst) =>
            addLabelObjects.push({
              inspection_instance_id: inst.pk,
              label_id: labelId,
            }),
          );
        });

        const setModelNubmer = values.model_number_required
          ? null
          : values.model_number && values.model_number.trim()
          ? values.model_number.trim()
          : null;
        const setModelNubmerReq = values.is_multi_inspection
          ? true
          : values.model_number_required;
        const inspSet = {
          model_number: setModelNubmer,
          model_number_required: setModelNubmerReq,
          is_multi_inspection: values.is_multi_inspection,
          allow_photo_to_checklist_item: values.allow_photo_to_checklist_item,
          image_required: values.image_required,
        };
        await editInspection({
          // console.log({
          variables: {
            templateId: data.pk,
            addLabelObjects,
            toDeleteLabels,
            templateSet: inspSet,
            instanceSet: inspSet,
            newName: values.name,
            toInsertInspectionChecklistItemTypes,
            nameIdsToUpdate: [
              data.name_id,
              ...data.inspection_instances.map((p) => p.name_id),
            ],
            now: dayjs().toISOString(),
            toDeleteTemplateChecklistItemIds,
            toInsertTemplateChecklistItemObjects,
          },
          updater: (store) => {
            const conn = ConnectionHandler.getConnection(
              store.getRoot(),
              "InspectionTemplateTable_inspection_template_connection",
            );
            const updatedInsp = store.getRootField(
              "update_inspection_template_by_pk",
            );
            if (conn) {
              const edges = conn.getLinkedRecords("edges");

              if (edges) {
                const edgeIndex = edges.findIndex((edge) => {
                  const node = edge.getLinkedRecord("node");
                  return node?.getValue("id") === data.id;
                });
                const edge = store.create(uuid.v4(), "edge");
                edge.setLinkedRecord(updatedInsp, "node");
                edges[edgeIndex] = edge;
                conn.setLinkedRecords(edges, "edges");
              }
            }
          },
        });
        setEditing(false);
        onClose();
        message.success("Successfully Edited Inspection");
      } catch (err) {
        console.error(err);
        notification.error({
          description: "Inspection could not be created",
          message: err instanceof Error ? err.message : JSON.stringify(err),
        });
      }
      setLoading(false);
    }
  };
  return (
    <FModal
      ref={modal}
      zIndex={10}
      width={900}
      title={"Inspection Details"}
      okText="Save"
      onOk={onSave}
      confirmLoading={loading}
      okButtonProps={{ hidden: !editing }}
      open={visible}
      cancelText={editing ? "Cancel" : "Close"}
      onCancel={() => {
        if (editing) {
          setEditing(false);
        } else onClose();
      }}
    >
      <div className="flex justify-end gap-0.5">
        <BPopconfirm
          title={
            <div>
              Are you sure you want to add this <br />
              Inspection to this project?
            </div>
          }
          onConfirm={async (e) => {
            setLoading(true);
            try {
              await insertInspection(data);
              message.success(
                `Successfully added ${data.name.en} to your Library`,
              );
            } catch (err) {
              console.log(err);
              message.error(
                `Error: Couldn't add ${data.name.en} to your Library`,
              );
            }
            setLoading(false);
          }}
        >
          <Button
            label="Add to project"
            onClick={() => {}}
            icon={IconPlus}
            loading={loading}
          />
        </BPopconfirm>
        {companyTemplate && !editing && (
          <Button
            label={"Edit"}
            icon={IconPencil}
            loading={loading}
            onClick={() => setEditing(true)}
          />
        )}
      </div>

      {editing ? (
        <EditInspectionFields
          form={modal.current?.form}
          labelOptions={labelOptions}
          onAllowItemPhotoChange={(checked) =>
            setAllowChecklistItemPhoto(checked)
          }
          template={companyTemplate}
          inspectionQROptions={[]}
          initialValues={{
            name: data.name.en,
            is_multi_inspection: data.is_multi_inspection,
            allow_photo_to_checklist_item: data.allow_photo_to_checklist_item,
            image_required: data.image_required,
            model_number: data.model_number ?? undefined,
            model_number_required: data.model_number_required,
            labels: data.inspection_labels.map((p) => p.label_id),
          }}
        />
      ) : (
        <>
          <div className="flex mt-1">
            <span className="font-accent">Inspection: &nbsp;&nbsp;</span>
            {data.name.en}
          </div>
          {data.model_number && (
            <div className="flex mt-1">
              <span className="font-accent">Model Number: &nbsp;&nbsp;</span>
              {data.model_number}
            </div>
          )}
          <div className="mt-1">
            Multi-Inspection: {data.is_multi_inspection ? "ON" : "OFF"}
          </div>

          {!data.is_multi_inspection && (
            <div className="mt-1">
              Require Model Number: {data.model_number_required ? "ON" : "OFF"}
            </div>
          )}
          <div className="mt-1">
            Require to Add Image(s): {data.image_required ? "ON" : "OFF"}
          </div>
          <div className="mt-1">
            Allow adding photos to checklist items:{" "}
            {data.allow_photo_to_checklist_item ? "ON" : "OFF"}
          </div>
          {data.inspection_labels.length > 0 && (
            <div className="mt-1">
              Inspection Labels:
              <div className="flex flex-wrap gap-0.5 ml-1">
                {data.inspection_labels.map((p) => (
                  <Tag key={p.label_id} status="pending">
                    {p.label.label_name}
                  </Tag>
                ))}
              </div>
            </div>
          )}
        </>
      )}

      <div className="font-accent my-1">
        Questions ({data.inspection_template_checklist_items.length})
      </div>
      {Object.entries(currentTemplateChecklistItems).map(
        ([type, checklistItemTypeObject]) => {
          return (
            <div key={type}>
              <InspectionChecklistItemTypeTable
                allowChecklistItemPhoto={allowChecklistItemPhoto}
                dataSource={Object.values(checklistItemTypeObject)}
                type={type}
                excludedKeys={
                  companyTemplate
                    ? ["notify_on_deficient"]
                    : ["notify_on_deficient", "action"]
                }
                projectEmployees={[]}
                editing={editing && companyTemplate}
                onDeleteItem={(item) => {
                  item.id
                    ? setCurrentTemplateChecklistItems((prev) => {
                        const newObj = { ...prev };
                        if (Object.entries(newObj[type]).length <= 1)
                          delete newObj[type];
                        else {
                          delete newObj[type][item.id!];
                        }
                        return newObj;
                      })
                    : noop();
                }}
                onEditOrEditDoneItem={(newItem, prevItem) => {
                  if (newItem?.id === prevItem.id) {
                    setCurrentTemplateChecklistItems((prev) => ({
                      ...prev,
                      [type]: {
                        ...prev[type],
                        [prevItem.id!]: { ...newItem },
                      },
                    }));
                  }
                }}
                onAddNewItem={(item, type) => {
                  const checklistItemId = uuid.v4();
                  setCurrentTemplateChecklistItems((prev) => ({
                    ...prev,
                    [type]: {
                      ...(prev[type] ?? {}),
                      [checklistItemId]: {
                        ...item,
                        item_type_label:
                          type !== generalChecklistType ? type : undefined,
                        id: checklistItemId,
                        sort_index: Object.keys(prev[type] ?? {}).length + 1,
                      },
                    },
                  }));
                }}
                onMoveRow={(dragIndex, hoverIndex) =>
                  setCurrentTemplateChecklistItems((prev) => {
                    const list = Object.values(prev[type]);
                    const newList =
                      reorderArrayInt<InspectionChecklistItemProps>(
                        list,
                        dragIndex,
                        hoverIndex,
                      );
                    return {
                      ...prev,
                      [type]: Object.fromEntries(
                        newList.map((val) => [val.id, val]),
                      ),
                    };
                  })
                }
              />
            </div>
          );
        },
      )}
      <AddInspectionChecklistItemModal
        allowChecklistItemPhoto={allowChecklistItemPhoto}
        projectEmployees={[]}
        checklistItemTypes={Object.keys(currentTemplateChecklistItems).filter(
          (p) => p != generalChecklistType,
        )}
        hideNotifyees={true}
        visible={newChecklistItem}
        onCancel={() => setNewChecklistItem(false)}
        onCreate={(values, creatingAnother) => {
          const typeKey = values.item_type_label
            ? values.item_type_label
            : generalChecklistType;
          const checklistItemId = uuid.v4();
          setCurrentTemplateChecklistItems((prev) => ({
            ...prev,
            [typeKey]: {
              ...(prev[typeKey] ?? {}),
              [checklistItemId]: {
                ...values,
                id: checklistItemId,
                sort_index: Object.keys(prev[typeKey] ?? {}).length + 1,
              },
            },
          }));
          if (!creatingAnother) {
            setNewChecklistItem(false);
          }
        }}
      />
      {editing && (
        <Button
          label="Add checklist items"
          onClick={() => setNewChecklistItem(true)}
        />
      )}
    </FModal>
  );
};
export default InspectionTemplate;

import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitGcProjectSettingCheckbox from "./basic/PermitGcProjectSettingCheckbox";
import ShowPermitChecklistsTables from "./basic/ShowPermitChecklistsTables";
import StyledContent from "src/common/components/layouts/StyledContent";
import { Checkbox } from "antd";
import {
  useUpdateGeneralContractorForPermitMutation,
  useUpdateProjectSettingByPkForPermitMutation,
} from "src/common/types/generated/apollo/graphQLTypes";

const OutOfBasketSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  data,
  checklistGc,
  mainGc,
  project,
}) => {
  const projSetting = project?.project_setting;
  const settingUseLevel = projSetting?.permit_setting_level;
  const [updateProjectSetting] = useUpdateProjectSettingByPkForPermitMutation();
  const [updateGeneralContractor] =
    useUpdateGeneralContractorForPermitMutation();
  const requireOnlySubmitter =
    projSetting && settingUseLevel === "project"
      ? projSetting.only_submitter_sign_the_out_of_basket_permit
      : mainGc.only_submitter_sign_the_out_of_basket_permit;
  return (
    <>
       <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: projSetting,
          field: 'only_submitter_sign_the_out_of_basket_permit',
          label:
          "Check to only require the person submitting the permit (foreman/competent person) to sign. When unchecked, both the Foreman and Onsite Safety Manager are required to sign",
        }}
      />
      {!requireOnlySubmitter && (
        <StyledContent>
          <Checkbox
            checked={
              projSetting && settingUseLevel === "project"
                ? projSetting.require_workers_to_sign_out_of_basket_permit
                : mainGc.require_workers_to_sign_out_of_basket_permit
            }
            disabled={settingUseLevel !== "project" && !!projSetting}
            onChange={async (e) => {
              const checked = e.target.checked;
              const toSet = {
                require_workers_to_sign_out_of_basket_permit: checked,
              };
              if (projSetting && settingUseLevel === "project") {
                updateProjectSetting({
                  variables: {
                    project_id: projSetting.project_id,
                    _set: toSet,
                  },
                  optimisticResponse: {
                    update_project_setting_by_pk: {
                      ...projSetting,
                      ...toSet,
                    },
                  },
                });
              } else {
                updateGeneralContractor({
                  variables: { gcId: mainGc.id, _set: toSet },
                  optimisticResponse: {
                    update_general_contractor_by_pk: {
                      ...mainGc,
                      ...toSet,
                    },
                  },
                });
              }
            }}
          >
            Require Workers Exiting Basket to sign the permit
          </Checkbox>
        </StyledContent>
      )}
      <ShowPermitChecklistsTables
        {...{
          checklistGc,
          data,
          project,
          gcPermitCheckListItems,
          queryVariables,
          checklistItemsType: "out_of_basket",
        }}
      />
    </>
  );
};
export default OutOfBasketSettings;

/**
 * @generated SignedSource<<873ee962f5d1f024eef23ab9749f25a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Worker_UpdateReceiveTextSetting_Mutation$variables = {
  projectId: string;
  projectWorkerId: string;
  value: boolean;
  workerId: string;
};
export type Worker_UpdateReceiveTextSetting_Mutation$data = {
  readonly update_project_worker: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_project_worker_by_pk: {
    readonly id: string;
    readonly receive_text_message: boolean;
  } | null | undefined;
};
export type Worker_UpdateReceiveTextSetting_Mutation = {
  response: Worker_UpdateReceiveTextSetting_Mutation$data;
  variables: Worker_UpdateReceiveTextSetting_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectWorkerId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerId"
},
v4 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "receive_text_message",
      "variableName": "value"
    }
  ],
  "kind": "ObjectValue",
  "name": "_set"
},
v5 = [
  {
    "alias": null,
    "args": [
      (v4/*: any*/),
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "projectWorkerId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "project_worker",
    "kind": "LinkedField",
    "name": "update_project_worker_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "receive_text_message",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v4/*: any*/),
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_neq",
                "variableName": "projectWorkerId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "projectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "project_id"
          },
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "workerId"
              }
            ],
            "kind": "ObjectValue",
            "name": "worker_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_worker_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_worker",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Worker_UpdateReceiveTextSetting_Mutation",
    "selections": (v5/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "Worker_UpdateReceiveTextSetting_Mutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "b373b71ad41d4bed87f3a445d24276c1",
    "id": null,
    "metadata": {},
    "name": "Worker_UpdateReceiveTextSetting_Mutation",
    "operationKind": "mutation",
    "text": "mutation Worker_UpdateReceiveTextSetting_Mutation(\n  $value: Boolean!\n  $projectId: uuid!\n  $workerId: uuid!\n  $projectWorkerId: uuid!\n) {\n  update_project_worker_by_pk(pk_columns: {id: $projectWorkerId}, _set: {receive_text_message: $value}) {\n    id\n    receive_text_message\n  }\n  update_project_worker(where: {project_id: {_eq: $projectId}, worker_id: {_eq: $workerId}, id: {_neq: $projectWorkerId}}, _set: {receive_text_message: $value}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "0d16c9d7ca834877d74c46a728724ac4";

export default node;

/**
 * @generated SignedSource<<488ebf79ed28fd0c9ad0477111c99134>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VisitorSettings_UpdateWaiverText_Mutation$variables = {
  projectId: string;
  waiverText: string;
};
export type VisitorSettings_UpdateWaiverText_Mutation$data = {
  readonly update_project: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type VisitorSettings_UpdateWaiverText_Mutation = {
  response: VisitorSettings_UpdateWaiverText_Mutation$data;
  variables: VisitorSettings_UpdateWaiverText_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "waiverText"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "visitor_waiver_text",
            "variableName": "waiverText"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "projectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_mutation_response",
    "kind": "LinkedField",
    "name": "update_project",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VisitorSettings_UpdateWaiverText_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VisitorSettings_UpdateWaiverText_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e01ed3e6c0d3b336d975c950177719fe",
    "id": null,
    "metadata": {},
    "name": "VisitorSettings_UpdateWaiverText_Mutation",
    "operationKind": "mutation",
    "text": "mutation VisitorSettings_UpdateWaiverText_Mutation(\n  $projectId: uuid!\n  $waiverText: String!\n) {\n  update_project(where: {id: {_eq: $projectId}}, _set: {visitor_waiver_text: $waiverText}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "0f9e2f2c9681eb99eb67d443ac01b075";

export default node;

/**
 * @generated SignedSource<<9032e403cb1fee16172069fbcbab2e01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { logistic_plan_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddNewPlanDocModal_InsertLogisticPlan_Mutation$variables = {
  object: logistic_plan_insert_input;
};
export type AddNewPlanDocModal_InsertLogisticPlan_Mutation$data = {
  readonly insert_logistic_plan_one: {
    readonly created_at: string;
    readonly created_by_uid: string;
    readonly created_by_user: {
      readonly employee: {
        readonly employee_title: {
          readonly name: {
            readonly en: string;
          };
        } | null | undefined;
      } | null | undefined;
      readonly name: string;
    };
    readonly documents: ReadonlyArray<{
      readonly id: string;
      readonly name: string | null | undefined;
      readonly pk: string;
      readonly sort_index: number;
      readonly url: string;
    }>;
    readonly id: string;
    readonly name: string;
    readonly pk: string;
  } | null | undefined;
};
export type AddNewPlanDocModal_InsertLogisticPlan_Mutation = {
  response: AddNewPlanDocModal_InsertLogisticPlan_Mutation$data;
  variables: AddNewPlanDocModal_InsertLogisticPlan_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_by_uid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "sort_index": "asc"
    }
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sort_index",
  "storageKey": null
},
v11 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddNewPlanDocModal_InsertLogisticPlan_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "logistic_plan",
        "kind": "LinkedField",
        "name": "insert_logistic_plan_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "created_by_user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "employee",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "employee_title",
                    "kind": "LinkedField",
                    "name": "employee_title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "document",
            "kind": "LinkedField",
            "name": "documents",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v7/*: any*/),
              (v6/*: any*/),
              (v10/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "documents(order_by:{\"sort_index\":\"asc\"})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddNewPlanDocModal_InsertLogisticPlan_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "logistic_plan",
        "kind": "LinkedField",
        "name": "insert_logistic_plan_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "created_by_user",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "employee",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "employee_title",
                    "kind": "LinkedField",
                    "name": "employee_title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": (v8/*: any*/),
            "concreteType": "document",
            "kind": "LinkedField",
            "name": "documents",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v6/*: any*/),
              (v10/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": "documents(order_by:{\"sort_index\":\"asc\"})"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8f212323002d3bd0c7b89685fe1e2f8c",
    "id": null,
    "metadata": {},
    "name": "AddNewPlanDocModal_InsertLogisticPlan_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddNewPlanDocModal_InsertLogisticPlan_Mutation(\n  $object: logistic_plan_insert_input!\n) {\n  insert_logistic_plan_one(object: $object) {\n    created_by_uid\n    name\n    created_by_user {\n      name\n      employee {\n        employee_title {\n          name {\n            en\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    created_at\n    id\n    pk: id\n    documents(order_by: {sort_index: asc}) {\n      url\n      pk: id\n      id\n      sort_index\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "84f5a3e441f6e9d23afd3ed9ffc854e5";

export default node;

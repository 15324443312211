/**
 * @generated SignedSource<<02052c51c8a80dafe5e4527e7adf24f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SFVerifyProfilePhotoReasonsQuery$variables = Record<PropertyKey, never>;
export type SFVerifyProfilePhotoReasonsQuery$data = {
  readonly reason_to_reject_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly reason: string;
        readonly type: string;
      };
    }>;
  };
};
export type SFVerifyProfilePhotoReasonsQuery = {
  response: SFVerifyProfilePhotoReasonsQuery$data;
  variables: SFVerifyProfilePhotoReasonsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "reason": "asc"
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reason",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SFVerifyProfilePhotoReasonsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "reason_to_rejectConnection",
        "kind": "LinkedField",
        "name": "reason_to_reject_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "reason_to_rejectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "reason_to_reject",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "reason_to_reject_connection(order_by:{\"reason\":\"asc\"})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SFVerifyProfilePhotoReasonsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "reason_to_rejectConnection",
        "kind": "LinkedField",
        "name": "reason_to_reject_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "reason_to_rejectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "reason_to_reject",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "reason_to_reject_connection(order_by:{\"reason\":\"asc\"})"
      }
    ]
  },
  "params": {
    "cacheID": "c037be2b479a12dace347448196e2462",
    "id": null,
    "metadata": {},
    "name": "SFVerifyProfilePhotoReasonsQuery",
    "operationKind": "query",
    "text": "query SFVerifyProfilePhotoReasonsQuery {\n  reason_to_reject_connection(order_by: {reason: asc}) {\n    edges {\n      node {\n        id\n        pk: id\n        reason\n        type\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "00846f4e8f1541f21eec67463ee731b8";

export default node;

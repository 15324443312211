import React from "react";
import { Form, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { SelectLocalCityQuery } from "src/common/types/generated/relay/SelectLocalCityQuery.graphql";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

const query = graphql`
  query SelectLocalCityQuery($stateCode: String!) {
    city_connection(
      where: { state_code: { _eq: $stateCode } }
      order_by: { name: asc }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
  }
`;
const SelectLocalCity: React.FC<{
  stateCode: string;
  required?: boolean;
  label: string;
  loading?: boolean;
}> = ({ stateCode, required, loading, label }) => {
  const data = useLazyLoadQuery<SelectLocalCityQuery>(query, { stateCode });
  return (
    <Form.Item
      label={label}
      name="local_cities"
      required={required}
      key={stateCode}
    >
      <Select
        loading={loading}
        showSearch
        filterOption={getNormalSelectOptionsFilter}
        options={data.city_connection.edges.map((ci) => ({
          value: ci.node.pk,
          label: ci.node.name,
        }))}
        mode="multiple"
      />
    </Form.Item>
  );
};
export default withCustomSuspense(SelectLocalCity);

import { Navigate, Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import NestedRoute from "src/common/types/manual/NestedRoute";
import GCDivisions from "./GCDivisions";
import GCDivisionTeam from "./team/GCDivisionTeam";
import GCDivision from "./GCDivision";
import GCDivisionProjects from "./projects/GCDivisionProjects";
import GCDivisionDetail from "./detail/GCDivisionDetail";
import GCDivisionWorkers from "./worker/GCDivisionWorkers";
import GCDivisionBUs from "./sub-levels/GCDivisionBUs";
import GCDivisionOffices from "./sub-levels/GCDivisionOffices";
import GCDivisionIncidents from "./incident/GCDivisionIncidents";
import GCDivisionObservations from "./observation/GCDivisionObservations";

const gcDivisionRoute: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole="employee">
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [
    {
      path: "",
      element: <GCDivisions />,
      children: [
        {
          path: ":divisionId",
          element: <GCDivision />,
          children: [
            { path: "", element: <Navigate to={"projects"} /> },
            // { path: "details", element: },
            { path: "teams", element: <GCDivisionTeam /> },
            { path: "workers", element: <GCDivisionWorkers /> },
            { path: "projects", element: <GCDivisionProjects /> },
            { path: "settings", element: <GCDivisionDetail /> },
            { path: "incidents", element: <GCDivisionIncidents /> },
            {
              path: "observations",
              element: <GCDivisionObservations />,
            },
            { path: "offices", element: <GCDivisionOffices /> },
            { path: "business-units", element: <GCDivisionBUs /> },
          ],
        },
      ],
    },
  ],
};
export default gcDivisionRoute;

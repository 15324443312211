import React, { useState } from "react";
import SiteOrientationInPersonManagerViewWorkersResultSublist from "./SiteOrientationInPersonManagerViewWorkersResultSublist";
import dayjs from "dayjs";
import { UploadResult } from "src/common/functions/upload-utility/uploadImage";
import { InsertCertificatesToVerifyMutation } from "src/common/types/generated/relay/InsertCertificatesToVerifyMutation.graphql";
import insertCertificatesToVerifyMutate from "src/common/api/relay/mutations/InsertCertificatesToVerify";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { useSearchParams } from "react-router-dom";
import { useGetSiteOrientationInPersonWorkerListQuery$data } from "src/common/types/generated/relay/useGetSiteOrientationInPersonWorkerListQuery.graphql";
import getCorporateCompletionMap from "../../../utils/getCorporateCompletionMap";

export type WorkerType = {
  name: string;
  imgSrc?: string;
  hardHatNumber?: number | string | null;
  trade?: string;
  company: { title: string; id: string };
  corporateOrientationPassed: boolean;
  universalCompleted: boolean;
  id: string;
};

export interface SiteOrientationInPersonManagerViewWorkersResultProps {
  data: useGetSiteOrientationInPersonWorkerListQuery$data;
  projectId: string;
}

type project_worker =
  useGetSiteOrientationInPersonWorkerListQuery$data["project_worker_connection"]["edges"][0]["node"];
type project_employee =
  useGetSiteOrientationInPersonWorkerListQuery$data["project_employee_connection"]["edges"][0]["node"];

const SiteOrientationInPersonManagerViewWorkersResult: React.FC<
  SiteOrientationInPersonManagerViewWorkersResultProps
> = ({ data, projectId }) => {
  const workers: { [userId: string]: project_worker } = {};
  (data.project_worker_connection.edges || []).forEach((v) => {
    if (!workers[v.node.worker_id]) {
      workers[v.node.worker_id] = v.node;
    }
  });
  const [searchParams] = useSearchParams();
  const completionType = searchParams.get("completionType");
  const projectData = data.project_connection.edges[0].node;
  const projectHasUniversal =
    projectData.agc_universal_orientation &&
    data.universalOrientations.edges.length > 0;
  const gcUsers: { [userId: string]: project_employee } = {};
  data.project_employee_connection.edges.forEach((v) => {
    if (!gcUsers[v.node.employee_id]) {
      gcUsers[v.node.employee_id] = v.node;
    }
  });
  const [currentUploads, setcurrentUploads] = useState<{
    [key: string]: UploadResult;
  }>({});
  const [insertImageToVerify, isInserting] =
    useAsyncMutation<InsertCertificatesToVerifyMutation>(
      insertCertificatesToVerifyMutate,
    );
  const selectedOnly: WorkerType[] = [],
    completedAndLeave: WorkerType[] = [],
    completedButStay: WorkerType[] = [];
  const corporateCompletionMap = getCorporateCompletionMap(
    data,
    completionType,
  );
  Object.values(workers).forEach((w) => {
    const obj = {
      name: w.user!.name,
      company: {
        //these will be present as we're calling only the workers that are employed
        title: w.worker.subcontractor!.name,
        id: w.worker.subcontractor_id!,
      },
      hardHatNumber: w.hard_hat_number,
      id: w.worker_id,
      imgSrc:
        currentUploads[w.worker_id]?.url ??
        w.worker.certificates_to_verify[0]?.front_image?.url ??
        w.user?.profile_picture?.url,
      trade: w.worker.worker_title?.translation?.en,
      corporateOrientationPassed:
        !projectHasUniversal &&
        !!(
          (data.corporateOrientation.edges.length > 0 &&
            corporateCompletionMap[w.worker_id]) ||
          (w.user_orientation?.orientation_provided_by_user_id &&
            w.user_orientation?.orientated_at)
        ),
      universalCompleted: projectHasUniversal
        ? w.user!.universal_orientations.length > 0 &&
          dayjs(
            w.user!.universal_orientations[0].universal_orientated_at,
          ).isBefore(dayjs().subtract(1, "hour"))
        : false,
    };
    if (w.user_orientation?.in_person_orientated_at) {
      if (
        projectHasUniversal
          ? obj.universalCompleted
          : obj.corporateOrientationPassed
      ) {
        completedAndLeave.push(obj);
      } else {
        completedButStay.push(obj);
      }
    } else selectedOnly.push(obj);
  });

  Object.values(gcUsers).forEach((w) => {
    const obj = {
      name: w.employee.user!.name,
      company: {
        title:
          w.employee.oac_company?.name ?? w.employee.general_contractor.name,
        id: w.employee.oac_company_id ?? w.employee.general_contractor_id,
      },
      hardHatNumber: w.hard_hat_number,
      trade:
        w.employee.oac_title?.name ?? w.employee.employee_title?.name?.en ?? "",
      id: w.employee_id,
      imgSrc:
        currentUploads[w.employee_id]?.url ??
        w.employee.user?.profile_picture?.url,
      corporateOrientationPassed:
        !projectHasUniversal &&
        !!(data.corporateOrientation.edges.length > 0
          ? corporateCompletionMap[w.employee_id] ||
            (w.user_orientation?.orientation_provided_by_user_id &&
              w.user_orientation?.orientated_at)
          : false),
      universalCompleted: projectHasUniversal
        ? w.employee.user.universal_orientations.length > 0 &&
          dayjs(
            w.employee.user!.universal_orientations[0].universal_orientated_at,
          ).isBefore(dayjs().subtract(1, "hour"))
        : false,
    };
    if (!!w.user_orientation?.in_person_orientated_at) {
      if (
        projectHasUniversal
          ? obj.universalCompleted
          : obj.corporateOrientationPassed
      ) {
        completedAndLeave.push(obj);
      } else {
        completedButStay.push(obj);
      }
    } else selectedOnly.push(obj);
  });

  const insertFinalImage = async (
    uploadProfile: UploadResult,
    workerId: string,
  ) => {
    await insertImageToVerify({
      variables: {
        objects: [
          {
            worker_id: workerId,
            project_id: projectId,
            document: "profilePhoto",
            uploaded_by_uid: auth.currentUser?.uid,
            status: "submitted",
            front_image: {
              data: {
                ...uploadProfile,
                description: "Profile Photo",
                created_by_user_id: auth.currentUser?.uid,
              },
            },
          },
        ],
      },
    });
  };

  return (
    <div className={`flex flex-col`}>
      <p>
        <span className={`font-accent`}>
          {selectedOnly.length +
            completedAndLeave.length +
            completedButStay.length}{" "}
        </span>{" "}
        workers total
      </p>
      <SiteOrientationInPersonManagerViewWorkersResultSublist
        workers={completedAndLeave}
        headline={
          projectHasUniversal
            ? " has valid CARE video, can leave after Site Specific orientation"
            : "valid company video, can leave after Site Specific orientation"
        }
        status={`positive`}
      />
      <SiteOrientationInPersonManagerViewWorkersResultSublist
        workers={completedButStay}
        headline={" needs to watch entire orientation"}
        status={`negative`}
      />
      <SiteOrientationInPersonManagerViewWorkersResultSublist
        workers={selectedOnly}
        headline={" did not select their name or sign"}
        status={`neutral`}
      />
    </div>
  );
};

export default SiteOrientationInPersonManagerViewWorkersResult;

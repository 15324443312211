/**
 * @generated SignedSource<<6dcc4209a0e195b2779d985a808c58e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditWorkAboveElevationModal_work_above_elevation_frag$data = {
  readonly completed_at: string | null | undefined;
  readonly created_at: string;
  readonly created_by_user: {
    readonly employee: {
      readonly employee_title: {
        readonly name: {
          readonly en: string;
        };
      } | null | undefined;
    } | null | undefined;
    readonly name: string;
  };
  readonly id: string;
  readonly name: {
    readonly en: string;
    readonly id: string;
  };
  readonly name_id: string;
  readonly pk: string;
  readonly type_id: string;
  readonly work_above_elevation_type: {
    readonly name: {
      readonly en: string;
    };
  };
  readonly work_above_floors: ReadonlyArray<{
    readonly completed_at: string | null | undefined;
    readonly has_netting: boolean;
    readonly id: string;
    readonly name: {
      readonly en: string;
      readonly id: string;
    };
    readonly name_id: string;
    readonly order: number;
    readonly pk: string;
  }>;
  readonly " $fragmentType": "EditWorkAboveElevationModal_work_above_elevation_frag";
};
export type EditWorkAboveElevationModal_work_above_elevation_frag$key = {
  readonly " $data"?: EditWorkAboveElevationModal_work_above_elevation_frag$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditWorkAboveElevationModal_work_above_elevation_frag">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v3/*: any*/)
    ],
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_at",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./EditWorkAboveElevationModal_work_above_elevation_frag_refetch.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "EditWorkAboveElevationModal_work_above_elevation_frag",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "text_translation",
      "kind": "LinkedField",
      "name": "name",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "user",
      "kind": "LinkedField",
      "name": "created_by_user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "general_contractor_employee",
          "kind": "LinkedField",
          "name": "employee",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "employee_title",
              "kind": "LinkedField",
              "name": "employee_title",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "work_above_elevation_type",
      "kind": "LinkedField",
      "name": "work_above_elevation_type",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "work_above_floor",
      "kind": "LinkedField",
      "name": "work_above_floors",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "order",
          "storageKey": null
        },
        (v2/*: any*/),
        (v1/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "has_netting",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "text_translation",
          "kind": "LinkedField",
          "name": "name",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "work_above_elevation",
  "abstractKey": null
};
})();

(node as any).hash = "41fa9410812babd89c1c1dd7547a7f31";

export default node;

import NestedRoute from "src/common/types/manual/NestedRoute";
import GCCorporateOrientations from "./GCCorporateOrientations";
import GCCorporateSettingsPermits from "./GCCorporateSettingsPermits";
import GCCorporateSettingsNavigation from "./GCCorporateSettingsNavigation";
import GCCompanySettings from "./company/GCCompanySettings";
import GCProjectOrientationDetail from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/slides/GCProjectOrientationDetail";
import searchParamsProvider from "src/common/functions/searchParamsProvider";
import { Navigate } from "react-router-dom";
import GCCorporateIncident from "../incident/GCCorporateIncidents";
import GCCorporateObservations from "../observation/GCCorporateObservations";

const gcSettingsNavigationRoute: NestedRoute = {
  element: <GCCorporateSettingsNavigation />,
  children: [
    { path: "", element: <Navigate to="orientations" /> },
    {
      path: "orientations",
      element: <GCCorporateOrientations />,
    },
    {
      path: "orientations/:orientationId",
      element: searchParamsProvider(GCProjectOrientationDetail),
    },
    {
      path: "permits",
      element: <GCCorporateSettingsPermits />,
    },
    {
      path: "corporate-settings",
      element: <GCCompanySettings />,
    },
    // {
    //   path: "incidents",
    //   element: <GCCorporateIncident />,
    // },
    // {
    //   path: "observations",
    //   element: <GCCorporateObservations />,
    // },
  ],
};
export default gcSettingsNavigationRoute;

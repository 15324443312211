import GCIncidentCharts from "src/domain-features/incident-management/components/chart/GCIncidentCharts";
import { IncidentSeverityMappingProps } from "./IncidentSeverityMapping";
import { useMemo, useState } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { Flex } from "antd";
import {
  useGetIncidentReportAndChartOptionsQuery,
  Project_Bool_Exp,
  GetIncidentReportAndChartOptionsQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import GCAdminIncidentsHeader from "./header/GCAdminIncidentsHeader";
import dayjs from "dayjs";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import useGetGCAdminCommonFilters from "../../utils/useGetGCAdminCommonFilters";
import useGetIncidentFilters from "./utils/useGetIncidentFilters";

type FilterOptionType = {
  id: string;
  title: string;
};

const GCAdminIncidents: React.FC<
  IncidentSeverityMappingProps & {
    projWhere: Project_Bool_Exp;
    optionsQueryVars: GetIncidentReportAndChartOptionsQueryVariables;
  }
> = ({
  gcEmployeeWhere,
  optionsQueryVars,
  severityUserFilter,
  toInsertWith,
  projWhere,
}) => {
  const { data: options, error: optionsError } =
    useGetIncidentReportAndChartOptionsQuery({ variables: optionsQueryVars });
  if (optionsError) throw optionsError;
  const {
    selectFilters: incidentSelectFilters,
    bodyPartsFilter,
    dateFilter,
    incidentTypesFilter,
    injuryCauseFilter,
    injuryTypesFilter,
    companiesFilter,
    severityFilter,
    setDateFilter,
  } = useGetIncidentFilters(options);
  const {
    radioFilters,
    selectFilters: commonSelectFilters,
    projectStatusFilter,
    projectsFilter,
    businessUnitsFilter,
    divisionsFilter,
    officesFilter,
  } = useGetGCAdminCommonFilters(options);
  return (
    <>
      <BasicWrapper scrollable>
        <Flex gap={"large"} vertical>
          <GCAdminIncidentsHeader
            reportCreatorButtonProps={{ options }}
            incidentSeverityMappingProps={{
              gcEmployeeWhere,
              severityUserFilter,
              toInsertWith,
            }}
            filters={{
              radioFilters: [...radioFilters],
              selectFilters: [...commonSelectFilters, ...incidentSelectFilters],
              dates: {
                selectedDates: dateFilter,
                onSelectedDatesChange: setDateFilter,
              },
            }}
          />

          <GCIncidentCharts
            projWhere={projWhere}
            options={options}
            currentFilters={{
              bodyPartsFilter,
              companiesFilter,
              dateFilter,
              incidentTypesFilter,
              severityFilter,
              projectStatusFilter,
              injuryCauseFilter,
              injuryTypesFilter,
              projectsFilter,
              businessUnitsFilter,
              divisionsFilter,
              officesFilter,
            }}
          />
        </Flex>
      </BasicWrapper>
    </>
  );
};
export default GCAdminIncidents;

import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Form, Select, Upload, message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { GraphQLError } from "graphql/error/GraphQLError";
import dayjs from "dayjs";
import { customAlphabet as nanoid } from "nanoid/non-secure";
import React, {
  FunctionComponent,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import {
  GetNameUsernameDocument,
  GetNameUsernameQuery,
  GetNameUsernameQueryVariables,
  useGetUserRoleQuery,
  useGetWorkerTitlesAndRolesQuery,
  useGetWorkerTitlesQuery,
  useInsertWorkersMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { AddWorkerSpreadsheetModal_subData_Query } from "src/common/types/generated/relay/AddWorkerSpreadsheetModal_subData_Query.graphql";
import * as xlsx from "xlsx";
import FModal, { FModalRef } from "./FModal";
import readAsArrayBuffer from "src/common/functions/readAsArrayBuffer";

interface AddWorkerSpreadsheetValues {
  workerSpreadsheetObj: Array<{ originFileObj: File }>;
}

interface FormValues {
  subcontractorId: string;
  workerSpreadsheetObj: Array<{ originFileObj: File }>;
}

export type AddWorkerSpreadsheetModalRef = FModalRef<FormValues> | null;

interface AddWorkerSpreadsheetModalProps {
  //CollectionCreateFormProps
  projectId?: string;
  subcontractorId?: string;
  subcontractors?: Array<{ name: string; id: string }>;
  onCreate?: (values: AddWorkerSpreadsheetValues) => void;
  onCancel?: () => void;
}

const query = graphql`
  query AddWorkerSpreadsheetModal_subData_Query($projectId: uuid!) {
    subcontractor_connection(
      where: {
        subcontractor_projects: { project: { id: { _eq: $projectId } } }
      }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          id
          name
        }
      }
    }
  }
`;

type SubcontractorDataProps = { projectId: string | undefined };

const SubcontractorData: FunctionComponent<SubcontractorDataProps> = ({
  projectId,
}) => {
  // why projectId can be undefined? what should happend ?
  // replace this if with another logic if it is ok to have it undefined
  if (!projectId) throw new Error("projectId is not defined");
  const data = useLazyLoadQuery<AddWorkerSpreadsheetModal_subData_Query>(
    query,
    {
      projectId: projectId,
    },
  );

  const subcontractorData = data.subcontractor_connection.edges;

  return (
    <>
      {subcontractorData?.map((t) => (
        <Select.Option key={t.node.pk} value={t.node.pk}>
          {t.node.name}
        </Select.Option>
      ))}
    </>
  );
};

const AddWorkerSpreadsheetModal = forwardRef<
  AddWorkerSpreadsheetModalRef,
  AddWorkerSpreadsheetModalProps
>(({ projectId, subcontractorId, subcontractors }, ref) => {
  const modal = useRef<FModalRef<FormValues>>(null);
  const [loading, setLoading] = useState(false);
  const isGC = useGetUserRoleQuery().data?.role == "employee";
  const [form] = Form.useForm();
  const { data: titlesData, client } = useGetWorkerTitlesAndRolesQuery();
  const workerTitlesQuery = useGetWorkerTitlesQuery();
  const workersTableRef = useRef<DataScrollTableRef>(null);
  const [workerSpreadsheetOpen, setWorkerSpreadsheetOpen] = useState(false);
  useImperativeHandle<
    AddWorkerSpreadsheetModalRef,
    AddWorkerSpreadsheetModalRef
  >(ref, () => modal.current);

  const reset = () => {
    setLoading(false);
    modal.current?.form.resetFields();
    modal.current?.close();
  };
  const [insertWorkers] = useInsertWorkersMutation();
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  console.log(isGC);

  return (
    <FModal
      //visible={visible}
      ref={modal}
      title="Create new workers from spreadsheet"
      okText="Create"
      cancelText="Cancel"
      onCancel={() => {
        reset();
      }}
      onOk={async () => {
        const form = modal.current?.form;
        if (!form) return;
        const values = await form.validateFields().catch((error) => {
          console.log("Validate Failed:", error);
        });
        if (!values) return;
        setLoading(true);
        try {
          const fileContent = await readAsArrayBuffer(
            values.workerSpreadsheetObj[0].originFileObj,
          );

          const wb = xlsx.read(fileContent, { type: "array" });
          const rows: Array<any> = xlsx.utils.sheet_to_json(
            wb.Sheets["Sheet1"],
          );

          const usernames = await Promise.all(
            rows.map((r: any) =>
              client.query<GetNameUsernameQuery, GetNameUsernameQueryVariables>(
                {
                  query: GetNameUsernameDocument,
                  variables: {
                    name: r["First Name"].trim() + " " + r["Last Name"].trim(),
                  },
                },
              ),
            ),
          );

          if (!usernames || usernames.some((u) => !u)) {
            message.error("some username could not be generated");
            return;
          }
          await insertWorkers({
            awaitRefetchQueries: true,
            variables: {
              objects: rows.map((r: any, i) => {
                const email = r["Email Address"];
                const phoneNumber = r["Phone Number"];
                const trade = r["Trade"].trim().toLowerCase();
                const role = r["Title"].trim().toLowerCase();
                const name =
                  r["First Name"].trim() + " " + r["Last Name"].trim();
                const dob = r["Birthdate (MM/DD/YYYY)"].trim()
                  ? dayjs(
                      r["Birthdate (MM/DD/YYYY)"].trim(),
                      "MM/DD/YYYY",
                    ).toISOString()
                  : null;
                return {
                  worker_title_id: workerTitlesQuery.data?.worker_title.find(
                    (t) => t.translation.en.toLowerCase() === trade,
                  )?.id,
                  current_worker_role: role,
                  subcontractor_id: isGC
                    ? values.subcontractorId
                    : subcontractorId,
                  user: {
                    data: {
                      username: usernames[i]?.data?.getNewUsername
                        ? usernames[i]?.data?.getNewUsername +
                          nanoid("0123456789", 2)()
                        : undefined,
                      role: "worker",
                      name,
                      birth_date: dob,
                      phone_number: !!phoneNumber ? phoneNumber.trim() : null,
                      email: !!email ? email.trim() : undefined,
                      phone_number_privacy_setting: { data: {} },
                      email_privacy_setting: { data: {} },
                      project_privacy_setting: { data: {} },
                    },
                  },
                  worker_projects: {
                    data: isGC
                      ? [
                          {
                            project_id: projectId,
                            worker_role: r["Title"].trim().toLowerCase(),
                            title_id: workerTitlesQuery.data?.worker_title.find(
                              (t) =>
                                t.translation.en.toLowerCase() ===
                                r["Trade"].trim().toLowerCase(),
                            )?.id,
                            subcontractor_id: isGC
                              ? values.subcontractorId
                              : subcontractorId,
                          },
                        ]
                      : [],
                  },
                };
              }),
            },
          });
          await workersTableRef.current?.refetch();

          notification.open({
            message: `Created ${usernames.length} new worker profiles!`,
          });
          form.resetFields();
        } catch (err) {
          if (err instanceof Error) {
            // TODO: do not handle all errror this way..  write more precise condition to catch speciall erros
            notification.open({
              type: "error",
              message:
                "Your spreadsheet contained invalid entries! Please fix them and try uploading again.",
              description: `Error details: ${err.name} ${err.message}`,
              duration: null,
            });
          } else {
            throw err;
          }
        } finally {
          setLoading(false);
          setWorkerSpreadsheetOpen(false);
        }
      }}
    >
      <p>
        Download the spreadsheet template, fill it out with your workers'
        information, and then upload it in the box below.
      </p>
      <a
        href="https://firebasestorage.googleapis.com/v0/b/siteform-3170b.appspot.com/o/SiteForm%20Subcontractor%20Worker%20Upload%20Spreadsheet%20.xlsx?alt=media&token=02055afd-a749-429a-a428-1cefcbc18382"
        target="_blank"
        rel="noreferrer"
      >
        <Button type="primary">Download template</Button>
      </a>
      <p />
      {isGC && (
        <Form.Item
          name="subcontractorId"
          label="Subcontractor"
          rules={[{ required: true, message: `Choose the subcontractor` }]}
        >
          <Select style={{ width: "100%" }}>
            {subcontractors?.map((s) => (
              <Select.Option key={s.id} value={s.id}>
                {s.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item
        label="Worker spreadsheet"
        name="workerSpreadsheetObj"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[{ required: true, message: "Upload worker spreadsheet" }]}
      >
        <Upload.Dragger
          customRequest={() => true}
          accept=".xlsx"
          iconRender={() => <AntCloudOutlined />}
          name="files"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p>Click or drag your completed worker spreadsheet here to upload</p>
        </Upload.Dragger>
      </Form.Item>
    </FModal>
  );
});

export default AddWorkerSpreadsheetModal;

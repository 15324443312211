import React, { useState } from "react";
import TextArea from "antd/es/input/TextArea";

interface IncidentInputFieldProps {
  text?: string | number;
  label: string;
  disabled?: boolean;
  onSave: (val: string | number) => void;
  validateAsNumber?: boolean;
}

const IncidentInputField: React.FC<IncidentInputFieldProps> = ({
  text,
  label,
  disabled,
  onSave,
  validateAsNumber = false,
}) => {
  const [newText, setNewText] = useState(text);

  return (
    <div className="flex gap-1 mt-1 items-center">
      <div className="w-10">{label}</div>
      <TextArea
        className="w-full"
        value={newText}
        autoSize={{ minRows: 1, maxRows: 10 }}
        onChange={(val) => {
          const inputValue = val.target.value;
          if (validateAsNumber) {
            if (/^\d*\.?\d*$/.test(inputValue)) {
              setNewText(inputValue);
            }
          } else {
            setNewText(inputValue);
          }
        }}
        onBlur={() => {
          if (typeof newText !== "undefined") onSave(newText);
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default IncidentInputField;

/**
 * @generated SignedSource<<6a7614f0fbe54dedc7ba88a1f54f951b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type useCheckAGCUserQuery$variables = {
  agcCompanyId: string;
};
export type useCheckAGCUserQuery$data = {
  readonly agc_employee: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly uid: string;
      };
    }>;
  };
};
export type useCheckAGCUserQuery = {
  response: useCheckAGCUserQuery$data;
  variables: useCheckAGCUserQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "agcCompanyId"
  }
],
v1 = [
  {
    "alias": "agc_employee",
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "agcCompanyId"
              }
            ],
            "kind": "ObjectValue",
            "name": "general_contractor_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "general_contractor_employeeConnection",
    "kind": "LinkedField",
    "name": "general_contractor_employee_connection",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "general_contractor_employeeEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractor_employee",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCheckAGCUserQuery",
    "selections": (v1/*: any*/),
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCheckAGCUserQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c07520e398645d854896136402aecba0",
    "id": null,
    "metadata": {},
    "name": "useCheckAGCUserQuery",
    "operationKind": "query",
    "text": "query useCheckAGCUserQuery(\n  $agcCompanyId: uuid!\n) {\n  agc_employee: general_contractor_employee_connection(where: {general_contractor_id: {_eq: $agcCompanyId}}) {\n    edges {\n      node {\n        uid\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0569e68fb868b64d3488571ec90c68f0";

export default node;

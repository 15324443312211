/**
 * @generated SignedSource<<04585db6a41b2cbe3bc86e8c62754c69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { lang_code_enum } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type UniversalOrientationCompletionPageQuery$variables = {
  workerId: string;
};
export type UniversalOrientationCompletionPageQuery$data = {
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly lang: lang_code_enum;
        readonly name: string;
        readonly profile_picture: {
          readonly url: string;
        } | null | undefined;
        readonly reference_number: string | null | undefined;
        readonly universal_orientations: ReadonlyArray<{
          readonly universal_orientated_at: string;
        }>;
        readonly worker: {
          readonly subcontractor: {
            readonly name: string;
          } | null | undefined;
          readonly worker_certifications: ReadonlyArray<{
            readonly certification: {
              readonly name: string;
            };
            readonly certification_id: string;
            readonly created_at: string;
            readonly expires_on: string | null | undefined;
            readonly images: ReadonlyArray<{
              readonly id: string;
            }>;
            readonly issued_on: string | null | undefined;
          }>;
          readonly worker_role: {
            readonly value: string;
          };
          readonly worker_title: {
            readonly translation: {
              readonly en: string;
            };
          } | null | undefined;
        } | null | undefined;
      };
    }>;
  };
};
export type UniversalOrientationCompletionPageQuery = {
  response: UniversalOrientationCompletionPageQuery$data;
  variables: UniversalOrientationCompletionPageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workerId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "workerId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reference_number",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lang",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "universal_orientated_at": "desc"
    }
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "universal_orientated_at",
  "storageKey": null
},
v8 = [
  (v3/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v11 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "certification": {
        "name": "asc"
      }
    }
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "certification_id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issued_on",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "limit",
      "value": 2
    },
    {
      "kind": "Literal",
      "name": "order_by",
      "value": {
        "description": "desc"
      }
    }
  ],
  "concreteType": "image",
  "kind": "LinkedField",
  "name": "images",
  "plural": true,
  "selections": [
    (v15/*: any*/)
  ],
  "storageKey": "images(limit:2,order_by:{\"description\":\"desc\"})"
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expires_on",
  "storageKey": null
},
v18 = [
  (v3/*: any*/),
  (v15/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UniversalOrientationCompletionPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "profile_picture",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "user_universal_orientation",
                    "kind": "LinkedField",
                    "name": "universal_orientations",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": "universal_orientations(limit:1,order_by:{\"universal_orientated_at\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": (v8/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker_title",
                        "kind": "LinkedField",
                        "name": "worker_title",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "text_translation",
                            "kind": "LinkedField",
                            "name": "translation",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker_role",
                        "kind": "LinkedField",
                        "name": "worker_role",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v11/*: any*/),
                        "concreteType": "worker_certification",
                        "kind": "LinkedField",
                        "name": "worker_certifications",
                        "plural": true,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "certification",
                            "kind": "LinkedField",
                            "name": "certification",
                            "plural": false,
                            "selections": (v8/*: any*/),
                            "storageKey": null
                          },
                          (v16/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": "worker_certifications(order_by:{\"certification\":{\"name\":\"asc\"}})"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UniversalOrientationCompletionPageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "profile_picture",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "user_universal_orientation",
                    "kind": "LinkedField",
                    "name": "universal_orientations",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": "universal_orientations(limit:1,order_by:{\"universal_orientated_at\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": (v18/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker_title",
                        "kind": "LinkedField",
                        "name": "worker_title",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "text_translation",
                            "kind": "LinkedField",
                            "name": "translation",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker_role",
                        "kind": "LinkedField",
                        "name": "worker_role",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v11/*: any*/),
                        "concreteType": "worker_certification",
                        "kind": "LinkedField",
                        "name": "worker_certifications",
                        "plural": true,
                        "selections": [
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "certification",
                            "kind": "LinkedField",
                            "name": "certification",
                            "plural": false,
                            "selections": (v18/*: any*/),
                            "storageKey": null
                          },
                          (v16/*: any*/),
                          (v17/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": "worker_certifications(order_by:{\"certification\":{\"name\":\"asc\"}})"
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d72f8d7bb4cbab21cee3c78368ba2197",
    "id": null,
    "metadata": {},
    "name": "UniversalOrientationCompletionPageQuery",
    "operationKind": "query",
    "text": "query UniversalOrientationCompletionPageQuery(\n  $workerId: uuid!\n) {\n  user_connection(where: {id: {_eq: $workerId}}) {\n    edges {\n      node {\n        reference_number\n        name\n        lang\n        profile_picture {\n          url\n          id\n        }\n        universal_orientations(limit: 1, order_by: {universal_orientated_at: desc}) {\n          universal_orientated_at\n          id\n        }\n        worker {\n          subcontractor {\n            name\n            id\n          }\n          worker_title {\n            translation {\n              en\n              id\n            }\n            id\n          }\n          worker_role {\n            value\n            id\n          }\n          worker_certifications(order_by: {certification: {name: asc}}) {\n            certification_id\n            issued_on\n            created_at\n            certification {\n              name\n              id\n            }\n            images(order_by: {description: desc}, limit: 2) {\n              id\n            }\n            expires_on\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "221079e643b794804e98b3563a66bc6a";

export default node;

import { Select } from "antd";
import React from "react";
import Title from "antd/es/typography/Title";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

export interface GCAdminSelectFilterProps {
  selectedItems: Set<string>;
  handleItemChange: (itemId: string) => void;
  setSelectedItems: (items: Set<string>) => void;
  options: { value: string; label: string }[];
  title: string;
  placeholder: string;
}

const GCAdminSelectFilter: React.FC<GCAdminSelectFilterProps & {}> = (
  props,
) => {
  return (
    <div>
      <Title level={4}>{props.title}</Title>
      <Select
        allowClear
        className={"w-full"}
        showSearch
        filterOption={getNormalSelectOptionsFilter}
        placeholder={props.placeholder}
        value={[...props.selectedItems]}
        options={props.options}
        onSelect={props.handleItemChange}
        onDeselect={props.handleItemChange}
        mode="multiple"
      />
    </div>
  );
};

export default GCAdminSelectFilter;

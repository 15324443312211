/**
 * @generated SignedSource<<ce8daeaa0422539532e20aa1cddd91a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AnonProjectWorkerTable_total$data = {
  readonly allProjectWorkersConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly " $fragmentType": "AnonProjectWorkerTable_total";
};
export type AnonProjectWorkerTable_total$key = {
  readonly " $data"?: AnonProjectWorkerTable_total$data;
  readonly " $fragmentSpreads": FragmentRefs<"AnonProjectWorkerTable_total">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./AnonProjectWorkerTableTotalRefetchableQuery.graphql')
    }
  },
  "name": "AnonProjectWorkerTable_total",
  "selections": [
    {
      "alias": "allProjectWorkersConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "project_workerConnection",
      "kind": "LinkedField",
      "name": "project_worker_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project_workerEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "project_worker",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};

(node as any).hash = "9cbf0d054a262440a51e8ff50395e6f3";

export default node;

import { Button, Form, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import { produce } from "immer";
import {
  General_Contractor_Employee_Bool_Exp,
  GetSeverityLevelsDocument,
  GetSeverityLevelsQuery,
  GetSeverityLevelsQueryVariables,
  Severity_User_Bool_Exp,
  Severity_User_Insert_Input,
  useGetSeverityLevelsQuery,
  useUpdateSeverityUsersMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";

const SelectSeverityUser: React.FC<{
  options: Array<{ value: string; label: string }>;
  severityId: string;
  data: GetSeverityLevelsQuery | undefined;
  variables: GetSeverityLevelsQueryVariables;
  toInsertWith: Partial<Severity_User_Insert_Input>;
  currentSeverityUsers: {
    user_id: string;
    id: string;
    user: { name: string };
  }[];
}> = ({
  severityId,
  options,
  currentSeverityUsers,
  toInsertWith,
  variables,
  data,
}) => {
  const [updateUsers, { loading }] = useUpdateSeverityUsersMutation();
  const [form] = Form.useForm();
  const [editing, setEditing] = useState(false);
  return (
    <div className="flex gap-1 w-full">
      <Button
        type={editing ? "default" : "primary"}
        loading={loading}
        onClick={async () => setEditing((i) => !i)}
      >
        {editing ? "Cancel" : "Edit"}
      </Button>
      {editing ? (
        <Form
          form={form}
          className="flex gap-1"
          initialValues={{
            userIds: currentSeverityUsers.map((u) => u.user_id),
          }}
          onFinish={async (vals) => {
            setEditing(false);
            if (!vals) return;
            const [toBeInsertedUserIds, toBeDeletedUserIds] = compareTwoLists(
              vals.userIds || [],
              currentSeverityUsers.map((u) => u.user_id),
            );
            const toBeDeletedSeverityUserIds = currentSeverityUsers
              .filter((u) => toBeDeletedUserIds.includes(u.user_id))
              .map((p) => p.id);
            console.log({
              newObjs: toBeInsertedUserIds.map((u) => ({
                user_id: u,
                ...toInsertWith,
                severity_id: severityId,
              })),
              deleteWhere: { id: { _in: toBeDeletedSeverityUserIds } },
            });
            await updateUsers({
              variables: {
                newObjs: toBeInsertedUserIds.map((u) => ({
                  user_id: u,
                  ...toInsertWith,
                  severity_id: severityId,
                })),
                deleteWhere: { id: { _in: toBeDeletedSeverityUserIds } },
              },
              update: (cache, { data: returningData }) => {
                if (data)
                  cache.writeQuery<GetSeverityLevelsQuery>({
                    query: GetSeverityLevelsDocument,
                    variables,
                    data: produce(data, (draft) => {
                      const index = draft.incident_severity.findIndex(
                        (s) => s.id === severityId,
                      );
                      if (index >= 0) {
                        const newUsersList = (
                          draft.incident_severity[index].severity_users || []
                        ).filter(
                          (su) => !toBeDeletedSeverityUserIds.includes(su.id),
                        );
                        newUsersList.push(
                          ...(returningData?.insert_severity_user?.returning ||
                            []),
                        );
                        draft.incident_severity[index].severity_users =
                          newUsersList;
                      }
                    }),
                  });
              },
            });
          }}
        >
          <Form.Item name="userIds">
            <Select
              style={{ minWidth: 700 }}
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              loading={loading}
              mode="multiple"
              options={options}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form>
      ) : (
        <div>
          {currentSeverityUsers.map((u) => (
            <span
              className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
              key={u.id}
            >
              {u.user.name}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export interface IncidentSeverityMappingProps {
  toInsertWith: Partial<Severity_User_Insert_Input>;
  severityUserFilter: Severity_User_Bool_Exp;
  gcEmployeeWhere: General_Contractor_Employee_Bool_Exp;
}

const IncidentSeverityMapping: React.FC<IncidentSeverityMappingProps> = ({
  severityUserFilter,
  gcEmployeeWhere,
  toInsertWith,
}) => {
  const variables = { includeUsers: true, severityUserFilter, gcEmployeeWhere };

  const { data, loading, error } = useGetSeverityLevelsQuery({ variables });
  const userOptions = (data?.general_contractor_employee || []).map((u) => ({
    value: u.uid,
    label: u.user.name,
  }));
  type DataType = NonNullable<
    NonNullable<GetSeverityLevelsQuery["incident_severity"]>[number]
  >;
  const [injury_severities, monetary_severities] = (
    data?.incident_severity || []
  ).reduce(
    ([injuries, monetaries], item) => {
      if (item.severity_type === "injury") injuries.push(item);
      else if (item.severity_type === "monetary") monetaries.push(item);
      return [injuries, monetaries];
    },
    [[] as DataType[], [] as DataType[]],
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const columns: ColumnsType<DataType> = [
    {
      title: "Severity Level",
      key: "severity_level",
      width: "30%",
      dataIndex: ["name", "en"],
    },
    {
      title: "Notifyees",
      key: "notifyee",
      width: "70%",
      dataIndex: ["severity_users"],
      render: (v, r) => {
        return (
          <SelectSeverityUser
            options={userOptions}
            variables={variables}
            data={data}
            toInsertWith={toInsertWith}
            severityId={r.id}
            currentSeverityUsers={r.severity_users || []}
          />
        );
      },
    },
  ];
  const type = searchParams.get("type");
  useEffect(() => {
    if (!type || (type !== "injury" && type !== "monetary"))
      setSearchParams({ type: "injury" });
  }, [type]);

  const tabs: ReportsTabType[] = [
    { id: "injury", label: "Injury Severity Notifyee" },
    { id: "monetary", label: "Monetary Severity Notifyee" },
  ];

  const handleReportsTabClick = (tabId: string) => {
    setSearchParams({ type: tabId });
  };
  if (error) throw error;
  if (loading) return <LargeTableSkeleton />;
  if (!data) throw new Error("Data not found");
  return (
    <>
      <ReportsTabsGroup
        onTabClick={handleReportsTabClick}
        tabs={tabs}
        tabsType="button"
        selectedTabId={type || "injury"}
      />
      {type == "injury" && (
        <Table
          rowKey={(item) => item.id}
          columns={columns}
          dataSource={injury_severities}
        />
      )}
      {type === "monetary" && (
        <Table
          title={() => <div className="text-1.2 font-accent"></div>}
          rowKey={(item) => item.id}
          columns={columns}
          dataSource={monetary_severities}
        />
      )}
    </>
  );
};
export default IncidentSeverityMapping;

/**
 * @generated SignedSource<<3aa214ba2db496a231183b9cb9ba1dc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useArchiveOrUnarchiveJHAMutation$variables = {
  _set: task_set_input;
  taskId: string;
};
export type useArchiveOrUnarchiveJHAMutation$data = {
  readonly update_task_by_pk: {
    readonly archived_at: string | null | undefined;
    readonly archived_by_uid: string | null | undefined;
    readonly id: string;
  } | null | undefined;
};
export type useArchiveOrUnarchiveJHAMutation = {
  response: useArchiveOrUnarchiveJHAMutation$data;
  variables: useArchiveOrUnarchiveJHAMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "taskId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "task",
    "kind": "LinkedField",
    "name": "update_task_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "archived_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "archived_by_uid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useArchiveOrUnarchiveJHAMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useArchiveOrUnarchiveJHAMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2986785eb4bf686d218fc6471bd2f60d",
    "id": null,
    "metadata": {},
    "name": "useArchiveOrUnarchiveJHAMutation",
    "operationKind": "mutation",
    "text": "mutation useArchiveOrUnarchiveJHAMutation(\n  $taskId: uuid!\n  $_set: task_set_input!\n) {\n  update_task_by_pk(pk_columns: {id: $taskId}, _set: $_set) {\n    id\n    archived_at\n    archived_by_uid\n  }\n}\n"
  }
};
})();

(node as any).hash = "86b3f3c1c313ef2d70f57d18c3f4bd6a";

export default node;

import { Checkbox } from "antd";
import React from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import { useUpdatePermitProjectByPkMutation } from "src/common/types/generated/apollo/graphQLTypes";

import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitGcProjectSettingCheckbox from "./basic/PermitGcProjectSettingCheckbox";
import ShowPermitChecklistsTables from "./basic/ShowPermitChecklistsTables";

const ConfinedSpaceSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  project,
  mainGc,
  checklistGc,
  data,
}) => {
  const [updateProject] = useUpdatePermitProjectByPkMutation();

  return (
    <>
      <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: project?.project_setting,
          field: 'only_submitter_sign_the_confined_space_permit'
        }}
      />
      {project && (
        <StyledContent>
          <Checkbox
            checked={project.prevent_confined_space_submission}
            onChange={(e) => {
              const set = {
                prevent_confined_space_submission: e.target.checked,
              };
              updateProject({
                variables: { id: project.id, _set: set },
                optimisticResponse: {
                  update_project_by_pk: { ...project, ...set },
                },
              });
            }}
          >
            Turn On Prevent Confined Space Submssion if expired Permit is not
            closed
          </Checkbox>
        </StyledContent>
      )}
      <ShowPermitChecklistsTables
        {...{
          checklistGc,
          project,
          data,
          gcPermitCheckListItems,
          queryVariables,
          checklistItemsType: "confined_space",
        }}
      />
    </>
  );
};
export default ConfinedSpaceSettings;

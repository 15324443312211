/**
 * @generated SignedSource<<95563b2d31ad300c587a27bcba173c4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SCProjectSDS_CompanySdsQuery$variables = {
  projId: string;
  subId: string;
};
export type SCProjectSDS_CompanySdsQuery$data = {
  readonly safety_data_sheet_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly child_sds: ReadonlyArray<{
          readonly project_id: string | null | undefined;
          readonly subcontractor_id: string | null | undefined;
        }>;
        readonly company_name: string;
        readonly company_safety_data_sheet_id: string | null | undefined;
        readonly created_at: string;
        readonly created_by_user: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
        readonly file_type: string;
        readonly id: string;
        readonly issue_date: string | null | undefined;
        readonly parent_sds: {
          readonly project_id: string | null | undefined;
          readonly subcontractor_id: string | null | undefined;
        } | null | undefined;
        readonly pk: string;
        readonly product_name: string;
        readonly project: {
          readonly name: string;
        } | null | undefined;
        readonly project_id: string | null | undefined;
        readonly reference_number: string | null | undefined;
        readonly subcontractor: {
          readonly name: string;
        } | null | undefined;
        readonly subcontractor_id: string | null | undefined;
        readonly url: string;
      };
    }>;
  };
};
export type SCProjectSDS_CompanySdsQuery = {
  response: SCProjectSDS_CompanySdsQuery$data;
  variables: SCProjectSDS_CompanySdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subId"
},
v2 = {
  "_is_null": true
},
v3 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "subId"
    }
  ],
  "kind": "ObjectValue",
  "name": "subcontractor_id"
},
v4 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "company_name": "asc"
    }
  },
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "deleted_at",
        "value": (v2/*: any*/)
      },
      {
        "kind": "Literal",
        "name": "project_id",
        "value": (v2/*: any*/)
      },
      (v3/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "product_name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company_name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = [
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reference_number",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issue_date",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company_safety_data_sheet_id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file_type",
  "storageKey": null
},
v19 = [
  (v10/*: any*/),
  (v13/*: any*/)
],
v20 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projId"
          }
        ],
        "kind": "ObjectValue",
        "name": "project_id"
      },
      (v3/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v21 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v22 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v23 = [
  (v11/*: any*/),
  (v5/*: any*/)
],
v24 = [
  (v10/*: any*/),
  (v13/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SCProjectSDS_CompanySdsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "safety_data_sheetConnection",
        "kind": "LinkedField",
        "name": "safety_data_sheet_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_data_sheetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_data_sheet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "created_by_user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "safety_data_sheet",
                    "kind": "LinkedField",
                    "name": "parent_sds",
                    "plural": false,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v20/*: any*/),
                    "concreteType": "safety_data_sheet",
                    "kind": "LinkedField",
                    "name": "child_sds",
                    "plural": true,
                    "selections": (v19/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SCProjectSDS_CompanySdsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "safety_data_sheetConnection",
        "kind": "LinkedField",
        "name": "safety_data_sheet_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_data_sheetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_data_sheet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v23/*: any*/),
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "created_by_user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v21/*: any*/),
                      (v22/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": (v23/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "safety_data_sheet",
                    "kind": "LinkedField",
                    "name": "parent_sds",
                    "plural": false,
                    "selections": (v24/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v20/*: any*/),
                    "concreteType": "safety_data_sheet",
                    "kind": "LinkedField",
                    "name": "child_sds",
                    "plural": true,
                    "selections": (v24/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "36ee00e60b92a13ca03502d54f4cec62",
    "id": null,
    "metadata": {},
    "name": "SCProjectSDS_CompanySdsQuery",
    "operationKind": "query",
    "text": "query SCProjectSDS_CompanySdsQuery(\n  $subId: uuid!\n  $projId: uuid!\n) {\n  safety_data_sheet_connection(where: {subcontractor_id: {_eq: $subId}, project_id: {_is_null: true}, deleted_at: {_is_null: true}}, order_by: {company_name: asc}) {\n    edges {\n      node {\n        id\n        pk: id\n        product_name\n        company_name\n        created_at\n        project_id\n        project {\n          name\n          id\n        }\n        subcontractor_id\n        reference_number\n        issue_date\n        url\n        company_safety_data_sheet_id\n        file_type\n        created_by_user {\n          id\n          pk: id\n          name\n        }\n        subcontractor {\n          name\n          id\n        }\n        parent_sds {\n          project_id\n          subcontractor_id\n          id\n        }\n        child_sds(where: {project_id: {_eq: $projId}, subcontractor_id: {_eq: $subId}}, limit: 1) {\n          project_id\n          subcontractor_id\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "764baf920e65d74e94a31a23ccc3cb50";

export default node;

import { useNavigate, useParams } from "react-router-dom";
import React, { useRef, useState } from "react";
import ObservationTable from "../../components/ObservationTable";
import useAuthUser from "src/common/hooks/useAuthUser";
import { IconSettings } from "@tabler/icons";
import { Button, Drawer } from "antd";
import GCProjObservationSettings from "./GCProjObservationSettings";
import {
  GetObservationOptionsDocument,
  GetObservationOptionsQuery,
  GetObservationOptionsQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";

const GCProjectObservations: React.FunctionComponent = () => {
  const projectId = useParams().projectId;
  if (!projectId) throw new Error("projectId not found");
  const ref = useRef(null);
  const authUser = useAuthUser();
  const { data: options } = useSuspenseQuery<
    GetObservationOptionsQuery,
    GetObservationOptionsQueryVariables
  >(GetObservationOptionsDocument, {
    variables: {
      projectId,
      userId: authUser.uid,
      projectWorkerWhere: { id: { _is_null: true } },
      subWhere: { subcontractor_projects: { project_id: { _eq: projectId } } },
    },
  });
  const navigate = useNavigate();
  const [settingDrawer, setSettingDrawer] = useState(false);
  return (
    <>
      <Drawer
        footer={
          <Button
            onClick={() => {
              setSettingDrawer(false);
            }}
          >
            Close
          </Button>
        }
        title="Settings"
        open={settingDrawer}
        width={"60%"}
        onClose={() => {
          setSettingDrawer(false);
        }}
      >
        <GCProjObservationSettings projectId={projectId} />
      </Drawer>
      <ObservationTable
        ref={ref}
        showInReview={
          options?.project_setting_by_pk
            ?.require_gc_to_mark_observation_complete
        }
        riskLevels={options?.risk_level}
        onRowClick={(item) => {
          navigate(
            `/gce/projects/${projectId}/observations/observations/${item.id}`,
          );
        }}
        subs={options?.subcontractor}
        topBarButtons={[
          {
            secondary: true,
            icon: IconSettings,
            onClick: () => setSettingDrawer(true),
          },
        ]}
        loggedInUserData={options.userData}
        where={{
          project_id: { _eq: projectId },
          _and: [
            {
              _or: [
                { status: { _neq: "draft" } },
                {
                  status: { _eq: "draft" },
                  created_by_uid: { _eq: authUser.uid },
                },
              ],
            },
            {
              _or: [
                {
                  observation_inspection: { submitted_on: { _is_null: false } },
                },
                { observation_inspection_id: { _is_null: true } },
              ],
            },
          ],
        }}
        projectId={projectId}
        excludedKeys={["project_name"]}
      />
    </>
  );
};
export default GCProjectObservations;

import { Radio } from "antd";
import React from "react";
import Title from "antd/es/typography/Title";

export interface GCAdminRadioFilterProps {
  selectedItem: string | undefined;
  handleItemChange: (itemId?: string) => void;
  options: { value: string; label: string }[];
  title: string;
  defaultVal?: string;
}

const GCAdminRadioFilter: React.FC<GCAdminRadioFilterProps> = (props) => {
  return (
    <div>
      <Title level={4}>{props.title}</Title>
      <Radio.Group
        // defaultValue={props.defaultVal}
        value={props.selectedItem}
        className="flex flex-wrap gap-1"
        onChange={(e) => {
          props.handleItemChange(e.target.value);
        }}
      >
        {props.options.map((opt) => (
          <Radio.Button value={opt.value} key={opt.value}>
            {opt.label}
          </Radio.Button>
        ))}
      </Radio.Group>
    </div>
  );
};

export default GCAdminRadioFilter;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TimePicker from "src/common/components/general/TimePicker";
import dayjs from "dayjs";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import * as uuid from "uuid";
import { ConnectionHandler } from "relay-runtime";
import { Button, Form, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { MorningManPowerReportDownloadAutoMutation } from "src/common/types/generated/relay/MorningManPowerReportDownloadAutoMutation.graphql";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

interface SelectEmailUserFormValues {
  userIds: Array<string>;
}

interface SelectEmailUserDefaultData {
  userId: string;
  emailProjectUserId: string;
  name?: string;
}

interface ProjectEmployeeData {
  value: string;
  key: string;
  label: string;
}

interface MorningManPowerreportProps {
  defaultUsers?: Array<SelectEmailUserDefaultData>;
  projectEmployee: Array<ProjectEmployeeData>;
  projectData: string;
}

const morningManPowerReportDownloadAutoMutation = graphql`
  mutation MorningManPowerReportDownloadAutoMutation(
    $objects: [email_project_user_insert_input!]!
    $where: email_project_user_bool_exp!
    $projectId: uuid!
    $_set: project_set_input
  ) {
    insert_email_project_user(objects: $objects) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
        user {
          id
          pk: id @__clientField(handle: "pk")
          name
          email
        }
      }
    }
    delete_email_project_user(where: $where) {
      returning {
        id
      }
    }
    update_project_by_pk(pk_columns: { id: $projectId }, _set: $_set) {
      morning_man_power_report_email_time
      timezone
    }
  }
`;

const MorningManPowerReportDownloadAuto: React.FC<
  MorningManPowerreportProps
> = (props) => {
  const { projectId } = useParams();
  if (!projectId) {
    throw new Error(
      "ManPowerReportDownload page requires project id to be non null",
    );
  }
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [time, setTime] = useState(
    dayjs(dayjs().format("YYYY-MM-DD ") + props.projectData),
  );
  const [isToggled, setIsToggled] = useState(false);

  function toggleButton() {
    setIsToggled((isToggled) => !isToggled);
  }

  const [updateMorningManPowerSettings] =
    useAsyncMutation<MorningManPowerReportDownloadAutoMutation>(
      morningManPowerReportDownloadAutoMutation,
    );

  const confirmTimeAndUsers = async () => {
    form
      .validateFields()
      .then(async (values: SelectEmailUserFormValues) => {
        setLoading(true);

        const insertEmailProjectUsers = values.userIds
          .filter(
            (uid) =>
              !(props.defaultUsers ?? []).find((user) => user.userId === uid),
          )
          .map((user) => {
            const insertEmailProjectUserId = uuid.v4();
            return {
              id: insertEmailProjectUserId,
              project_id: projectId!,
              user_id: user,
              type: "morning_man_power_report",
            };
          });

        const deleteEmailProjectUsers = (props.defaultUsers ?? []).filter(
          (user) =>
            !values.userIds.find((u) => {
              return u === user.userId;
            }),
        );
        await updateMorningManPowerSettings({
          variables: {
            objects: insertEmailProjectUsers,
            projectId: projectId,
            where: {
              project_id: { _eq: projectId },
              type: { _eq: "morning_man_power_report" },
              user_id: {
                _in: deleteEmailProjectUsers.map((user) => user.userId),
              },
            },
            _set: {
              morning_man_power_report_email_time: time.format("HH:mm"),
            },
          },
          updater: (store) => {
            const insertedEmailProjectUsers = store.getRootField(
              "insert_email_project_user",
            );

            const deletedEmailProjectUser = store.getRootField(
              "delete_email_project_user",
            );

            const conn = ConnectionHandler.getConnection(
              store.getRoot(),
              "MorningManPowerReport_email_project_user_connection",
            );

            if (conn) {
              insertedEmailProjectUsers
                .getLinkedRecords("returning")
                .forEach((c) => {
                  const edge = store.create(uuid.v4(), "edge");
                  edge.setLinkedRecord(c, "node");
                  ConnectionHandler.insertEdgeAfter(conn, edge);
                });

              deletedEmailProjectUser
                .getLinkedRecords("returning")
                .forEach((c) => {
                  ConnectionHandler.deleteNode(conn, c.getDataID());
                });
            }
          },
        });
        setLoading(false);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
    setIsToggled((isToggled) => !isToggled);
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="text-1.25">Auto Email</div>
      <p className="mb-1">
        Team Members to be emailed the Morning Man Power Report
      </p>
      <div className="flex-col">
        <div className="-mx-0.25 mb-2 ml-2">
          Send To:
          {(props.defaultUsers || []).map((user) => (
            <span
              className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
              key={user.userId}
            >
              {user.name}
            </span>
          ))}
        </div>
        <div className="-mx-0.25 mb-2 ml-2">
          Send Time:
          <span className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1">
            {props.projectData}
          </span>
        </div>
        <>
          {!isToggled && (
            <div className="ml-2 mb-2">
              <Button
                onClick={() => toggleButton()}
                loading={false}
                size="large"
                type="primary"
                className="rounded-2 font-accent flex"
              >
                Edit Time or Recipients
              </Button>
            </div>
          )}
          {isToggled && (
            <>
              <Form
                form={form}
                name="form_in_modal"
                initialValues={{ modifier: "public" }}
              >
                <Form.Item
                  name="userIds"
                  label={<div className="text-1">Select Users</div>}
                  initialValue={(props.defaultUsers || []).map(
                    (user) => user.userId,
                  )}
                  rules={[{ required: true, message: "Select users" }]}
                >
                  <Select
                    className="min-w-12"
                    listItemHeight={10}
                    mode="multiple"
                    showSearch
                    filterOption={getNormalSelectOptionsFilter}
                    options={props.projectEmployee.map((employee) => ({
                      value: employee.value,
                      key: employee.key,
                      label: employee.label,
                    }))}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <div className="text-1">Choose Time to email Report</div>
                  }
                >
                  <TimePicker
                    onSelect={(time) => {
                      if (time) setTime(time);
                    }}
                    value={time}
                  />
                </Form.Item>
              </Form>
              <div className="ml-2 mb-5">
                <Button
                  onClick={() => confirmTimeAndUsers()}
                  loading={loading}
                  size="large"
                  type="primary"
                  className="rounded-2 font-accent flex"
                >
                  Comfirm Time
                </Button>
              </div>
            </>
          )}
        </>
      </div>
    </div>
  );
};
export default MorningManPowerReportDownloadAuto;

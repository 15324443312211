/**
 * @generated SignedSource<<fff4f3bba4e87f7f1dbbd48f15f84620>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type GetJHAApprovalModalDataQuery$variables = {
  projectId: string;
  subId: string;
  taskId: string;
};
export type GetJHAApprovalModalDataQuery$data = {
  readonly email_request_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly email: string;
        readonly type: string | null | undefined;
        readonly user: {
          readonly name: string;
        } | null | undefined;
        readonly user_id: string | null | undefined;
      };
    }>;
  };
  readonly project_employee_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly employee: {
          readonly employee_title: {
            readonly employee_title_category: {
              readonly name: {
                readonly en: string;
              };
            } | null | undefined;
            readonly id: string;
            readonly name: {
              readonly en: string;
            };
          } | null | undefined;
          readonly uid: string;
          readonly user: {
            readonly email: string | null | undefined;
            readonly id: string;
            readonly name: string;
            readonly pk: string;
          };
        };
        readonly id: string;
        readonly pk: string;
        readonly project: {
          readonly id: string;
          readonly name: string;
        };
        readonly status: string;
      };
    }>;
  };
  readonly project_worker_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly user: {
          readonly email: string | null | undefined;
          readonly name: string;
        } | null | undefined;
        readonly worker_id: string;
      };
    }>;
  };
  readonly subcontractor_employee_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly user: {
          readonly email: string | null | undefined;
          readonly name: string;
        };
        readonly user_id: string;
      };
    }>;
  };
};
export type GetJHAApprovalModalDataQuery = {
  response: GetJHAApprovalModalDataQuery$data;
  variables: GetJHAApprovalModalDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskId"
  }
],
v1 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "projectId"
    }
  ],
  "kind": "ObjectValue",
  "name": "project_id"
},
v2 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "subId"
    }
  ],
  "kind": "ObjectValue",
  "name": "subcontractor_id"
},
v3 = [
  {
    "fields": [
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = [
  {
    "fields": [
      (v2/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "employee": {
        "user": {
          "name": "asc"
        }
      }
    }
  },
  {
    "fields": [
      (v1/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v12 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "project",
  "kind": "LinkedField",
  "name": "project",
  "plural": false,
  "selections": [
    (v11/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v16/*: any*/)
  ],
  "storageKey": null
},
v18 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "opened",
        "value": {
          "_eq": false
        }
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "taskId"
          }
        ],
        "kind": "ObjectValue",
        "name": "task_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v21 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v22 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v16/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GetJHAApprovalModalDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "subcontractor_employeeConnection",
        "kind": "LinkedField",
        "name": "subcontractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "project_employeeConnection",
        "kind": "LinkedField",
        "name": "project_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "employee_title",
                        "kind": "LinkedField",
                        "name": "employee_title",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "employee_title_category",
                            "kind": "LinkedField",
                            "name": "employee_title_category",
                            "plural": false,
                            "selections": [
                              (v17/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/),
                          (v17/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          (v11/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v18/*: any*/),
        "concreteType": "email_requestConnection",
        "kind": "LinkedField",
        "name": "email_request_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_requestEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "email_request",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GetJHAApprovalModalDataQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v20/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "subcontractor_employeeConnection",
        "kind": "LinkedField",
        "name": "subcontractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/),
                  (v20/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "project_employeeConnection",
        "kind": "LinkedField",
        "name": "project_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v11/*: any*/),
                  (v21/*: any*/),
                  (v22/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "general_contractor_employee",
                    "kind": "LinkedField",
                    "name": "employee",
                    "plural": false,
                    "selections": [
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "employee_title",
                        "kind": "LinkedField",
                        "name": "employee_title",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "employee_title_category",
                            "kind": "LinkedField",
                            "name": "employee_title_category",
                            "plural": false,
                            "selections": [
                              (v23/*: any*/),
                              (v11/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/),
                          (v23/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v11/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v18/*: any*/),
        "concreteType": "email_requestConnection",
        "kind": "LinkedField",
        "name": "email_request_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "email_requestEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "email_request",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v19/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "17e2d45090130c0b3af3ed5991bb3f00",
    "id": null,
    "metadata": {},
    "name": "GetJHAApprovalModalDataQuery",
    "operationKind": "query",
    "text": "query GetJHAApprovalModalDataQuery(\n  $projectId: uuid!\n  $subId: uuid!\n  $taskId: uuid!\n) {\n  project_worker_connection(where: {project_id: {_eq: $projectId}, subcontractor_id: {_eq: $subId}}) {\n    edges {\n      node {\n        worker_id\n        user {\n          name\n          email\n          id\n        }\n        id\n      }\n    }\n  }\n  subcontractor_employee_connection(where: {subcontractor_id: {_eq: $subId}}) {\n    edges {\n      node {\n        user_id\n        user {\n          name\n          email\n          id\n        }\n        id\n      }\n    }\n  }\n  project_employee_connection(where: {project_id: {_eq: $projectId}}, order_by: {employee: {user: {name: asc}}}) {\n    edges {\n      node {\n        id\n        pk: id\n        status\n        project {\n          id\n          name\n        }\n        employee {\n          uid\n          employee_title {\n            employee_title_category {\n              name {\n                en\n                id\n              }\n              id\n            }\n            id\n            name {\n              en\n              id\n            }\n          }\n          user {\n            pk: id\n            id\n            name\n            email\n          }\n          id\n        }\n      }\n    }\n  }\n  email_request_connection(where: {task_id: {_eq: $taskId}, opened: {_eq: false}}) {\n    edges {\n      node {\n        email\n        user_id\n        user {\n          name\n          id\n        }\n        type\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4810e6c96a31c0750046a670edda8b5f";

export default node;

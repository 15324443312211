import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import OpenQrCode from "src/common/components/OpenQrCode";
import iconLogo from "src/common/assets/icon-logo.png";
import PermitGcProjectSettingCheckbox from "./basic/PermitGcProjectSettingCheckbox";
import ShowPermitChecklistsTables from "./basic/ShowPermitChecklistsTables";

const LotoSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  data,
  checklistGc,
  mainGc,
  project,
}) => {
  return (
    <>
      {project && (
        <div className="w-16 h-24">
          <OpenQrCode
            alwaysVisible
            imageSettings={{
              src: iconLogo,
              excavate: true,
              height: 100,
              width: 100,
            }}
            downloadableFileName={`${project.name} Loto Permits QR`}
            destinationUrl={`${window.location.origin}/qr/p/${project.id}/permits/loto`}
          />
        </div>
      )}
      <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: project?.project_setting,
          field: 'only_submitter_sign_the_loto_permit'
        }}
      />
      <ShowPermitChecklistsTables
        {...{
          checklistGc,
          data,
          project,
          gcPermitCheckListItems,
          queryVariables,
          checklistItemsType: "loto",
        }}
      />
    </>
  );
};
export default LotoSettings;

/**
 * @generated SignedSource<<560d3acb75e1c4fbf222724ca49f3326>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SCProjectAutoEmailTablesQuery$variables = {
  projectId: string;
  subcontractorId: string;
};
export type SCProjectAutoEmailTablesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SCProjectAutoEmailTables_auto_email_connection_frag">;
};
export type SCProjectAutoEmailTablesQuery = {
  response: SCProjectAutoEmailTablesQuery$data;
  variables: SCProjectAutoEmailTablesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subcontractorId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "project_id"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "subcontractorId"
          }
        ],
        "kind": "ObjectValue",
        "name": "sub_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCProjectAutoEmailTablesQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "projectId",
            "variableName": "projectId"
          },
          {
            "kind": "Variable",
            "name": "subcontractorId",
            "variableName": "subcontractorId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "SCProjectAutoEmailTables_auto_email_connection_frag"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCProjectAutoEmailTablesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "auto_emailConnection",
        "kind": "LinkedField",
        "name": "auto_email_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "auto_emailEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "auto_email",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "event",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "where"
        ],
        "handle": "connection",
        "key": "SCProjectAutoEmailTables_auto_email_connection",
        "kind": "LinkedHandle",
        "name": "auto_email_connection"
      }
    ]
  },
  "params": {
    "cacheID": "1e48d9898cbfbb9bdb11ffe42bd6d25c",
    "id": null,
    "metadata": {},
    "name": "SCProjectAutoEmailTablesQuery",
    "operationKind": "query",
    "text": "query SCProjectAutoEmailTablesQuery(\n  $projectId: uuid!\n  $subcontractorId: uuid!\n) {\n  ...SCProjectAutoEmailTables_auto_email_connection_frag_1YHi4D\n}\n\nfragment SCProjectAutoEmailTables_auto_email_connection_frag_1YHi4D on query_root {\n  auto_email_connection(first: 100000, where: {project_id: {_eq: $projectId}, sub_id: {_eq: $subcontractorId}}) {\n    edges {\n      node {\n        id\n        event\n        email\n        pk: id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e0bd07e6076c1e86db6ae66ef8e4d616";

export default node;

import { Card, Typography } from "antd";
import clsx from "clsx";

export interface GCObservationChartsTitleNumbersProps {
  items: {
    title: string;
    info: React.ReactNode;
    borderColorClassName?: string;
  }[];
}

const { Title } = Typography;

const ChartTitleNumbers: React.FC<GCObservationChartsTitleNumbersProps> = (
  props,
) => {
  return (
    <div className={`flex flex-row gap-1 w-full`}>
      {props.items.map((item) => (
        <Card
          key={item.title}
          className={clsx(
            item.borderColorClassName && `border ${item.borderColorClassName}`,
            `flex-1`,
          )}
        >
          <p>{item.title}</p>
          <Title level={1}>
            <span
              className={clsx(
                !!item.borderColorClassName &&
                  `border-b ${item.borderColorClassName}`,
              )}
            >
              {item.info}
            </span>
          </Title>
        </Card>
      ))}
    </div>
  );
};

export default ChartTitleNumbers;

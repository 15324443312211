/**
 * @generated SignedSource<<5003708b95f646b5c9c7265379f92285>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCCompanySettings_removeAuthData_Mutation$variables = {
  general_contractor_id: string;
};
export type GCCompanySettings_removeAuthData_Mutation$data = {
  readonly delete_procore_auth_data: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type GCCompanySettings_removeAuthData_Mutation = {
  response: GCCompanySettings_removeAuthData_Mutation$data;
  variables: GCCompanySettings_removeAuthData_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "general_contractor_id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "general_contractor_id"
              }
            ],
            "kind": "ObjectValue",
            "name": "general_contractor_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "procore_auth_data_mutation_response",
    "kind": "LinkedField",
    "name": "delete_procore_auth_data",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCCompanySettings_removeAuthData_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCCompanySettings_removeAuthData_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1ab492e6d68db7d7e60c47d42f442c06",
    "id": null,
    "metadata": {},
    "name": "GCCompanySettings_removeAuthData_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCCompanySettings_removeAuthData_Mutation(\n  $general_contractor_id: uuid!\n) {\n  delete_procore_auth_data(where: {general_contractor_id: {_eq: $general_contractor_id}}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d1d371d8a2363462844d8625d90e05f";

export default node;

import { DatePicker, TimeRangePickerProps } from "antd";
import dayjs from "dayjs";
import Title from "antd/es/typography/Title";
import { dateFormat } from "../../../../../../../common/functions/getDateStr";

const rangePresets: TimeRangePickerProps["presets"] = [
  { label: "This Year", value: [dayjs().startOf("year"), dayjs()] },
  { label: "This Month", value: [dayjs().startOf("month"), dayjs()] },
];

type DatesRangeType = null | [dayjs.Dayjs | null, dayjs.Dayjs | null];

export interface GCAdminFilterDatesProps {
  selectedDates: DatesRangeType;
  onSelectedDatesChange: (newValue: DatesRangeType) => void;
}

const GCAdminFilterDates: React.FC<GCAdminFilterDatesProps> = (props) => {
  return (
    <div>
      <Title level={4}>Date Range</Title>
      <DatePicker.RangePicker
        className={"w-full"}
        allowClear
        value={props.selectedDates}
        // placeholder={[`All Time`]}
        presets={rangePresets}
        format={dateFormat.mmddyyyy}
        onChange={props.onSelectedDatesChange}
      />
    </div>
  );
};

export default GCAdminFilterDates;

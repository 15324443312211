import { DatePicker, Form, Input, message, Modal, Select } from "antd";
import dayjs from "dayjs";
import { graphql } from "babel-plugin-relay/macro";
import React, { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { SitedeliveryAddNewDeliveryModalMutation } from "src/common/types/generated/relay/SitedeliveryAddNewDeliveryModalMutation.graphql";
import { auth } from "src/common/functions/firebase";
import { ConnectionHandler } from "relay-runtime";
import * as uuid from "uuid";
import pluralize from "pluralize";
import { GCProjectCalendarSitedeliveryDeliveriesQuery$data } from "src/common/types/generated/relay/GCProjectCalendarSitedeliveryDeliveriesQuery.graphql";
import {
  groupBlockOutIntervalsByDay,
  groupSubBlockIntervalsByDay,
} from "./events/DeliveryEvents";
import {
  eventTimeSeq,
  formatTime,
  mergeTimeSeq,
  MS_PER_HOUR,
} from "src/domain-features/site-delivery/entry-routes/calendar/sitedelivery/utilities/timeEvents";
import useLangStrings from "src/utility-features/i18n/context/languageHooks";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

export interface SitedeliveryAddNewDeliveryProps {
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
  subcontractorIds: Array<string>;
  calendarIds: Array<string>;
  weekday: number;
}

const insertDeliveryOneMutation = graphql`
  mutation SitedeliveryAddNewDeliveryModalMutation(
    $object: delivery_insert_input!
  ) {
    insert_delivery_one(object: $object) {
      ...DeliveryFrag @relay(mask: false)
    }
  }
`;

interface SitedeliveryTimeBlockFormValues {
  name: string;
  calendars: Array<string>;
  deliveryFor?: string;
  dateAndTime: dayjs.Dayjs;
  storageLocation: string;
  detail: string;
  duration: number;
}

interface SitedeliveryAddNewDeliveryModalProps {
  onSubmit: (values: SitedeliveryAddNewDeliveryProps) => any;
  modalClose: () => void;
  modalVisible: boolean;
  projectId: string;
  isUserGC: boolean;
  gcId: string;
  timezone?: string;
  isApprovalNeeded: boolean;
  subcontractorData?: Array<{ id: string; name: string }>;
  subcontractorId?: string;
  deliveryData: GCProjectCalendarSitedeliveryDeliveriesQuery$data;
}

type SubBlockNode =
  GCProjectCalendarSitedeliveryDeliveriesQuery$data["project_delivery_sub_block_connection"]["edges"][number]["node"];

type TimeInterval = {
  start: number;
  end: number;
};

const SitedeliveryAddNewDeliveryModal: React.FC<
  SitedeliveryAddNewDeliveryModalProps
> = (props) => {
  const langStrings = useLangStrings("en");
  const [showWarning, setShowWarning] = useState<string>("");
  const [form] = Form.useForm();
  const { projectId } = useParams();
  const [insertDeliveryOne] =
    useAsyncMutation<SitedeliveryAddNewDeliveryModalMutation>(
      insertDeliveryOneMutation,
    );
  if (!projectId) throw new Error("Project id is not defined");

  const project = props.deliveryData.project_connection.edges[0];
  if (!project) {
    throw new Error("Project does not exist");
  }

  const block_out_edges = project.node.is_sitedelivery_block_out_active
    ? props.deliveryData.project_delivery_block_out_connection.edges
    : [];
  const block_outs = useMemo(
    () => groupBlockOutIntervalsByDay(block_out_edges),
    [block_out_edges],
  );

  const sub_block_edges =
    props.deliveryData.project_delivery_sub_block_connection.edges;
  const sub_blocks = useMemo(
    () => groupSubBlockIntervalsByDay(sub_block_edges),
    [sub_block_edges],
  );

  const validateFields = (values: SitedeliveryTimeBlockFormValues) => {
    const startAtDateTime = values.dateAndTime;
    if (!startAtDateTime) {
      setShowWarning("Select Date");
      return false;
    }
    const startInProjectZone = dayjs(startAtDateTime).tz(
      project.node.timezone,
      true,
    );
    const isInPast = startInProjectZone < dayjs().subtract(2, "minutes");
    if (isInPast) {
      const now_local = dayjs();
      const now_project = now_local.clone().tz(project.node.timezone, true);
      const same_timezone = now_local.valueOf() === now_project.valueOf();
      // TODO: localize
      const suffix = same_timezone
        ? ""
        : ". Current project time is " +
          dayjs().tz(project.node.timezone).format("HH:mm");

      setShowWarning("Cannot create or edit delivery in past" + suffix);
      return false;
    }

    const deliveryDurationHours = values.duration;

    const deliveryStartTime = startInProjectZone.valueOf();
    const deliveryEndTime =
      deliveryStartTime + deliveryDurationHours * MS_PER_HOUR;
    const startOfFirstDay = startInProjectZone.clone().startOf("day").valueOf();
    const startTime = deliveryStartTime - startOfFirstDay;
    const endTime = deliveryEndTime - startOfFirstDay;
    const startWeekDay = startInProjectZone.day();

    const overlappedDelivery =
      props.deliveryData.delivery_connection.edges.find((dc) => {
        const hasCommonCalendar = dc.node.calendars.some(
          (calendar) =>
            calendar.calendar &&
            values.calendars.includes(calendar.calendar.pk),
        );
        if (!hasCommonCalendar) return false;
        const startTime = dayjs(dc.node.start_at).valueOf();
        const endTime = startTime + (dc.node.duration ?? 0) * MS_PER_HOUR;
        return deliveryStartTime < endTime && deliveryEndTime > startTime;
      });

    if (overlappedDelivery) {
      const calendars = overlappedDelivery.node.calendars.map(
        (c) => c.calendar?.name?.en ?? "",
      );
      const node = overlappedDelivery.node;
      const startTime = startInProjectZone.format("HH:mm");
      const endTime = startInProjectZone
        .clone()
        .add(node.duration ?? 0, "h")
        .format("HH:mm");
      setShowWarning(
        langStrings.strings.deliveryOverlapWarning(
          calendars.join(", "),
          node.name ? node.name.en : "",
          startTime,
          endTime,
        ),
      );
      return false;
    }

    const same_calendar_sub_blocks = eventTimeSeq(
      sub_blocks,
      startWeekDay,
      (event) => {
        const hasCommonCalendar = event.block.calendars.some(
          (calendar) =>
            calendar.calendar &&
            values.calendars.includes(calendar.calendar.pk),
        );
        return hasCommonCalendar;
      },
    );

    const subId = props.isUserGC
      ? values.deliveryFor === props.gcId
        ? null
        : values.deliveryFor
      : props.subcontractorId;

    let block_out_count = 0;
    let sub_block_same_sub = 0;
    let overlapBlockedInterval = false;
    let subBlockForWarning = null;
    let blockedIntervals: TimeInterval[] = [];
    let currentTime = startTime;
    let lastTime = 0;
    let otherSubBlocks = new Set<SubBlockNode>();

    for (const event of mergeTimeSeq(
      eventTimeSeq(block_outs, startWeekDay),
      same_calendar_sub_blocks,
    )) {
      if (event.time > currentTime) {
        //        console.log(`move sub_block_same_sub = ${sub_block_same_sub} sub_block_other_sub = ${sub_block_other_sub} block_out_count = ${block_out_count}`)
        if (sub_block_same_sub === 0) {
          if (block_out_count > 0) {
            overlapBlockedInterval = true;
          } else if (otherSubBlocks.size > 0) {
            for (const block of otherSubBlocks) {
              subBlockForWarning = block;
              break;
            }
          }
        }
        currentTime = event.time;
      }

      const isBlocked = sub_block_same_sub === 0 && block_out_count > 0;
      if (event.time > lastTime) {
        if (isBlocked) {
          if (
            blockedIntervals.length > 0 &&
            blockedIntervals[blockedIntervals.length - 1].end === lastTime
          ) {
            blockedIntervals[blockedIntervals.length - 1].end = event.time;
          } else {
            blockedIntervals.push({ start: lastTime, end: event.time });
          }
        }
        lastTime = event.time;
      }

      if (currentTime >= endTime && !isBlocked) {
        break;
      }
      if (event.type === "block_out_start") {
        block_out_count++;
      } else if (event.type === "block_out_end") {
        block_out_count--;
      } else if (
        event.type === "sub_block_start" ||
        event.type === "sub_block_end"
      ) {
        const start = event.type === "sub_block_start";
        const same_sub = event.block.subcontractors.some(
          (sub) => sub.subcontractor?.pk === subId,
        )
          ? 1
          : 0;
        if (same_sub) {
          const delta = start ? 1 : -1;
          sub_block_same_sub += delta;
        } else {
          if (start) {
            otherSubBlocks.add(event.block);
          } else {
            otherSubBlocks.delete(event.block);
          }
        }
      }
    }

    //  'This delivery is scheduled during someone else’s Block. You are only allowed to proceed if you have coordinated with that entity.'
    if (subBlockForWarning) {
      const companies = subBlockForWarning.subcontractors.map(
        (sub) => sub.subcontractor?.name ?? "",
      );
      setShowWarning(
        `This delivery is scheduled during ${companies.join(
          ", ",
        )}'s Delivery Block (${subBlockForWarning.start_time} - ${
          subBlockForWarning.end_time
        }). Only proceed if you have coordinated this delivery with them.`,
      );
      return false;
    }

    if (overlapBlockedInterval) {
      const relevantIntervals = blockedIntervals.filter(
        (v) => startTime < v.end && v.start < endTime,
      );
      const status = `${
        langStrings.strings.deliveryBlockOutError
      } ${relevantIntervals
        .map((v) => `${formatTime(v.start)} - ${formatTime(v.end)}`)
        .join(", ")}`;

      setShowWarning(status);
      return false;
    }

    return true;
  };

  const calendars = useMemo(
    () =>
      props.deliveryData.calendar_connection.edges
        .filter(
          (calendar) =>
            !calendar.node.projects.find(
              (project) => project.project.pk === projectId,
            )?.is_archive,
        )
        .map((c) => c.node) ?? [],
    [props.deliveryData],
  );

  const durationOptions = useMemo(
    () =>
      [...Array(47)].map((_, i) => {
        if (i % 2 == 0)
          return {
            title:
              (i != 0
                ? pluralize("hour", Math.floor((i + 1) * 0.5), true) + " "
                : "") +
              "30 " +
              "minutes",
            value: (i + 1) * 0.5,
          };
        else
          return {
            title: pluralize("hour", Math.floor((i + 1) * 0.5), true),
            value: (i + 1) * 0.5,
          };
      }),
    [],
  );

  const handleTimeBlockSubmit = async () => {
    form
      .validateFields()
      .then(async (values: SitedeliveryTimeBlockFormValues) => {
        const warning = validateFields(values);
        try {
          const subId =
            props.gcId && values.deliveryFor === props.gcId
              ? null
              : values.deliveryFor ?? props.subcontractorId;

          if (warning) {
            await insertDeliveryOne({
              variables: {
                object: {
                  project_id: projectId,
                  created_by_user_id: auth.currentUser?.uid,
                  duration: values.duration,
                  name: {
                    data: {
                      en: values.name,
                      original: values.name,
                    },
                  },
                  detail: !values.detail
                    ? null
                    : {
                        data: {
                          en: values.detail,
                          original: values.detail,
                        },
                      },
                  storage_location: !values.storageLocation
                    ? null
                    : {
                        data: {
                          original: values.storageLocation,
                        },
                      },
                  subcontractor_id: subId,
                  start_at: dayjs(values.dateAndTime)
                    .tz(props.timezone, true)
                    .toISOString(),
                  calendars: {
                    data: values.calendars.map((calendarId) => {
                      return {
                        calendar_id: calendarId,
                      };
                    }),
                  },
                  status:
                    props.isUserGC || !props.isApprovalNeeded
                      ? "Approved"
                      : "Pending",
                },
              },

              updater: (store) => {
                const delivery = store.getRootField("insert_delivery_one");
                const conn = ConnectionHandler.getConnection(
                  store.getRoot(),
                  "GCProjectCalendarSitedeliveryDeliveries_delivery_connection",
                );
                if (conn) {
                  const edge = store.create(uuid.v4(), "edge");
                  edge.setLinkedRecord(delivery, "node");
                  ConnectionHandler.insertEdgeAfter(conn, edge);
                }
              },
            });
            message.success("Delivery Added");
            form.resetFields();
            props.modalClose();
          }
        } catch (err) {
          message.error({ content: "Could not add delivery " + err });
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <>
      <Modal
        title={"Add a New Delivery"}
        open={props.modalVisible}
        onCancel={() => {
          props.modalClose();
          setShowWarning("");
        }}
        onOk={() => {
          handleTimeBlockSubmit();
          setShowWarning("");
        }}
        okText={"Add"}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{ modifier: "public" }}
        >
          {showWarning.length > 0 && (
            <div className="mb-1">NOTE: {showWarning}</div>
          )}
          <Form.Item
            label={"Delivery Name"}
            name={"name"}
            rules={[{ required: true, message: "Enter Name of the delivery" }]}
          >
            <Input placeholder="What is a good name for this delivery?" />
          </Form.Item>
          <Form.Item
            label={"Select which Calendar(s)"}
            name={"calendars"}
            rules={[
              {
                required: true,
                message: "Select atleast one calendar for the delivery",
              },
            ]}
          >
            <Select
              mode="multiple"
              placeholder={
                "What access point(s), equipment, lifts are required for this delivery?"
              }
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              options={calendars.map((c) => ({
                value: c.pk,
                label: c.name?.en,
              }))}
            />
          </Form.Item>

          {props.subcontractorData && (
            <Form.Item
              label={"Delivery for"}
              name={"deliveryFor"}
              initialValue={props.subcontractorData[0].id}
              rules={[
                { required: true, message: "Select whom the delivery is for" },
              ]}
            >
              <Select
                showSearch
                filterOption={getNormalSelectOptionsFilter}
                options={(props.subcontractorData || []).map((s) => ({
                  label: s.name,
                  value: s.id,
                }))}
              />
            </Form.Item>
          )}

          <Form.Item
            label={"Date and Time of Delivery"}
            name={"dateAndTime"}
            rules={[
              {
                required: true,
                message: "Select date and time of the delivery",
              },
            ]}
            // initialValue={moment().format("YYYY-MM-DD h:mm A")}
          >
            <DatePicker
              showTime={{
                format: "h:mm A",
                minuteStep: 5,
              }}
              minDate={dayjs().subtract(30, "days")}
              placeholder="When is the delivery arriving?"
              format="YYYY-MM-DD h:mm A"
              className="w-full"
            />
          </Form.Item>
          <Form.Item
            label={"Duration of Delivery"}
            name={"duration"}
            rules={[
              { required: true, message: "Select duration of the delivery" },
            ]}
          >
            <Select placeholder={"Duration of the Delivery"}>
              {durationOptions.map((option) => (
                <Select.Option
                  id={option.value}
                  value={option.value}
                  label={option.title}
                >
                  {option.title}
                </Select.Option>
              ))}{" "}
            </Select>
          </Form.Item>
          <Form.Item label={"Delivery Contents and Details"} name={"detail"}>
            <Input placeholder="What is being delivered?" />
          </Form.Item>
          <Form.Item label={"Storage Location"} name={"storageLocation"}>
            <Input placeholder="Where will it be stored?" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SitedeliveryAddNewDeliveryModal;

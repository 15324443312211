import DataSet from "@antv/data-set";
import { Chart } from "@antv/g2";
import { DatePicker, Skeleton, Space } from "antd";
import dayjs, { Dayjs } from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { titleCase } from "src/common/functions/stringFormatters";
import { useGetReportsByRangeQuery } from "src/common/types/generated/apollo/graphQLTypes";
import ChartWrapperCard from "../../../../common/components/charts/ChartWrapperCard";

const { RangePicker } = DatePicker;

export interface GCDashboardReportsBySubsChartProps {
  projectId: string;
}

const defaultRange: [Dayjs, Dayjs] = [
  dayjs().subtract(10, "year").startOf("day"),
  dayjs().endOf("day"),
];

type ChartData = {
  name: string;
  date: string;
  title: string;
  count: number;
}[];

interface SubsByChartUIProps {
  // Describe Chart Data Type
  chartData: ChartData;

  dateRange: [Dayjs, Dayjs];
}

const SubsByChartUI: React.FC<SubsByChartUIProps> = (props) => {
  const containerId = `subs_by_date_container`;
  useEffect(() => {
    const chart = new Chart({
      container: containerId,
      autoFit: true,
      height: 400,
      padding: [20, 150, 65, 50],
    });
    const dv = new DataSet().createView().source(props.chartData);

    // Filter By Date Range
    if (props.dateRange) {
      dv.transform({
        type: "filter",
        callback: (row) => {
          return dayjs(row.date).isBetween(
            dayjs(props.dateRange[0]).subtract(1, "d"),
            props.dateRange[1],
          );
        },
      });
    }

    dv.transform({
      type: "aggregate",
      fields: ["count"],
      operations: ["sum"],
      as: ["total"],
      groupBy: ["date", "title"],
    }).transform({
      type: "map",
      callback: (row) => {
        delete row.count;
        return row;
      },
    });

    chart.clear();

    chart.data(dv.rows);

    chart.scale("total", { nice: true });

    chart.tooltip({
      title: "Roles",
      showMarkers: false,
      shared: true,
    });

    chart.legend("title", {
      position: "right",
      itemName: {
        formatter(text) {
          return titleCase(text, "_");
        },
      },
    });

    chart.interaction("brush");
    // chart.interaction('other-filter');

    chart
      .interval()
      .adjust("stack")
      .position("date*total")
      .tooltip({
        fields: ["title", "total"],
        callback: (title, total) => {
          return { name: titleCase(title, "_"), value: total };
        },
      })
      .color("title");

    chart.render();
    // This prevents overflow when navigating from another route.
    setTimeout(() => chart.forceFit(), 1000);

    return () => chart.destroy();
  }, []);
  return <div id={containerId}></div>;
};

const GCProjectPreTaskPlansByRolesChart: React.FC<
  GCDashboardReportsBySubsChartProps
> = (props) => {
  // TODO change any
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(defaultRange);
  const { data, error, loading } = useGetReportsByRangeQuery({
    variables: {
      projectId: props.projectId,
      _gte: dateRange[0].toISOString(),
      _lte: dateRange[1].toISOString(),
    },
    onCompleted: (data) => {
      console.log(`completed. data:`);
      console.log(data);
    },
  });
  if (error) {
    console.log(`error:`);
    console.error(error);
  }

  const chartData = useMemo<ChartData>(() => {
    const subMap = new Map<string, any>();

    data &&
      data.reports.forEach((report: any) => {
        const roles = data.worker_role.map((role) => role.value);

        const getRoleCount = (role: string, workerList: any): number =>
          workerList.filter(
            (worker: any) => worker.project_worker.worker_role === role,
          ).length;

        // for each role for each report by sub on date
        roles.forEach((role: string) => {
          const template: {
            name: string;
            date: string;
            title: string;
            count?: number;
          } = {
            name: report.subcontractor.name as string,
            date: dayjs(report.created_at).format("ll").toString(),
            title: role,
          };
          const key = `${report.subcontractor.name}_${report.created_at}_${role}`;

          if (!subMap.has(key)) {
            template.count = getRoleCount(role, report.report_project_workers);
            subMap.set(key, template);
          } else {
            const existing = subMap.get(key);
            existing.count += getRoleCount(role, report.report_project_workers);
          }
        });
      });
    return Array.from(subMap.values());
  }, [data]);

  const handleDateRangeChange = (
    newRange: [Dayjs | null, Dayjs | null] | null,
  ) => {
    const newDate1 = newRange && newRange[0] ? newRange[0] : defaultRange[0];
    const newDate2 = newRange && newRange[1] ? newRange[1] : defaultRange[1];
    setDateRange(
      newDate1.isBefore(newDate2)
        ? [newDate1.startOf("d"), newDate2.endOf("d")]
        : [newDate2.startOf("d"), newDate1.endOf("d")],
    );
  };

  console.log(`data`);
  console.log(data);
  console.log(`chart data:`);
  console.log(chartData);

  return (
    <ChartWrapperCard
      title={`PTPs by Roles`}
      extraTop={
        <Space>
          <RangePicker onChange={handleDateRangeChange} value={dateRange} />
        </Space>
      }
    >
      {loading ? (
        <Skeleton loading />
      ) : (
        <SubsByChartUI chartData={chartData} dateRange={dateRange} />
      )}
    </ChartWrapperCard>
  );
};

export default GCProjectPreTaskPlansByRolesChart;

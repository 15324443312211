/**
 * @generated SignedSource<<a56ee3092be459cf5a6edf830a95eb68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type GCSdsQuery$variables = {
  projectId: string;
};
export type GCSdsQuery$data = {
  readonly project_subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly subcontractor: {
          readonly name: string;
          readonly safety_data_sheets_aggregate: {
            readonly aggregate: {
              readonly count: number;
            } | null | undefined;
          };
        };
        readonly subcontractor_id: string;
      };
    }>;
  };
  readonly safety_data_sheet_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly company_name: string;
        readonly company_safety_data_sheet_id: string | null | undefined;
        readonly created_at: string;
        readonly created_by_user: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
        readonly file_type: string;
        readonly id: string;
        readonly issue_date: string | null | undefined;
        readonly parent_sds: {
          readonly project_id: string | null | undefined;
          readonly subcontractor_id: string | null | undefined;
        } | null | undefined;
        readonly pk: string;
        readonly product_name: string;
        readonly project: {
          readonly name: string;
        } | null | undefined;
        readonly project_id: string | null | undefined;
        readonly reference_number: string | null | undefined;
        readonly subcontractor: {
          readonly name: string;
        } | null | undefined;
        readonly subcontractor_id: string | null | undefined;
        readonly url: string;
      };
    }>;
  };
};
export type GCSdsQuery = {
  response: GCSdsQuery$data;
  variables: GCSdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = {
  "fields": [
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "_eq",
          "variableName": "projectId"
        }
      ],
      "kind": "ObjectValue",
      "name": "project_id"
    }
  ],
  "kind": "ObjectValue",
  "name": "where"
},
v2 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "subcontractor": {
        "name": "asc"
      }
    }
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    (v1/*: any*/)
  ],
  "concreteType": "safety_data_sheet_aggregate",
  "kind": "LinkedField",
  "name": "safety_data_sheets_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "safety_data_sheet_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "_is_null": true
},
v7 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "product_name": "asc"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "company_safety_data_sheet_id": (v6/*: any*/),
      "deleted_at": (v6/*: any*/)
    }
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "product_name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company_name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v14 = [
  (v4/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reference_number",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "issue_date",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company_safety_data_sheet_id",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "file_type",
  "storageKey": null
},
v20 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v21 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v22 = [
  (v4/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCSdsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "safety_data_sheetConnection",
        "kind": "LinkedField",
        "name": "safety_data_sheet_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_data_sheetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_data_sheet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "created_by_user",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": (v14/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "safety_data_sheet",
                    "kind": "LinkedField",
                    "name": "parent_sds",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "safety_data_sheet_connection(order_by:{\"product_name\":\"asc\"},where:{\"company_safety_data_sheet_id\":{\"_is_null\":true},\"deleted_at\":{\"_is_null\":true}})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCSdsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "safety_data_sheetConnection",
        "kind": "LinkedField",
        "name": "safety_data_sheet_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_data_sheetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_data_sheet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v22/*: any*/),
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "created_by_user",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": (v22/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "safety_data_sheet",
                    "kind": "LinkedField",
                    "name": "parent_sds",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v3/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "safety_data_sheet_connection(order_by:{\"product_name\":\"asc\"},where:{\"company_safety_data_sheet_id\":{\"_is_null\":true},\"deleted_at\":{\"_is_null\":true}})"
      }
    ]
  },
  "params": {
    "cacheID": "9e23b43f6aca07560d0d7d7f836def5a",
    "id": null,
    "metadata": {},
    "name": "GCSdsQuery",
    "operationKind": "query",
    "text": "query GCSdsQuery(\n  $projectId: uuid!\n) {\n  project_subcontractor_connection(where: {project_id: {_eq: $projectId}}, order_by: {subcontractor: {name: asc}}) {\n    edges {\n      node {\n        subcontractor_id\n        subcontractor {\n          name\n          safety_data_sheets_aggregate(where: {project_id: {_eq: $projectId}}) {\n            aggregate {\n              count\n            }\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n  safety_data_sheet_connection(where: {deleted_at: {_is_null: true}, company_safety_data_sheet_id: {_is_null: true}}, order_by: {product_name: asc}) {\n    edges {\n      node {\n        id\n        pk: id\n        product_name\n        company_name\n        created_at\n        project_id\n        project {\n          name\n          id\n        }\n        subcontractor_id\n        reference_number\n        issue_date\n        url\n        company_safety_data_sheet_id\n        file_type\n        created_by_user {\n          id\n          pk: id\n          name\n        }\n        subcontractor {\n          name\n          id\n        }\n        parent_sds {\n          project_id\n          subcontractor_id\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b32e89c385208870e16d1889cfc2ffd8";

export default node;

/**
 * @generated SignedSource<<8b87a91a85291ba4e91e76af1eac44d5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AGCGeneralContractors_UpdateProject_Mutation$variables = {
  _set: project_set_input;
  gcId: string;
};
export type AGCGeneralContractors_UpdateProject_Mutation$data = {
  readonly update_project: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly completed: boolean;
      readonly id: string;
    }>;
  } | null | undefined;
};
export type AGCGeneralContractors_UpdateProject_Mutation = {
  response: AGCGeneralContractors_UpdateProject_Mutation$data;
  variables: AGCGeneralContractors_UpdateProject_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gcId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "gcId"
              }
            ],
            "kind": "ObjectValue",
            "name": "general_contractor_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_mutation_response",
    "kind": "LinkedField",
    "name": "update_project",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completed",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AGCGeneralContractors_UpdateProject_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AGCGeneralContractors_UpdateProject_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a930d76790c1d42be44775e2b0a001e8",
    "id": null,
    "metadata": {},
    "name": "AGCGeneralContractors_UpdateProject_Mutation",
    "operationKind": "mutation",
    "text": "mutation AGCGeneralContractors_UpdateProject_Mutation(\n  $gcId: uuid!\n  $_set: project_set_input!\n) {\n  update_project(where: {general_contractor_id: {_eq: $gcId}}, _set: $_set) {\n    affected_rows\n    returning {\n      id\n      completed\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2147f684ec6a79b19e22ccc15db301d6";

export default node;

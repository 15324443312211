/**
 * @generated SignedSource<<6510a9910857be21575c49f97fd8e8b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCInviteSubAdminsToAddJhasUpdateTaskMutation$variables = {
  _set: task_set_input;
  taskId: string;
};
export type GCInviteSubAdminsToAddJhasUpdateTaskMutation$data = {
  readonly update_task_by_pk: {
    readonly creation_request_id: string | null | undefined;
    readonly id: string;
  } | null | undefined;
};
export type GCInviteSubAdminsToAddJhasUpdateTaskMutation = {
  response: GCInviteSubAdminsToAddJhasUpdateTaskMutation$data;
  variables: GCInviteSubAdminsToAddJhasUpdateTaskMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "taskId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "task",
    "kind": "LinkedField",
    "name": "update_task_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "creation_request_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCInviteSubAdminsToAddJhasUpdateTaskMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCInviteSubAdminsToAddJhasUpdateTaskMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f817b6a8501bdae30435a6d2d573c967",
    "id": null,
    "metadata": {},
    "name": "GCInviteSubAdminsToAddJhasUpdateTaskMutation",
    "operationKind": "mutation",
    "text": "mutation GCInviteSubAdminsToAddJhasUpdateTaskMutation(\n  $taskId: uuid!\n  $_set: task_set_input!\n) {\n  update_task_by_pk(pk_columns: {id: $taskId}, _set: $_set) {\n    id\n    creation_request_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "acd4575d0097c4c36a8d495bafc6918d";

export default node;

//TODO fix this so it doesn't hide errors but informs

const filterFailedTasks = async <T,>(tasks: Promise<T>[]) => {
  const waitres = await Promise.allSettled(tasks);
  const res: T[] = [];
  for (const task of waitres) {
    if (task.status === "fulfilled") {
      res.push(task.value);
    } else if (task.status === "rejected") {
      console.log("upload failed", task.reason);
    }
  }
  return res;
};
export default filterFailedTasks;

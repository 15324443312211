/**
 * @generated SignedSource<<d7676ff99133e9599e2be5cfc4055482>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { certificates_to_verify_bool_exp, certificates_to_verify_set_input, image_set_input, user_orientation_insert_input, worker_drug_test_bool_exp, worker_drug_test_insert_input, worker_drug_test_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyDrugtestUpsertWorkerOrientationMutation$variables = {
  certToVerifySet?: certificates_to_verify_set_input | null | undefined;
  certToVerifyWhere: certificates_to_verify_bool_exp;
  imageIds: ReadonlyArray<string>;
  imageSet: image_set_input;
  userOrientationObjects: ReadonlyArray<user_orientation_insert_input>;
  workerDTObjects: ReadonlyArray<worker_drug_test_insert_input>;
  workerDTSet: worker_drug_test_set_input;
  workerDTWhere: worker_drug_test_bool_exp;
};
export type VerifyDrugtestUpsertWorkerOrientationMutation$data = {
  readonly insert_user_orientation: {
    readonly returning: ReadonlyArray<{
      readonly drug_test_id: string | null | undefined;
      readonly drug_test_updated_at: string | null | undefined;
      readonly drug_test_updated_by_user_id: string | null | undefined;
      readonly id: string;
      readonly project_id: string;
      readonly user_id: string;
    }>;
  } | null | undefined;
  readonly insert_worker_drug_test: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
  readonly update_certificates_to_verify: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_image: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_worker_drug_test: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type VerifyDrugtestUpsertWorkerOrientationMutation = {
  response: VerifyDrugtestUpsertWorkerOrientationMutation$data;
  variables: VerifyDrugtestUpsertWorkerOrientationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certToVerifySet"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certToVerifyWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "imageIds"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "imageSet"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userOrientationObjects"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerDTObjects"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerDTSet"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerDTWhere"
},
v8 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "workerDTSet"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "workerDTWhere"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = [
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "worker_drug_test",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      (v10/*: any*/),
      {
        "alias": "pk",
        "args": null,
        "kind": "ScalarField",
        "name": "__id_pk",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v12 = [
  (v9/*: any*/)
],
v13 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "_set",
      "variableName": "certToVerifySet"
    },
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "certToVerifyWhere"
    }
  ],
  "concreteType": "certificates_to_verify_mutation_response",
  "kind": "LinkedField",
  "name": "update_certificates_to_verify",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "workerDTObjects"
  }
],
v15 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "_set",
      "variableName": "imageSet"
    },
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_in",
              "variableName": "imageIds"
            }
          ],
          "kind": "ObjectValue",
          "name": "id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "image_mutation_response",
  "kind": "LinkedField",
  "name": "update_image",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v16 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "userOrientationObjects"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "user_orientation_user_id_project_id_key",
      "update_columns": [
        "drug_test_id",
        "drug_test_updated_at",
        "drug_test_updated_by_user_id"
      ]
    }
  }
],
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drug_test_id",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drug_test_updated_at",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drug_test_updated_by_user_id",
  "storageKey": null
},
v22 = [
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "worker_drug_test",
    "kind": "LinkedField",
    "name": "returning",
    "plural": true,
    "selections": [
      (v10/*: any*/),
      {
        "alias": "pk",
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": "pk",
        "args": null,
        "filters": null,
        "handle": "pk",
        "key": "",
        "kind": "ScalarHandle",
        "name": "id"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyDrugtestUpsertWorkerOrientationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "worker_drug_test_mutation_response",
        "kind": "LinkedField",
        "name": "update_worker_drug_test",
        "plural": false,
        "selections": (v11/*: any*/),
        "storageKey": null
      },
      (v13/*: any*/),
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "worker_drug_test_mutation_response",
        "kind": "LinkedField",
        "name": "insert_worker_drug_test",
        "plural": false,
        "selections": (v11/*: any*/),
        "storageKey": null
      },
      (v15/*: any*/),
      {
        "alias": null,
        "args": (v16/*: any*/),
        "concreteType": "user_orientation_mutation_response",
        "kind": "LinkedField",
        "name": "insert_user_orientation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_orientation",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": "id",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Operation",
    "name": "VerifyDrugtestUpsertWorkerOrientationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "worker_drug_test_mutation_response",
        "kind": "LinkedField",
        "name": "update_worker_drug_test",
        "plural": false,
        "selections": (v22/*: any*/),
        "storageKey": null
      },
      (v13/*: any*/),
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "worker_drug_test_mutation_response",
        "kind": "LinkedField",
        "name": "insert_worker_drug_test",
        "plural": false,
        "selections": (v22/*: any*/),
        "storageKey": null
      },
      (v15/*: any*/),
      {
        "alias": null,
        "args": (v16/*: any*/),
        "concreteType": "user_orientation_mutation_response",
        "kind": "LinkedField",
        "name": "insert_user_orientation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "user_orientation",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d15470a171c8cdf30fbc3f1006b0e36a",
    "id": null,
    "metadata": {},
    "name": "VerifyDrugtestUpsertWorkerOrientationMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyDrugtestUpsertWorkerOrientationMutation(\n  $imageIds: [uuid!]!\n  $imageSet: image_set_input!\n  $userOrientationObjects: [user_orientation_insert_input!]!\n  $certToVerifyWhere: certificates_to_verify_bool_exp!\n  $certToVerifySet: certificates_to_verify_set_input\n  $workerDTObjects: [worker_drug_test_insert_input!]!\n  $workerDTSet: worker_drug_test_set_input!\n  $workerDTWhere: worker_drug_test_bool_exp!\n) {\n  update_worker_drug_test(where: $workerDTWhere, _set: $workerDTSet) {\n    affected_rows\n    returning {\n      id\n      pk: id\n    }\n  }\n  update_certificates_to_verify(where: $certToVerifyWhere, _set: $certToVerifySet) {\n    affected_rows\n  }\n  insert_worker_drug_test(objects: $workerDTObjects) {\n    affected_rows\n    returning {\n      id\n      pk: id\n    }\n  }\n  update_image(where: {id: {_in: $imageIds}}, _set: $imageSet) {\n    affected_rows\n  }\n  insert_user_orientation(objects: $userOrientationObjects, on_conflict: {constraint: user_orientation_user_id_project_id_key, update_columns: [drug_test_id, drug_test_updated_at, drug_test_updated_by_user_id]}) {\n    returning {\n      id\n      project_id\n      user_id\n      drug_test_id\n      drug_test_updated_at\n      drug_test_updated_by_user_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "67fb05e567dd983608e0237b9cd62bab";

export default node;

import React, { useState } from "react";
import { Button, message, notification } from "antd";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { SiteOrientationStepsUIProfilePhotoMutation } from "src/common/types/generated/relay/SiteOrientationStepsUIProfilePhotoMutation.graphql";
import uploadImage from "src/common/functions/upload-utility/uploadImage";
import useUpdateUserWorker from "src/common/api/relay/mutationHooks/useUpdateUserWorker";
import * as uuid from "uuid";
import Image from "src/common/components/general/images/Image";

import dayjs from "dayjs";
import {
  OacUserType,
  SiteOrientationStepProps,
  UserType,
  WorkerType,
} from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { OrientationType } from "src/common/types/manual/OrientationType";
import { CameraOutlined } from "@ant-design/icons";
import { IconUser } from "@tabler/icons";
import FullScreenCamera from "../../../../../common/components/camera/FullScreenCamera";
import { CurrentLangStrings } from "../../../../../utility-features/i18n/language-utils/i18nTypes";
import safeRequestHandler from "../../../../../utility-features/error-handling/safeRequestHandler";
import useAuthUser from "src/common/hooks/useAuthUser";
import { graphql } from "babel-plugin-relay/macro";

export interface SiteOrientationStepsUIProfilePhotoProps
  extends SiteOrientationStepProps {
  user: WorkerType | OacUserType | UserType;
  projectId?: string;
  type: OrientationType;
  requireProfile?: boolean;
  onNewProfilePhotoUpload: (newProfilePhotoImage?: string) => void;
  langStrings: CurrentLangStrings;
}

const profilePhotoDimensions = {
  width: 1280,
  height: 1920,
  // aspect ratio should be 2:3
};

const SiteOrientationStepsUIProfilePhoto: React.FC<
  SiteOrientationStepsUIProfilePhotoProps
> = ({ user, langStrings, type, ...props }) => {
  const [profilePhoto, setProfilePhoto] = useState(
    user?.profileImg?.new || null,
  );
  const authUser = useAuthUser();
  const [cameraOpened, setCameraOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateProfileAndInsertImage] =
    useAsyncMutation<SiteOrientationStepsUIProfilePhotoMutation>(graphql`
      mutation SiteOrientationStepsUIProfilePhotoMutation(
        $certsToVerifyObjects: [certificates_to_verify_insert_input!]!
        $userId: uuid!
        $imgId: uuid!
        $imageObj: image_insert_input!
      ) {
        insert_image_one(object: $imageObj) {
          id
        }
        update_user_by_pk(
          _set: { profile_picture_id: $imgId }
          pk_columns: { id: $userId }
        ) {
          id
          profile_picture_id
        }

        insert_certificates_to_verify(objects: $certsToVerifyObjects) {
          affected_rows
        }
      }
    `);
  const [updateUserData, isUpdating] = useUpdateUserWorker();
  const updateRegistrationQuestions = async () => {
    if (user?.userType === "worker" && user.id && type !== "universal") {
      try {
        await updateUserData({
          variables: {
            userId: user.id,
            userSet:
              user.emergencyPersonName && user.emergencyPersonPhone
                ? {
                    emergency_contact:
                      user.emergencyPersonName +
                      ": " +
                      user.emergencyPersonPhone,
                  }
                : {},
            workerSet: user
              ? {
                  ...(user.age?.new
                    ? {
                        age: user.age.new.val,
                        age_entered_at: dayjs().toISOString(),
                      }
                    : {}),
                  ...(user.veteranType?.new
                    ? { veteran_type: user.veteranType.new }
                    : {}),
                  ...(user.ethnicity?.new
                    ? { ethnicity: user.ethnicity.new }
                    : {}),
                  ...(user.gender?.new ? { gender: user.gender.new } : {}),
                  ...(user.workYears?.new
                    ? {
                        years_in_construction: user.workYears.new.val,
                        years_in_construction_entered_at: dayjs().toISOString(),
                      }
                    : {}),
                  ...(user.yearsWithEmployer
                    ? {
                        years_with_employer: user.yearsWithEmployer,
                        years_with_employer_entered_at: dayjs().toISOString(),
                      }
                    : {}),
                  ...(user.residence?.cityZipCode?.id
                    ? {
                        city_zip_code_id: user.residence.cityZipCode.id,
                      }
                    : {}),
                }
              : {},
          },
        });
      } catch (e) {
        notification.error({
          message: "Question answers not inserted properly",
          description:
            "Error while inserting demographic questions result please go back and try again",
        });
      }
    }
  };
  const onEnter = safeRequestHandler(async () => {
    updateRegistrationQuestions();
    try {
      if (profilePhoto && user?.id) {
        setLoading(true);
        const uploadProfile = await uploadImage(profilePhoto);
        const imgId = uuid.v4();
        await updateProfileAndInsertImage({
          variables: {
            imgId,
            userId: user.id,
            imageObj: {
              ...uploadProfile,
              id: imgId,
              description: "Profile Photo",
              created_by_user_id: authUser.uid,
            },
            certsToVerifyObjects:
              user.userType === "worker"
                ? [
                    {
                      worker_id: user?.id,
                      project_id: props.projectId,
                      document: "profilePhoto",
                      uploaded_by_uid: authUser.uid,
                      status: "submitted",
                      front_image_id: imgId,
                    },
                  ]
                : [],
          },
        });

        props.onNewProfilePhotoUpload(profilePhoto);
      }
      props.onNext();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
      notification.error({
        message: "Profile Photo Upload failed",
        description: e instanceof Error ? e.message : JSON.stringify(e),
      });
    }
  });

  const oldPhoto = user?.profileImg?.old;
  return (
    <SiteFeatureStepLayout
      nextButtonDisabled={
        props.requireProfile && !user?.profileImg?.old && !profilePhoto
      }
      loading={loading}
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
    >
      <SiteFeatureStepsInputLayout
        headline={
          <span className={`text-center block`}>
            {!oldPhoto
              ? langStrings.strings.addYourPhoto
              : langStrings.strings.updateYourPhoto}
          </span>
        }
      >
        <div className={`flex flex-col items-center justify-center gap-1`}>
          {!!oldPhoto && (
            <p className={`text-center`}>
              You submitted your photo earlier. You can skip this step.
            </p>
          )}
          <div
            className={`w-4 h-6 desktop:w-8 desktop:h-12 rounded-0.5 overflow-hidden`}
          >
            {cameraOpened && (
              <FullScreenCamera
                onClose={() => {
                  setCameraOpened(false);
                }}
                onCapture={([image]) => setProfilePhoto(image)}
                photoDimensions={profilePhotoDimensions}
                facingMode={`user`}
              />
            )}
            <Image
              src={profilePhoto ?? undefined}
              preview={true}
              icon={IconUser}
            />
          </div>
          <div className={`flex flex-col gap-0.5 relative inset-x-0 mx-auto`}>
            <Button
              // disabled={!profilePhoto}
              block
              type={"primary"}
              onClick={() => {
                setCameraOpened(true);
              }}
              icon={<CameraOutlined />}
            >
              {"Open camera"}
            </Button>
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationStepsUIProfilePhoto;

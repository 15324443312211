/**
 * @generated SignedSource<<3de55ff8a1570b2590238eba1514ca85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SFUpdateCertsData_total$data = {
  readonly allSubcontractorsToAddConnection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly " $fragmentType": "SFUpdateCertsData_total";
};
export type SFUpdateCertsData_total$key = {
  readonly " $data"?: SFUpdateCertsData_total$data;
  readonly " $fragmentSpreads": FragmentRefs<"SFUpdateCertsData_total">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./SFUpdateCertsDataTotalRefetchableQuery.graphql')
    }
  },
  "name": "SFUpdateCertsData_total",
  "selections": [
    {
      "alias": "allSubcontractorsToAddConnection",
      "args": [
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "worker_certificationConnection",
      "kind": "LinkedField",
      "name": "worker_certification_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "worker_certificationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "worker_certification",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};

(node as any).hash = "600e21fef95e55a6867d72d9319ea4ff";

export default node;

/**
 * @generated SignedSource<<a0efdb1063a39d50ef0956da5d12a483>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { user_set_input, worker_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useUpdateUserWorkerMutation$variables = {
  userId: string;
  userSet: user_set_input;
  workerSet: worker_set_input;
};
export type useUpdateUserWorkerMutation$data = {
  readonly update_user_by_pk: {
    readonly email: string | null | undefined;
    readonly emergency_contact: string | null | undefined;
    readonly id: string;
    readonly name: string;
    readonly phone_number: string | null | undefined;
  } | null | undefined;
  readonly update_worker_by_pk: {
    readonly age: number | null | undefined;
    readonly ethnicity: string | null | undefined;
    readonly gender: string | null | undefined;
    readonly uid: string;
    readonly veteran_type: string | null | undefined;
  } | null | undefined;
};
export type useUpdateUserWorkerMutation = {
  response: useUpdateUserWorkerMutation$data;
  variables: useUpdateUserWorkerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userSet"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "workerSet"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "_set",
      "variableName": "userSet"
    },
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "userId"
        }
      ],
      "kind": "ObjectValue",
      "name": "pk_columns"
    }
  ],
  "concreteType": "user",
  "kind": "LinkedField",
  "name": "update_user_by_pk",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone_number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emergency_contact",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "workerSet"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "uid",
        "variableName": "userId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gender",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "age",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ethnicity",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "veteran_type",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateUserWorkerMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "worker",
        "kind": "LinkedField",
        "name": "update_worker_by_pk",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateUserWorkerMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "worker",
        "kind": "LinkedField",
        "name": "update_worker_by_pk",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e2ddd1af06e7ab87c941b1ab7ca62013",
    "id": null,
    "metadata": {},
    "name": "useUpdateUserWorkerMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateUserWorkerMutation(\n  $userId: uuid!\n  $userSet: user_set_input!\n  $workerSet: worker_set_input!\n) {\n  update_user_by_pk(pk_columns: {id: $userId}, _set: $userSet) {\n    id\n    name\n    email\n    phone_number\n    emergency_contact\n  }\n  update_worker_by_pk(pk_columns: {uid: $userId}, _set: $workerSet) {\n    uid\n    gender\n    age\n    ethnicity\n    veteran_type\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f22e83a2099805d654396cfc00f9ea2";

export default node;

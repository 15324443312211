import { useEffect, useState } from "react";
import SignaturePad from "signature_pad";
import { CustomSignatureCanvasProps } from "./CustomSignatureCanvas";
import useUploadSignature, {
  UploadSignatureReturnType,
} from "./useUploadSignature";

interface SignaturePadHookProps extends UploadSignatureReturnType {
  signaturePadProps: CustomSignatureCanvasProps;
  isEmpty: boolean;
}

const useSignaturePad: () => SignaturePadHookProps = () => {
  const [signaturePad, setSignaturePad] = useState<SignaturePad | null>(null);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const uploadSignatureReturn = useUploadSignature(signaturePad);
  useEffect(() => {
    const onEndStroke = () => {
      setIsEmpty(!signaturePad || signaturePad?.isEmpty());
    };

    signaturePad?.addEventListener("endStroke", onEndStroke);
    return () => {
      signaturePad?.removeEventListener("endStroke", onEndStroke);
    };
  }, [signaturePad]);

  return {
    ...uploadSignatureReturn,
    isEmpty,
    signaturePadProps: {
      signaturePadState: [signaturePad, setSignaturePad],
      loading: uploadSignatureReturn.uploading,
      isEmptyState: [isEmpty, setIsEmpty],
    },
  };
};

export default useSignaturePad;

/**
 * @generated SignedSource<<e7933a04c4dc6cf0154a198daa4f9c34>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CrewTable_project_crew$data = {
  readonly project_crew_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly deleted_at: string | null | undefined;
        readonly id: string;
        readonly lead_foreman_project_worker: {
          readonly id: string;
          readonly pk: string;
          readonly subcontractor_worker: {
            readonly id: string;
          } | null | undefined;
          readonly user: {
            readonly id: string;
            readonly name: string;
            readonly pk: string;
            readonly role: string;
            readonly username: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
        readonly name: string;
        readonly pk: string;
        readonly project: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
        readonly project_subcontractor_crew_onsite_periods: ReadonlyArray<{
          readonly id: string;
        }>;
        readonly project_workers: ReadonlyArray<{
          readonly hard_hat_number: string | null | undefined;
          readonly id: string;
          readonly pk: string;
          readonly title: {
            readonly translation: {
              readonly clientText: string;
              readonly en: string;
              readonly es: string;
              readonly id: string;
              readonly original: string;
              readonly pk: string;
              readonly pt: string;
            };
          } | null | undefined;
          readonly user: {
            readonly email: string | null | undefined;
            readonly name: string;
          } | null | undefined;
          readonly worker_role: string;
        }>;
        readonly project_workers_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
        readonly subcontractor: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
      };
    }>;
  };
  readonly " $fragmentType": "CrewTable_project_crew";
};
export type CrewTable_project_crew$key = {
  readonly " $data"?: CrewTable_project_crew$data;
  readonly " $fragmentSpreads": FragmentRefs<"CrewTable_project_crew">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "project_crew_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "_is_null": true
},
v6 = [
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./CrewTableRefetchableQuery.graphql')
    }
  },
  "name": "CrewTable_project_crew",
  "selections": [
    {
      "alias": "project_crew_connection",
      "args": null,
      "concreteType": "project_crewConnection",
      "kind": "LinkedField",
      "name": "__CrewTableQuery_project_crew_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project_crewEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "project_crew",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deleted_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "user": {
                          "name": "asc"
                        }
                      }
                    },
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "subcontractor_worker": {}
                      }
                    }
                  ],
                  "concreteType": "project_worker",
                  "kind": "LinkedField",
                  "name": "project_workers",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "worker_role",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hard_hat_number",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "worker_title",
                      "kind": "LinkedField",
                      "name": "title",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "text_translation",
                          "kind": "LinkedField",
                          "name": "translation",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "en",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "es",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "pt",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "original",
                              "storageKey": null
                            },
                            (v2/*: any*/),
                            {
                              "alias": "clientText",
                              "args": null,
                              "kind": "ScalarField",
                              "name": "__original_clientText",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "user",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "project_workers(order_by:{\"user\":{\"name\":\"asc\"}},where:{\"subcontractor_worker\":{}})"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "subcontractor",
                  "kind": "LinkedField",
                  "name": "subcontractor",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": (v4/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "closed_at": (v5/*: any*/)
                      }
                    }
                  ],
                  "concreteType": "project_subcontractor_crew_onsite_period",
                  "kind": "LinkedField",
                  "name": "project_subcontractor_crew_onsite_periods",
                  "plural": true,
                  "selections": (v6/*: any*/),
                  "storageKey": "project_subcontractor_crew_onsite_periods(where:{\"closed_at\":{\"_is_null\":true}})"
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "where",
                      "value": {
                        "deleted_at": (v5/*: any*/),
                        "subcontractor_worker": {}
                      }
                    }
                  ],
                  "concreteType": "project_worker_aggregate",
                  "kind": "LinkedField",
                  "name": "project_workers_aggregate",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "project_worker_aggregate_fields",
                      "kind": "LinkedField",
                      "name": "aggregate",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "count",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "project_workers_aggregate(where:{\"deleted_at\":{\"_is_null\":true},\"subcontractor_worker\":{}})"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project_worker",
                  "kind": "LinkedField",
                  "name": "lead_foreman_project_worker",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "worker",
                      "kind": "LinkedField",
                      "name": "subcontractor_worker",
                      "plural": false,
                      "selections": (v6/*: any*/),
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "user",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v2/*: any*/),
                        (v3/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "username",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "role",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "7214d0ab48c933c01f1cbdd41fc835f4";

export default node;

/**
 * @generated SignedSource<<d27c9c2d331573aab5a70890c16b76a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectLinking_AddOrientationProject_Mutation$variables = {
  _set?: project_set_input | null | undefined;
  newLinkedProjectIds: ReadonlyArray<string>;
};
export type GCProjectLinking_AddOrientationProject_Mutation$data = {
  readonly update_project: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
      readonly orientation_project_id: string;
    }>;
  } | null | undefined;
};
export type GCProjectLinking_AddOrientationProject_Mutation = {
  response: GCProjectLinking_AddOrientationProject_Mutation$data;
  variables: GCProjectLinking_AddOrientationProject_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_set"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newLinkedProjectIds"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "newLinkedProjectIds"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_mutation_response",
    "kind": "LinkedField",
    "name": "update_project",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orientation_project_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectLinking_AddOrientationProject_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectLinking_AddOrientationProject_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "44812bbdccbbfeebfb42f4c93110a9ef",
    "id": null,
    "metadata": {},
    "name": "GCProjectLinking_AddOrientationProject_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectLinking_AddOrientationProject_Mutation(\n  $_set: project_set_input\n  $newLinkedProjectIds: [uuid!]!\n) {\n  update_project(_set: $_set, where: {id: {_in: $newLinkedProjectIds}}) {\n    returning {\n      name\n      id\n      orientation_project_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "904ec63cb7d2543377b5075569f47311";

export default node;

import { Form, Upload, Button, Card, Image, Modal } from "antd";
import dayjs from "dayjs";
import { useState, memo } from "react";
import PdfModal from "src/common/components/modals/PdfModal";
import VideoPlayerForAnyUrl from "src/common/components/VideoPlayer/VideoPlayerForAnyUrl";
import normalizeUploadFile from "src/common/functions/normalizeUploadFile";
import { uploadFilesWithPath } from "src/common/functions/upload-utility/uploadFiles";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  GetObservationByPkQuery,
  Lang_Code_Enum,
  useDeleteDocumentAndAddObsHistoryMutation,
  useInsertObservationDocMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import * as uuid from "uuid";
import createObservationPatch from "../../utils/createObservationPatch";
import { produce } from "immer";
import {
  IconClipboardData,
  IconDeviceAudioTape,
  IconTrash,
  IconVideo,
} from "@tabler/icons";
import Meta from "antd/es/card/Meta";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import Icon from "src/common/components/general/Icon";
import { InboxOutlined, LeftCircleOutlined } from "@ant-design/icons";
import AudioPlayer from "src/common/components/audio-player/AudioPlayer";
import { UploadedFileType } from "src/common/functions/upload-utility/uploadFile";
import uploadImage, {
  UploadResult,
} from "src/common/functions/upload-utility/uploadImage";
import uploadImageFiles from "src/common/functions/upload-utility/uploadImageFiles";

const ObservationDocUploader: React.FunctionComponent<{
  docType: "image" | "other";
  acceptedFiles: string;
  editing: boolean;
  observation: NonNullable<GetObservationByPkQuery["observation_by_pk"]>;
}> = memo(({ docType, observation, acceptedFiles, editing }) => {
  const authUser = useAuthUser();
  const [form] = Form.useForm();

  const [deleteDocAndAddHistory] = useDeleteDocumentAndAddObsHistoryMutation();
  const [uploadingDoc, setUploadingDoc] = useState(false);
  const [insertDocument, { loading: insertingDoc }] =
    useInsertObservationDocMutation();
  const [videoSelected, setVideoSelected] = useState<string>();
  const [selectedPdf, setSelectedPdf] = useState<string>();

  const isSubmitted =
    observation.status !== "draft" &&
    (!observation.observation_inspection ||
      !!observation.observation_inspection.submitted_on);
  const [currentToUploadCount, setCurrentToCOuntCount] = useState(0);
  const onRemoveDocClick = async (index: number) => {
    const item = observation.attached_files[index];
    if (item) {
      const newData = produce(observation, (draft) => {
        draft.attached_files = draft.attached_files.filter(
          (doc) => doc.id !== item.id,
        );
      });
      const patch = createObservationPatch(newData, observation);
      await deleteDocAndAddHistory({
        variables: {
          docId: item.id,
          editHistoryObjects: isSubmitted
            ? [
                {
                  comment: {
                    data: {
                      original: `removed ${
                        item.image ? "image" : item.document_type
                      }`,
                      lang: Lang_Code_Enum.En,
                    },
                  },
                  edit_type: item.image
                    ? "image_removal"
                    : `${item.document_type} removal`,
                  prev_val: item.url,
                  new_val: null,
                  observation_id: observation.id,
                  edited_by_uid: authUser.uid,
                  patch,
                },
              ]
            : [],
        },
        optimisticResponse: {
          delete_document_by_pk: { id: item.id, __typename: "document" },
          insert_observation_edit_history: {
            affected_rows: 1,
            returning: [],
          },
        },
        update: (cache, returningData) => {
          const returningEdits =
            returningData.data?.insert_observation_edit_history?.returning ||
            [];
          const deletedDocId = returningData.data?.delete_document_by_pk?.id;
          if (!deletedDocId) throw new Error("No deleted doc id found");
          cache.modify<typeof observation>({
            id: cache.identify(observation),
            fields: {
              observation_edits(existingEdits, { toReference }) {
                if (!existingEdits) return existingEdits;
                return [
                  ...existingEdits,
                  ...returningEdits.map((edit) => toReference(edit)!),
                ];
              },
              attached_files(existingFiles, { readField }) {
                return existingFiles.filter(
                  (v) => readField("id", v) !== deletedDocId,
                );
              },
            },
          });
        },
      });
    }
  };
  type DocType = {
    doc_type: "image" | "other";
    id: string;
    name: string;
    type: string;
    url: string;
  } & (
    | { doc_type: "other" }
    | { doc_type: "image"; imageResult: UploadResult }
  );
  const onAddDocs = async (uploadedDocs: DocType[]) => {
    const objectsToInsert = uploadedDocs.map((doc) => ({
      group_id: observation.id,
      author_id: authUser.uid,
      document_type: doc.type,
      type: "observation",
      name: doc.name,
      id: docType === "image" ? uuid.v4() : doc.id,
      url: doc.url,
      image:
        doc.doc_type === "image"
          ? { data: { id: doc.id, ...doc.imageResult } }
          : null,
    }));
    const expectedReturningDocs = objectsToInsert.map((doc) => ({
      __typename: "document" as const,
      ...doc,
      created_at: dayjs().format(),
      url: doc.url!,
      image:
        docType === "image" && doc.image
          ? {
              url: doc.url,
              __typename: "image" as const,
              id: doc.image.data.id,
            }
          : null,
    }));
    const newData = produce(observation, (draft) => {
      draft.attached_files = [
        ...expectedReturningDocs,
        ...draft.attached_files,
      ];
    });
    const patch = createObservationPatch(newData, observation);
    await insertDocument({
      variables: {
        objects: objectsToInsert,
        editHistoryObjects: isSubmitted
          ? objectsToInsert.map((doc) => ({
              comment: {
                data: {
                  lang: Lang_Code_Enum.En,
                  original:
                    "inserted " +
                    (docType === "image" ? "image_add" : doc.document_type),
                },
              },
              prev_val: null,
              new_val: doc.url,
              observation_id: observation.id,
              edited_by_uid: authUser.uid,
              edit_type:
                docType === "image"
                  ? "image_add"
                  : doc.document_type + ` added`,
              patch,
            }))
          : [],
      },
      optimisticResponse: {
        insert_document: {
          returning: expectedReturningDocs,
          affected_rows: expectedReturningDocs.length,
        },
        insert_observation_edit_history: { affected_rows: 1, returning: [] },
      },
      update: (cache, returningData) => {
        const returningEdits =
          returningData.data?.insert_observation_edit_history?.returning || [];
        const returningFiles = returningData.data?.insert_document?.returning;
        if (!returningFiles?.length)
          throw new Error("No inserted document found");
        cache.modify<typeof observation>({
          id: cache.identify(observation),
          fields: {
            observation_edits(existingEdits, { toReference }) {
              if (!existingEdits) return existingEdits;
              return [
                ...existingEdits,
                ...returningEdits.map((edit) => toReference(edit)!),
              ];
            },
            attached_files(existingFiles, { toReference }) {
              return [
                ...existingFiles,
                ...returningFiles.map((doc) => toReference(doc)!),
              ];
            },
          },
        });
      },
    });

    form.resetFields();
    setCurrentToCOuntCount(0);
  };
  return (
    <Card className="w-4/5">
      <Modal
        footer={<></>}
        width={700}
        open={!!videoSelected}
        onCancel={() => {
          setVideoSelected(undefined);
        }}
        destroyOnClose
      >
        {videoSelected && <VideoPlayerForAnyUrl url={videoSelected} />}
      </Modal>
      <PdfModal
        visible={!!selectedPdf}
        pdfUrl={selectedPdf}
        destroyOnClose
        onClose={() => setSelectedPdf(undefined)}
      />
      {docType === "image" ? "Images" : "Videos or Documents"}:{" "}
      <div className="flex gap-0.5 flex-wrap">
        {observation.attached_files.map((doc, i) => {
          return doc.image && docType === "image" ? (
            <Card
              key={doc.id}
              className="w-1/12"
              styles={{
                body: {
                  padding: 2,
                  borderColor: "black",
                  borderWidth: 2,
                  borderStyle: "solid",
                },
              }}
            >
              <div className="relative z-0">
                <div className="flex justify-between cursor-pointer"></div>
                <Image
                  className="z-0"
                  src={doc.image!.url!}
                  key={doc.id}
                  alt={"Image: " + (i + 1)}
                />
              </div>
              <Meta
                description={
                  <BPopconfirm
                    onConfirm={() => onRemoveDocClick(i)}
                    title="Remove this Image from the observation"
                  >
                    <div className="flex justify-between cursor-pointer p-0.5">
                      Delete <Icon icon={IconTrash} color="negative" />
                    </div>
                  </BPopconfirm>
                }
              />
            </Card>
          ) : !doc.image && doc.document_type && docType === "other" ? (
            <Card
              onClick={() => {
                if (doc.document_type?.includes("video")) {
                  setVideoSelected(doc.url);
                } else if (doc.document_type?.includes("audio")) {
                } else setSelectedPdf(doc.url);
              }}
              key={doc.id}
              styles={{
                body: {
                  padding: 5,
                  border: "1px solid black",
                },
              }}
            >
              <div className="flex mx-1 justify-between">
                <Icon
                  icon={
                    doc.document_type.includes("video")
                      ? IconVideo
                      : doc.document_type.includes("audio")
                      ? IconDeviceAudioTape
                      : IconClipboardData
                  }
                />
                <div className="cursor-pointer">
                  <BPopconfirm
                    onConfirm={() => onRemoveDocClick(i)}
                    title="Remove this Doc from the observation"
                  >
                    <Icon icon={IconTrash} color="negative" />
                  </BPopconfirm>
                </div>
              </div>{" "}
              {doc.name}
              {doc.document_type?.includes("audio") && (
                <AudioPlayer
                  audioSrc={doc.url}
                  allowedControls={["rewind", "skip"]}
                />
              )}{" "}
            </Card>
          ) : null;
        })}
      </div>
      {editing && (
        <Form
          layout="vertical"
          form={form}
          onFinish={async (v) => {
            console.log(v, "SHDJKSHDB 1163r2763r2JHK");

            if (!v) return;
            const files = v[docType].map(
              (file: { originFileObj: File; name: string; type: string }) => ({
                file: file.originFileObj!,
                directoryName: "observation-doc",
              }),
            );
            let res: DocType[] = [];
            try {
              setUploadingDoc(true);
              if (docType === "image") {
                const uploadedImagesResPromises = await uploadImageFiles(files);

                res = (await Promise.all(uploadedImagesResPromises)).map(
                  (img, i) => ({
                    doc_type: "image",
                    id: uuid.v4(),
                    url: img.url,
                    imageResult: img,
                    name: files[i]?.name,
                    type: files[i]?.type,
                  }),
                );
              } else {
                res = (await uploadFilesWithPath(files)).map((file) => ({
                  doc_type: "other",
                  ...file,
                }));
              }
              setUploadingDoc(false);
            } catch (err) {
              setUploadingDoc(false);
              throw err;
            }
            await onAddDocs(res);
          }}
        >
          <Form.Item
            label={
              docType === "image"
                ? "Upload Images"
                : "Upload New Upload And Press Button to add them "
            }
            name={docType}
            getValueFromEvent={normalizeUploadFile}
          >
            <Upload.Dragger
              accept={acceptedFiles}
              listType="picture-card"
              onChange={(t) => {
                setCurrentToCOuntCount(t.fileList.length);
              }}
              disabled={insertingDoc || uploadingDoc}
              maxCount={4}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p>
                Click or drag-and-drop{" "}
                {docType === "image"
                  ? "Image(s)"
                  : "Document(s), Video(s), and Audio file(s)"}{" "}
                here
              </p>
            </Upload.Dragger>
          </Form.Item>
          {!!currentToUploadCount && (
            <Button
              type="primary"
              htmlType="submit"
              loading={insertingDoc || uploadingDoc}
            >
              {docType === "image" ? "Save" : "Add Documents To Observation"}
            </Button>
          )}
        </Form>
      )}
    </Card>
  );
});
export default ObservationDocUploader;

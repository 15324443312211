/**
 * @generated SignedSource<<99aee4fdbe8919ac2172564c537e70c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_onsite_period_bool_exp, project_subcontractor_onsite_period_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentOnsiteSettingUpdateSubOnsiteMutation$variables = {
  _set: project_subcontractor_onsite_period_set_input;
  where: project_subcontractor_onsite_period_bool_exp;
};
export type usePermanentOnsiteSettingUpdateSubOnsiteMutation$data = {
  readonly update_project_subcontractor_onsite_period: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type usePermanentOnsiteSettingUpdateSubOnsiteMutation = {
  response: usePermanentOnsiteSettingUpdateSubOnsiteMutation$data;
  variables: usePermanentOnsiteSettingUpdateSubOnsiteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_set"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "project_subcontractor_onsite_period_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_subcontractor_onsite_period",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "project_subcontractor_onsite_period",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentOnsiteSettingUpdateSubOnsiteMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePermanentOnsiteSettingUpdateSubOnsiteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f4e2a8c874aa3737ff6e8d59fbbc7fec",
    "id": null,
    "metadata": {},
    "name": "usePermanentOnsiteSettingUpdateSubOnsiteMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentOnsiteSettingUpdateSubOnsiteMutation(\n  $_set: project_subcontractor_onsite_period_set_input!\n  $where: project_subcontractor_onsite_period_bool_exp!\n) {\n  update_project_subcontractor_onsite_period(where: $where, _set: $_set) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3012ea6e05a36763d3a609857b18da65";

export default node;

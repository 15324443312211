/**
 * @generated SignedSource<<0d6e1027737c40a15a31f3c1c9a6de16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_worker_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WorkerProfileUpdateProjectWorkerMutation$variables = {
  id: string;
  set: project_worker_set_input;
};
export type WorkerProfileUpdateProjectWorkerMutation$data = {
  readonly update_project_worker_by_pk: {
    readonly id: string;
    readonly title: {
      readonly translation: {
        readonly en: string;
      };
    } | null | undefined;
    readonly title_id: string | null | undefined;
    readonly worker_role: string;
  } | null | undefined;
};
export type WorkerProfileUpdateProjectWorkerMutation = {
  response: WorkerProfileUpdateProjectWorkerMutation$data;
  variables: WorkerProfileUpdateProjectWorkerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "set"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_role",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerProfileUpdateProjectWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_worker",
        "kind": "LinkedField",
        "name": "update_project_worker_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_title",
            "kind": "LinkedField",
            "name": "title",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "text_translation",
                "kind": "LinkedField",
                "name": "translation",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WorkerProfileUpdateProjectWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_worker",
        "kind": "LinkedField",
        "name": "update_project_worker_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_title",
            "kind": "LinkedField",
            "name": "title",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "text_translation",
                "kind": "LinkedField",
                "name": "translation",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9a007c8bed99282f5c0e29adf6d54a6",
    "id": null,
    "metadata": {},
    "name": "WorkerProfileUpdateProjectWorkerMutation",
    "operationKind": "mutation",
    "text": "mutation WorkerProfileUpdateProjectWorkerMutation(\n  $set: project_worker_set_input!\n  $id: uuid!\n) {\n  update_project_worker_by_pk(_set: $set, pk_columns: {id: $id}) {\n    id\n    title_id\n    worker_role\n    title {\n      translation {\n        en\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "862751f94988ae6a3b0f2bb78907a738";

export default node;

/**
 * @generated SignedSource<<01d1b284ad925500f86e791bfcf6c3fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectOrientationDetail_DeleteSlide_Mutation$variables = {
  now: string;
  slideId: string;
};
export type GCProjectOrientationDetail_DeleteSlide_Mutation$data = {
  readonly update_orientation_slide: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly orientation_id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type GCProjectOrientationDetail_DeleteSlide_Mutation = {
  response: GCProjectOrientationDetail_DeleteSlide_Mutation$data;
  variables: GCProjectOrientationDetail_DeleteSlide_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "now"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slideId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "archived_at",
        "variableName": "now"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "archived_at",
        "value": {
          "_is_null": true
        }
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "slideId"
          }
        ],
        "kind": "ObjectValue",
        "name": "slide_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectOrientationDetail_DeleteSlide_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "orientation_slide_mutation_response",
        "kind": "LinkedField",
        "name": "update_orientation_slide",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "orientation_slide",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectOrientationDetail_DeleteSlide_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "orientation_slide_mutation_response",
        "kind": "LinkedField",
        "name": "update_orientation_slide",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "orientation_slide",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b0bc87a24ae1f3a4c771bc7172e8b00",
    "id": null,
    "metadata": {},
    "name": "GCProjectOrientationDetail_DeleteSlide_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectOrientationDetail_DeleteSlide_Mutation(\n  $slideId: uuid!\n  $now: timestamptz!\n) {\n  update_orientation_slide(where: {slide_id: {_eq: $slideId}, archived_at: {_is_null: true}}, _set: {archived_at: $now}) {\n    affected_rows\n    returning {\n      id\n      pk: id\n      orientation_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "48aac19a49eb1ce85a1edaad108e343f";

export default node;

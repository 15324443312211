import {
  IconBriefcase,
  IconCalendar,
  IconClock,
  IconRefresh,
  IconTie,
} from "@tabler/icons";
import {
  Alert,
  Button as AntdButton,
  Input,
  Menu,
  MenuProps,
  Popover,
} from "antd";
import Button from "../general/Button";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, forwardRef, useEffect, useRef, useState } from "react";
import { useRelayEnvironment } from "react-relay/hooks";
import { commitMutation } from "relay-runtime";
import OnSiteTag from "src/common/components/OnSiteTag";
import Tag from "src/common/components/general/Tag";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { useSiteOrientationGcDashboardDataQuery$data } from "src/common/types/generated/relay/useSiteOrientationGcDashboardDataQuery.graphql";
import { ProjectWorkerTable_ArchiveWorkerMutation } from "src/common/types/generated/relay/ProjectWorkerTable_ArchiveWorkerMutation.graphql";
import {
  ProjectWorkerTableQuery,
} from "src/common/types/generated/relay/ProjectWorkerTableQuery.graphql";
import {
  worker_drug_test_bool_exp
} from "src/common/types/generated/relay/types";

import { ProjectWorkerTable_project_worker$data } from "src/common/types/generated/relay/ProjectWorkerTable_project_worker.graphql";
import Icon from "../general/Icon";
import CertificationCard from "../workerCertificationCard/CertificationCard";
import DrugtestCard from "../workerCertificationCard/DrugtestCard";
import ProcessingCertCard from "../workerCertificationCard/ProcessingCertCard";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "./basic/DataScrollTable";
import { getLatestDate } from "../Project";
import ContactLink from "../general/ContactLink";
import InviteWorkerModal from "../projectWorkerDescription/InviteWorkerModal";
import capitalize from "src/common/functions/capitalize";
import {
  getCertificateExpiryValue,
  getCertificateExpiryValueFromDate,
} from "src/common/functions/getCertificateExpiryValue";
import { Certificate } from "src/root/routes/views/subcontractor/people/workers/SCWorkersUI";
import { auth } from "src/common/functions/firebase";
import getOrientationCompleted from "src/domain-features/siteorientation/entryRoutes/gcDashboard/utils/getOrientationCompleted";
import {
  QuizResult,
  QuizResultProps,
} from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/status/components/QuizResults";

export type ColumnKeys =
  | "name"
  | "subcontractor"
  | "hardHatNumber"
  | "title"
  | "role"
  | "orientatedOn"
  | "orientated_at"
  | "project"
  | "registeredDate"
  | "dateLogged"
  | "inPersonOrientatedDate"
  | "extra"
  | "status"
  | "archivedAt"
  | "drugTest"
  | "drugtestDate"
  | "universal_orientated_at"
  | "trainingAndCertifications";

const CONNECTION_NAME = "project_worker_connection";
export type DConnection =
  ProjectWorkerTable_project_worker$data[typeof CONNECTION_NAME];

type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  ProjectWorkerTableQuery,
  { pk: string }
> & {
  searchString?: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    ProjectWorkerTableQuery
  >["columns"];
  markDeleted?: boolean;
  showOrientationTag?: boolean;
  showArchiveButton?: boolean;
  showOnsiteTag?: boolean;
  startTime?: string;
  startDate?: string;
  onAddHH?: (user_id: string) => void;
  subs?: useSiteOrientationGcDashboardDataQuery$data["project_connection"]["edges"][0]["node"]["project_subcontractors"];
  titles?: useSiteOrientationGcDashboardDataQuery$data["worker_title_connection"]["edges"];
  showSubAdminTag?: boolean;
  hasInPerson?: boolean;
  hideContactInfo?: boolean;
  showRowBorderOnHover?: boolean;
  includeValidDTs?: boolean;
  includePrevPositiveProjectDTs?: boolean;
  drugtestWhere?: worker_drug_test_bool_exp;
  hasUniversal?: boolean;
  hasSlides?: boolean;
  assignHardHat?: boolean;
  allowEditHH?: boolean;
  projectId?: string;
  defaultSortColumnKey?: ColumnKeys;
  filterNotVisibleByDefault?: boolean;
  onRowItemClick?: (row: ProjectWorkerTableType) => void;
};

export type ProjectWorkerTableType = DConnection["edges"][number]["node"];

const saveHHMutation = graphql`
  mutation ProjectWorkerTable_SaveHHMutation(
    $projectUserId: uuid!
    $projectId: uuid!
    $userId: uuid!
    $hardHat: String!
  ) {
    update_project_worker_by_pk(
      pk_columns: { id: $projectUserId }
      _set: { hard_hat_number: $hardHat }
    ) {
      pk: id @__clientField(handle: "pk")
      hard_hat_number
    }
    update_project_employee_by_pk(
      pk_columns: { id: $projectUserId }
      _set: { hard_hat_number: $hardHat }
    ) {
      pk: id @__clientField(handle: "pk")
      hard_hat_number
    }

    update_project_worker(
      where: {
        project_id: { _eq: $projectId }
        worker_id: { _eq: $userId }
        id: { _neq: $projectUserId }
      }
      _set: { hard_hat_number: $hardHat }
    ) {
      affected_rows
    }
  }
`;
export const archiveWorkerMutation = graphql`
  mutation ProjectWorkerTable_ArchiveWorkerMutation(
    $projectWorkerId: uuid!
    $_set: project_worker_set_input
  ) {
    update_project_worker_by_pk(
      pk_columns: { id: $projectWorkerId }
      _set: $_set
    ) {
      pk: id @__clientField(handle: "pk")
      archived_at
      archived_by_uid
      archived_by_user {
        name
        role
      }
    }
  }
`;

interface EditableHHCellProps {
  value: string | null;
  record: { id: string; workerId: string };
  projectId: string;
  tableView: boolean;
  initiallyOpen: boolean;
}

export const EditableHHCell: FC<EditableHHCellProps> = ({
  value,
  record,
  projectId,
  ...props
}) => {
  const [editing, setEditing] = useState(value ? false : true);
  const [hhNum, setHhNum] = useState<string>(value ?? "");
  const environment = useRelayEnvironment();

  useEffect(() => {
    setHhNum(value ?? "");
    setEditing(!value);
  }, [value]);

  const onSave = () => {
    if (!hhNum) {
      return;
    }
    commitMutation(environment, {
      mutation: saveHHMutation,
      variables: {
        projectId,
        userId: record.workerId,
        projectUserId: record.id,
        hardHat: hhNum,
      },
      onCompleted: () => {
        setEditing(false);
      },
    });
  };
  const onCancel = () => {
    setEditing(false);
  };

  if (editing || props.initiallyOpen) {
    return (
      <Input
        value={hhNum}
        onChange={(e) => setHhNum(e.target.value)}
        // ref={editRef}
        // bordered={editing ? true : false}
        // defaultValue={value ?? ""}
        className="w-6"
        placeholder={"Add HH#"}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        size="middle"
        onBlur={onSave}
        onPressEnter={onSave}
        onKeyDown={(e) => {
          if (e.key === "Esc" || e.key === "Escape") {
            e.stopPropagation();
            e.preventDefault();
            setEditing(false);
          }
        }}
      />
    );
  }
  return (
    <div
      className="mt-0.25 editable-cell-value-wrap"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditing(true);
      }}
    >
      {props.tableView ? hhNum : `HH# ${hhNum}`}
    </div>
  );
};

// declare getSuffix to avoid depricated substr
function getSuffix(s: string, len: number) {
  return s.substring(Math.max(s.length - len, 0));
}

const returnVal = (valueA: any, valueB: any) =>
  !valueA || !valueB
    ? !valueA < !valueB
      ? 1
      : !valueA > !valueB
      ? -1
      : 0
    : valueA < valueB
    ? -1
    : valueA > valueB
    ? 1
    : 0;

const buttonstyle = {
  borderRadius: "20px",
  fontSize: "14px",
  height: "auto",
  color: `white`,
};
const ProjectWorkerTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Workers",
      markDeleted = false,
      showOrientationTag = false,
      showArchiveButton = false,
      showSubAdminTag = false,
      showOnsiteTag = false,
      includeValidDTs = false,
      includePrevPositiveProjectDTs = false,
      assignHardHat,
      searchString,
      hideContactInfo = false,
      hasUniversal,
      hasInPerson,
      hasSlides,
      projectId,
      datePickerDataTitle,
      datePickerDataIndex,
      headerComponent,
      customFilters,
      drugtestWhere = { id: { _is_null: true } },
      startDate = dayjs().startOf("d").format("YYYY-MM-DD"),
      startTime = dayjs().startOf("d").format(),
      extraColumns,
      where,
      allowEditHH,
      defaultSortColumnKey,
      ...props
    },
    ref,
  ) => {
    const [archiveWorker, isArchiving] =
      useAsyncMutation<ProjectWorkerTable_ArchiveWorkerMutation>(
        archiveWorkerMutation,
      );

    const [workerData, setWorkerData] = useState<
      | {
          id: string;
          email: string | undefined;
          phone: string | undefined;
          dataId: string | undefined;
        }
      | undefined
    >(undefined);
    return (
      <>
        {workerData && (
          <InviteWorkerModal
            key={workerData.id}
            visible={!!workerData}
            onFinish={(inviteId) => {
              setWorkerData(undefined);
            }}
            onClose={() => setWorkerData(undefined)}
            workerId={workerData.id}
            projectId={projectId ?? ""}
            email={workerData.email}
            phone={workerData.phone}
            dataId={workerData.dataId}
          />
        )}

        <DataScrollTable<DConnection, ColumnKeys, ProjectWorkerTableQuery>
          {...props}
          newCustomTableLook
          filterNotVisibleByDefault
          ref={ref}
          title={title}
          onRowClick={(row) => {
            // console.log("ROW ITEM CLICKED", row);
            props.onRowItemClick && props.onRowItemClick(row);
          }}
          where={{ ...where }}
          customFilters={customFilters}
          headerComponent={headerComponent}
          // style={{ width: "100%" }}
          extraQueryVariables={{
            includeOnSite: showOnsiteTag,
            startDate,
            includePrevPositiveProjectDTs,
            includeValidDTs,
            startTime,
            projectId,
            drugtestWhere,
          }}
          connectionName={CONNECTION_NAME}
          showRowBorderOnHover={props.showRowBorderOnHover}
          totalCountConnectionName={"allProjectWorkersConnection"}
          datePickerDataIndex={datePickerDataIndex}
          datePickerDataTitle={datePickerDataTitle}
          columns={[
            {
              title: "Status",
              key: "status",
              size: "md",
              dataIndex: [],
              render: (r, a) => {
                let orientationCompleted: boolean | null =
                  !!a.user_orientation?.completed_at;
                if (hasSlides && hasInPerson) {
                  orientationCompleted = !!(
                    orientationCompleted ||
                    (a.user_orientation?.in_person_orientated_at &&
                      a.user_orientation.orientated_at)
                  );
                } else if (hasSlides) {
                  orientationCompleted = !!(
                    orientationCompleted || a.user_orientation?.orientated_at
                  );
                } else if (hasInPerson) {
                  orientationCompleted = !!(
                    orientationCompleted ||
                    a.user_orientation?.in_person_orientated_at
                  );
                } else {
                  orientationCompleted = null;
                }

                return (
                  <>
                    {orientationCompleted !== null && (
                      <>
                        {orientationCompleted ? (
                          assignHardHat && a.hard_hat_number === null ? (
                            <AntdButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                props.onAddHH && props.onAddHH(a.worker_id);
                              }}
                              className="text-0.75 bg-white text-semantic-pending border-0.125 border-semantic-pending hover:text-semantic-pending hover:border-semantic-pending rounded-2 mb-0.25"
                            >
                              Add HH #
                            </AntdButton>
                          ) : (
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="text-0.75 p-0.25 bg-white text-semantic-positive-green border-0.125 border-semantic-positive-green hover:text-semantic--positive-green hover:border-semantic-positive-green rounded-2 mb-0.25 focus:border-semantic-positive-green focus:text-semantic-positive-green"
                            >
                              Orientated
                            </div>
                          )
                        ) : (
                          <div
                            onClick={(e) => e.stopPropagation()}
                            className="text-0.75 p-0.25 bg-white text-semantic-negative border-0.125 border-semantic-negative hover:text-semantic-negative hover:border-semantic-negative rounded-2 mb-0.25 focus:border-semantic-negative focus:text-semantic-negative"
                          >
                            Not Orientated
                          </div>
                        )}
                        <div>
                          {showOrientationTag &&
                            orientationCompleted === false &&
                            a.user_orientation?.in_person_orientated_at && (
                              <Tag status="positive">✅ InPerson</Tag>
                            )}

                          {showOrientationTag &&
                            orientationCompleted === false &&
                            a.user_orientation?.orientated_at && (
                              <Tag status="positive">✅ Online</Tag>
                            )}
                        </div>
                      </>
                    )}
                  </>
                );
              },
            },
            {
              title: "Name",
              key: "name",
              size: hideContactInfo ? "md" : "lg",
              sortable: true,
              contentType: {
                type: "row-title",
              },

              defaultSortOrder:
                defaultSortColumnKey === undefined ||
                defaultSortColumnKey === "name"
                  ? "asc"
                  : undefined,
              dataIndex: ["user", "name"],
              queryIncludeVarKey: "includeOrienation",
              searchDataIndex: ["user", "name"],

              render: (_, a) => {
                let orientationCompleted = !!a.user_orientation?.completed_at;

                if (hasSlides && hasInPerson) {
                  orientationCompleted = !!(
                    orientationCompleted ||
                    (a.user_orientation?.in_person_orientated_at &&
                      a.user_orientation.orientated_at)
                  );
                } else if (hasSlides) {
                  orientationCompleted = !!(
                    orientationCompleted || a.user_orientation?.orientated_at
                  );
                } else if (hasInPerson) {
                  orientationCompleted = !!(
                    orientationCompleted ||
                    a.user_orientation?.in_person_orientated_at
                  );
                } else {
                  orientationCompleted = true;
                }
                let onsite = false;
                for (let pw of a.project_workers || []) {
                  if (
                    pw.daily_work_log_workers.length +
                    pw.permits.length +
                    pw.report_injuries.length +
                    pw.reports.length +
                    pw.toolbox_talks.length
                  ) {
                    onsite = true;
                    break;
                  }
                }
                return (
                  <div
                    className="flex flex-row"
                    style={{ wordBreak: "break-word" }}
                  >
                    <div className="flex flex-row w-3/4">
                      {a.user?.name}
                      {showOnsiteTag && onsite && (
                        <OnSiteTag {...{ orientationCompleted }} />
                      )}
                    </div>
                    {!hideContactInfo && (
                      <div className="flex flex-row w-1/4">
                        <div className="w-1/2">
                          {a.user?.phone_number ? (
                            <div className="flex flex-row items-center  ">
                              <ContactLink
                                hideValue
                                type="phone-number"
                                value={a.user?.phone_number}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="w-1/2">
                          {a.user?.email ? (
                            <div className="flex flex-row items-center  ">
                              <ContactLink
                                hideValue
                                type="email"
                                value={a.user?.email}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              },
            },
            {
              title: "Project",
              key: "project",
              size: "md",
              dataIndex: ["project", "name"],
              searchDataIndex: ["project", "name"],
              includeInMainSearchBox: false,
            },
            {
              title: "Subcontractor",
              key: "subcontractor",
              size: "md",
              sortable: true,
              queryIncludeVarKey: "includeSub",
              dataIndex: ["subcontractor", "name"],
              searchValue: searchString,
              includeInMainSearchBox: false,
              ...(props.subs
                ? {
                    filters: {
                      type: "checklist",
                      initialClose: true,
                      dataIndex: ["subcontractor_id"],
                      options: props.subs.map((r) => ({
                        text: capitalize(r.subcontractor.name),
                        value: r.subcontractor_id,
                      })),
                    },
                  }
                : {}),
              // contentType: {
              //   type: "with-icon",
              //   renderOptions: (value) => ({ icon: IconBriefcase, label: value }),
              // },
              render: (val, a) => (
                <div className="flex flex-row gap-1">
                  <Icon icon={IconBriefcase} />

                  <div className="flex flex-row">
                    {val}
                    {showSubAdminTag &&
                    a.user?.role === "subcontractor_employee" ? (
                      <Icon
                        icon={IconTie}
                        hoverContent={{
                          content: (
                            <div className="flex justify-center items-center">
                              <Icon icon={IconTie} />
                              Indicates an Administrator for the subcontractor
                              or
                              <br />
                              trade partner. These users can manage their
                              company's
                              <br />
                              JHAs, workers, and more on the SiteForm website.
                              <br />
                              Invite these users by selecting "Invite Sub Admin"
                              on
                              <br />
                              the "Subcontractors" page.
                            </div>
                          ),
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ),
            },
            {
              title: "Hard Hat #",
              key: "hardHatNumber",
              sortable: true,
              clientCompareFn: (a, b) => {
                const sa = getSuffix(
                  "0000000000" + (a.hard_hat_number || ""),
                  10,
                );
                const sb = getSuffix(
                  "0000000000" + (b.hard_hat_number || ""),
                  10,
                );

                return sa === sb ? 0 : sa < sb ? 1 : -1;
              },
              dataIndex: ["hard_hat_number"],
              searchDataIndex: ["hard_hat_number"],
              render: (value, a, index) => {
                let orientationCompleted = !!a.user_orientation?.completed_at;

                if (hasSlides && hasInPerson) {
                  orientationCompleted = !!(
                    orientationCompleted ||
                    (a.user_orientation?.in_person_orientated_at &&
                      a.user_orientation.orientated_at)
                  );
                } else if (hasSlides) {
                  orientationCompleted = !!(
                    orientationCompleted || a.user_orientation?.orientated_at
                  );
                } else if (hasInPerson) {
                  orientationCompleted = !!(
                    orientationCompleted ||
                    a.user_orientation?.in_person_orientated_at
                  );
                } else {
                  orientationCompleted = true;
                }
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    {orientationCompleted && allowEditHH && projectId && (
                      <EditableHHCell
                        value={value}
                        tableView={true}
                        record={{ id: a.pk, workerId: a.worker_id }}
                        projectId={projectId}
                        initiallyOpen={!value?.trim?.()}
                      />
                    )}
                  </div>
                );
              },
              size: "sm",
            },
            {
              title: "Title",
              key: "title",
              sortable: true,
              size: "sm",
              dataIndex: ["title", "translation", "en"],
              // searchDataIndex: ["title", "translation", "en"],
              includeInMainSearchBox: false,
              ...(props.titles
                ? {
                    filters: {
                      type: "checklist",
                      initialClose: true,
                      dataIndex: ["title_id"],
                      options: props.titles.map((r) => ({
                        text: capitalize(r.node.translation.en),
                        value: r.node.pk,
                      })),
                    },
                  }
                : {}),
            },
            {
              width: 150,
              title: "Role",
              key: "role",
              sortable: true,
              dataIndex: ["worker_role"],
              size: "sm",
              contentType: {
                type: "tag",
                renderOptions: (value) => ({
                  label: capitalize(value),
                }),
              },
              filters: {
                dataIndex: ["worker_role"],
                options: ["apprentice", "foreman", "journeyman"].map((r) => ({
                  text: capitalize(r),
                  value: r,
                })),
              },
            },
            {
              sortable: true,
              title: "Corporate Orientated Date",
              key: "inPersonOrientatedDate",
              size: "lg",
              dataIndex: ["user_orientation", "in_person_orientated_at"],
              defaultSortOrder:
                defaultSortColumnKey === "inPersonOrientatedDate"
                  ? "desc"
                  : undefined,
              contentType: {
                type: "date",
                renderOptions: (value) => ({ format: "mmddyyyy" }),
              },
              queryIncludeVarKey: "includeOrienation",
            },
            {
              sortable: true,
              title: "Orientated Date",
              key: "orientatedOn",
              size: "md",
              dataIndex: ["user_orientation", "orientated_at"],
              queryIncludeVarKey: "includeOrienation",
              defaultSortOrder:
                defaultSortColumnKey === "orientatedOn" ? "desc" : undefined,
              render: (value, record) => {
                if (!record.user_orientation) return <></>;
                const date = getOrientationCompleted(
                  record.user_orientation,
                  hasSlides,
                  hasInPerson,
                )
                  ? getLatestDate(
                      record.user_orientation.orientated_at,
                      record.user_orientation.in_person_orientated_at,
                    ) ?? record.user_orientation.completed_at
                  : undefined;
                const universalPresentAndCompletedA = hasUniversal
                  ? record.user!.universal_orientations.length > 0
                  : true;
                return (
                  <div className="flex">
                    {(hasInPerson || hasSlides) && date
                      ? dayjs(date).format("M/DD/YYYY")
                      : hasUniversal && universalPresentAndCompletedA
                      ? dayjs(
                          record.user!.universal_orientations[0]
                            ?.universal_orientated_at,
                        ).format("M/DD/YYYY")
                      : ""}
                    {/* {date && (
                      <Popover
                        content={
                          <OrientatedDateCell
                            value={date}
                            orientationMap={orientationMap}
                          />
                        }
                        placement="left"
                        trigger={"hover"}
                      >
                        <div className="text-1 ml-1">📝</div>
                      </Popover>
                    )} */}
                  </div>
                );
              },
            },
            {
              sortable: true,
              title: "Universal Orientated Date",
              key: "universal_orientated_at",
              size: "md",
              dataIndex: ["universal_orientations"],
              clientCompareFn: (a, b) => {
                const valueA = a.user
                  ? a.user.universal_orientations[0]?.universal_orientated_at
                  : undefined;
                const valueB = b.user
                  ? b.user.universal_orientations[0]?.universal_orientated_at
                  : undefined;
                return returnVal(valueA, valueB);
              },
              render: (_, a) => {
                if (!a.user) return <></>;
                const date =
                  a.user.universal_orientations[0]?.universal_orientated_at;
                const orientationMap: {
                  [key: string]: QuizResultProps["orientationResults"][number];
                } = {};
                if (a.user.universal_orientations[0])
                  a.user.universal_orientations[0].universal_orientation_results.forEach(
                    (or) => (orientationMap[or.orientation_id ?? ""] = or),
                  );
                return (
                  <div className="flex">
                    {date === undefined ? (
                      "not complete"
                    ) : date === null ? (
                      "not logged"
                    ) : (
                      <>
                        <Icon icon={IconCalendar} />
                        {dayjs(date).format("M/DD/YYYY")}
                      </>
                    )}
                    {a.user.universal_orientations[0] &&
                      a.user.universal_orientations[0]
                        .universal_orientation_results.length > 0 && (
                        <Popover
                          content={
                            <QuizResult
                              orientationResults={Object.values(orientationMap)}
                            />
                          }
                          placement="left"
                          trigger={"hover"}
                        >
                          <div className="text-1 ml-1">📝</div>
                        </Popover>
                      )}
                  </div>
                );
              },
            },
            {
              sortable: true,
              title: "Registered Date",
              key: "registeredDate",
              size: "md",
              defaultSortOrder:
                defaultSortColumnKey === "registeredDate" ? "desc" : undefined,
              dataIndex: ["created_at"],
              dateRangeSearchIndex: ["created_at"],
              contentType: {
                type: "date",
                renderOptions: () => ({
                  format: "mmddyyyy",
                }),
              },
            },
            {
              sortable: true,
              title: "Date Logged",
              size: "md",
              key: "dateLogged",
              dataIndex: ["user_orientation", "background_check_updated_at"],
              queryIncludeVarKey: "includeOrienation",
              defaultSortOrder:
                defaultSortColumnKey === "dateLogged" ? "desc" : undefined,
              render: (_, row) => {
                const time = row.user_orientation?.background_check_updated_at;
                const date = dayjs(time).format("YYYY-MM-DD");
                return <div className="text-center">{date}</div>;
              },
            },
            {
              title: "Training & Certifications",
              key: "trainingAndCertifications",
              size: "ml",
              dataIndex: ["worker"],
              render: (_, row) => {
                const d = row.worker;
                const certificates = d.worker_certifications.map(
                  (cert): Certificate => {
                    return {
                      name: cert.certification.name,
                      expiryDate: cert.expires_on,
                    };
                  },
                );
                const clockColor = getCertificateExpiryValue(certificates);

                const menu: MenuProps = {
                  items: d.worker_certifications.map((cert) => {
                    let textColor = "";
                    if (cert && cert.expires_on) {
                      textColor = getCertificateExpiryValueFromDate(
                        new Date(cert.expires_on),
                      );
                    }
                    return {
                      key: cert.pk,
                      label: (
                        <Popover
                          placement="left"
                          title={
                            <div className="font-accent text-1 w-full">
                              {cert.certification.name}
                            </div>
                          }
                          content={
                            <CertificationCard worker_cert_id={cert.pk} />
                          }
                        >
                          <div className={textColor}>
                            {cert?.certification?.name}{" "}
                          </div>
                          {(
                            cert?.expires_on
                              ? dayjs(cert?.expires_on).isAfter(dayjs())
                              : true
                          ) ? (
                            ""
                          ) : (
                            <p>
                              Expired on{" "}
                              {dayjs(cert?.expires_on).format("MMM DD, YYYY")}
                            </p>
                          )}
                        </Popover>
                      ),
                    };
                  }),
                };

                const docsToVerify = d.certificates_to_verify;

                return (
                  <>
                    {d.certificates_to_verify.length !== 0 && (
                      <Popover
                        trigger={"hover"}
                        placement="left"
                        content={
                          <ProcessingCertCard
                            requestIds={d.certificates_to_verify.map(
                              (p) => p.pk,
                            )}
                          />
                        }
                        title={
                          <div style={{ fontSize: "18px" }}>
                            SiteForm is verifying these Certificate Images
                          </div>
                        }
                      >
                        <div>
                          <Tag status="pending">
                            {`(${d.certificates_to_verify.length}) Processing`}
                          </Tag>
                        </div>
                      </Popover>
                    )}

                    {!!d.worker_certifications &&
                      d.worker_certifications.length !== 0 && (
                        <div className="flex flex-row gap-0.5">
                          <Popover
                            trigger={"hover"}
                            content={<Menu {...menu} />}
                            placement="bottom"
                          >
                            <div>
                              <Tag status={`positive`}>
                                {`✓ (${d.worker_certifications.length}
                              ) Verified`}
                              </Tag>
                            </div>
                          </Popover>
                          {clockColor && (
                            <Icon icon={IconClock} color={clockColor} />
                          )}
                        </div>
                      )}
                  </>
                );
              },
            },
            {
              title: "Drug Test Status",
              key: "drugTest",
              size: "md",
              queryIncludeVarKey: "includeOrienation",
              dataIndex: ["user_orientation", "drug_test", "status"],
              render: (a, row) => {
                const dt = row.user_orientation?.drug_test_id;
                if (!a) {
                  return <div style={{ textAlign: "center" }}>🚫</div>;
                }
                const prevPositiveDTs = (
                  row.worker.prevPositiveProjectDTs || []
                ).map((dt) => dt.pk);

                return (
                  <div style={{ textAlign: "center" }}>
                    <Popover
                      placement="left"
                      content={
                        dt ? (
                          <DrugtestCard
                            drugTestIds={[dt, ...prevPositiveDTs]}
                          />
                        ) : undefined
                      }
                    >
                      <div>
                        <Tag
                          status={
                            a == "non-negative"
                              ? "inactive"
                              : a === "positive"
                              ? "negative"
                              : "positive"
                          }
                        >
                          {capitalize(a)}
                        </Tag>
                      </div>
                    </Popover>
                  </div>
                );
              },
            },
            {
              title: "Drug test date",
              sortable: true,
              key: "drugtestDate",
              defaultSortOrder:
                defaultSortColumnKey === "drugtestDate" ? "desc" : undefined,
              size: "md",
              queryIncludeVarKey: "includeOrienation",
              dataIndex: ["user_orientation", "drug_test", "drug_test_date"],
              contentType: {
                type: "date",
                renderOptions: () => ({ format: "mmddyyyy" }),
              },
            },
            ...(extraColumns || []),
            {
              title: "Status",
              key: "archivedAt",
              size: "sm",
              dataIndex: ["archived_at"],
              defaultSortOrder:
                defaultSortColumnKey === "archivedAt" ? "asc" : undefined,
              sortable: true,
              render: (d, row) => {
                const createdPassword = row.user?.created_password;
                const inviteSent = row.user?.invites;

                const ArchivedStatus: React.FC = () => (
                  <Popover
                    trigger={"click"}
                    title="Worker Archived By GC on this Project"
                    content={
                      <Alert
                        type="warning"
                        message={
                          <div>
                            An archived worker is a worker that was added <br />
                            to the project by a foreman, subcontractor,
                            <br />
                            or your team but never worked on the project. <br />
                            An archived worker has not completed orientation.
                            <br />
                            Archived By:{" "}
                            {row.archived_by_user?.name
                              ? `${row.archived_by_user.name}, ${
                                  row.archived_by_user.employee?.employee_title
                                    ?.name.en ?? ""
                                }`
                              : ""}
                            <br /> Archived Date: {dayjs(d).format("LL") ?? ""}
                          </div>
                        }
                      />
                    }
                  >
                    <AntdButton
                      size="small"
                      className="text-warn bg-white border-warn border-0.125 rounded-2 text-0.75 hover:text-warn hover:border-warn"
                      onClick={(e) => e.stopPropagation()}
                    >
                      Archived
                    </AntdButton>
                  </Popover>
                );

                return (
                  <div>
                    {markDeleted ? (
                      row.subcontractor_id !== row.worker.subcontractor_id ? (
                        <AntdButton
                          size="small"
                          onClick={(e) => e.stopPropagation()}
                          className="text-0.75 bg-white text-semantic-negative border-0.125 border-semantic-negative hover:text-semantic-negative hover:border-semantic-negative rounded-2"
                        >
                          No Longer Employed
                        </AntdButton>
                      ) : row.deleted_at ? (
                        <AntdButton
                          size="small"
                          onClick={(e) => e.stopPropagation()}
                          className="text-0.75 bg-white text-semantic-negative border-0.125 border-semantic-negative hover:text-semantic-negative hover:border-semantic-negative rounded-2"
                        >
                          Inactive
                        </AntdButton>
                      ) : (
                        row.archived_at && <ArchivedStatus />
                      )
                    ) : (
                      d && <ArchivedStatus />
                    )}
                    {showArchiveButton && (
                      <AntdButton
                        loading={isArchiving}
                        type="primary"
                        size="small"
                        className={` border-warn hover:border-warn rounded-2 text-0.9 ${
                          d
                            ? "text-white hover:text-white bg-warn hover:bg-warn"
                            : "text-warn hover:text-warn bg-white hover:bg-white"
                        }`}
                        onClick={async () => {
                          if (d)
                            await archiveWorker({
                              variables: {
                                projectWorkerId: row.pk,
                                _set: {
                                  archived_at: null,
                                  archived_by_uid: null,
                                },
                              },
                            });
                          else
                            await archiveWorker({
                              variables: {
                                projectWorkerId: row.pk,
                                _set: {
                                  archived_at: dayjs().format(),
                                  archived_by_uid: auth.currentUser?.uid,
                                },
                              },
                            });
                          if (typeof ref !== `function`)
                            ref?.current?.refetch();
                        }}
                      >
                        {!d ? "Archive" : "+ Add Back"}
                      </AntdButton>
                    )}

                    {createdPassword && (
                      <AntdButton
                        size="small"
                        onClick={(e) => e.stopPropagation()}
                        className="text-0.75 bg-white text-semantic-positive-green border-0.125 border-semantic-positive-green hover:text-semantic-positive-green hover:border-semantic-positive-green rounded-2 mb-0.25"
                      >
                        APP USER
                      </AntdButton>
                    )}

                    {!createdPassword &&
                      inviteSent &&
                      inviteSent.length > 0 && (
                        <Popover
                          trigger={"hover"}
                          title="Resend invite link?"
                          content={
                            <div
                              className="flex"
                              style={{ alignItems: "center" }}
                            >
                              <Button
                                fullWidth
                                icon={IconRefresh}
                                label="Resend"
                                onClick={() => {
                                  setWorkerData({
                                    id: row.worker_id,
                                    email: row.user?.email ?? undefined,
                                    phone: row.user?.phone_number ?? undefined,
                                    dataId: row.worker.pk,
                                  });
                                }}
                              />
                            </div>
                          }
                        >
                          <AntdButton
                            size="small"
                            onClick={(e) => e.stopPropagation()}
                            className="text-0.75 bg-white text-semantic-pending border-0.125 border-semantic-pending hover:text-semantic-pending hover:border-semantic-pending rounded-2 mb-0.25"
                          >
                            INVITED
                          </AntdButton>
                        </Popover>
                      )}
                  </div>
                );
              },
            },
          ]}
          queryNode={graphql`
            query ProjectWorkerTableQuery(
              $first: Int!
              $after: String
              $projectId: uuid!
              $where: project_worker_bool_exp!
              $order_by: [project_worker_order_by!]!
              $includeSub: Boolean!
              $startDate: date!
              $startTime: timestamptz!
              $drugtestWhere: worker_drug_test_bool_exp!
              $includeOnSite: Boolean!
              $includeOrienation: Boolean!
              $includeValidDTs: Boolean!
              $includePrevPositiveProjectDTs: Boolean!
            ) {
              ...ProjectWorkerTable_project_worker
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  startTime: $startTime
                  drugtestWhere: $drugtestWhere
                  projectId: $projectId
                  startDate: $startDate
                  order_by: $order_by
                  includeSub: $includeSub
                  includeOrienation: $includeOrienation
                  includeOnSite: $includeOnSite
                  includeValidDTs: $includeValidDTs
                  includePrevPositiveProjectDTs: $includePrevPositiveProjectDTs
                )
              ...ProjectWorkerTable_total @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment ProjectWorkerTable_total on query_root
            @argumentDefinitions(where: { type: "project_worker_bool_exp!" })
            @refetchable(queryName: "ProjectWorkerTableTotalRefetchableQuery") {
              allProjectWorkersConnection: project_worker_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment ProjectWorkerTable_project_worker on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              projectId: { type: "uuid!" }
              startTime: { type: "timestamptz!" }
              startDate: { type: "date!" }
              drugtestWhere: { type: "worker_drug_test_bool_exp!" }
              where: { type: "project_worker_bool_exp!" }
              order_by: { type: "[project_worker_order_by!]!" }
              includeSub: { type: "Boolean!" }
              includeOnSite: { type: "Boolean!" }
              includeOrienation: { type: "Boolean!" }
              includeValidDTs: { type: "Boolean!" }
              includePrevPositiveProjectDTs: { type: "Boolean!" }
            )
            @refetchable(queryName: "ProjectWorkerTableRefetchableQuery") {
              project_worker_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "ProjectWorkerTable_project_worker_connection"
                  filters: ["where"]
                ) {
                edges {
                  node {
                    id
                    pk: id @__clientField(handle: "pk")
                    worker_role
                    title_id
                    subcontractor_id
                    hard_hat_number
                    archived_at
                    project_id
                    project_crew {
                      pk: id @__clientField(handle: "pk")
                      lead_foreman_project_worker {
                        pk: id @__clientField(handle: "pk")
                      }
                    }

                    daily_report_project_workers(
                      order_by: { daily_report: { created_at: asc } }
                      limit: 1
                    ) {
                      daily_report {
                        created_at
                      }
                    }
                    permits_aggregate(distinct_on: permit_id) {
                      aggregate {
                        count
                      }
                    }
                    reports_aggregate {
                      aggregate {
                        count
                      }
                    }
                    daily_work_log_workers {
                      hours
                      created_at
                    }

                    archived_by_user {
                      name
                      role
                      employee {
                        general_contractor {
                          name
                        }
                        employee_title {
                          name {
                            en
                          }
                        }
                      }
                    }
                    deleted_at
                    created_at
                    worker_id

                    worker {
                      id
                      pk: id @__clientField(handle: "pk")
                      subcontractor_id
                      allOrientationProjects: worker_projects(
                        where: {
                          project: {
                            orientation_project_id: { _eq: $projectId }
                          }
                        }
                      ) {
                        project_id
                      }
                      worker_certifications {
                        pk: id @__clientField(handle: "pk")
                        expires_on
                        certification {
                          name
                        }
                      }
                      latestDT: worker_drug_tests(
                        where: {
                          _and: [
                            { status: { _is_null: false } }
                            $drugtestWhere
                          ]
                        }
                        order_by: { drug_test_date: desc_nulls_last }
                        limit: 1
                      ) {
                        status
                        pk: id @__clientField(handle: "pk")
                        drug_test_date
                      }
                      validDTs: worker_drug_tests(
                        where: {
                          _and: [
                            { status: { _eq: "negative" } }
                            $drugtestWhere
                          ]
                        }
                        order_by: { drug_test_date: desc_nulls_last }
                      ) @include(if: $includeValidDTs) {
                        pk: id @__clientField(handle: "pk")
                      }
                      prevPositiveProjectDTs: worker_drug_tests(
                        where: {
                          status: { _eq: "positive" }
                          project_id: { _eq: $projectId }
                          entered_through_user: { role: { _eq: "employee" } }
                        }

                        order_by: { drug_test_date: desc_nulls_last }
                      ) @include(if: $includePrevPositiveProjectDTs) {
                        pk: id @__clientField(handle: "pk")
                      }
                      certificates_to_verify(
                        where: {
                          verified_at: { _is_null: true }
                          document: { _eq: "certificate" }
                          status: { _eq: "submitted" }
                        }
                      ) {
                        pk: id @__clientField(handle: "pk")
                      }
                    }
                    user {
                      name
                      email
                      role
                      id @__clientField(handle: "pk")
                      allow_mobile_login
                      phone_number
                      created_password
                      invites(
                        where: {
                          claiming_account: { _eq: true }
                          accepted_at: { _is_null: true }
                        }
                        order_by: { created_at: desc }
                        limit: 1
                      ) {
                        id
                        pk: id @__clientField(handle: "pk")
                        accepted_at
                      }
                      universal_orientations(
                        where: { universal_orientated_at: { _is_null: false } }
                        limit: 1
                        order_by: { universal_orientated_at: desc }
                      ) {
                        universal_orientated_at
                        universal_orientation_results(
                          where: {
                            project_id: { _is_null: true }
                            status: { _eq: "completed" }
                          }
                          order_by: [
                            { orientation: { order: asc } }
                            { orientation: { name: asc } }
                          ]
                        ) {
                          orientation_id
                          quiz_results(order_by: { slide: { order: asc } }) {
                            orientation_slide_id
                            answer
                            lang
                            slide {
                              title
                              question_ui_element
                              answer_items
                              answer_items_es
                              answer_items_pt
                              answer_items_it
                              answer_items_fr
                              answer_items_zh_hans
                              check_correct_answer
                              correct_answers
                            }
                          }
                        }
                      }
                    }
                    project {
                      name
                      show_onsite_for_not_oriented_workers
                    }
                    subcontractor @include(if: $includeSub) {
                      name
                    }
                    title {
                      translation {
                        ...TextTranslationFrag @relay(mask: false)
                      }
                    }
                    project_workers @include(if: $includeOnSite) {
                      reports(
                        limit: 1
                        where: { report: { created_at: { _gte: $startTime } } }
                        order_by: { report: { created_at: desc } }
                      ) {
                        id
                      }
                      report_injuries(
                        limit: 1
                        where: { report: { created_at: { _gte: $startTime } } }
                        order_by: { report: { created_at: desc } }
                      ) {
                        id
                      }
                      daily_work_log_workers(
                        limit: 1
                        where: {
                          daily_work_log: { date: { _gte: $startDate } }
                        }
                        order_by: { daily_work_log: { date: desc } }
                      ) {
                        id
                      }
                      toolbox_talks(
                        limit: 1
                        where: {
                          toolbox_talk: { created_at: { _gte: $startTime } }
                        }
                        order_by: { toolbox_talk: { created_at: desc } }
                      ) {
                        id
                      }
                      permits(
                        limit: 1
                        where: {
                          permit: {
                            created_at: { _gte: $startTime }
                            current_status: {
                              _in: ["submitted", "resubmitted"]
                            }
                            type: { _neq: "ladder" }
                            is_historical: { _eq: true }
                          }
                        }
                        order_by: { permit: { created_at: desc } }
                      ) {
                        id
                      }
                    }
                    user_orientation @include(if: $includeOrienation) {
                      ...UserOrientationFrag @relay(mask: false)
                    }
                  }
                }
              }
            }
          `}
        />
      </>
    );
  },
);

export default withCustomSuspense(ProjectWorkerTable);

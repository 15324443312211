/**
 * @generated SignedSource<<b689015313390767294e34cec27eb498>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VisitorSettings_toggleSignWaiverSwitch_Mutation$variables = {
  _set: project_set_input;
  projectId: string;
};
export type VisitorSettings_toggleSignWaiverSwitch_Mutation$data = {
  readonly update_project: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly visitor_sign_waiver: boolean | null | undefined;
    }>;
  } | null | undefined;
};
export type VisitorSettings_toggleSignWaiverSwitch_Mutation = {
  response: VisitorSettings_toggleSignWaiverSwitch_Mutation$data;
  variables: VisitorSettings_toggleSignWaiverSwitch_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "projectId"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_mutation_response",
    "kind": "LinkedField",
    "name": "update_project",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "visitor_sign_waiver",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VisitorSettings_toggleSignWaiverSwitch_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "VisitorSettings_toggleSignWaiverSwitch_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5edd0eb7ab21eddfe6d90e93e93b0d59",
    "id": null,
    "metadata": {},
    "name": "VisitorSettings_toggleSignWaiverSwitch_Mutation",
    "operationKind": "mutation",
    "text": "mutation VisitorSettings_toggleSignWaiverSwitch_Mutation(\n  $projectId: uuid!\n  $_set: project_set_input!\n) {\n  update_project(where: {id: {_eq: $projectId}}, _set: $_set) {\n    returning {\n      id\n      visitor_sign_waiver\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dfcdacd4d2d48d162e42966eaab6921a";

export default node;

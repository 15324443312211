import { Form, Input, Select } from "antd";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

export interface AddressFormItemsName {
  addressLine1: string;
  zip: string;
  city: string;
  state_code: string;
}

const AddressFormItems: React.FC<{
  onStateChange: (stateCode: string) => void;
  states: Array<{ name: string; code: string }>;
}> = ({ onStateChange, states }) => {
  return (
    <div>
      <Form.Item
        name="addressLine1"
        label="Street Address"
        rules={[{ required: true, message: "Enter a value" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="city"
        label="City"
        rules={[{ required: true, message: "Enter a city" }]}
      >
        <Input />
      </Form.Item>
      {states.length > 0 && (
        <Form.Item
          name="stateCode"
          label="State"
          rules={[{ required: true, message: "Pick a State" }]}
        >
          <Select
            style={{ width: "100%" }}
            filterOption={getNormalSelectOptionsFilter}
            showSearch
            options={states.map((s) => ({ label: s.name, value: s.code }))}
            onChange={(v) => onStateChange(v)}
          />
        </Form.Item>
      )}
      <Form.Item
        name="zip"
        label="Zip Code"
        rules={[{ required: true, message: "Enter a zip code" }]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};
export default AddressFormItems;

/**
 * @generated SignedSource<<e8109a8db06a9b4ac17bfd732af12a68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type OrientationPosterUploadQuery$variables = {
  gcId: string;
};
export type OrientationPosterUploadQuery$data = {
  readonly general_contractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly in_person_registration_poster_background_url: string | null | undefined;
        readonly orientation_qr_code_poster_background_url: string | null | undefined;
      };
    }>;
  };
};
export type OrientationPosterUploadQuery = {
  response: OrientationPosterUploadQuery$data;
  variables: OrientationPosterUploadQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gcId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_neq",
            "variableName": "gcId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation_qr_code_poster_background_url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "in_person_registration_poster_background_url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrientationPosterUploadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrientationPosterUploadQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "general_contractorConnection",
        "kind": "LinkedField",
        "name": "general_contractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a1053e4b1d85f39034f83f253bd807ab",
    "id": null,
    "metadata": {},
    "name": "OrientationPosterUploadQuery",
    "operationKind": "query",
    "text": "query OrientationPosterUploadQuery(\n  $gcId: uuid!\n) {\n  general_contractor_connection(where: {id: {_neq: $gcId}}) {\n    edges {\n      node {\n        orientation_qr_code_poster_background_url\n        in_person_registration_poster_background_url\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "86fbe7c2bd1010427cb62fee7b6e0a2a";

export default node;

/**
 * @generated SignedSource<<60300fd6f66e02d4325d115048b6b7a3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectOrientationStatus_UpdateSettingsMutation$variables = {
  _set?: project_set_input | null | undefined;
  projectId: string;
};
export type GCProjectOrientationStatus_UpdateSettingsMutation$data = {
  readonly update_project_by_pk: {
    readonly id: string;
    readonly next_hard_hat_number: number | null | undefined;
  } | null | undefined;
};
export type GCProjectOrientationStatus_UpdateSettingsMutation = {
  response: GCProjectOrientationStatus_UpdateSettingsMutation$data;
  variables: GCProjectOrientationStatus_UpdateSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_set"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "project",
    "kind": "LinkedField",
    "name": "update_project_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "next_hard_hat_number",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectOrientationStatus_UpdateSettingsMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectOrientationStatus_UpdateSettingsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8d1c243316d9fc0b1d93100f5c684570",
    "id": null,
    "metadata": {},
    "name": "GCProjectOrientationStatus_UpdateSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectOrientationStatus_UpdateSettingsMutation(\n  $_set: project_set_input\n  $projectId: uuid!\n) {\n  update_project_by_pk(_set: $_set, pk_columns: {id: $projectId}) {\n    id\n    next_hard_hat_number\n  }\n}\n"
  }
};
})();

(node as any).hash = "63468682eeedcbd8c8aa0f50e90f0b19";

export default node;

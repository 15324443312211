/**
 * @generated SignedSource<<f0cf6d49452874a43bef862228e1807b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type UploadedToolboxTalkQuery$variables = {
  id: string;
};
export type UploadedToolboxTalkQuery$data = {
  readonly uploaded_toolbox_talk_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly added_to_community: boolean;
        readonly assign_to: string | null | undefined;
        readonly content: {
          readonly en: string;
          readonly id: string;
        } | null | undefined;
        readonly content_id: string | null | undefined;
        readonly due_time: string | null | undefined;
        readonly general_contractor_id: string | null | undefined;
        readonly id: string;
        readonly images: ReadonlyArray<{
          readonly id: string;
          readonly pk: string;
          readonly url: string;
        }>;
        readonly name: string;
        readonly pk: string;
        readonly subcontractor_id: string | null | undefined;
        readonly uploaded_toolbox_talk_projects: ReadonlyArray<{
          readonly project: {
            readonly name: string;
          };
          readonly project_id: string;
        }>;
        readonly uploaded_toolbox_talk_subcontractors: ReadonlyArray<{
          readonly subcontractor: {
            readonly name: string;
          };
          readonly subcontractor_id: string;
        }>;
      };
    }>;
  };
};
export type UploadedToolboxTalkQuery = {
  response: UploadedToolboxTalkQuery$data;
  variables: UploadedToolboxTalkQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "sort_index": "asc"
    }
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "general_contractor_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v7 = {
  "name": "asc"
},
v8 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "project": (v7/*: any*/)
    }
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = [
  (v10/*: any*/)
],
v12 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "subcontractor": (v7/*: any*/)
    }
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assign_to",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "added_to_community",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content_id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "content",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "en",
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "due_time",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v21 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v22 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v23 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v24 = [
  (v10/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadedToolboxTalkQuery",
    "selections": [
      {
        "alias": "uploaded_toolbox_talk_connection",
        "args": null,
        "concreteType": "uploaded_toolbox_talkConnection",
        "kind": "LinkedField",
        "name": "__UploadedToolboxTalk_uploaded_toolbox_talk_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "uploaded_toolbox_talkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "uploaded_toolbox_talk",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": "images(order_by:{\"sort_index\":\"asc\"})"
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "uploaded_toolbox_talk_project",
                    "kind": "LinkedField",
                    "name": "uploaded_toolbox_talk_projects",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": "uploaded_toolbox_talk_projects(order_by:{\"project\":{\"name\":\"asc\"}})"
                  },
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "concreteType": "uploaded_toolbox_talk_subcontractor",
                    "kind": "LinkedField",
                    "name": "uploaded_toolbox_talk_subcontractors",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": (v11/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": "uploaded_toolbox_talk_subcontractors(order_by:{\"subcontractor\":{\"name\":\"asc\"}})"
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v10/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              (v19/*: any*/)
            ],
            "storageKey": null
          },
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadedToolboxTalkQuery",
    "selections": [
      {
        "alias": null,
        "args": (v21/*: any*/),
        "concreteType": "uploaded_toolbox_talkConnection",
        "kind": "LinkedField",
        "name": "uploaded_toolbox_talk_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "uploaded_toolbox_talkEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "uploaded_toolbox_talk",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v22/*: any*/),
                  (v23/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v1/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/)
                    ],
                    "storageKey": "images(order_by:{\"sort_index\":\"asc\"})"
                  },
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": (v8/*: any*/),
                    "concreteType": "uploaded_toolbox_talk_project",
                    "kind": "LinkedField",
                    "name": "uploaded_toolbox_talk_projects",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project",
                        "kind": "LinkedField",
                        "name": "project",
                        "plural": false,
                        "selections": (v24/*: any*/),
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": "uploaded_toolbox_talk_projects(order_by:{\"project\":{\"name\":\"asc\"}})"
                  },
                  {
                    "alias": null,
                    "args": (v12/*: any*/),
                    "concreteType": "uploaded_toolbox_talk_subcontractor",
                    "kind": "LinkedField",
                    "name": "uploaded_toolbox_talk_subcontractors",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": (v24/*: any*/),
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": "uploaded_toolbox_talk_subcontractors(order_by:{\"subcontractor\":{\"name\":\"asc\"}})"
                  },
                  (v13/*: any*/),
                  (v14/*: any*/),
                  (v15/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v10/*: any*/),
                  (v18/*: any*/)
                ],
                "storageKey": null
              },
              (v19/*: any*/)
            ],
            "storageKey": null
          },
          (v20/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v21/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "UploadedToolboxTalk_uploaded_toolbox_talk_connection",
        "kind": "LinkedHandle",
        "name": "uploaded_toolbox_talk_connection"
      }
    ]
  },
  "params": {
    "cacheID": "0b072611adbc2c7b9e959539adfed109",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "uploaded_toolbox_talk_connection"
          ]
        }
      ]
    },
    "name": "UploadedToolboxTalkQuery",
    "operationKind": "query",
    "text": "query UploadedToolboxTalkQuery(\n  $id: uuid!\n) {\n  uploaded_toolbox_talk_connection(where: {id: {_eq: $id}}, first: 1) {\n    edges {\n      node {\n        id\n        pk: id\n        images(order_by: {sort_index: asc}) {\n          url\n          id\n          pk: id\n        }\n        general_contractor_id\n        subcontractor_id\n        uploaded_toolbox_talk_projects(order_by: {project: {name: asc}}) {\n          project_id\n          project {\n            name\n            id\n          }\n          id\n        }\n        uploaded_toolbox_talk_subcontractors(order_by: {subcontractor: {name: asc}}) {\n          subcontractor_id\n          subcontractor {\n            name\n            id\n          }\n          id\n        }\n        assign_to\n        added_to_community\n        content_id\n        content {\n          en\n          id\n        }\n        due_time\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "166ba495945fc380a8b3afeb22b89012";

export default node;

import { CheckOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  GetObservationByPkQuery,
  Lang_Code_Enum,
  useUpdateTextTranslationAndObservationEditHistoryMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import createObservationPatch from "../../utils/createObservationPatch";
import { produce } from "immer";

type ObservationType = NonNullable<
  GetObservationByPkQuery["observation_by_pk"]
>;
const ObservationTextField: React.FC<
  {
    notEditing: boolean;
    textId: string;
    required?: boolean;
    autoSize?: { maxRows: number; minRows: number };
    text: string;
    observation: ObservationType;
    label: string;
    fieldTypeKey: "observation" | "unsafe_observation";
  } & (
    | {
        fieldTypeKey: "observation";
        fieldName: "description" | "location";
      }
    | {
        fieldName: "action" | "corrective_action" | "root_cause";
        fieldTypeKey: "unsafe_observation";
      }
  )
> = ({ text, label, textId, observation, autoSize, ...props }) => {
  const [newText, setNewText] = useState(text);
  const authUser = useAuthUser();
  const [updateText, { loading: updating }] =
    useUpdateTextTranslationAndObservationEditHistoryMutation();
  const isSubmitted =
    observation.status !== "draft" &&
    (!observation.observation_inspection ||
      !!observation.observation_inspection.submitted_on);
  const onFinish = async () => {
    const prevText = text;

    const newData = produce(observation, (draft) => ({
      ...draft,
      ...(props.fieldTypeKey === "observation"
        ? { [props.fieldName]: { en: newText } }
        : draft.unsafe_observation
        ? {
            unsafe_observation: {
              ...draft.unsafe_observation,
              [props.fieldName]: { en: newText },
            },
          }
        : {}),
    }));
    if (newText.trim() !== text.trim()) {
      const patch = createObservationPatch(newData, observation);
      const comment = `${props.fieldName} changed to ${newText.trim()}`;
      await updateText({
        variables: {
          pk_columns: { id: textId },
          _set: { original: newText.trim(), en: newText.trim() },
          editHistoryObjects: isSubmitted
            ? [
                {
                  prev_val: prevText,
                  new_val: newText.trim(),
                  field: props.fieldName,
                  observation_id: observation.id,
                  patch,
                  comment: {
                    data: {
                      lang: Lang_Code_Enum.En,
                      original: comment,
                      en: comment,
                    },
                  },
                  edit_type: "edited",
                  edited_by_uid: authUser.uid,
                },
              ]
            : [],
        },
        optimisticResponse: {
          insert_observation_edit_history: {
            affected_rows: 1,
            returning: [],
          },
          update_text_translation_by_pk: {
            en: newText,
            original: newText,
            id: textId,
            __typename: "text_translation",
          },
        },
        update: (cache, returningData) => {
          const returningEdits =
            returningData.data?.insert_observation_edit_history?.returning ||
            [];
          cache.modify<typeof observation>({
            id: cache.identify(observation),
            fields: {
              observation_edits(existingEdits, { toReference }) {
                return [
                  ...existingEdits,
                  ...returningEdits.map((edit) => toReference(edit)!),
                ];
              },
            },
          });
        },
      });
      console.log(
        text,
        newText,
        props.fieldTypeKey === "observation"
          ? observation[props.fieldName]
          : "",
      );
    }
  };
  return (
    <Card style={{ width: "81.3%" }} key={textId}>
      <div className="flex gap-2 items-center justify-start mb-1">
        <b>
          {props.required && <span className="text-semantic-negative">*</span>}
          {label}:{" "}
        </b>
        {text !== newText && (
          <Button
            shape="circle"
            type="primary"
            icon={<CheckOutlined />}
            loading={updating}
            onClick={onFinish}
          />
        )}
      </div>
      {props.notEditing ? (
        <div className="">{text}</div>
      ) : (
        <TextArea
          disabled={updating}
          onBlur={onFinish}
          autoSize={autoSize || { maxRows: 10, minRows: 5 }}
          value={newText}
          onChange={async (e) => {
            setNewText(e.currentTarget.value);
          }}
        />
      )}
    </Card>
  );
};
export default ObservationTextField;

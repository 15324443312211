import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import React, { useRef, useState } from "react";
import * as uuid from "uuid";
import { Button, message } from "antd";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import { CreateWorkAboveElevationMutation } from "src/common/types/generated/relay/CreateWorkAboveElevationMutation.graphql";
import { ConnectionHandler } from "relay-runtime";
import { auth } from "src/common/functions/firebase";

const CreateWorkAboveElevation: React.FC<{
  visible: boolean;
  projectId: string;
  typeOptions: Array<{
    name: { en: string };
    id: string;
  }>;
  onClose: () => void;
}> = ({ visible, onClose, typeOptions, projectId }) => {
  const [floors, setFloors] = useState([uuid.v4()]);
  const modalRef = useRef<
    FModalRef<{
      name: string;
      typeId: string;
      [key: string]: string;
    }>
  >();
  const [insertElevation, inserting] =
    useAsyncMutation<CreateWorkAboveElevationMutation>(
      graphql`
        mutation CreateWorkAboveElevationMutation(
          $objects: [work_above_elevation_insert_input!]!
        ) {
          insert_work_above_elevation(objects: $objects) {
            returning {
              ...WorkAboveElevationFrag @relay(mask: false)
            }
          }
        }
      `,
    );
  return (
    <FModal
      confirmLoading={inserting}
      ref={modalRef}
      okText="Save"
      title="Create New Location"
      open={visible}
      onCancel={onClose}
      destroyOnClose
      onOk={async () => {
        const vals = await modalRef.current?.form.validateFields();
        if (!vals) return;
        const name = vals.name.trim();
        if (!name) {
          message.error("Location Name not found");
          return;
        }
        await insertElevation({
          variables: {
            objects: [
              {
                type_id: vals.typeId,
                project_id: projectId,
                created_by_uid: auth.currentUser?.uid,
                name: { data: { original: vals.name, en: vals.name } },
                work_above_floors: {
                  data: floors
                    .filter((cur) => (vals["floor_" + cur] || "").trim())

                    .map((cur, i) => {
                      const name = (vals["floor_" + cur] || "").trim();
                      return {
                        order: (i + 1) * 1000,
                        name: { data: { original: name, en: name } },
                        has_netting: Boolean(vals["floorNet_" + cur]).valueOf(),
                      };
                    }),
                },
              },
            ],
          },
          updater: (store) => {
            const conn = ConnectionHandler.getConnection(
              store.getRoot(),
              "WorkAboveElevationTable_work_above_elevation_connection",
            );
            if (conn) {
              const insertedElevations = store
                .getRootField("insert_work_above_elevation")
                .getLinkedRecords("returning");
              insertedElevations.map((ele) => {
                const edge = ConnectionHandler.createEdge(
                  store,
                  conn,
                  ele,
                  "edge",
                );
                ConnectionHandler.insertEdgeBefore(conn, edge);
              });
            }
          },
        });
        onClose();
      }}
    >
      <FModal.Text
        name={"name"}
        label="Name"
        required={true}
        requiredMessage="Type Location Name"
        props={{
          placeholder: "Location name, example Shaft 3 or South Elevation",
        }}
      />
      <FModal.Select
        name={"typeId"}
        required={true}
        label="Select Type"
        requiredMessage="Select type of location"
        props={{
          placeholder: "Select type of Location",
          options: typeOptions.map((t) => ({ label: t.name.en, value: t.id })),
        }}
      />
      <div className="flex gap-1 items-center mb-1">
        Floors &nbsp; &nbsp; &nbsp;
        <Button
          size="small"
          type="primary"
          onClick={() => setFloors((floors) => [...floors, uuid.v4()])}
        >
          + Add
        </Button>
      </div>
      {floors.map((cur, i) => (
        <div key={cur} className="flex flex-row justify-between">
          <div className="flex flex-row justify-start">
            <FModal.Text
              name={"floor_" + cur}
              required={true}
              requiredMessage="Type Name"
              className="w-12"
              initialValue={"Floor " + (i + 1)}
              props={{
                placeholder: "Enter Name of Floor",
              }}
            />
            <FModal.Checkbox
              className="ml-1"
              name={"floorNet_" + cur}
              initialValue={false}
            />
            <span className="ml-0.5 mt-0.25">Has Protection</span>
          </div>
          {i !== 0 && (
            <Button
              type="text"
              onClick={() =>
                setFloors((floors) => floors.filter((f) => f !== cur))
              }
            >
              X
            </Button>
          )}
        </div>
      ))}
    </FModal>
  );
};
export default CreateWorkAboveElevation;

import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitGcProjectSettingCheckbox from "./basic/PermitGcProjectSettingCheckbox";
import ShowPermitChecklistsTables from "./basic/ShowPermitChecklistsTables";

const SteelErectionSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  data,
  checklistGc,
  mainGc,
  project,
}) => {
  return (
    <>
      <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: project?.project_setting,
          field: 'only_submitter_sign_the_steel_erection_permit'
        }}
      />
      <ShowPermitChecklistsTables
        {...{
          checklistGc,
          data,
          project,
          gcPermitCheckListItems,
          queryVariables,
          checklistItemsType: "steel_erection",
        }}
      />
    </>
  );
};
export default SteelErectionSettings;

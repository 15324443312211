/**
 * @generated SignedSource<<9fb9c44e0258097dd46a9b2605dbf29c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ht_integration_account_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HtLicenseTypesModal_updateHtAccount_Mutation$variables = {
  _set: ht_integration_account_set_input;
  htAccountId: string;
};
export type HtLicenseTypesModal_updateHtAccount_Mutation$data = {
  readonly update_ht_integration_account_by_pk: {
    readonly access_token: string | null | undefined;
    readonly id: string;
  } | null | undefined;
};
export type HtLicenseTypesModal_updateHtAccount_Mutation = {
  response: HtLicenseTypesModal_updateHtAccount_Mutation$data;
  variables: HtLicenseTypesModal_updateHtAccount_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "htAccountId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "htAccountId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "ht_integration_account",
    "kind": "LinkedField",
    "name": "update_ht_integration_account_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "access_token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HtLicenseTypesModal_updateHtAccount_Mutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HtLicenseTypesModal_updateHtAccount_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "f4ceae65e2836638a822981ba2a59f53",
    "id": null,
    "metadata": {},
    "name": "HtLicenseTypesModal_updateHtAccount_Mutation",
    "operationKind": "mutation",
    "text": "mutation HtLicenseTypesModal_updateHtAccount_Mutation(\n  $htAccountId: uuid!\n  $_set: ht_integration_account_set_input!\n) {\n  update_ht_integration_account_by_pk(pk_columns: {id: $htAccountId}, _set: $_set) {\n    id\n    access_token\n  }\n}\n"
  }
};
})();

(node as any).hash = "d451b8fd11516438f654472c5fcc6e47";

export default node;

/**
 * @generated SignedSource<<c9752d31a3cd4754996804473daf325f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type HtProjectFilterModalQuery$variables = {
  htAccountId: string;
};
export type HtProjectFilterModalQuery$data = {
  readonly ht_integration_account_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly pk: string;
        readonly project_filter: any | null | undefined;
        readonly tenant: string;
      };
    }>;
  };
};
export type HtProjectFilterModalQuery = {
  response: HtProjectFilterModalQuery$data;
  variables: HtProjectFilterModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "htAccountId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "htAccountId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tenant",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_filter",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HtProjectFilterModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ht_integration_accountConnection",
        "kind": "LinkedField",
        "name": "ht_integration_account_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ht_integration_accountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ht_integration_account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HtProjectFilterModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ht_integration_accountConnection",
        "kind": "LinkedField",
        "name": "ht_integration_account_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ht_integration_accountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ht_integration_account",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "800b9ec973787ab09e28d4d5ae99b80d",
    "id": null,
    "metadata": {},
    "name": "HtProjectFilterModalQuery",
    "operationKind": "query",
    "text": "query HtProjectFilterModalQuery(\n  $htAccountId: uuid!\n) {\n  ht_integration_account_connection(where: {id: {_eq: $htAccountId}}) {\n    edges {\n      node {\n        tenant\n        pk: id\n        project_filter\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "21b9022d786ce944c9fdb80bc378b882";

export default node;

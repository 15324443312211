import { Navigate, Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import NestedRoute from "src/common/types/manual/NestedRoute";
import GCOffices from "../office/GCOffices";
import GCOfficeTeam from "./team/GCOfficeTeam";
import GCOffice from "./GCOffice";
import GCOfficeProjects from "./projects/GCOfficeProjects";
import GCOfficeDetail from "./detail/GCOfficeDetail";
import GCOfficeWorkers from "./worker/GCOfficeWorkers";
import GCOfficeIncidents from "./incident/GCOfficeIncidents";
import GCOfficeObservations from "./observation/GCOfficeObservations";

const gcOfficesRoute: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole="employee">
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [
    {
      path: "",
      element: <GCOffices />,
      children: [
        {
          path: ":officeId",
          element: <GCOffice />,
          children: [
            { path: "", element: <Navigate to={"projects"} /> },
            { path: "workers", element: <GCOfficeWorkers /> },
            // { path: "details", element:},
            { path: "teams", element: <GCOfficeTeam /> },
            { path: "incidents", element: <GCOfficeIncidents /> },
            {
              path: "observations",
              element: <GCOfficeObservations />,
            },
            { path: "projects", element: <GCOfficeProjects /> },
            { path: "settings", element: <GCOfficeDetail /> },
          ],
        },
      ],
    },
  ],
};
export default gcOfficesRoute;

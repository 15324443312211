import React, { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import {
  CurrentSessionInspectionDataType,
  UserType,
} from "../../utils/siteInspectionTypes";
import SiteFeatureLayoutBody from "src/common/components/layouts/steps-navigation-layout/SiteFeatureLayoutBody";
import SiteInspectionUserContext from "../../utils/siteInspectionUserContext";
import SiteInspectionWorkerStepsHello from "./steps/hello/SiteInspectionWorkerStepsHello";
import SiteInspectionWorkerStepsCompleted from "./steps/completed/SiteInspectionWorkerStepsCompleted";
import {
  useSiteFeatureStepsNavigationDirection,
  useSiteFeatureStepsNavigationEffect,
} from "src/common/components/layouts/steps-navigation-layout/siteFeatureRegistrationNavigationHooks";
import SiteInspectionWorkerStepsEmployer from "./steps/employer/SiteInspectionWorkerStepsEmployer";
import SiteInspectionWorkerStepsNameFullName from "./steps/name/SiteInspectionWorkerStepsFullName";
import SiteInspectionWorkerStepsSignature from "./steps/signature/SiteInspectionWorkerStepsSignature";
import { ConfigProvider } from "antd";
import SiteOrientationLayoutWrapper from "src/common/components/layouts/steps-navigation-layout/SiteFeatureLayoutWrapper";
import SiteInspectionWorkerInspections from "./steps/inspections/SiteInspectionWorkerStepsInspections";
import {
  GetInspectionDataQuery,
  GetInspectionDataQueryVariables,
  GetInspectionDataDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import SiteInspectionWorkerStepsModel from "./steps/model/SiteInspectionWorkerViewStepsModel";
import SiteInspectionWorkerStepsImages from "./steps/image/SiteInspectionWorkerStepsImages";
import SiteInspectionWorkerStepChecklistItemList from "./steps/checklistItems/SiteInspectionWorkerStepChecklistItemList";
import SiteInspectionWorkerStepsMultiModelCheck from "./steps/multi-inspection/SiteInspectionWorkerStepsMultiModelCheck";
import SiteInspectionCompletedResults from "./steps/completed-results/SiteInspectionCompletedResults";
import { LanguageProvider } from "src/utility-features/i18n/context/languageContext";
import { AVAILABLE_DB_LANGUAGES } from "../../../../utility-features/i18n/languages";
import {
  useCurrentDBLangSelector,
  useCurrentLangStrings,
  useCurrentLanguageState,
} from "../../../../utility-features/i18n/context/languageHooks";
import SiteInspectionJobLocation from "./steps/job-location/SiteInspectionJobLocation";
import { useSuspenseQuery } from "@apollo/client";

const SiteInspectionWorkerViewBody: FunctionComponent = () => {
  const [user, setUser] = useState<UserType | null>(null);
  const [
    currentSessionInspectionsRequiredData,
    setCurrentSessionInspectionsRequiredData,
  ] = useState<CurrentSessionInspectionDataType>({});
  const qrCodeId = useParams().qrCodeId;
  if (!qrCodeId) throw new Error("qrCoreId param is missing");
  //IN Future maybe if we have exact project address we can use geoLocation to determine the project QR is being used on
  const projectId = useParams().projectId;
  if (!projectId) throw new Error("projectId param is missing");
  const { data } = useSuspenseQuery<
    GetInspectionDataQuery,
    GetInspectionDataQueryVariables
  >(GetInspectionDataDocument, {
    variables: { input: { projectId, qrCodeId } },
  });
  const inspectionData = data.getInspectionData;
  const langStrings = useCurrentLangStrings();
  const [language, setLanguage] = useCurrentLanguageState();
  const gs = useCurrentDBLangSelector();

  const { goToNextStep, goToPrevStep, nextStepDirection, clearStepDirection } =
    useSiteFeatureStepsNavigationDirection();
  const [showCompleted, setShowCompleted] = useState(false);
  const steps = [
    {
      id: "hello",
      title: "Welcome",
      body: (
        <SiteInspectionWorkerStepsHello
          onNext={goToNextStep}
          showLangSelector
          projectName={inspectionData.projectName}
          gcName={inspectionData.gcs.map((gc) => gc.name).join(", ")}
          gcLogoUrl={inspectionData.logoToShow}
        />
      ),
    },
    {
      id: "location",
      title: "Job Location",
      body: (
        <SiteInspectionJobLocation
          onBack={goToPrevStep}
          onNext={goToNextStep}
          locationsOptions={(inspectionData.projectLocations || [])?.map(
            (l) => ({ value: l.id, label: gs(l.name) }),
          )}
        />
      ),
      disabled: !inspectionData.projectLocations?.length,
    },
    {
      title: langStrings.strings.selectInspection,
      id: "inspection",
      body: (
        <SiteInspectionWorkerInspections
          onBack={goToPrevStep}
          showCompleted={showCompleted}
          setShowCompleted={setShowCompleted}
          onNext={goToNextStep}
          qrName={inspectionData.qrName}
          inspectionOptions={inspectionData.inspections}
        />
      ),
    },

    ...(showCompleted
      ? [
          {
            title: langStrings.strings.inspectionResults,
            id: "completed-inspection",
            body: (
              <SiteInspectionCompletedResults
                projectId={projectId}
                qrCodeId={qrCodeId}
                onBack={goToPrevStep}
                onNext={() => {}}
              />
            ),
          },
        ]
      : [
          {
            title: "Employer",
            id: "employer",
            body: (
              <SiteInspectionWorkerStepsEmployer
                onBack={goToPrevStep}
                onNext={goToNextStep}
                subOptions={inspectionData.subcontractors}
              />
            ),
          },
          {
            title: "Full name",
            id: "full_name",
            body: (
              <SiteInspectionWorkerStepsNameFullName
                onBack={goToPrevStep}
                onNext={goToNextStep}
                workers={inspectionData.workers
                  .filter((w) =>
                    user?.company?.id
                      ? w.subcontractor.id === user.company.id
                      : false,
                  )
                  .map((w) => ({
                    id: w.id,
                    name: w.name,
                    trade: w.trade ? gs(w.trade.name) : undefined,
                  }))}
              />
            ),
          },
          {
            title: "Multi-Inspection",
            id: "multi",
            disabled: !user?.inspection?.is_multi_inspection,
            body: (
              <SiteInspectionWorkerStepsMultiModelCheck
                deficientTypeOptions={inspectionData.deficientAnswerSelectTypes}
                onNext={goToNextStep}
                onBack={goToPrevStep}
              />
            ),
          },
          {
            title: "Model Number",
            id: "model",
            body: (
              <SiteInspectionWorkerStepsModel
                onBack={goToPrevStep}
                onNext={goToNextStep}
              />
            ),
            disabled:
              !!user?.inspection?.is_multi_inspection ||
              (!user?.inspection?.mode_number &&
                !user?.inspection?.model_number_required),
          },
          {
            title: "Images",
            id: "images",
            disabled: !!user?.inspection?.is_multi_inspection,
            body: (
              <SiteInspectionWorkerStepsImages
                onBack={goToPrevStep}
                onNext={goToNextStep}
              />
            ),
          },

          {
            id: "checklist",
            title: "Q's",
            body: (
              <SiteInspectionWorkerStepChecklistItemList
                onNext={goToNextStep}
                onBack={goToPrevStep}
                deficientTypeOptions={inspectionData.deficientAnswerSelectTypes}
              />
            ),
          },
          {
            title: `Signature`,
            body: (
              <SiteInspectionWorkerStepsSignature
                onBack={goToPrevStep}
                onNext={goToNextStep}
              />
            ),
            id: `signature`,
          },
          {
            title: "Completed",
            body: (
              <SiteInspectionWorkerStepsCompleted
                onNext={() => {
                  goToStepById("hello");
                  setUser((u) =>
                    u
                      ? {
                          name: u.name,
                          company: u.company,
                          id: u.id,
                          projectLocationId: u.projectLocationId,
                        }
                      : null,
                  );
                }}
              />
            ),
            id: "completed",
          },
        ]),
  ];
  const { currentStepId, goToStepById } = useSiteFeatureStepsNavigationEffect(
    nextStepDirection,
    clearStepDirection,
    steps,
  );

  return (
    <SiteInspectionUserContext.Provider
      value={{
        projectId,
        qrCodeId,
        setCurrentSessionInspectionsRequiredData,
        currentSessionInspectionsRequiredData,
        user: user ?? undefined,
        updateUser: setUser,
      }}
    >
      <SiteFeatureLayoutBody
        steps={steps}
        currentStepId={currentStepId || undefined}
        languagePicker={{
          availableLangs: AVAILABLE_DB_LANGUAGES,
          selectedLang: language,
          onLangeChange: (newLang) => {
            setLanguage(newLang);
            localStorage.setItem("inspectionLang", newLang);
          },
        }}
      />
    </SiteInspectionUserContext.Provider>
  );
};

const SiteInspectionWorkerView: React.FC = () => {
  const storedLang = localStorage.getItem("inspectionLang");
  return (
    <SiteOrientationLayoutWrapper>
      <ConfigProvider componentSize={`large`}>
        <LanguageProvider
          defaultLang={
            storedLang === "es" || storedLang === "pt" ? storedLang : "en"
          }
        >
          <SiteInspectionWorkerViewBody />
        </LanguageProvider>
      </ConfigProvider>
    </SiteOrientationLayoutWrapper>
  );
};

export default SiteInspectionWorkerView;

import {
  CaretDownOutlined,
  CaretUpOutlined,
  SolutionOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Card, Col, Divider, Skeleton, Statistic } from "antd";
import dayjs from "dayjs";
import React, { PropsWithChildren, useMemo } from "react";
import { useParams } from "react-router-dom";
import PieChart from "src/common/components/charts/PieChart";
import BaseTable from "src/common/components/tables/basic/BaseTable";
import WorkerStatistics, {
  DefaultStats,
  WorkerManHourRecord,
  WorkerStatsSchema,
} from "src/common/models/WorkerStatistics";
import { titleCase } from "src/common/functions/stringFormatters";
import {
  useGetWorkerStatsByRangeLazyQuery,
  useGetWorkerStatsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import randomHexColor from "src/common/functions/randomHexColor";

type pieStats = {
  foremen: number;
  journeymen: number;
  apprentice: number;
};

interface WorkerBreakDownPieChartProps {
  statsData: any;
  loading: boolean;
}

interface StatProps {
  title: React.ReactNode;
  menu?: React.ReactNode;
  children?: React.ReactNode | React.ReactChild | React.ReactChildren;
}

const WorkerOnsiteStatCard: React.FC<PropsWithChildren<StatProps>> = ({
  title,
  menu,
  children,
}) => (
  <div className="flex-1">
    <span style={{ display: "flex", justifyContent: "space-between" }}>
      <h3>{title}</h3>
    </span>
    {children}
  </div>
);

type MemoizedStats = {
  onsite: WorkerStatsSchema;
  manhours: WorkerStatsSchema;
  averages: any;
};

const DefaultAverages = (): {
  average30: number;
  manHoursAverage30: number;
  manhoursAverageThisWeek: number;
  manhoursAverageLastWeek: number;
  manhoursAverageThisMonth: number;
  manhoursAverageLastMonth: number;
} => ({
  average30: 0,
  manHoursAverage30: 0,
  manhoursAverageThisWeek: 0,
  manhoursAverageLastWeek: 0,
  manhoursAverageThisMonth: 0,
  manhoursAverageLastMonth: 0,
});

interface GCProjectWorkersOverviewProps {}

const WorkerBreakDownPieChart = ({
  loading,
  statsData,
}: WorkerBreakDownPieChartProps) => {
  const manHourStats = useMemo<pieStats>((): pieStats => {
    const defaultStats: pieStats = {
      foremen: 12,
      journeymen: 2,
      apprentice: 1,
    };

    if (!statsData) return defaultStats;

    return statsData.reduce(
      (current: pieStats, record: WorkerManHourRecord) => {
        current.foremen += record.foreman_today;
        current.journeymen += record.journeymen_today;
        current.apprentice += record.apprentice_today;
        return current;
      },
      defaultStats,
    );
  }, [statsData]);
  console.log("manHourStats", manHourStats);
  return loading ? (
    <Card style={{ height: 320, overflow: "hidden" }}>
      <Skeleton active={true} />
    </Card>
  ) : (
    <PieChart
      title="Workers Breakdown"
      label={[
        "name",
        (name: string) => ({
          content: (record: any) =>
            `${titleCase(name)}-${Math.round(100 * record.percent)}%`,
        }),
      ]}
      chartCfg={{
        autoFit: true,
        height: 300,
        // width: 350,
        padding: [10, 110, 10, 30],
      }}
      tooltip={{
        config: {
          showTitle: false,
          showMarkers: false,
        },
        displayAs: [
          "name*value",
          (name, value) => ({
            name: titleCase(name),
            value: `${value} workers`,
          }),
        ],
      }}
      legend={{
        show: true,
        options: {
          position: "right",
          itemName: {
            formatter(text: string) {
              return titleCase(text);
            },
          },
        },
      }}
      interactions={["tooltip", "element-active"]}
      color={{
        field: "name",
        colors: ["#0000ff", "#69c0ff", "#ff6600"],
      }}
      dataSource={[
        {
          name: "foremen",
          value: manHourStats.foremen,
          color: randomHexColor(),
        },
        {
          name: "journeymen",
          value: manHourStats.journeymen,
          color: randomHexColor(),
        },
        {
          name: "apprentice",
          value: manHourStats.apprentice,
          color: randomHexColor(),
        },
      ]}
    />
  );
};

interface WorkerOnsiteTableProps {
  dataSource: any;
}

const WorkerOnsiteTable = ({ dataSource }: WorkerOnsiteTableProps) => {
  const memoizedCols = useMemo<any>(() => {
    return [
      {
        rowKey: "onsite",
        title: "Workers On Site Today",
        children: [
          {
            rowKey: "subcontractor",
            title: "Subcontractors",
            dataIndex: ["sub_name"],
          },
          {
            rowKey: "workerType",
            title: "Worker Type",
            dataIndex: ["sub_trade"],
          },
          {
            title: "Worker Role",
            children: [
              {
                rowKey: "foremen",
                title: "Foremen",
                dataIndex: ["foreman_today"],
              },
              {
                rowKey: "journeymen",
                title: "Journeymen",
                dataIndex: ["journeymen_today"],
              },
              {
                rowKey: "apprentice",
                title: "Apprentice",
                dataIndex: ["apprentice_today"],
              },
            ],
          },
        ],
      },
      {
        rowKey: "manHours",
        title: "Man Hours",
        children: [
          {
            rowKey: "manHoursMonthly",
            title: "This Month",
            dataIndex: ["man_hours_this_mo"],
            render: (val: number) => (val ? val.toLocaleString() : 0),
          },
          {
            rowKey: "manHoursMonthlyLast",
            title: "Last Month",
            dataIndex: ["man_hours_last_mo"],
            render: (val: number) => (val ? val.toLocaleString() : 0),
          },
          {
            rowKey: "manHoursMonthlyLast2",
            title: "2 Months Ago",
            dataIndex: ["man_hours_two_mo_ago"],
            render: (val: number) => (val ? val.toLocaleString() : 0),
          },
          {
            rowKey: "manHoursYTD",
            title: "Total YTD",
            dataIndex: ["man_hours_ytd"],
            render: (val: number) => (val ? val.toLocaleString() : 0),
          },
          {
            rowKey: "manHoursProjectTotal",
            title: "Total for Project",
            dataIndex: ["man_hours_total"],
            render: (val: number) => (val ? val.toLocaleString() : 0),
          },
        ],
      },
    ];
  }, [dataSource]);
  return (
    <Card style={{ padding: 0, overflow: "x-scroll" }}>
      <BaseTable
        size="small"
        style={{ height: "100%" }}
        columns={memoizedCols}
        dataSource={dataSource}
      />
    </Card>
  );
};

interface StatCardProps {
  record: any;
}

const StatCard = ({ record }: StatCardProps) => {
  return (
    <Col flex="1 1 200px">
      <Card>
        <h3 style={{ letterSpacing: "1.5" }}>{record.title}</h3>
        <Statistic value={record.value} />
      </Card>
    </Col>
  );
};

const GCProjectWorkersOverview: React.FC<
  GCProjectWorkersOverviewProps
> = () => {
  const projectId = useParams()["projectId"] as string; //TODO

  const {
    data: workersStatistics,
    loading: workersStatisticsLoading,
    error: workersStatisticsError,
  } = useGetWorkerStatsQuery({
    variables: {
      projectId,
    },
  });

  const [
    getRangeStats,
    {
      data: statsByRange,
      loading: statsByRangeLoading,
      error: statsByRangeError,
    },
  ] = useGetWorkerStatsByRangeLazyQuery();

  const loading = workersStatisticsLoading || statsByRangeLoading;

  const workerStats = useMemo<MemoizedStats>((): MemoizedStats => {
    getRangeStats({
      variables: {
        projectId,
        startDate: dayjs().subtract(3, "M").startOf("d").toISOString(),
        endDate: dayjs().endOf("d").toISOString(),
      },
    });
    if (loading || !workersStatistics || !statsByRange) {
      return {
        onsite: DefaultStats(),
        manhours: DefaultStats(),
        averages: DefaultAverages(),
      };
    } else {
      const { worker_onsite_stats, worker_manhour_stats } = workersStatistics;
      const { workersByRange, manHoursByRange } = statsByRange;

      const stats = new WorkerStatistics(
        worker_onsite_stats,
        worker_manhour_stats,
        workersByRange,
        manHoursByRange,
      );

      return {
        onsite: stats.onSite(),
        manhours: stats.manHours(),
        averages: {
          //Workers Onsite 30 avg
          average30: stats.average("onsite", 30, "day") || 0,
          // Manhours avg this week
          manhoursAverage30: stats.average("manhours", 30, "day") || 0,
          manhoursAverageThisWeek:
            stats.average("manhours", [
              dayjs().startOf("isoWeek").toString(),
              dayjs().endOf("isoWeek").toString(),
            ]) || 0,
          // Manhours avg last week
          manhoursAverageLastWeek:
            stats.average("manhours", [
              dayjs().subtract(1, "week").startOf("isoWeek").toString(),
              dayjs().subtract(1, "week").endOf("isoWeek").toString(),
            ]) || 0,
          manhoursAverageThisMonth:
            stats.average("manhours", [
              dayjs().startOf("month").toString(),
              dayjs().endOf("month").toString(),
            ]) || 0,
          // Manhours avg last Month
          manhoursAverageLastMonth:
            stats.average("manhours", [
              dayjs().subtract(1, "M").startOf("M").toString(),
              dayjs().subtract(1, "M").endOf("M").toString(),
            ]) || 0,
          // Manhours avg -2 Month
          manoursAverageTwoMonthsAgo: stats.average("manhours", [
            dayjs().subtract(2, "M").startOf("M").toString(),
            dayjs().subtract(2, "M").endOf("M").toString(),
          ]),
        },
      };
    }
  }, [loading, workersStatistics, statsByRange]);

  const snapshotsRow: Array<{
    id: string | number;
    title: React.ReactNode;
    value: string | number | undefined;
    action?: {
      title: string;
      onClick: () => void;
    };
    footer?: () => JSX.Element;
  }> = [
    {
      id: "manHoursToday",
      title: "Manhours Today",
      value: workerStats.manhours.today.toLocaleString(),
    },
    {
      id: "manHoursThisWeek",
      title: "Manhours this Week",
      value: workerStats.manhours.thisWeek.toLocaleString(),
    },
    {
      id: "manHoursThisMonth",
      title: "Manhours this Month",
      value: workerStats.manhours.thisMonth.toLocaleString(),
    },
    {
      id: "manHoursLastMonth",
      title: "Manhours last Month",
      value: workerStats.manhours.lastMonth.toLocaleString(),
    },
    {
      id: "manHoursYTD",
      title: "Manhours Total YTD",
      value: workerStats.manhours.YTD.toLocaleString(),
    },
    {
      id: "manHoursTotal",
      title: "Manhours Project Total",
      value: workerStats.manhours.lifetime.toLocaleString(),
    },
  ];

  const PercentChangeStat = (
    first: number,
    second: number,
    caption: string,
  ) => {
    let diff = Math.round(((first - second) / first) * 100);

    if (!isFinite(diff)) diff = 0;

    return diff < 0 ? (
      <div className="">
        <CaretDownOutlined style={{ color: "#FF4D4F" }} />
        <span style={{ paddingLeft: "2%" }}>
          ({Math.abs(diff)})% {caption}
        </span>
      </div>
    ) : (
      <div className="">
        <CaretUpOutlined style={{ color: "#73D13D" }} />
        <span style={{ paddingLeft: "2%" }}>
          {diff}% {caption}
        </span>
      </div>
    );
  };

  return (
    <div className="w-full h-full overflow-y-auto">
      <div className="flex flex-col w-full gap-2">
        <div className="flex flex-row gap-1">
          {snapshotsRow.map((col) => (
            <StatCard key={col.id} record={col} />
          ))}
        </div>
        <div className="flex flex-row w-full gap-1">
          <WorkerOnsiteStatCard title="Workers On-Site">
            {loading ? (
              <Skeleton active={true} />
            ) : (
              <>
                <div>
                  <SolutionOutlined />
                  <span style={{ fontSize: 36, paddingLeft: "2%" }}>
                    {workerStats.onsite.today}
                  </span>
                </div>
                <div>
                  {PercentChangeStat(
                    workerStats.onsite.today,
                    workerStats.onsite.lastWeek,
                    "from last week",
                  )}
                  {PercentChangeStat(
                    workerStats.onsite.thisMonth,
                    workerStats.onsite.lastMonth,
                    "from last month",
                  )}
                </div>
                <Divider style={{ margin: "12px 0" }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>30 Day Average</div>
                  <div>{workerStats.averages.average30.toLocaleString()}</div>
                </div>
              </>
            )}
          </WorkerOnsiteStatCard>
          <WorkerOnsiteStatCard title="Man Hours this Month">
            {loading ? (
              <Skeleton active={true} />
            ) : (
              <>
                <div>
                  <UsergroupAddOutlined />
                  <span style={{ fontSize: 36, paddingLeft: "2%" }}>
                    {workerStats.manhours.thisMonth.toLocaleString()}
                  </span>
                </div>
                <div>
                  {PercentChangeStat(
                    workerStats.manhours.thisMonth,
                    workerStats.manhours.lastMonth,
                    "from last month",
                  )}
                  {PercentChangeStat(
                    workerStats.manhours.thisMonth,
                    workerStats.manhours.twoMonthsAgo,
                    "from 2 months ago",
                  )}
                </div>
                <Divider style={{ margin: "12px 0" }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>30 Day Average</div>
                  {workerStats?.averages?.manhoursAverage30?.toLocaleString() && (
                    <div>
                      {workerStats.averages.manhoursAverage30.toLocaleString()}
                    </div>
                  )}
                </div>
              </>
            )}
          </WorkerOnsiteStatCard>
          <WorkerOnsiteStatCard title="Total Unique Workers">
            {loading ? (
              <Skeleton active={true} />
            ) : (
              <>
                <div>
                  <UsergroupAddOutlined />
                  <span style={{ fontSize: 36, paddingLeft: "2%" }}>
                    {workerStats.onsite.lifetime.toLocaleString()}
                  </span>
                </div>
                <div></div>
                <Divider style={{ margin: "12px 0" }} />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Unique Workers YTD</div>
                  <div>{workerStats.onsite.YTD.toLocaleString()}</div>
                </div>
              </>
            )}
          </WorkerOnsiteStatCard>
        </div>
        <div>
          <Col md={24} lg={12}>
            <WorkerBreakDownPieChart
              loading={workersStatisticsLoading}
              statsData={
                workersStatistics && workersStatistics.worker_manhour_stats
              }
            />
          </Col>
        </div>
        <div>
          <Col sm={24} lg={24}>
            <WorkerOnsiteTable
              key="onsiteTable"
              dataSource={
                workersStatistics && workersStatistics.worker_manhour_stats
              }
            />
          </Col>
        </div>
      </div>
    </div>
  );
};

export default GCProjectWorkersOverview;

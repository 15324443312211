import React, { forwardRef, useState } from "react";
import {
  GetObservationDistributedUserDocument,
  GetObservationDistributedUserQuery,
  GetObservationDistributedUserQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import DataApolloScrollTable, {
  DataApolloScrollTableImplementorProps,
  DataApolloScrollTableProps,
  DataApolloScrollTableRef,
} from "src/common/components/tables/basic/DataApolloScrollTable";
import { IconBriefcase, IconPlus } from "@tabler/icons";
import Icon from "src/common/components/general/Icon";
import capitalize from "src/common/functions/capitalize";
type ColumnKeys = "created_at" | "user" | "status" | "role" | "email";
type DataType =
  GetObservationDistributedUserQuery["observation_distributed_user"][number];
type Props = DataApolloScrollTableImplementorProps<
  GetObservationDistributedUserQueryVariables,
  ColumnKeys,
  DataType,
  GetObservationDistributedUserQuery
> & {
  projectId?: string;

  searchString?: string;
  extraColumns?: DataApolloScrollTableProps<
    GetObservationDistributedUserQueryVariables,
    ColumnKeys,
    DataType,
    GetObservationDistributedUserQuery
  >["columns"];
  riskLevels?: { name: string; value: string }[];
  subs?: { name: string; id: string }[];
  headerComponent?: React.ReactElement;
  datePickerDataTitle?: string;
  subcontractorId?: string;
  datePickerDataIndex?: string[];

  defaultSortColumnKey?: ColumnKeys;
};
const ObservationDistributedUserTable = forwardRef<
  DataApolloScrollTableRef,
  Props
>(
  (
    {
      title = "Distributed to Users",
      onRowClick,
      searchString,
      subcontractorId,
      datePickerDataTitle,
      datePickerDataIndex,
      projectId,
      headerComponent,
      extraColumns,
      where,
      defaultSortColumnKey,
      ...props
    },
    ref,
  ) => {
    return (
      <DataApolloScrollTable<
        GetObservationDistributedUserQueryVariables,
        ColumnKeys,
        DataType,
        GetObservationDistributedUserQuery
      >
        {...props}
        newCustomTableLook
        extraQueryVariables={{
          includeProj: !projectId,
          includeSub: !subcontractorId,
        }}
        title="Distributed To Users"
        loadAll
        ref={ref}
        filterNotVisibleByDefault
        where={where}
        queryNode={GetObservationDistributedUserDocument}
        aggregateCountIndex="observation_distributed_user_aggregate"
        queryDataIndex="observation_distributed_user"
        columns={[
          {
            title: "Distributed On",
            key: "created_at",
            sortable: true,
            dataIndex: ["created_at"],
            defaultSortOrder: "desc",
            contentType: {
              type: "date",
              renderOptions: (v) => ({ format: "full" }),
            },
          },
          {
            title: "Distributed to",
            key: "user",
            sortable: true,
            dataIndex: ["user", "name"],
            searchDataIndex: ["user", "name"],
            render: (v) =>
              v ? <div className="flex font-accent">{v}</div> : "",
          },
          {
            title: "Role",
            dataIndex: ["user", "role"],
            key: "role",
            render: (v) =>
              v === "subcontractor_employee" ? "Subadmin" : capitalize(v),
          },
          {
            title: "Email",
            key: "email",
            searchDataIndex: ["user", "email"],
            dataIndex: ["user", "email"],
          },
          {
            title: "Email Status",
            key: "status",
            dataIndex: ["sent_error"],
            render: (v) =>
              v ? <div className="text-semantic-negative">{v}</div> : "Sent",
          },
        ]}
      />
    );
  },
);
export default ObservationDistributedUserTable;

/**
 * @generated SignedSource<<ff490e093d1c2555f8349423f7846929>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadedToolboxTalkTableMutation$variables = {
  curTime: string;
  ids: ReadonlyArray<string>;
};
export type UploadedToolboxTalkTableMutation$data = {
  readonly update_uploaded_toolbox_talk: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly archived_at: string | null | undefined;
      readonly id: string;
    }>;
  } | null | undefined;
};
export type UploadedToolboxTalkTableMutation = {
  response: UploadedToolboxTalkTableMutation$data;
  variables: UploadedToolboxTalkTableMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "curTime"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "archived_at",
            "variableName": "curTime"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "ids"
              }
            ],
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "uploaded_toolbox_talk_mutation_response",
    "kind": "LinkedField",
    "name": "update_uploaded_toolbox_talk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "uploaded_toolbox_talk",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "archived_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadedToolboxTalkTableMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadedToolboxTalkTableMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "48a4613f7b6ff3bce1ece72328a5ce22",
    "id": null,
    "metadata": {},
    "name": "UploadedToolboxTalkTableMutation",
    "operationKind": "mutation",
    "text": "mutation UploadedToolboxTalkTableMutation(\n  $curTime: timestamptz!\n  $ids: [uuid!]!\n) {\n  update_uploaded_toolbox_talk(where: {id: {_in: $ids}}, _set: {archived_at: $curTime}) {\n    affected_rows\n    returning {\n      archived_at\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0e7ecea2fc763fadfaaa0fd3d243f52";

export default node;

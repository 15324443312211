import { storage } from "../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { v1 as uuidv1 } from "uuid";
import { RcFile } from "antd/es/upload";
import * as uuid from "uuid";
import encodePathComponent from "../encodePathComponent";
export type UploadedFileType = {
  id: string;
  url: string;
  name?: string;
  type?: string;
};
const uploadFile = async (
  file: File | RcFile,
  directoryName?: string,
  onUploadPercent?: (percent: number) => any,
) => {
  try {
    const path = directoryName
      ? `${directoryName}/${uuid.v4()}/${encodePathComponent(file.name)}`
      : undefined;
    const id = uuidv1();
    const filePath = path ?? `images/${id}`;

    const storageRef = ref(storage, filePath);

    const uploadTask = uploadBytesResumable(storageRef, file);
    const url = await new Promise<void>((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snap) => {
          if (onUploadPercent) {
            onUploadPercent(snap.bytesTransferred / snap.totalBytes);
          }
        },
        reject,
        resolve,
      );
    }).then(() => getDownloadURL(uploadTask.snapshot.ref));

    return { id, url, name: file.name, type: file.type };
  } catch (err) {
    console.log("upload exception", err);
    throw err;
  }
};

export default uploadFile;

import { graphql } from "babel-plugin-relay/macro";
import React, { forwardRef } from "react";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { IncidentTable_incidents$data } from "src/common/types/generated/relay/IncidentTable_incidents.graphql";
import { IncidentTableQuery } from "src/common/types/generated/relay/IncidentTableQuery.graphql";
import capitalize from "../../../../common/functions/capitalize";
import { Popover } from "antd";
import dayjs from "dayjs";
import { IconTrash } from "@tabler/icons";
import Icon from "src/common/components/general/Icon";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import { useUpdateIncidentMutation } from "src/common/types/generated/apollo/graphQLTypes";
import useAuthUser from "src/common/hooks/useAuthUser";

type ColumnKeys =
  | "description"
  | "summary"
  | "incident_time"
  | "status"
  | "action"
  | "company"
  | "injured_users"
  | "attachments";
const CONNECTION_NAME = "incident_connection";
type DConnection = IncidentTable_incidents$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  IncidentTableQuery,
  string
>;

const IncidentTable = forwardRef<DataScrollTableRef, Props>(
  ({ title = "Project Incidents", onRowClick, where, ...props }, ref) => {
    const [updateIncident] = useUpdateIncidentMutation();
    const authUser = useAuthUser();

    const deleteIncident = (id: string) => {
      updateIncident({
        variables: {
          incidentId: id,
          _set: { deleted_at: dayjs().format() },
          objects: {
            comment: `Incident Deleted`,
            patch: [],
            edited_by_uid: authUser.uid,
            incident_id: id,
            edit_type: "delete",
          },
        },
      });
      if (ref && typeof ref !== "function") {
        ref.current?.refetch();
      }
    };

    const getCompanyOfIncidentUser = (
      incidentUser?: {
        employee?: {
          general_contractor?: {
            name: string;
          };
        } | null;
        general_person?: {
          readonly employer?: string | null;
        } | null;
        name: string;
        role: string;
        worker?: {
          subcontractor?: {
            name: string;
          } | null;
        } | null;
      } | null,
    ) => {
      return (
        incidentUser?.worker?.subcontractor?.name ||
        incidentUser?.employee?.general_contractor?.name ||
        incidentUser?.general_person?.employer
      );
    };

    return (
      <DataScrollTable<DConnection, ColumnKeys, IncidentTableQuery>
        {...props}
        where={where}
        newCustomTableLook
        extraSearchDataIndexes={[
          [{ index: "incident_users" }, { index: "user" }, { index: "name" }],
          [
            { index: "incident_types" },
            { index: "incident_type" },
            { index: "detail" },
            { index: "en" },
          ],
          [{ index: "submitted_by_user" }, { index: "name" }],
          [{ index: "reported_by_user" }, { index: "name" }],
          [{ index: "supervisor_user" }, { index: "name" }],
          [
            { index: "vehicle_incident" },
            { index: "person_involved" },
            { index: "name" },
          ],
          [
            { index: "vehicle_incident" },
            { index: "vehicle_owner" },
            { index: "name" },
          ],
          [
            { index: "vehicle_incident" },
            { index: "driver" },
            { index: "name" },
          ],
          [
            {
              index: "property_damage_incident",
            },
            {
              index: "property_owner",
            },
            {
              index: "name",
            },
          ],
          [
            { index: "equipment_incident" },
            { index: "operator" },
            { index: "name" },
          ],
          [
            { index: "theft_incident" },
            { index: "stolen_items_owner" },
            { index: "name" },
          ],
        ]}
        ref={ref}
        title={title}
        datePickerDataTitle="Pick date range"
        datePickerDataIndex={["incident_time"]}
        onRowClick={(report) => {
          onRowClick && onRowClick(report.pk);
        }}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allIncidentsConnection"}
        columns={[
          {
            title: "Incident Types",
            dataIndex: [],
            key: "description",
            render: (_, row) => {
              const incidentTypes = row.incident_types
                .map((incidentType) => incidentType.incident_type.detail.en)
                .join(", ");

              return incidentTypes;
            },
            size: "lg",
          },
          {
            title: "Incident Date",
            dataIndex: ["incident_time"],
            key: "incident_time",
            size: "lg",
            sortable: true,
            defaultSortOrder: "desc",
            contentType: {
              type: "date",
              renderOptions: () => ({
                format: "mmddyyyy",
              }),
            },
          },
          {
            title: "Injured Persons",
            dataIndex: [],
            key: "injured_users",
            render: (_, row) => {
              const injuredUsers = row.injuredUsers
                .map((incidentUser) => incidentUser.user.name)
                .join(", ");
              return injuredUsers;
            },
          },
          {
            title: "Company",
            key: "company",
            dataIndex: [],

            render: (_, row) => {
              const operatorCompany = getCompanyOfIncidentUser(
                row.equipment_incident?.operator,
              );
              const propertyOwnerCompany = getCompanyOfIncidentUser(
                row.property_damage_incident?.property_owner,
              );
              const vehicleOwnerCompany = getCompanyOfIncidentUser(
                row.vehicle_incident?.vehicle_owner,
              );
              const driverCompany = getCompanyOfIncidentUser(
                row.vehicle_incident?.driver,
              );
              const personInvolvedCompany = getCompanyOfIncidentUser(
                row.vehicle_incident?.person_involved,
              );
              const supervisorCompany = getCompanyOfIncidentUser(
                row.supervisor_user,
              );

              const injuredUsersCompanies = row.injuredUsers
                .map((injuredUser) => {
                  const userCompany =
                    injuredUser.user.role === "worker"
                      ? injuredUser.user?.worker?.subcontractor?.name
                      : injuredUser.user.role === "employee"
                      ? injuredUser.user?.employee?.general_contractor?.name
                      : injuredUser.user?.general_person?.employer;
                  return userCompany || "";
                })
                .filter(Boolean);

              const companies = [
                supervisorCompany,
                personInvolvedCompany,
                driverCompany,
                vehicleOwnerCompany,
                propertyOwnerCompany,
                operatorCompany,
                ...injuredUsersCompanies,
              ].filter(Boolean);

              return companies.length > 1 ? "Multiple" : companies[0] || "";
            },
          },
          {
            title: "Summary",
            dataIndex: ["summary", "en"],
            key: "summary",
            size: "lg",
            searchDataIndex: ["description", "en"],
          },
          {
            title: "Attachments",
            dataIndex: [],
            key: "attachments",
            render: (_, row) => {
              let attachments =
                row.attached_files_aggregate.aggregate?.count ?? 0;

              attachments += row.incident_types.reduce((agg, incidentType) => {
                agg =
                  agg +
                  (incidentType.documents_aggregate.aggregate?.count ?? 0);
                return agg;
              }, 0);

              attachments += row.incident_users.reduce((agg, incidentUser) => {
                agg =
                  agg +
                  (incidentUser.injury_detail?.attached_files_aggregate
                    .aggregate?.count ?? 0);

                agg =
                  agg +
                  (incidentUser.statement_detail?.attached_files_aggregate
                    .aggregate?.count ?? 0);

                return agg;
              }, 0);

              attachments +=
                row.root_cause_analysis?.document_aggregate.aggregate?.count ??
                0;

              return attachments;
            },
          },
          {
            title: "Status",
            dataIndex: ["status"],
            sortable: true,
            key: "status",
            size: "lg",
            render: (_, row) => {
              const status = capitalize(
                row.status === "pending" ? "In-progress" : row.status ?? "",
              );
              return (
                <Popover
                  trigger={"hover"}
                  content={
                    status === "Open" ? (
                      <div> Additional details or information required</div>
                    ) : status === "In-progress" ? (
                      <div className="w-20">
                        All general details or information have been provided.
                        In-Progress incidents are following cases that have not
                        been closed, like a worker that has not returned to work
                      </div>
                    ) : (
                      ""
                    )
                  }
                >
                  {status}
                </Popover>
              );
            },
            searchDataIndex: ["description", "en"],
          },
          {
            title: "",
            dataIndex: [],
            key: "action",
            render: (_, row) => {
              return (
                <div
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <BPopconfirm
                    onConfirm={() => {
                      deleteIncident(row.pk);
                    }}
                    title="Delete this Incident"
                  >
                    <Icon icon={IconTrash} color="negative" />
                  </BPopconfirm>
                </div>
              );
            },
          },
        ]}
        queryNode={graphql`
          query IncidentTableQuery(
            $first: Int!
            $after: String
            $where: incident_bool_exp!
            $order_by: [incident_order_by!]!
          ) {
            ...IncidentTable_incidents
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...IncidentTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment IncidentTable_total on query_root
          @argumentDefinitions(where: { type: "incident_bool_exp!" })
          @refetchable(queryName: "IncidentTableTotalRefetchableQuery") {
            allIncidentsConnection: incident_connection(where: $where) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment IncidentTable_incidents on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "incident_bool_exp!" }
            order_by: { type: "[incident_order_by!]!" }
          )
          @refetchable(queryName: "IncidentTableRefetchableQuery") {
            incident_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "IncidentTable_incident_connection"
                filters: []
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  incident_time
                  description {
                    en
                  }
                  summary {
                    en
                  }
                  status
                  incident_types {
                    incident_type {
                      detail {
                        en
                      }
                    }
                    documents_aggregate {
                      aggregate {
                        count
                      }
                    }
                  }
                  injuredUsers: incident_users(
                    where: {
                      type: { _eq: "injured" }
                      deleted_at: { _is_null: true }
                    }
                  ) {
                    user {
                      role
                      name
                      worker {
                        subcontractor {
                          name
                        }
                      }
                      general_person {
                        employer
                      }
                      employee {
                        general_contractor {
                          name
                        }
                      }
                    }
                  }

                  incident_users {
                    injury_detail {
                      attached_files_aggregate {
                        aggregate {
                          count
                        }
                      }
                    }

                    statement_detail {
                      attached_files_aggregate {
                        aggregate {
                          count
                        }
                      }
                    }
                  }

                  supervisor_user {
                    role
                    name
                    worker {
                      subcontractor {
                        name
                      }
                    }
                    general_person {
                      employer
                    }
                    employee {
                      general_contractor {
                        name
                      }
                    }
                  }

                  attached_files_aggregate {
                    aggregate {
                      count
                    }
                  }

                  root_cause_analysis {
                    document_aggregate {
                      aggregate {
                        count
                      }
                    }
                  }

                  equipment_incident {
                    operator {
                      ...IncidentTableUserFrag @relay(mask: false)
                    }
                  }

                  property_damage_incident {
                    property_owner {
                      ...IncidentTableUserFrag @relay(mask: false)
                    }
                  }

                  vehicle_incident {
                    vehicle_owner {
                      ...IncidentTableUserFrag @relay(mask: false)
                    }

                    driver {
                      ...IncidentTableUserFrag @relay(mask: false)
                    }

                    person_involved {
                      ...IncidentTableUserFrag @relay(mask: false)
                    }
                  }

                  theft_incident {
                    stolen_items_owner {
                      ...IncidentTableUserFrag @relay(mask: false)
                    }
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default IncidentTable;

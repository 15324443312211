/**
 * @generated SignedSource<<802073a73659c8a1d69edcf842c8c504>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type HtLicenseTypesModal_deleteLicenseType_Mutation$variables = {
  certId: string;
  htAccountId: string;
};
export type HtLicenseTypesModal_deleteLicenseType_Mutation$data = {
  readonly delete_ht_license_type: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type HtLicenseTypesModal_deleteLicenseType_Mutation = {
  response: HtLicenseTypesModal_deleteLicenseType_Mutation$data;
  variables: HtLicenseTypesModal_deleteLicenseType_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "htAccountId"
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "certId"
          }
        ],
        "kind": "ObjectValue",
        "name": "certification_id"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "htAccountId"
          }
        ],
        "kind": "ObjectValue",
        "name": "ht_account_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HtLicenseTypesModal_deleteLicenseType_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ht_license_type_mutation_response",
        "kind": "LinkedField",
        "name": "delete_ht_license_type",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ht_license_type",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "HtLicenseTypesModal_deleteLicenseType_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ht_license_type_mutation_response",
        "kind": "LinkedField",
        "name": "delete_ht_license_type",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ht_license_type",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7cb9ec33c581d2f40df89269df4d5f9b",
    "id": null,
    "metadata": {},
    "name": "HtLicenseTypesModal_deleteLicenseType_Mutation",
    "operationKind": "mutation",
    "text": "mutation HtLicenseTypesModal_deleteLicenseType_Mutation(\n  $htAccountId: uuid!\n  $certId: uuid!\n) {\n  delete_ht_license_type(where: {ht_account_id: {_eq: $htAccountId}, certification_id: {_eq: $certId}}) {\n    affected_rows\n    returning {\n      pk: id\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d02fedfb6097e2c3bd95006367a4101";

export default node;

/**
 * @generated SignedSource<<340db4c66adadc1d64d5e59415dd50d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SubProjectMobilizationStepSssp_CompanySafetyPlanQuery$variables = {
  subId: string;
};
export type SubProjectMobilizationStepSssp_CompanySafetyPlanQuery$data = {
  readonly safety_plan_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly add_to_future_projects: boolean;
        readonly company_safety_plan_id: string | null | undefined;
        readonly created_at: string;
        readonly created_by_user: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
        readonly id: string;
        readonly pk: string;
        readonly project_id: string | null | undefined;
        readonly revision_date: string | null | undefined;
        readonly revision_number: string | null | undefined;
        readonly title: string;
        readonly url: string;
      };
    }>;
  };
};
export type SubProjectMobilizationStepSssp_CompanySafetyPlanQuery = {
  response: SubProjectMobilizationStepSssp_CompanySafetyPlanQuery$data;
  variables: SubProjectMobilizationStepSssp_CompanySafetyPlanQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revision_date",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revision_number",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company_safety_plan_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "add_to_future_projects",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "title": "asc"
    }
  },
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "deleted_at",
        "value": {
          "_is_null": true
        }
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "subId"
          }
        ],
        "kind": "ObjectValue",
        "name": "subcontractor_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v16 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubProjectMobilizationStepSssp_CompanySafetyPlanQuery",
    "selections": [
      {
        "alias": "safety_plan_connection",
        "args": null,
        "concreteType": "safety_planConnection",
        "kind": "LinkedField",
        "name": "__SubProjectMobilizationStepSssp_CompanySafetyPlanQuery_safety_plan_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_planEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_plan",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "created_by_user",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubProjectMobilizationStepSssp_CompanySafetyPlanQuery",
    "selections": [
      {
        "alias": null,
        "args": (v15/*: any*/),
        "concreteType": "safety_planConnection",
        "kind": "LinkedField",
        "name": "safety_plan_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "safety_planEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "safety_plan",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "created_by_user",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v16/*: any*/),
                      (v17/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v12/*: any*/)
                ],
                "storageKey": null
              },
              (v13/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v15/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "SubProjectMobilizationStepSssp_CompanySafetyPlanQuery_safety_plan_connection",
        "kind": "LinkedHandle",
        "name": "safety_plan_connection"
      }
    ]
  },
  "params": {
    "cacheID": "1b35d727bfe7589bc67209c8791d6f3b",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "safety_plan_connection"
          ]
        }
      ]
    },
    "name": "SubProjectMobilizationStepSssp_CompanySafetyPlanQuery",
    "operationKind": "query",
    "text": "query SubProjectMobilizationStepSssp_CompanySafetyPlanQuery(\n  $subId: uuid!\n) {\n  safety_plan_connection(first: 10000, where: {subcontractor_id: {_eq: $subId}, deleted_at: {_is_null: true}}, order_by: {title: asc}) {\n    edges {\n      node {\n        id\n        pk: id\n        title\n        revision_date\n        created_at\n        project_id\n        revision_number\n        url\n        company_safety_plan_id\n        add_to_future_projects\n        created_by_user {\n          id\n          pk: id\n          name\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fff5455cc2603b4b4bab73537288cfc2";

export default node;

/**
 * @generated SignedSource<<69e009cc4edb2d4da82216761d3f942e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { inspection_qr_code_bool_exp, inspection_qr_code_order_by, order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InspectionQRTableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  order_by: ReadonlyArray<inspection_qr_code_order_by>;
  where: inspection_qr_code_bool_exp;
};
export type InspectionQRTableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InspectionQRTable_inspection_qr_codes" | "InspectionQRTable_total">;
};
export type InspectionQRTableQuery = {
  response: InspectionQRTableQuery$data;
  variables: InspectionQRTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order_by"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order_by",
    "variableName": "order_by"
  },
  (v4/*: any*/)
],
v6 = [
  (v4/*: any*/)
],
v7 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v12 = [
  (v10/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "InspectionQRTableQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "InspectionQRTable_inspection_qr_codes"
      },
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "InspectionQRTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "InspectionQRTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "inspection_qr_codeConnection",
        "kind": "LinkedField",
        "name": "inspection_qr_code_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "inspection_qr_codeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "inspection_qr_code",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_by_uid",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "project_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "created_by_user",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "limit",
                        "value": 1
                      },
                      {
                        "kind": "Literal",
                        "name": "order_by",
                        "value": {
                          "created_at": "desc"
                        }
                      }
                    ],
                    "concreteType": "inspection_result",
                    "kind": "LinkedField",
                    "name": "inspection_results",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "user_name",
                        "storageKey": null
                      },
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": (v12/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": "inspection_results(limit:1,order_by:{\"created_at\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "inspection_instance": {
                            "deleted_at": {
                              "_is_null": true
                            }
                          }
                        }
                      }
                    ],
                    "concreteType": "inspection_instance_qr_code",
                    "kind": "LinkedField",
                    "name": "inspection_qr_code_instances",
                    "plural": true,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "inspection_instance",
                        "kind": "LinkedField",
                        "name": "inspection_instance",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deleted_at",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "inspection_result_aggregate",
                            "kind": "LinkedField",
                            "name": "inspection_results_aggregate",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "inspection_result_aggregate_fields",
                                "kind": "LinkedField",
                                "name": "aggregate",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "count",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "text_translation",
                            "kind": "LinkedField",
                            "name": "name",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "en",
                                "storageKey": null
                              },
                              (v9/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "inspection_qr_code_instances(where:{\"inspection_instance\":{\"deleted_at\":{\"_is_null\":true}}})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "InspectionQRTable_inspection_qr_code_connection",
        "kind": "LinkedHandle",
        "name": "inspection_qr_code_connection"
      },
      {
        "alias": "allInstancesConnection",
        "args": (v6/*: any*/),
        "concreteType": "inspection_qr_codeConnection",
        "kind": "LinkedField",
        "name": "inspection_qr_code_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "inspection_qr_codeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "inspection_qr_code",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9127551e4e41dea4dec3e9eb7952f6af",
    "id": null,
    "metadata": {},
    "name": "InspectionQRTableQuery",
    "operationKind": "query",
    "text": "query InspectionQRTableQuery(\n  $first: Int!\n  $after: String\n  $where: inspection_qr_code_bool_exp!\n  $order_by: [inspection_qr_code_order_by!]!\n) {\n  ...InspectionQRTable_inspection_qr_codes_4g04uc\n  ...InspectionQRTable_total_3FC4Qo\n}\n\nfragment InspectionQRTable_inspection_qr_codes_4g04uc on query_root {\n  inspection_qr_code_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        pk: id\n        id\n        name\n        created_at\n        created_by_uid\n        project_id\n        created_by_user {\n          name\n          id\n        }\n        inspection_results(limit: 1, order_by: {created_at: desc}) {\n          id\n          user_name\n          created_at\n          user {\n            name\n            id\n          }\n        }\n        inspection_qr_code_instances(where: {inspection_instance: {deleted_at: {_is_null: true}}}) {\n          id\n          inspection_instance {\n            pk: id\n            deleted_at\n            id\n            inspection_results_aggregate {\n              aggregate {\n                count\n              }\n            }\n            name {\n              en\n              id\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment InspectionQRTable_total_3FC4Qo on query_root {\n  allInstancesConnection: inspection_qr_code_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2cd58e9eeab6f2efc37dbfc97c98cd3e";

export default node;

import React, { Suspense, useState } from "react";
import CompletedSiteInspection from "./CompleteSiteInspection";
import { SiteInspectionStepProps } from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import ErrorMessage from "src/utility-features/error-handling/components/ErrorMessage";
import SiteInspectionWorkerViewStepsCompletedUI from "./SiteInspectionWorkerStepsCompletedUI";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";

import { useCurrentLangStrings } from "../../../../../../utility-features/i18n/context/languageHooks";

export interface SiteInspectionWorkerViewStepsCompletedProps
  extends SiteInspectionStepProps {}

const SiteInspectionWorkerViewStepsCompleted: React.FC<
  SiteInspectionWorkerViewStepsCompletedProps
> = (props) => {
  const workerContext = useSiteInspectionUser();
  const qrCodeId = workerContext.qrCodeId;
  const langStrings = useCurrentLangStrings();

  const [completeInspectionLoading, setCompleteInspectionLoading] =
    useState<boolean>(true);
  const [animationFinished, setAnimationFinished] = useState(false);

  if (!qrCodeId || !workerContext.user?.signatureImageUrl) {
    return <ErrorMessage />;
  }
  const newlyEnteredModelNumbers = workerContext.user?.inspection
    ?.is_multi_inspection
    ? (workerContext.user.modelAnswersAndImages || []).map(
        (p) => [p.modelNumber.toUpperCase(), true] as const,
      )
    : workerContext.user?.model
    ? [[workerContext.user.model.toUpperCase(), true] as const]
    : [];
  const deficientItemsFound = workerContext.user.inspection?.is_multi_inspection
    ? Math.max(
        ...Object.values(workerContext.user.modelAnswersAndImages || {}).map(
          (val) =>
            Object.values(val.checklistItemAnswers || {}).filter(
              (ch) =>
                ch.option_response !== "na" &&
                ch.correct_answer != ch.option_response,
            ).length,
        ),
      )
    : Object.values(workerContext.user?.checklistItemAnswers || {}).filter(
        (ch) =>
          ch.option_response !== "na" &&
          ch.correct_answer != ch.option_response,
      ).length;
  return (
    <SiteFeatureStepLayout
      loading={completeInspectionLoading}
      nextButtonDisabled={!animationFinished}
      onNextButtonClick={props.onNext}
      nextButtonText={deficientItemsFound ? "Confirmed" : `Done`}
    >
      <>
        <Suspense fallback={null}>
          <CompletedSiteInspection
            {...{
              onFinish: () => {
                if (workerContext.user!.inspection?.id) {
                  workerContext.setCurrentSessionInspectionsRequiredData(
                    (prev) => {
                      return {
                        ...prev,
                        [workerContext.user!.inspection!.id]: {
                          defCount: deficientItemsFound,
                          enteredModelNumbers: {
                            ...(prev[workerContext.user!.inspection!.id]
                              ?.enteredModelNumbers || {}),
                            ...Object.fromEntries(newlyEnteredModelNumbers),
                          },
                        },
                      };
                    },
                  );
                }
                setCompleteInspectionLoading(false);
              },
            }}
          />
        </Suspense>

        <div className={`flex flex-col justify-center min-h-full`}>
          <SiteInspectionWorkerViewStepsCompletedUI
            langStrings={langStrings}
            deficientItemFound={deficientItemsFound > 0}
            onAnimationFinished={() => {
              setAnimationFinished(true);
            }}
          />
        </div>
      </>
    </SiteFeatureStepLayout>
  );
};

export default SiteInspectionWorkerViewStepsCompleted;

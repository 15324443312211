/**
 * @generated SignedSource<<7f686154869b027b76adea72597f1f8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SCSafetyPlan_ProjectQuery$variables = {
  subcontractorId: string;
};
export type SCSafetyPlan_ProjectQuery$data = {
  readonly project_subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly project: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
        };
      };
    }>;
  };
};
export type SCSafetyPlan_ProjectQuery = {
  response: SCSafetyPlan_ProjectQuery$data;
  variables: SCSafetyPlan_ProjectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subcontractorId"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "project": {
        "name": "asc"
      }
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "subcontractorId"
          }
        ],
        "kind": "ObjectValue",
        "name": "subcontractor_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCSafetyPlan_ProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": "pk",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id_pk",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCSafetyPlan_ProjectQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": "pk",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": "pk",
                        "args": null,
                        "filters": null,
                        "handle": "pk",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "id"
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "225f3788db3f770477f48ffdad2ff777",
    "id": null,
    "metadata": {},
    "name": "SCSafetyPlan_ProjectQuery",
    "operationKind": "query",
    "text": "query SCSafetyPlan_ProjectQuery(\n  $subcontractorId: uuid!\n) {\n  project_subcontractor_connection(where: {subcontractor_id: {_eq: $subcontractorId}}, order_by: {project: {name: asc}}) {\n    edges {\n      node {\n        project {\n          id\n          pk: id\n          name\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a7ab8301488ae80f721fd7fa25633bfe";

export default node;

import React, { useEffect } from "react";
import {
  ImageDataType,
  OptionType,
  useCreateMultipleInspectionResultsMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import {
  ChecklistItemAnswerType,
  OptionResponseType,
} from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";

export interface CompleteSiteInspectionProps {
  onFinish: () => void;
}

const optionResponseTypeToOptionType = (
  value: OptionResponseType,
): OptionType => {
  switch (value) {
    case "yes":
      return OptionType.Yes;
    case "no":
      return OptionType.No;
    case "na":
      return OptionType.Na;
    default:
      throw new Error(`Unknow OptionResponseType value ${value}`);
  }
};
const getChecklistItemsAnswersToInsert = (item: ChecklistItemAnswerType) => ({
  deficientText: item.text_response,
  deficientTypeId: item.deficient_type_id,
  checklistItemId: item.id,
  images: (item.images || []).map(
    ({ id, edited, ...img }): ImageDataType => img,
  ),
  answer: optionResponseTypeToOptionType(item.option_response),
});
const CompleteSiteInspection: React.FC<CompleteSiteInspectionProps> = (
  props,
) => {
  const [createMultipleInspectionResults, { loading }] =
    useCreateMultipleInspectionResultsMutation();
  const langStrings = useCurrentLangStrings();
  const workerContext = useSiteInspectionUser();
  const completeSiteInspection = async () => {
    try {
      const vals = workerContext.user;
      if (vals) {
        if (!vals.signatureImageUrl)
          throw new Error(langStrings.strings.signatureImageNotFoundMessage);
        if (!vals.inspection?.id)
          throw new Error(
            langStrings.strings.selectedInspectionNotFoundPleaseGoBackAndTryAgain,
          );
        await createMultipleInspectionResults({
          variables: {
            input: {
              qrCodeId: workerContext.qrCodeId,
              signatureUrl: vals.signatureImageUrl,
              inspectionId: vals.inspection?.id,
              projectLocationId: vals.projectLocationId,
              ...(vals.company?.id
                ? { companyId: vals.company?.id }
                : { companyName: vals.company?.name }),
              ...(vals.id ? { userId: vals.id } : { userName: vals.name }),
              modelAnswersAndImages: vals.inspection?.is_multi_inspection
                ? (vals.modelAnswersAndImages || []).map((p) => {
                    const answers =
                      (p.result === "fail"
                        ? p.checklistItemAnswers
                        : vals.checklistItemAnswers) || {};
                    return {
                      imagesData: p.images.map(
                        ({ id, edited, ...image }): ImageDataType => image,
                      ),
                      modelNumber: p.modelNumber,
                      checklistAnswers: Object.values(answers).map(
                        getChecklistItemsAnswersToInsert,
                      ),
                    };
                  })
                : [
                    {
                      imagesData: (vals.uploadedImages || []).map(
                        ({ id, ...image }): ImageDataType => image,
                      ),
                      modelNumber: vals.model,
                      checklistAnswers: Object.values(
                        vals.checklistItemAnswers ?? {},
                      ).map(getChecklistItemsAnswersToInsert),
                    },
                  ],
            },
          },
        });
      }
    } catch (err) {
      console.log(err);
      throw new Error(err instanceof Error ? err.message : JSON.stringify(err));
    } finally {
      props.onFinish();
    }
  };

  useEffect(() => {
    completeSiteInspection();
  }, []);

  return null;
};

export default CompleteSiteInspection;

import React, { useEffect, useRef } from "react";
import { Chart } from "@antv/g2";
import DataSet from "@antv/data-set";
import { ChartCfg, TextOption, TooltipCfg } from "@antv/g2/lib/interface";
import colorList from "../lists/chartColorList";
import randomHexColor from "src/common/functions/randomHexColor";

type DataSourceType = Array<{ name: string; color: string; value: number }>;

export interface PieChartProps {
  title?: React.ReactNode;
  dataSource: DataSourceType;
  children?: React.ReactNode;
  label?: false | string | [string, (...args: any[]) => any];
  tooltip?:
    | false
    | {
        config: TooltipCfg;
        displayAs: [string, (...args: any[]) => any];
      };
  color?: {
    field: string;
    colors: string[];
  };
  chartCfg?: Omit<ChartCfg, "container">;
  legend?: {
    show: boolean;
    options?: any;
  };
  annotations?: TextOption[];
  singleMetric?: boolean;
  interactions?: string[];
  othersCount?: number;
}

const PieChart = ({ title, dataSource, children, ...props }: PieChartProps) => {
  const {
    singleMetric = false,
    label = false,
    tooltip = false,
    legend = { show: false },
    interactions = [],
    annotations = [],
    chartCfg = {},
  } = props;

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = divRef.current;
    if (!container) return;
    const chart = new Chart({
      container,
      ...chartCfg,
    });

    const view = chart.createView({
      region: {
        start: { x: 0, y: 0 },
        end: { x: 1, y: 1 },
      },
    });

    // TODO change the legend to ABC, while the chart pie - to value sorted
    const dataSortedAbc = [...dataSource];
    const dataSortedByValue = [...dataSource];

    dataSortedAbc.sort((a, b) => (a.name > b.name ? 1 : -1));
    dataSortedByValue.sort((a, b) => b.value - a.value);
    if (props.othersCount) {
      const othersData = {
        name: "Others",
        value: props.othersCount,
        color: colorList[dataSortedByValue.length] || randomHexColor(),
      };
      dataSortedAbc.push(othersData);
      dataSortedByValue.push(othersData);
    }
    if (!props.color)
      props.color = {
        field: "name",
        colors: dataSortedByValue.map((s) => s.color),
      };
    let data: any[];
    if (singleMetric) {
      // Add dummy to represent second half of pie
      data = [
        ...dataSortedByValue,
        { name: "other", value: 100 - dataSource[0].value },
      ];
      props.color.colors = ["#95DE64", "#eceef100"];
    } else {
      const dataSet = new DataSet();
      const dataView = dataSet.createView();
      dataView.source(dataSortedByValue).transform({
        type: "percent",
        field: "value",
        dimension: "name",
        as: "percent",
      });
      data = dataView.rows;
    }

    // register data
    view.data(data);

    // define plotting as circular
    view.coordinate("theta", { radius: 0.75, innerRadius: 0.5 });

    // View configurations
    chart.legend({
      show: legend.show,
      position: "right",
      items: dataSortedAbc.map((item) => ({
        name: item.name,
        value: item.value,
        marker: {
          symbol: "circle",
          style: {
            fill: item.color,
            r: 5,
          },
        },
      })),
    });

    if (tooltip) chart.tooltip(tooltip.config);

    interactions.forEach((interaction) => chart.interaction(interaction));

    annotations.forEach((textOpts) => chart.annotation().text(textOpts));

    // Generate view params
    const geometry = view
      .interval()
      .adjust("stack")
      .position("value")
      .color(props.color.field, props.color.colors);

    // Configure Geometry object
    if (Array.isArray(label)) {
      geometry.label(label[0], label[1]);
    } else {
      geometry.label(label);
    }

    if (tooltip) {
      geometry.tooltip(tooltip.displayAs[0], tooltip.displayAs[1]);
    }

    // Render the chart to the dom
    chart.render();
    //    setTimeout(() => chart.forceFit(), 200);

    return () => chart.destroy();
  }, [dataSource]);

  return (
    <div className={`w-full h-full overflow-hidden`}>
      <div ref={divRef} style={{ width: "100%", display: "inline-block" }} />
    </div>
  );
};

export default PieChart;

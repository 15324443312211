/**
 * @generated SignedSource<<7dc278397b4fccfd0ac5f5dab7c21f2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectSettingsOld_deleteProcoreProjectData_Mutation$variables = {
  projectId: string;
};
export type GCProjectSettingsOld_deleteProcoreProjectData_Mutation$data = {
  readonly delete_procore_project_data_by_pk: {
    readonly id: string;
  } | null | undefined;
  readonly delete_procore_subcontractor: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type GCProjectSettingsOld_deleteProcoreProjectData_Mutation = {
  response: GCProjectSettingsOld_deleteProcoreProjectData_Mutation$data;
  variables: GCProjectSettingsOld_deleteProcoreProjectData_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "project_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  {
    "kind": "Variable",
    "name": "project_id",
    "variableName": "projectId"
  }
],
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "filters": null,
    "handle": "deleteRecord",
    "key": "",
    "kind": "ScalarHandle",
    "name": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectSettingsOld_deleteProcoreProjectData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "procore_subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "delete_procore_subcontractor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "procore_subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "procore_project_data",
        "kind": "LinkedField",
        "name": "delete_procore_project_data_by_pk",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectSettingsOld_deleteProcoreProjectData_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "procore_subcontractor_mutation_response",
        "kind": "LinkedField",
        "name": "delete_procore_subcontractor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "procore_subcontractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "procore_project_data",
        "kind": "LinkedField",
        "name": "delete_procore_project_data_by_pk",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "116b5191725a959300c8daf894a504da",
    "id": null,
    "metadata": {},
    "name": "GCProjectSettingsOld_deleteProcoreProjectData_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectSettingsOld_deleteProcoreProjectData_Mutation(\n  $projectId: uuid!\n) {\n  delete_procore_subcontractor(where: {project_id: {_eq: $projectId}}) {\n    returning {\n      id\n    }\n  }\n  delete_procore_project_data_by_pk(project_id: $projectId) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6b40355880697bceca7cb848e34f4c66";

export default node;

import { IconCalendar } from "@tabler/icons";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { forwardRef } from "react";
import { CustomTableCellWrapper } from "src/common/components/tables/basic/CustomTableCell";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import getDateStr from "src/common/functions/getDateStr";
import { InspectionResultSummaryTableQuery } from "src/common/types/generated/relay/InspectionResultSummaryTableQuery.graphql";
import { InspectionResultSummaryTable_inspection_instances$data } from "src/common/types/generated/relay/InspectionResultSummaryTable_inspection_instances.graphql";
import ShowInspectionResults from "../ShowInspectionResults";
import Icon from "src/common/components/general/Icon";
import ShowInspectionResultStatus from "../ShowInspectionResultStatus";
import { GCInspectionsQuery$data } from "src/common/types/generated/relay/GCInspectionsQuery.graphql";

type ColumnKeys =
  | "date"
  | "name"
  | "results"
  | "qr"
  | "location"
  | "model"
  | "created_by"
  | "company"
  | "deficient";
const CONNECTION_NAME = "inspection_instance_connection";
export type InspectionResultType =
  InspectionResultSummaryTable_inspection_instances$data["inspection_instance_connection"]["edges"][number]["node"]["inspection_results"][number];
type DConnection =
  InspectionResultSummaryTable_inspection_instances$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  InspectionResultSummaryTableQuery,
  string
> & {
  showDateRangeFilter?: boolean;
  showLocation?: boolean;
  inspections: Array<{ text: string; value: string }>;
  qrs: Array<{ text: string; value: string }>;
  projectId: string;
  subs: GCInspectionsQuery$data["project_subcontractor_connection"]["edges"];
  manuallyEnteredCompanies: Array<{ name: string; inspInstanceId: string }>;
};

const InspectionResultSummaryTable = forwardRef<DataScrollTableRef, Props>(
  (
    { title = "Inspections", onRowClick, showDateRangeFilter, ...props },
    ref,
  ) => {
    return (
      <DataScrollTable<
        DConnection,
        ColumnKeys,
        InspectionResultSummaryTableQuery
      >
        {...props}
        ref={ref}
        loadAll
        title={title}
        extraQueryVariables={{ includeLocation: !!props.showLocation }}
        expandable={{
          rowExpandable: (row) => true,
          expandedRowRender: (row) => {
            const companies: Array<string> = [];
            props.manuallyEnteredCompanies.forEach((p) => {
              if (p.inspInstanceId === row.pk) {
                companies.push(p.name);
              }
            });
            const subs: Array<{ id: string; name: string }> = [];
            props.subs.forEach((p) => {
              if (
                p.node.subcontractor.inspection_results.find(
                  (ir) => ir.inspection_instance_id === row.pk,
                )
              ) {
                subs.push({
                  name: p.node.subcontractor.name,
                  id: p.node.subcontractor_id,
                });
              }
            });
            return (
              <>
                <ShowInspectionResults
                  projectId={props.projectId}
                  selectedInspectionId={row.pk}
                  qrs={props.qrs}
                  subs={subs}
                  showLocation={props.showLocation}
                  manuallyEnteredCompanies={companies}
                />
              </>
            );
          },
        }}
        defaultCustomInitialSort={true}
        datePickerDataIndex={showDateRangeFilter ? ["created_at"] : undefined}
        countFromTotalCountNode
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allInspectionResultsConnection"}
        columns={[
          {
            title: "Completed",
            key: "results",
            dataIndex: ["inspection_results_aggregate", "aggregate", "count"],
            size: "sm",
            width: "80px",
          },
          {
            title: "Inspection Name",
            key: "name",
            sortable: true,
            size: "md",
            dataIndex: ["name", "en"],
            searchDataIndex: ["name", "en"],
            contentType: {
              type: "row-title",
            },
            render: (val) => <span className="font-accent">{val || ""}</span>,
          },
          {
            title: "Status",
            key: "deficient",
            size: "md",
            dataIndex: ["inspection_answers"],
            render: (_, row) => (
              <ShowInspectionResultStatus
                answers={row.inspection_results[0].inspection_answers}
              />
            ),
          },

          {
            title: "Model",
            key: "model",
            size: "md",
            dataIndex: ["model_number"],
            render: (v) => v ?? "",
          },

          {
            title: "Location Last Inspected",
            key: "location" as ColumnKeys,
            dataIndex: ["project_location", "name", "en"],
            queryIncludeVarKey: "includeLocation",
            render: (_, row) =>
              row.inspection_results.length > 0
                ? row.inspection_results[0].project_location?.name.en
                : "",
          },
          {
            title: "Date Last Inspected",
            key: "date",
            defaultSortOrder: "desc",
            sortable: true,
            clientCompareFn: (a, b) =>
              dayjs(b.inspection_results[0].created_at).valueOf() -
              dayjs(a.inspection_results[0].created_at).valueOf(),
            size: "lg",
            dataIndex: ["inspection_results", "created_at"],
            render: (_, row) => {
              return row.inspection_results.length > 0 ? (
                <CustomTableCellWrapper>
                  <div className="flex flex-row gap-0.5 items-center ">
                    <Icon {...{ icon: IconCalendar, color: "static" }} />
                    <span className="text-1">
                      {getDateStr(dayjs(row.inspection_results[0].created_at), {
                        format: "full",
                        withTime: true,
                      })}
                    </span>
                  </div>
                </CustomTableCellWrapper>
              ) : (
                ""
              );
            },
          },
          {
            title: "Company",
            key: "company",
            size: "md",
            // searchDataIndex: ["inspection_results", "subcontractor", "name"],
            dataIndex: ["inspection_results", "subcontractor", "name"],
            render: (_, row) => {
              return row.inspection_results[0]
                ? row.inspection_results[0].subcontractor?.name ||
                    row.inspection_results[0].company_name ||
                    ""
                : "";
            },
          },
          {
            title: "Last Inspector",
            key: "created_by",
            size: "md",
            dataIndex: ["inspection_results", "user", "name"],
            // searchDataIndex: ["inspection_results", "user", "name"],
            render: (val, row) => {
              const result = row.inspection_results[0];
              return result?.user ? result.user.name : result?.user_name || "";
            },
          },
          {
            title: "Last Scanned QR",
            key: "qr",
            size: "sm",
            dataIndex: ["inspection_results", "inspection_qr_code", "name"],
            render: (_, row) =>
              row.inspection_results[0].inspection_qr_code.name,
          },
        ]}
        queryNode={graphql`
          query InspectionResultSummaryTableQuery(
            $first: Int!
            $after: String
            $where: inspection_instance_bool_exp!
            $order_by: [inspection_instance_order_by!]!
            $includeLocation: Boolean!
          ) {
            ...InspectionResultSummaryTable_inspection_instances
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
                includeLocation: $includeLocation
              )
            ...InspectionResultSummaryTable_total @arguments(where: $where)
          }
        `}
        //HERE TOTAL NODE WILL BE RESUlT COUNT INSTEAD OF INSPECTION INSTANCE COUNT
        totalCountNode={graphql`
          fragment InspectionResultSummaryTable_total on query_root
          @argumentDefinitions(where: { type: "inspection_instance_bool_exp!" })
          @refetchable(
            queryName: "InspectionResultSummaryTableTotalRefetchableQuery"
          ) {
            allInspectionResultsConnection: inspection_result_connection(
              where: { inspection_instance: $where }
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment InspectionResultSummaryTable_inspection_instances on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "inspection_instance_bool_exp!" }
            order_by: { type: "[inspection_instance_order_by!]!" }
            includeLocation: { type: "Boolean!" }
          )
          @refetchable(
            queryName: "InspectionResultSummaryTableRefetchableQuery"
          ) {
            inspection_instance_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "InspectionResultSummaryTable_inspection_instance_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  id
                  pk: id @__clientField(handle: "pk")
                  name {
                    en
                  }
                  model_number
                  inspection_results_aggregate {
                    aggregate {
                      count
                    }
                  }
                  inspection_results(order_by: { created_at: desc }, limit: 1) {
                    created_at
                    user_name
                    user {
                      name
                    }
                    project_location @include(if: $includeLocation) {
                      name {
                        en
                      }
                    }
                    subcontractor {
                      name
                    }
                    company_name
                    inspection_qr_code {
                      name
                    }
                    inspection_answers {
                      ...InspectionAnswerFrag @relay(mask: false)
                    }
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default InspectionResultSummaryTable;

import { RcFile } from "antd/es/upload";
import uploadFile from "./uploadFile";
import filterFailedTasks from "./filterFailedTasks";

// temporary fix to make it compatible
// remove this method
//all<T extends readonly unknown[] | []>(values: T): Promise<{ -readonly [P in keyof T]: Awaited<T[P]>; }>;

const uploadFiles = (files: Array<File>) =>
  filterFailedTasks(files.map((file) => uploadFile(file)));

export default uploadFiles;
export const uploadFilesWithPath = (
  files: Array<{
    file: File;
    directoryName: string;
  }>,
) => filterFailedTasks(files.map((o) => uploadFile(o.file, o.directoryName)));

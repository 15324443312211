import { Button, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate, useParams } from "react-router-dom";
import insertCertificatesToVerifyMutate from "src/common/api/relay/mutations/InsertCertificatesToVerify";
import logo from "src/common/assets/logo.png";
import WebcamPhoto from "src/common/components/WebcamPhoto";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import uploadFiles from "src/common/functions/upload-utility/uploadFiles";
import uploadImage from "src/common/functions/upload-utility/uploadImage";
import { InsertCertificatesToVerifyMutation } from "src/common/types/generated/relay/InsertCertificatesToVerifyMutation.graphql";
import { UploadSingleCertQuery } from "src/common/types/generated/relay/UploadSingleCertQuery.graphql";
import * as uuid from "uuid";

type FormValues = {
  certFrontImage: Array<{ originFileObj: File }>;
  certBackImage: Array<{ originFileObj: File }>;
};
const query = graphql`
  query UploadSingleCertQuery($docId: uuid!) {
    certificates_to_verify_connection(
      where: { doc_id: { _eq: $docId } }
      order_by: { created_at: desc }
      first: 1
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          status
          document
          doc_id
          worker_id
          project_id
          project {
            name
          }
          worker {
            user {
              name
            }
          }
        }
      }
    }
  }
`;

interface DocProps {
  docId: string;
}

const UploadSingleCert: FC<DocProps> = ({ docId }) => {
  const data = useLazyLoadQuery<UploadSingleCertQuery>(query, { docId })
    .certificates_to_verify_connection?.edges?.[0]?.node;

  const [insertCertToVerify, isInserting] =
    useAsyncMutation<InsertCertificatesToVerifyMutation>(
      insertCertificatesToVerifyMutate,
    );
  const navigate = useNavigate();
  const modal = useRef<FModalRef<FormValues>>(null);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const reset = () => {
    modal.current?.form.resetFields();
    modal.current?.close();
  };
  if (data.status === "submitted")
    return (
      <div className="mt-4 text -center">
        No rejected Cert present or images already submitted for this
        certificate/Drugtest
        <br />{" "}
        <Button onClick={() => navigate("/")}>Go Back to Login Page</Button>
      </div>
    );
  const projectId = data.project_id;
  const workerId = data.worker_id;
  const type = data.document;
  const workerName = data.worker.user.name;
  const insertCerts = async (
    uploadFront: { url: string },
    uploadBack: { url: string } | undefined,
  ) => {
    const newCertId = uuid.v4();
    await insertCertToVerify({
      variables: {
        objects: [
          {
            id: newCertId,
            project_id: projectId,
            document: type,
            worker_id: workerId,
            doc_id: docId,
            uploaded_by_uid: auth.currentUser?.uid,
            front_image: {
              data: {
                ...uploadFront,
                description: "Front Image",
                created_by_user_id: workerId,
              },
            },
            back_image: !uploadBack
              ? null
              : {
                  data: {
                    ...uploadBack,
                    description: "Back Image",
                    created_by_user_id: workerId,
                  },
                },
          },
        ],
      },
    })
      .then(() => {
        console.log("uploaded Certificate");
      })
      .catch((e) => {
        console.log(e);
        message.error(e);
      });
  };
  const onSubmit = async (
    fileList: Array<{ front: string; back: string | undefined }>,
  ) => {
    setLoading(true);
    fileList.forEach(
      async (cur: { front: string; back: string | undefined }) => {
        const uploadFront = await uploadImage(cur.front);
        const uploadBack = cur.back ? await uploadImage(cur.back) : undefined;
        if (uploadFront) await insertCerts(uploadFront, uploadBack);
      },
    );
    setLoading(false);
    message.success("Certificate uploaded successfully");
    setDone(true);
  };
  return done ? (
    <div className="mt-5 text-center">
      Thank you! We will review and let you know if we have any questions. Have
      a great day! Contact us for help{" "}
      <a href="mailto: support@siteform.io">support@siteform.io</a>
    </div>
  ) : (
    <>
      <FModal
        ref={modal}
        confirmLoading={loading || isInserting}
        title={`Certificate Images for ${workerName}`}
        onCancel={() => reset()}
        okText={"Add"}
        onOk={async (v) => {
          const form = modal.current?.form;
          if (!form) return;
          setLoading(true);
          try {
            const value = await form.validateFields();
            if (!value) {
              setLoading(false);
              throw new Error("Form values not found");
            }
            console.log(value);
            const frontFile = await uploadFiles(
              value.certFrontImage.map((o) => o.originFileObj),
            );
            const backFile = value.certBackImage
              ? await uploadFiles(
                  value.certBackImage.map((o) => o.originFileObj),
                )
              : [];

            const frontFileUrl = frontFile[0]?.url;
            const backFileUrl = backFile[0]?.url;
            if (frontFileUrl) {
              await insertCerts(
                { url: frontFileUrl },
                backFileUrl ? { url: backFileUrl } : undefined,
              );
            }
          } finally {
            setLoading(false);
          }
          message.success("Certificate uploaded successfully");
          reset();
          setDone(true);
        }}
      >
        <FModal.SingleImgDragUpload
          name="certFrontImage"
          label="Front Image"
          required={true}
          requiredMessage="Front Image is Required"
        />
        <FModal.SingleImgDragUpload name="certBackImage" label="Back Image" />
      </FModal>
      <img
        src={logo}
        style={{
          paddingTop: "50px",
          paddingLeft: "25px",
          display: "flex",
          width: "250px",
        }}
      />
      <div className="text-center text-1.5">
        Upload Certificate Images for {workerName}
      </div>
      <WebcamPhoto
        onSubmit={onSubmit}
        lang={"en"}
        insertingImage={isInserting || loading}
        single
        imageTitle="Certificate Photo"
      />

      <div style={{ textAlign: "center" }}>
        <div>OR</div>
        <Button
          loading={loading}
          onClick={() => {
            modal.current?.open();
          }}
        >
          Upload Images From your device
        </Button>
      </div>
    </>
  );
};
const UploadSingleCertWrapper: FC = () => {
  const navigate = useNavigate();
  const { docId } = useParams();
  return docId ? (
    <UploadSingleCert docId={docId} />
  ) : (
    <div className="mt-4 text -center">
      No CertificateId present
      <div className="text-center">
        <br />
        <Button onClick={() => navigate("/")}>Go Back to Login Page</Button>
      </div>
    </div>
  );
};
export default UploadSingleCertWrapper;

import { Typography, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import insertCertificatesToVerifyMutate from "src/common/api/relay/mutations/InsertCertificatesToVerify";
import AddWorkerCertModal, {
  AddWorkerCertModalRef,
} from "src/common/components/dialogs/AddWorkerCertModal";
import Button from "src/common/components/general/Button";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { InsertCertificatesToVerifyMutation } from "src/common/types/generated/relay/InsertCertificatesToVerifyMutation.graphql";
import {
  NewCertificationType,
  SiteOrientationStepProps,
} from "../utils/siteOrientationTypes";
import SiteOrientationDocumentCameraNewDocument from "./documentCamera/SiteOrientationDocumentCameraNewDocument";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import uploadImage from "src/common/functions/upload-utility/uploadImage";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import useLangStrings from "src/utility-features/i18n/context/languageHooks";
import { Language } from "src/utility-features/i18n/language-utils/i18nTypes";

export interface SiteOrientationCertificateUploadUIProps
  extends SiteOrientationStepProps {
  userId: string;
  userName?: string;
  lang?: Language;
  nextButtonText?: string;
  projectId?: string;
  managerDevice?: boolean;
  onCertificationsUpload: (newCertifications: NewCertificationType[]) => void;
  requireCerts?: boolean;
  pastCertifications?: Array<{
    certification_id: string;
    certification_name: string;
  }>;
  projectCertifications?: Array<{
    certification_id: string;
    certification_name: string;
    alternate_certification_ids: string[];
  }>;
}
const SiteOrientationCertificateUploadUI: React.FC<
  SiteOrientationCertificateUploadUIProps
> = ({
  userId,
  userName,
  lang,
  projectId,
  onBack,
  onCertificationsUpload,
  requireCerts,
  nextButtonText,
  pastCertifications,
  projectCertifications,
  onNext,
}) => {
  const langStrings = useLangStrings(lang);
  const [insertCertsToVerify] =
    useAsyncMutation<InsertCertificatesToVerifyMutation>(
      insertCertificatesToVerifyMutate,
    );
  const [uploadingCertificates, setUploadingCertificates] = useState(false);

  const [newCertifications, setNewCertifications] = useState<
    NewCertificationType[]
  >([]);
  const insertImages = async (
    uploadFront: { url: string },
    uploadBack: { url: string } | undefined | null,
  ) => {
    await insertCertsToVerify({
      variables: {
        objects: [
          {
            project_id: projectId || null,
            worker_id: userId,
            document: "certificate",
            uploaded_by_uid: userId,
            front_image: {
              data: {
                ...uploadFront,
                description: "Front Image",
                created_by_user_id: userId,
              },
            },
            back_image: !uploadBack
              ? null
              : {
                  data: {
                    ...uploadBack,
                    description: "Back Image",
                    created_by_user_id: userId,
                  },
                },
          },
        ],
      },
    })
      .then(() => {
        console.log("uploaded Certificate");
      })
      .catch((e) => {
        console.error(e);
        message.error(e);
      });
  };

  const onEnter = async () => {
    onNext();
    newCertifications.forEach(async (cert, i) => {
      if (cert.frontImage) {
        setUploadingCertificates(true);

        try {
          const uploadFront = await uploadImage(cert.frontImage);
          const uploadBack = cert.backImage
            ? await uploadImage(cert.backImage)
            : undefined;
          if (uploadFront && (!cert.backImage || uploadBack)) {
            await insertImages(uploadFront, uploadBack);
            // doing it one by one so even if there's an error with one of the images upload it doesn't stop others in current loop
          }
          setUploadingCertificates(false);
          message.success("Certification uploaded for verification.");
        } catch (e) {
          setUploadingCertificates(false);

          console.log(e);
          message.error(e instanceof Error ? e.message : JSON.stringify(e));
        }
      }
    });
    onCertificationsUpload(newCertifications);
  };
  const addWorkerCertModalRef = useRef<AddWorkerCertModalRef>(null);
  const [totalUploads, setTotalUploads] = useState<number>(0);

  useEffect(() => {
    let countEmpty = 0;
    newCertifications.forEach((newCert) => {
      if (!newCert.frontImage && !newCert.backImage) {
        countEmpty++;
      }
    });
    if (countEmpty !== 1) {
      setNewCertifications((prevState) => {
        const newState = prevState.filter(
          (newCert) => newCert.backImage || newCert.frontImage,
        );
        newState.push({
          backImage: null,
          frontImage: null,
        });
        return newState;
      });
    }
  }, [newCertifications]);
  return (
    <SiteFeatureStepLayout
      nextButtonText={nextButtonText}
      onNextButtonClick={() => onEnter()}
      onBackButtonClick={onBack}
      loading={uploadingCertificates}
    >
      {userName && <div>{`Hi ${userName},`}</div>}
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.addYourCertsAndTrainings}
      >
        {requireCerts && !!projectCertifications?.length && (
          <div>
            <p className="font-accent">{`*${langStrings.strings.requiredCertifications}:`}</p>
            {projectCertifications.map((cert) => (
              <div>
                {cert.certification_name}
                {cert.alternate_certification_ids &&
                cert.alternate_certification_ids.length > 0
                  ? " (or better)"
                  : ""}
                {pastCertifications &&
                  pastCertifications.some(
                    (pastCertificate) =>
                      pastCertificate.certification_id ==
                        cert.certification_id ||
                      cert.alternate_certification_ids.some(
                        (alternateCertId) =>
                          alternateCertId == pastCertificate.certification_id,
                      ),
                  ) && <> &#9989;</>}
              </div>
            ))}
          </div>
        )}
        <div className={`flex flex-col gap-1`}>
          {pastCertifications && pastCertifications.length > 0 && (
            <div className={`flex flex-col gap-0.5`}>
              <p className="font-accent">
                {langStrings.strings.pastCertificationsAndTrainings}
              </p>
              <Typography>
                <ol className={`list-decimal`}>
                  {pastCertifications.map((pastCertification) => (
                    <li
                      className={`font-accent`}
                      id={pastCertification.certification_id}
                      key={pastCertification.certification_id}
                    >
                      {`${pastCertification.certification_name}`}
                    </li>
                  ))}
                </ol>
              </Typography>
            </div>
          )}
          <div className={`flex flex-col gap-0.5 mt-1`}>
            {!!newCertifications.filter((p) => p.frontImage).length && (
              <p>{langStrings.strings.recentlyUploadedCerts}:</p>
            )}
            <div className={`grid grid-cols-2 gap-1`}>
              {newCertifications.map((newCert, i) => (
                <SiteOrientationDocumentCameraNewDocument
                  key={i}
                  frontImageSrc={newCert.frontImage}
                  backImageSrc={newCert.backImage}
                  onFrontImageChange={(image) => {
                    setNewCertifications((prevState) => {
                      const newState = [...prevState];
                      newState[i].frontImage = image;
                      return newState;
                    });
                  }}
                  onBackImageChange={(image) => {
                    setNewCertifications((prevState) => {
                      const newState = [...prevState];
                      newState[i].backImage = image;
                      return newState;
                    });
                  }}
                />
              ))}
            </div>
            <>
              <AddWorkerCertModal
                {...{
                  workerId: userId ?? "",
                  byWorker: true,
                  lang: lang,
                  projectId: projectId ?? "",
                  ref: addWorkerCertModalRef,
                  onInserted: () => {
                    setTotalUploads((i) => i + 1);
                    message.success(
                      langStrings.strings
                        .certificatesUploadedForVerificationYouCanMoveToNextPag,
                    );
                  },
                }}
              />
              <div className="text-center mt-2"> {"OR"}</div>
              <div className="h-0.5"></div>
              <Button
                secondary
                onClick={() => addWorkerCertModalRef.current?.open()}
                label={langStrings.strings.uploadFromYourDevice}
              />
              <div className="h-0.5"></div>
              {totalUploads > 0 ? (
                <div>
                  {`${totalUploads} Certification${totalUploads > 1 ? "s" : ""}
          Uploaded`}
                  &nbsp; ✅
                </div>
              ) : (
                ""
              )}
            </>{" "}
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};
export default SiteOrientationCertificateUploadUI;

import { Skeleton } from "antd";
import { TableProps } from "antd/es/table";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useMemo,
} from "react";
import { CustomFilterType } from "src/common/components/filter/CustomFilterComponents/CustomFilterController";
import { Diff } from "utility-types";
import withCustomSuspense from "../../general/withCustomSuspense";
import ScrollTable, {
  DEFAULT_TABLE_HEADER_HEIGHT,
  FilterData,
  FilterSorterData,
  ScrollTableColumn,
  ScrollTableProps,
  SortOrder,
} from "./ScrollTable";

import { DocumentNode } from "graphql";
import { isDevelopmentBuild } from "../../../constants/environment";
import buildGraphQLSearchFilter from "./utils/graphql-filter-functions/buildGraphQLSearchFilter";
import buildGraphQLSorter from "./utils/graphql-filter-functions/buildGraphQLSorter";
import buildGraphQLFilter from "./utils/graphql-filter-functions/buildGraphqlFilter";
import arrayFilterSearchData from "./utils/client-filter-functions/arrayFilterSearchData";
import arrayFilterData from "./utils/client-filter-functions/arrayFilterData";
import arraySortData from "./utils/client-filter-functions/arraySortData";
import { DataRecord } from "./utils/dataScrollTableTypes";
import { useQuery } from "@apollo/client";
import { JSONObject } from "src/common/types/manual/JsonType";
import LargeTableSkeleton from "./LargeTableSkeletion";

export type DataApolloScrollTableRef = {
  refetch: (silent?: boolean) => void;
};

interface BaseDataApolloScrollTableProps<
  VariablesType,
  ColumnKeys,
  DataRecordType,
  Query extends { [key: string]: unknown },
> {
  ref: any;
  title?: string;
  queryNode: DocumentNode;
  countFromTotalCountNode?: boolean; // useful to show total Count node in a summary table, e.g. InspectionResultSummaryTable
  queryDataIndex: Exclude<keyof Query, "__typename">;
  aggregateCountIndex: Exclude<keyof Query, "__typename">;
  customFilters?: CustomFilterType[];
  headerComponent?: React.ReactElement;
  //  setCurrentTypePermit?: any;
  //currentTypePermit?: string;
  where: VariablesType extends { where: infer WhereType } ? WhereType : unknown;
  extraQueryVariables?: Partial<
    Omit<
      Omit<Omit<Omit<VariablesType, "where">, "order_by">, "offset">,
      "limit"
    >
  >;
  excludedKeys?: Array<ColumnKeys>;
  loadAll?: boolean;
  defaultTableSort?: {
    key: ColumnKeys;
    order: SortOrder;
  };
  headerContol?: () => React.ReactNode;
  onRowClick?: (item: DataRecordType) => void;
  columns: Array<
    ScrollTableColumn<DataRecordType, ColumnKeys> & {
      queryIncludeVarKey?: keyof VariablesType;
    }
  >;
  disableSuspense?: boolean;
  filterNotVisibleByDefault?: boolean;
}

export type DataApolloScrollTableProps<
  VariablesType,
  ColumnKeys,
  DataRecordType,
  Query extends { [key: string]: unknown },
> = BaseDataApolloScrollTableProps<
  VariablesType,
  ColumnKeys,
  DataRecordType,
  Query
> &
  Diff<
    TableProps<DataRecordType>,
    BaseDataApolloScrollTableProps<
      VariablesType,
      ColumnKeys,
      DataRecordType,
      Query
    >
  > &
  Diff<
    Omit<ScrollTableProps<any>, "dataSource" | "onChange" | "totalCount">,
    BaseDataApolloScrollTableProps<
      VariablesType,
      ColumnKeys,
      DataRecordType,
      Query
    >
  > & {
    showRowBorderOnHover?: boolean;
    extraSearchDataIndexes?: Array<
      Array<{
        index: string;
        extraConstraint?: JSONObject; // remember extraConstraint is only for array relation indexes
      }>
    >;
    newCustomTableLook?: boolean; // TODO - ask Seva to remove this. This option is to control whether to display the new table design or now
  };
export interface DataApolloScrollTableImplementorProps<
  VariablesType,
  ColumnKeys,
  DataRecordType,
  Query extends { [key: string]: unknown },
> extends Pick<
    DataApolloScrollTableProps<
      VariablesType,
      ColumnKeys,
      DataRecordType,
      Query
    >,
    | "excludedKeys"
    | "loadAll"
    | "customFilters"
    | "headerComponent"
    | "datePickerDataTitle"
    | "datePickerDataIndex"
    | "where"
    | "defaultTableSort"
    | "title"
    | "searchDataIndex"
    | "headerContol"
    | "headerComponent"
    | "disableSuspense"
    | "topBarButtons"
  > {
  onRowClick?: (args: DataRecordType) => void;
}

const DataApolloScrollTable = <
  VariablesType,
  ColumnKeys extends string,
  DataRecordType,
  Query extends { [key: string]: unknown },
>(
  {
    where,
    columns,
    title,
    extraSearchDataIndexes,
    queryNode,
    queryDataIndex,
    extraQueryVariables,
    aggregateCountIndex,
    onRowClick,
    defaultTableSort,
    loadAll = false,
    disableSuspense = false,
    ...props
  }: DataApolloScrollTableProps<
    VariablesType,
    ColumnKeys,
    DataRecordType,
    Query
  >,
  ref: any,
) => {
  const defaultSortCol = columns.find((c) => !!c.defaultSortOrder)!;
  const [filterSortersData, setFilterSorterData] = useState<FilterSorterData>({
    filterData: [],
    sorterData: defaultSortCol
      ? {
          key: defaultSortCol.key,
          dataIndex: defaultSortCol.dataIndex,
          order: defaultSortCol.defaultSortOrder || null,
        }
      : undefined,
  });

  const hasSortableCols = columns.find((c) => !!c.sortable);
  const hasDefaultSortCol = columns.find((c) => !!c.defaultSortOrder);
  if (!hasSortableCols) {
    throw new Error("Table must have at least 1 sortable column");
  }

  if (!hasSortableCols && hasDefaultSortCol) {
    throw new Error("Default sort column must have sortable column");
  }
  const filters = [
    ...(filterSortersData?.filterData || []),
    ...columns
      .filter((c) => c.searchValue)
      .map((c) => ({
        key: "",
        dataIndex: c.dataIndex!,
        filter: {
          type: "search",
          text: c.searchValue!,
        },
      })),
  ] as FilterData;

  const [clientFiltering, setClientFiltering] = useState(loadAll);
  // if loadAll is true - fetch all without filter and perform cliend side filtering
  const whereStatement = clientFiltering
    ? where
    : {
        _and: [
          where,
          buildGraphQLFilter(filters),
          buildGraphQLSearchFilter(
            filterSortersData.searchData,
            extraSearchDataIndexes,
          ),
        ],
      };
  // and client side sorting
  const orderBy = buildGraphQLSorter(
    // clientFiltering ||
    !filterSortersData?.sorterData
      ? { key: "", dataIndex: hasSortableCols.dataIndex, order: "asc" }
      : filterSortersData?.sorterData,
  );
  const variables = useMemo(
    () => ({
      where: whereStatement,
      limit: clientFiltering
        ? ScrollTable.LOAD_ALL_COUNT
        : ScrollTable.INITIAL_COUNT,
      order_by: orderBy,
      offset: 0,
      ...columns.reduce((a, c) => {
        return !!c.queryIncludeVarKey
          ? {
              ...a,
              [c.queryIncludeVarKey]:
                a[c.queryIncludeVarKey] || !props.excludedKeys?.includes(c.key),
            }
          : a;
      }, {} as any),
      ...(extraQueryVariables ? extraQueryVariables : {}),
    }),
    [whereStatement, orderBy, clientFiltering],
  );

  // if we want change disableSuspense, we must remount component.
  //   otherwise we will have conditional hook problem. (react-hooks/rules-of-hooks)
  // so we copy disableSuspense to state on first render, and use it from state
  const [noSuspense] = useState(disableSuspense || false);
  if (isDevelopmentBuild && noSuspense !== disableSuspense) {
    throw new Error(
      "disableSuspense must remain unchanged during component lifecycle. Remount compoenent using key if you need to change it",
    );
  }
  const [loadingMore, setLoadingMore] = useState(false);
  const {
    data,
    refetch,
    loading: dataLoading,
    fetchMore,
    error,
  } = useQuery(queryNode, {
    variables,
    fetchPolicy: "cache-and-network",
  });

  useImperativeHandle<DataApolloScrollTableRef, DataApolloScrollTableRef>(
    ref,
    () => ({ refetch: () => refetch() }),
  );
  let items = (data?.[queryDataIndex] as unknown as Array<DataRecord>) || [];
  const totalCount = data?.[aggregateCountIndex]?.aggregate?.count || 0;
  // replace this with better code (automatically switch to client mode on small datasets)
  ///   -----------
  if (error) {
    throw error;
  }

  if (clientFiltering) {
    // perform client side filtering
    if (filters.length > 0) {
      items = arrayFilterData(items, filters);
    }
    // search filter
    if (filterSortersData?.searchData) {
      items = arrayFilterSearchData(items, filterSortersData?.searchData);
    }
    // and sorting
    if (filterSortersData?.sorterData) {
      items = arraySortData(items, filterSortersData?.sorterData);
    }
  }

  const itemsCount = props.countFromTotalCountNode
    ? totalCount ?? items.length
    : clientFiltering
    ? items.length
    : totalCount ?? items.length;

  return (
    <ScrollTable
      {...props}
      dataSource={items}
      headerContol={props.headerContol}
      headerComponent={props.headerComponent}
      totalCount={itemsCount}
      columns={columns}
      title={title || ""}
      loading={dataLoading}
      filterSorter={filterSortersData}
      onFilterSorterChange={setFilterSorterData}
      interactive={!!onRowClick || props.showRowBorderOnHover}
      onRow={(item: any) => ({
        onClick: (e) => {
          e.stopPropagation();
          if (onRowClick) onRowClick(item);
        },
      })}
      pagination={{
        hasNext: items.length < totalCount,
        isLoadingNext: loadingMore,
        onLoadMoreClick: async () => {
          setLoadingMore(true);
          try {
            await fetchMore({
              variables: {
                ...variables,
                offset: items.length,
              },
              query: queryNode,
              updateQuery: (prevResult, { fetchMoreResult }) => {
                // Merge new data with existing data
                return {
                  ...prevResult,
                  [queryDataIndex]: [
                    ...prevResult[queryDataIndex],
                    ...fetchMoreResult[queryDataIndex],
                  ],
                };
              },
            });
          } finally {
            setLoadingMore(false);
          }
        },
      }}
    />
  );
};
//@ts-ignore
export default withCustomSuspense(forwardRef(DataApolloScrollTable), {
  fallback: <LargeTableSkeleton />,
}) as typeof DataApolloScrollTable;

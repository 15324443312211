/**
 * @generated SignedSource<<850d6550c272caf76524457c27901cf2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectWorkerTable_SaveHHMutation$variables = {
  hardHat: string;
  projectId: string;
  projectUserId: string;
  userId: string;
};
export type ProjectWorkerTable_SaveHHMutation$data = {
  readonly update_project_employee_by_pk: {
    readonly hard_hat_number: string | null | undefined;
    readonly pk: string;
  } | null | undefined;
  readonly update_project_worker: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_project_worker_by_pk: {
    readonly hard_hat_number: string | null | undefined;
    readonly pk: string;
  } | null | undefined;
};
export type ProjectWorkerTable_SaveHHMutation = {
  response: ProjectWorkerTable_SaveHHMutation$data;
  variables: ProjectWorkerTable_SaveHHMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hardHat"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectUserId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v4 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "hard_hat_number",
      "variableName": "hardHat"
    }
  ],
  "kind": "ObjectValue",
  "name": "_set"
},
v5 = [
  (v4/*: any*/),
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "projectUserId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hard_hat_number",
  "storageKey": null
},
v7 = [
  {
    "alias": "pk",
    "args": null,
    "kind": "ScalarField",
    "name": "__id_pk",
    "storageKey": null
  },
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": [
    (v4/*: any*/),
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_neq",
              "variableName": "projectUserId"
            }
          ],
          "kind": "ObjectValue",
          "name": "id"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_eq",
              "variableName": "projectId"
            }
          ],
          "kind": "ObjectValue",
          "name": "project_id"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_eq",
              "variableName": "userId"
            }
          ],
          "kind": "ObjectValue",
          "name": "worker_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "project_worker_mutation_response",
  "kind": "LinkedField",
  "name": "update_project_worker",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affected_rows",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": "pk",
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": "pk",
    "args": null,
    "filters": null,
    "handle": "pk",
    "key": "",
    "kind": "ScalarHandle",
    "name": "id"
  },
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectWorkerTable_SaveHHMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "project_worker",
        "kind": "LinkedField",
        "name": "update_project_worker_by_pk",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "project_employee",
        "kind": "LinkedField",
        "name": "update_project_employee_by_pk",
        "plural": false,
        "selections": (v7/*: any*/),
        "storageKey": null
      },
      (v8/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectWorkerTable_SaveHHMutation",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "project_worker",
        "kind": "LinkedField",
        "name": "update_project_worker_by_pk",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "project_employee",
        "kind": "LinkedField",
        "name": "update_project_employee_by_pk",
        "plural": false,
        "selections": (v9/*: any*/),
        "storageKey": null
      },
      (v8/*: any*/)
    ]
  },
  "params": {
    "cacheID": "63f78aa5dd67fb5ad4f068f189cf3a6e",
    "id": null,
    "metadata": {},
    "name": "ProjectWorkerTable_SaveHHMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectWorkerTable_SaveHHMutation(\n  $projectUserId: uuid!\n  $projectId: uuid!\n  $userId: uuid!\n  $hardHat: String!\n) {\n  update_project_worker_by_pk(pk_columns: {id: $projectUserId}, _set: {hard_hat_number: $hardHat}) {\n    pk: id\n    hard_hat_number\n    id\n  }\n  update_project_employee_by_pk(pk_columns: {id: $projectUserId}, _set: {hard_hat_number: $hardHat}) {\n    pk: id\n    hard_hat_number\n    id\n  }\n  update_project_worker(where: {project_id: {_eq: $projectId}, worker_id: {_eq: $userId}, id: {_neq: $projectUserId}}, _set: {hard_hat_number: $hardHat}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "d7f0325ec6a89e95416e7ca51b463c32";

export default node;

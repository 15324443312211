import { Outlet, useNavigate } from "react-router-dom";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import CustomSuspense from "../../../../common/components/general/CustomSuspense";

const GCObservationNavigation: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const handleReportsTabClick = (tabId: string) => {
    navigate(tabId);
  };
  const tabs: ReportsTabType[] = [
    {
      id: "charts",
      label: "Overview",
    },
    {
      id: "observations",
      label: "Observations",
    },
    {
      id: "audits",
      label: "Audits",
    },
  ];
  return (
    <BasicWrapper>
      <ReportsTabsGroup
        onTabClick={handleReportsTabClick}
        tabs={tabs}
        tabsType="link"
      />
      <div className="flex-1 w-full pt-1 overflow-scroll ">
        <div className={`min-h-min w-full h-full`}>
          <CustomSuspense>
            <Outlet />
          </CustomSuspense>
        </div>
      </div>
    </BasicWrapper>
  );
};
export default GCObservationNavigation;

/**
 * @generated SignedSource<<4a67a21d7bdb2432becd35525acdbc2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AGCGeneralContractors_InsertGC_Mutation$variables = {
  gcName: string;
};
export type AGCGeneralContractors_InsertGC_Mutation$data = {
  readonly insert_general_contractor_one: {
    readonly id: string;
    readonly name: string;
    readonly pk: string;
    readonly projects: ReadonlyArray<{
      readonly completed: boolean;
      readonly id: string;
    }>;
  } | null | undefined;
};
export type AGCGeneralContractors_InsertGC_Mutation = {
  response: AGCGeneralContractors_InsertGC_Mutation$data;
  variables: AGCGeneralContractors_InsertGC_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gcName"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "agc_universal_orientation",
        "value": true
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "gcName"
      }
    ],
    "kind": "ObjectValue",
    "name": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "agc_universal_orientation": {
          "_eq": true
        }
      }
    }
  ],
  "concreteType": "project",
  "kind": "LinkedField",
  "name": "projects",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed",
      "storageKey": null
    }
  ],
  "storageKey": "projects(where:{\"agc_universal_orientation\":{\"_eq\":true}})"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AGCGeneralContractors_InsertGC_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "general_contractor",
        "kind": "LinkedField",
        "name": "insert_general_contractor_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AGCGeneralContractors_InsertGC_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "general_contractor",
        "kind": "LinkedField",
        "name": "insert_general_contractor_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "688fc512ba656bf9e139d939f9428716",
    "id": null,
    "metadata": {},
    "name": "AGCGeneralContractors_InsertGC_Mutation",
    "operationKind": "mutation",
    "text": "mutation AGCGeneralContractors_InsertGC_Mutation(\n  $gcName: String!\n) {\n  insert_general_contractor_one(object: {name: $gcName, agc_universal_orientation: true}) {\n    id\n    pk: id\n    name\n    projects(where: {agc_universal_orientation: {_eq: true}}) {\n      id\n      completed\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18dfd79b99475ca3953cc0a1c71f29f5";

export default node;

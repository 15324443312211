import { Line } from "@ant-design/charts";
import React from "react";
import remToPx from "../../functions/remToPx";

type DataSource = {
  xField: string;
  value: number;
}[];

export interface LineChartProps {
  dataSource: DataSource;
  valueTitle?: string;
}

const defaultValueTitle = `Value`;

const LineChart: React.FC<LineChartProps> = (props) => {
  const valueTitle = props.valueTitle || defaultValueTitle;

  return (
    <Line
      data={props.dataSource}
      xField={"xField"}
      yField={"value"}
      point={{ size: remToPx(0.5), shape: "circle" }}
      tooltip={{
        field: "value",
        showTitle: false,
        name: valueTitle,
      }}
    />
  );
};

export default LineChart;

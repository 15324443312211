import EnvironmentalIncident from "../components/incident-types/EnvironmentalIncident";
import EquipmentIncident from "../components/incident-types/EquipmentIncident";
import NearMissIncident from "../components/incident-types/NearMissIncident";
import IncidentDetail from "../components/IncidentDetail";
import PropertyDamageIncident from "../components/incident-types/PropertyDamageIncident";
import VehicleIncident from "../components/incident-types/VehicleIncident";
import UtilityIncident from "../components/incident-types/UtilityIncident";
import TheftIncident from "../components/incident-types/TheftIncident";
import IncidentManagementInjury from "../components/IncidentManagementInjury";
import IncidentNotesTable from "../components/table/IncidentNotesTable";
import IncidentProjectInfo from "../components/IncidentProjectInfo";
import RootCauseAnalysis from "../components/RootCauseAnalysis";
import WitnessDetail from "../components/incident-users/WitnessDetail";
import IncidentLogsTable from "src/domain-features/incident-management/components/table/IncidentLogsTable";
import IncidentTypeProps from "./IncidentTypeProps";

const switchToIncidentMenuItems = (
  typeProps: IncidentTypeProps,
  subview: string | null,
) => {
  switch (subview) {
    case "project_info":
      return <IncidentProjectInfo {...typeProps} />;
    case "incident_detail":
      return <IncidentDetail {...typeProps} />;
    case "environmental":
      return <EnvironmentalIncident {...typeProps} />;
    case "equipment":
      return <EquipmentIncident {...typeProps} />;
    case "near_miss":
      return <NearMissIncident {...typeProps} />;
    case "property_damage":
      return <PropertyDamageIncident {...typeProps} />;
    case "vehicle":
      return <VehicleIncident {...typeProps} />;
    case "utility":
      return <UtilityIncident {...typeProps} />;
    case "theft":
      return <TheftIncident {...typeProps} />;
    case "injured_user":
      return <IncidentManagementInjury {...typeProps} />;
    case "witness":
      return <WitnessDetail {...typeProps} />;
    case "root_cause":
      return <RootCauseAnalysis {...typeProps} />;
    case "notes":
      return (
        <IncidentNotesTable
          incidentId={typeProps.incident.id}
          projectId={typeProps.incident.project_id}
        />
      );
    case "logs":
      return <IncidentLogsTable incidentId={typeProps.incident.id} />;
    default:
      //by default return to project info page
      return <IncidentProjectInfo {...typeProps} />;
  }
};
export default switchToIncidentMenuItems;

/**
 * @generated SignedSource<<ed8def4f5215b023bd4b900c16438fd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { task_admin_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskInfo_delete_task_admin_Mutation$variables = {
  where: task_admin_bool_exp;
};
export type TaskInfo_delete_task_admin_Mutation$data = {
  readonly delete_task_admin: {
    readonly returning: ReadonlyArray<{
      readonly general_contractor_employee: {
        readonly employee_title: {
          readonly name: {
            readonly en: string;
          };
        } | null | undefined;
        readonly user: {
          readonly name: string;
          readonly pk: string;
        };
      };
      readonly id: string;
    }>;
  } | null | undefined;
};
export type TaskInfo_delete_task_admin_Mutation = {
  response: TaskInfo_delete_task_admin_Mutation$data;
  variables: TaskInfo_delete_task_admin_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskInfo_delete_task_admin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "task_admin_mutation_response",
        "kind": "LinkedField",
        "name": "delete_task_admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "task_admin",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "general_contractor_employee",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "employee_title",
                    "kind": "LinkedField",
                    "name": "employee_title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "pk",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id_pk",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskInfo_delete_task_admin_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "task_admin_mutation_response",
        "kind": "LinkedField",
        "name": "delete_task_admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "task_admin",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "general_contractor_employee",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "employee_title",
                    "kind": "LinkedField",
                    "name": "employee_title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "name",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "alias": "pk",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": "pk",
                        "args": null,
                        "filters": null,
                        "handle": "pk",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "id"
                      },
                      (v4/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af144f8f99f007cd943c39d554ada7f7",
    "id": null,
    "metadata": {},
    "name": "TaskInfo_delete_task_admin_Mutation",
    "operationKind": "mutation",
    "text": "mutation TaskInfo_delete_task_admin_Mutation(\n  $where: task_admin_bool_exp!\n) {\n  delete_task_admin(where: $where) {\n    returning {\n      id\n      general_contractor_employee {\n        employee_title {\n          name {\n            en\n            id\n          }\n          id\n        }\n        user {\n          pk: id\n          name\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "07187e8690372a414e86bd9486941777";

export default node;

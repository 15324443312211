import {
  useDeleteDocumentByPkMutation,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import { deletedDocument } from "../components/basic/IncidentDocViewer";
import getIncidentTypeName from "../utils/getIncidentTypeName";
import createIncidentPatch from "./createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";

const useDeleteIncidentTypeDocument = () => {
  const [deleteDocumentMutation] = useDeleteDocumentByPkMutation();
  const authUser = useAuthUser();
  const deleteDocument = async (
    doc: deletedDocument,
    incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
    type: string,
  ) => {
    const incidentTypes = incident.incident_types;

    const updatedIncidentTypes = incidentTypes.map((incidentType) => {
      if (incidentType.type_value === type) {
        return {
          ...incidentType,
          documents: incidentType.documents.filter(
            (document) => document.id !== doc.id,
          ),
        };
      } else {
        return incidentType;
      }
    });

    const updatedIncident = {
      ...incident,
      incident_types: updatedIncidentTypes,
    };

    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = `Deleted document for ${getIncidentTypeName(
      type,
    )} incident`;

    await deleteDocumentMutation({
      variables: {
        id: doc.id,
        editObjects: {
          comment: comment,
          edited_by_uid: authUser.uid,
          edit_type: "document-delete",
          patch: patch,
          incident_id: incident.id,
        },
      },
      update(cache, result) {
        const docId = result.data?.delete_document_by_pk?.id;

        const updatedIncidentTypes = incidentTypes.map((incidentType) => {
          if (incidentType.type_value === type) {
            return {
              ...incidentType,
              documents: incidentType.documents.filter(
                (doc) => doc.id !== docId,
              ),
            };
          } else {
            return incidentType;
          }
        });

        const updatedIncident = {
          ...incident,
          incident_types: updatedIncidentTypes,
        };

        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          data: {
            incident_by_pk: updatedIncident,
          },
          query: GetIncidentByPkDocument,
        });
      },
    });
  };

  return [deleteDocument] as const;
};

export default useDeleteIncidentTypeDocument;

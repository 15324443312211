/**
 * @generated SignedSource<<46243ae2f73462414f90110467f34688>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { work_above_floor_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WorkAboveElevationFloor_FloorUpdate_Mutation$variables = {
  _set: work_above_floor_set_input;
  id: string;
};
export type WorkAboveElevationFloor_FloorUpdate_Mutation$data = {
  readonly update_work_above_floor_by_pk: {
    readonly completed_at: string | null | undefined;
    readonly has_netting: boolean;
    readonly id: string;
    readonly name: {
      readonly en: string;
      readonly id: string;
    };
    readonly name_id: string;
    readonly order: number;
    readonly pk: string;
  } | null | undefined;
};
export type WorkAboveElevationFloor_FloorUpdate_Mutation = {
  response: WorkAboveElevationFloor_FloorUpdate_Mutation$data;
  variables: WorkAboveElevationFloor_FloorUpdate_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_at",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "has_netting",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "en",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkAboveElevationFloor_FloorUpdate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "work_above_floor",
        "kind": "LinkedField",
        "name": "update_work_above_floor_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WorkAboveElevationFloor_FloorUpdate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "work_above_floor",
        "kind": "LinkedField",
        "name": "update_work_above_floor_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a8f40f613de1fab85421d190ce5a69fd",
    "id": null,
    "metadata": {},
    "name": "WorkAboveElevationFloor_FloorUpdate_Mutation",
    "operationKind": "mutation",
    "text": "mutation WorkAboveElevationFloor_FloorUpdate_Mutation(\n  $id: uuid!\n  $_set: work_above_floor_set_input!\n) {\n  update_work_above_floor_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n    order\n    name_id\n    pk: id\n    completed_at\n    has_netting\n    name {\n      id\n      en\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b135f3a7a8ffe98f0970b9f5eb60d3b";

export default node;

/**
 * @generated SignedSource<<a51f5f44d2de97ba415030df87efcba8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectOrientationStatus_SetHardHatNumberMutation$variables = {
  hardHatNumber: string;
  projectId: string;
  userId: string;
};
export type GCProjectOrientationStatus_SetHardHatNumberMutation$data = {
  readonly update_project_employee: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_project_worker: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type GCProjectOrientationStatus_SetHardHatNumberMutation = {
  response: GCProjectOrientationStatus_SetHardHatNumberMutation$data;
  variables: GCProjectOrientationStatus_SetHardHatNumberMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hardHatNumber"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v3 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "hard_hat_number",
      "variableName": "hardHatNumber"
    }
  ],
  "kind": "ObjectValue",
  "name": "_set"
},
v4 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "projectId"
    }
  ],
  "kind": "ObjectValue",
  "name": "project_id"
},
v5 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "userId"
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": [
      (v3/*: any*/),
      {
        "fields": [
          (v4/*: any*/),
          {
            "fields": (v5/*: any*/),
            "kind": "ObjectValue",
            "name": "worker_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_worker_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_worker",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      (v3/*: any*/),
      {
        "fields": [
          {
            "fields": (v5/*: any*/),
            "kind": "ObjectValue",
            "name": "employee_id"
          },
          (v4/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_employee_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_employee",
    "plural": false,
    "selections": (v6/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectOrientationStatus_SetHardHatNumberMutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectOrientationStatus_SetHardHatNumberMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "5dde03c6a784a3a1d8661923ac5f645c",
    "id": null,
    "metadata": {},
    "name": "GCProjectOrientationStatus_SetHardHatNumberMutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectOrientationStatus_SetHardHatNumberMutation(\n  $projectId: uuid!\n  $userId: uuid!\n  $hardHatNumber: String!\n) {\n  update_project_worker(where: {project_id: {_eq: $projectId}, worker_id: {_eq: $userId}}, _set: {hard_hat_number: $hardHatNumber}) {\n    affected_rows\n  }\n  update_project_employee(where: {project_id: {_eq: $projectId}, employee_id: {_eq: $userId}}, _set: {hard_hat_number: $hardHatNumber}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "374001257bf21e7f0f815e5831cd4aa6";

export default node;

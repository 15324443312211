import { FC } from "react";
import {
  useUpdateUtilityIncidentMutation,
  Document_Insert_Input,
  Utility_Incident_Set_Input,
  useGetMonetarySeverityLevelsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Card } from "antd";
import useUploadIncidentTypeDocument from "../../utils/useUploadIncidentTypeDocument";
import useDeleteIncidentTypeDocument from "../../utils/useDeleteIncidentTypeDocument";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import IncidentTextField from "../basic/IncidentTextField";
import IncidentSelectField from "../basic/IncidentSelectField";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";
import { deletedDocument } from "../basic/IncidentDocViewer";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../../utils/IncidentTypeProps";

const UtilityIncident: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();

  const [updateMonetarySeverity] = useUpdateMonetarySeverity();
  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery({
    fetchPolicy: "cache-first",
  });
  const monetarySeverityLevels = monetarySeverity?.incident_severity;
  const [uploadIncidentDocument] = useUploadIncidentTypeDocument();
  const [deleteIncidentDocument] = useDeleteIncidentTypeDocument();
  const [updateUtilityIncident] = useUpdateUtilityIncidentMutation();
  const [insertIncidentType] = useInsertIncidentType();
  const utility = incident.utility_incident;
  const documents = getIncidentDocuments(incident, "utility");
  if (!utility) {
    throw new Error("utlity_incident is missing");
  }

  const serviceTypeOptions = [
    { label: "Communications", value: "communication" },
    {
      label: "Electrical - High Voltage",
      value: "electrical_high_voltage",
    },
    {
      label: "Electrical - Low Voltage",
      value: "electrical_low_voltage",
    },
    { label: "Fiber Optics", value: "fiber_optics" },
    {
      label: "Gas High Pressure",
      value: "gas_high_pressure",
    },
    {
      label: "Gas Low Pressure",
      value: "gas_low_pressure",
    },
    { label: "Sewar", value: "sewar" },
    { label: "Storm", value: "storm" },
    { label: "Water", value: "water" },
    { label: "Other", value: "other" },
  ];

  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incident.id, "utility");
  };

  const updateUtility = async (
    _set: Omit<Utility_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      utility_incident: { ...utility, ..._set },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await insertIncidentTypeLink();
    await updateUtilityIncident({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "utility-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_utility_incident_by_pk: {
          ...utility,
          incident_id: incident.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };
  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocument(objects, incident, "utility");
  };
  const deleteDocument = async (doc: deletedDocument) => {
    await deleteIncidentDocument(doc, incident, "utility");
  };
  return (
    <>
      <div className="absolute left-24 top-2 text-2">
        Utility/Service Strike
      </div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <Card className="w-4/5">
          <IncidentSelectField
            onChange={(option) => {
              if (typeof option.value === "string")
                updateUtility(
                  { service_hit: option.value },
                  `Updated Utility/Service Strike - "What type of service was hit" to "${option.label}" `,
                );
            }}
            options={serviceTypeOptions}
            title="* What type of service was hit"
            value={utility.service_hit ?? undefined}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentSelectField
            title="Monetary Severity"
            options={
              monetarySeverityLevels?.map((severity) => ({
                label: severity.name.en,
                value: severity.id,
              })) ?? []
            }
            value={incident.monetary_severity_id ?? undefined}
            onChange={(option) => {
              if (typeof option.value === "string") {
                updateMonetarySeverity(incident, option.value, option.label);
              }
            }}
          />
        </Card>
        <Card className="w-4/5">
          <IncidentSelectField
            onChange={(option) => {
              if (typeof option.value === "boolean")
                updateUtility(
                  {
                    digsafe_requested: option.value,
                  },
                  `Updated Utility/Service Strike - "Was DigSafe or similar local organization requested" to "${option.label}" `,
                );
            }}
            options={[
              { value: false, label: "No" },
              { value: true, label: "Yes" },
            ]}
            title="Was DigSafe or similar local organization requested"
            value={utility.digsafe_requested ?? undefined}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentSelectField
            onChange={(option) => {
              if (typeof option.value === "boolean")
                updateUtility(
                  { markings_verified: option.value },
                  `Updated Utility/Service Strike - "Were markings checked/potholed/verified" to "${option.label}" `,
                );
            }}
            options={[
              { value: false, label: "No" },
              { value: true, label: "Yes" },
            ]}
            title="Were markings checked/potholed/verified"
            value={utility.markings_verified ?? undefined}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentSelectField
            onChange={(option) => {
              if (typeof option.value === "boolean")
                updateUtility(
                  { utility_marked: option.value },
                  `Updated Utility/Service Strike - "Was utility marked out" to "${option.label}" `,
                );
            }}
            options={[
              { value: false, label: "No" },
              { value: true, label: "Yes" },
            ]}
            title="Was utility marked out"
            value={utility.utility_marked ?? undefined}
          />

          {utility.utility_marked === false && (
            <IncidentTextField
              label="Why was utility not marked out"
              text={utility.utility_not_marked_detail.en}
              textId={utility.utility_not_marked_detail.id}
              key={utility.utility_not_marked_detail.id}
              saveIncidentField={insertIncidentTypeLink}
              fieldTypeKey="utility"
              incident={incident}
              field="utility_not_marked_detail"
            />
          )}
        </Card>

        {incident.incident_types.findIndex(
          (indexType) => indexType.type_value === "utility",
        ) !== -1 && (
          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={documents}
            groupId={incident.id}
            uploadDocument={uploadDocument}
            type="utility"
          />
        )}

        <IncidentBottomButtons
          saveDisabled={!utility.service_hit}
          onNextClick={() => {
            const next = getNextPage(incident, "utility");
            setSearchParams({ subview: next });
          }}
          onSave={() => {
            updateUtility(
              { completed_at: dayjs().format() },
              `Utility/Service Strike section marked as completed`,
            );
          }}
        />
      </div>
    </>
  );
};

export default UtilityIncident;

import GCProjectIncidents from "./GCProjectIncidents";
import GcIncidentManagement from "./GcIncidentManagement";

import { Outlet } from "react-router-dom";

const gcIncidentRoute = {
  element: <Outlet />,
  children: [
    { path: "", element: <GCProjectIncidents /> },
    {
      path: ":incidentId",
      element: <GcIncidentManagement />,
    },
  ],
};

export default gcIncidentRoute;

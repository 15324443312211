import GCAdminObservations from "../../component/observation/GCAdminObservations";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";

const GCCoporateObservations: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const projWhere = {
    general_contractor_id: { _eq: employee.general_contractor_id },
  };
  return (
    <GCAdminObservations
      projWhere={projWhere}
      optionsQueryVars={{
        projWhere,
        gcBuWhere: employee.general_contractor.hierarchy_business_unit_name
          ? { general_contractor_id: { _eq: employee.general_contractor_id } }
          : { id: { _is_null: true } },
        gcDivisionWhere: employee.general_contractor.hierarchy_division_name
          ? { general_contractor_id: { _eq: employee.general_contractor_id } }
          : { id: { _is_null: true } },
        gcOfficeWhere: employee.general_contractor.hierarchy_office_name
          ? { general_contractor_id: { _eq: employee.general_contractor_id } }
          : { id: { _is_null: true } },
      }}
      levelId={employee.general_contractor_id}
      levelName="gc_id"
      gcEmployeeWhere={{ is_corporate_admin: { _eq: true } }}
    />
  );
};
export default withCorporateAdminWrapper(GCCoporateObservations);

/**
 * @generated SignedSource<<9a7ae89be16c7c85766955e8e602dfb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useUpdateProjectSettingsMutation$variables = {
  _set?: project_set_input | null | undefined;
  projectId: string;
};
export type useUpdateProjectSettingsMutation$data = {
  readonly update_project: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_project_by_pk: {
    readonly add_hard_hat_during_in_person: boolean;
    readonly add_hard_hat_during_registration: boolean;
    readonly ask_age: boolean;
    readonly ask_emergency_contact: boolean;
    readonly ask_ethnicity: boolean;
    readonly ask_gender: boolean;
    readonly ask_number_of_years_in_construction: boolean;
    readonly ask_residence: boolean;
    readonly ask_veteran_type: boolean;
    readonly ask_years_with_employer: boolean;
    readonly assign_hard_hat: boolean;
    readonly automatically_assign_hard_hat: boolean;
    readonly background_check_tracker: boolean;
    readonly drug_test_retest_days: number;
    readonly drugtest_validity_days: number;
    readonly in_person_orientation: boolean;
    readonly next_hard_hat_number: number | null | undefined;
    readonly orientation_tv_player: boolean;
    readonly perform_drugtest: boolean;
    readonly require_certification_upload: boolean;
    readonly require_certs: boolean;
    readonly require_dob: boolean;
    readonly require_drugtest_upload: boolean;
    readonly require_phone: boolean;
    readonly require_profile_photo: boolean;
    readonly required_quiz_percentage: number;
    readonly send_email_for_drug_test_worker_arrival: boolean;
    readonly send_verification_invite: boolean;
    readonly show_onsite_for_not_oriented_workers: boolean;
    readonly union_labor: boolean;
    readonly worker_scan_in_person_qr_code: boolean;
  } | null | undefined;
};
export type useUpdateProjectSettingsMutation = {
  response: useUpdateProjectSettingsMutation$data;
  variables: useUpdateProjectSettingsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_set"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "_set",
  "variableName": "_set"
},
v2 = {
  "alias": null,
  "args": [
    (v1/*: any*/),
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_neq",
              "variableName": "projectId"
            }
          ],
          "kind": "ObjectValue",
          "name": "id"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_eq",
              "variableName": "projectId"
            }
          ],
          "kind": "ObjectValue",
          "name": "orientation_project_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "project_mutation_response",
  "kind": "LinkedField",
  "name": "update_project",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affected_rows",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "projectId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "union_labor",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ask_age",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ask_ethnicity",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ask_veteran_type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ask_years_with_employer",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ask_number_of_years_in_construction",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_phone",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_dob",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ask_gender",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ask_residence",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assign_hard_hat",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ask_emergency_contact",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation_tv_player",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "in_person_orientation",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "automatically_assign_hard_hat",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "add_hard_hat_during_in_person",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_scan_in_person_qr_code",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "next_hard_hat_number",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_certification_upload",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "perform_drugtest",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "required_quiz_percentage",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drug_test_retest_days",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "drugtest_validity_days",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_profile_photo",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "background_check_tracker",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_onsite_for_not_oriented_workers",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "send_email_for_drug_test_worker_arrival",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "add_hard_hat_during_registration",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "send_verification_invite",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_certs",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "require_drugtest_upload",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUpdateProjectSettingsMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "update_project_by_pk",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUpdateProjectSettingsMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project",
        "kind": "LinkedField",
        "name": "update_project_by_pk",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "01fb900e3322633d074aeec40068c88a",
    "id": null,
    "metadata": {},
    "name": "useUpdateProjectSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation useUpdateProjectSettingsMutation(\n  $_set: project_set_input\n  $projectId: uuid!\n) {\n  update_project(where: {orientation_project_id: {_eq: $projectId}, id: {_neq: $projectId}}, _set: $_set) {\n    affected_rows\n  }\n  update_project_by_pk(_set: $_set, pk_columns: {id: $projectId}) {\n    union_labor\n    ask_age\n    ask_ethnicity\n    ask_veteran_type\n    ask_years_with_employer\n    ask_number_of_years_in_construction\n    require_phone\n    require_dob\n    ask_gender\n    ask_residence\n    assign_hard_hat\n    ask_emergency_contact\n    orientation_tv_player\n    in_person_orientation\n    automatically_assign_hard_hat\n    add_hard_hat_during_in_person\n    worker_scan_in_person_qr_code\n    next_hard_hat_number\n    require_certification_upload\n    perform_drugtest\n    required_quiz_percentage\n    drug_test_retest_days\n    drugtest_validity_days\n    require_profile_photo\n    background_check_tracker\n    show_onsite_for_not_oriented_workers\n    send_email_for_drug_test_worker_arrival\n    add_hard_hat_during_registration\n    send_verification_invite\n    require_certs\n    require_drugtest_upload\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d34bcf3cc27805b6da098c770c020cae";

export default node;

/**
 * @generated SignedSource<<f31ebe047e4bdd1bfa466179cefb60eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InspectionResultTable_inspection_results$data = {
  readonly inspection_result_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly company_name: string | null | undefined;
        readonly created_at: string;
        readonly id: string;
        readonly inspection_answers: ReadonlyArray<{
          readonly correction_inspection_answer: ReadonlyArray<{
            readonly corrected_inspection_answer: {
              readonly created_at: string;
              readonly inspection_result: {
                readonly user: {
                  readonly name: string;
                } | null | undefined;
                readonly user_name: string | null | undefined;
              };
            } | null | undefined;
          }> | null | undefined;
          readonly created_at: string;
          readonly id: string;
          readonly images: ReadonlyArray<{
            readonly blurhash: string | null | undefined;
            readonly id: string;
            readonly url: string;
          }>;
          readonly inspection_checklist_item_deficient_type: {
            readonly is_immediate_fix: boolean;
            readonly text: {
              readonly en: string;
            };
          } | null | undefined;
          readonly inspection_instance_checklist_item: {
            readonly correct_answer: string;
            readonly description: {
              readonly en: string;
            };
            readonly inspection_checklist_item_type: {
              readonly text: {
                readonly en: string;
              };
            } | null | undefined;
          };
          readonly option_response: string;
          readonly text_response: {
            readonly en: string;
            readonly id: string;
          } | null | undefined;
        }>;
        readonly inspection_images: ReadonlyArray<{
          readonly id: string;
          readonly url: string;
        }>;
        readonly inspection_name: {
          readonly en: string;
          readonly id: string;
        };
        readonly inspection_qr_code: {
          readonly name: string;
        };
        readonly model_number: string | null | undefined;
        readonly pk: string;
        readonly project_location?: {
          readonly name: {
            readonly en: string;
          };
        } | null | undefined;
        readonly signature: {
          readonly url: string;
        };
        readonly subcontractor: {
          readonly name: string;
        } | null | undefined;
        readonly subcontractor_id: string | null | undefined;
        readonly user: {
          readonly name: string;
          readonly worker: {
            readonly worker_projects: ReadonlyArray<{
              readonly hard_hat_number: string | null | undefined;
            }>;
          } | null | undefined;
        } | null | undefined;
        readonly user_id: string | null | undefined;
        readonly user_name: string | null | undefined;
      };
    }>;
  };
  readonly " $fragmentType": "InspectionResultTable_inspection_results";
};
export type InspectionResultTable_inspection_results$key = {
  readonly " $data"?: InspectionResultTable_inspection_results$data;
  readonly " $fragmentSpreads": FragmentRefs<"InspectionResultTable_inspection_results">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "inspection_result_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
],
v8 = [
  (v4/*: any*/),
  (v1/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "text",
  "plural": false,
  "selections": (v5/*: any*/),
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeLocation"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "projectId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./InspectionResultTableRefetchableQuery.graphql')
    }
  },
  "name": "InspectionResultTable_inspection_results",
  "selections": [
    {
      "alias": "inspection_result_connection",
      "args": [
        {
          "kind": "Variable",
          "name": "order_by",
          "variableName": "order_by"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "inspection_resultConnection",
      "kind": "LinkedField",
      "name": "__InspectionResultTable_inspection_result_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "inspection_resultEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "inspection_result",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": "pk",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id_pk",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "model_number",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "user_id",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "condition": "includeLocation",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "project_location",
                      "kind": "LinkedField",
                      "name": "project_location",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "text_translation",
                          "kind": "LinkedField",
                          "name": "name",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "worker",
                      "kind": "LinkedField",
                      "name": "worker",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": [
                            {
                              "fields": [
                                {
                                  "kind": "Literal",
                                  "name": "is_last",
                                  "value": {
                                    "_eq": true
                                  }
                                },
                                {
                                  "fields": [
                                    {
                                      "kind": "Variable",
                                      "name": "_eq",
                                      "variableName": "projectId"
                                    }
                                  ],
                                  "kind": "ObjectValue",
                                  "name": "project_id"
                                }
                              ],
                              "kind": "ObjectValue",
                              "name": "where"
                            }
                          ],
                          "concreteType": "project_worker",
                          "kind": "LinkedField",
                          "name": "worker_projects",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "hard_hat_number",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "subcontractor_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "subcontractor",
                  "kind": "LinkedField",
                  "name": "subcontractor",
                  "plural": false,
                  "selections": (v7/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "company_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "text_translation",
                  "kind": "LinkedField",
                  "name": "inspection_name",
                  "plural": false,
                  "selections": (v8/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "inspection_qr_code",
                  "kind": "LinkedField",
                  "name": "inspection_qr_code",
                  "plural": false,
                  "selections": (v7/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "image",
                  "kind": "LinkedField",
                  "name": "inspection_images",
                  "plural": true,
                  "selections": [
                    (v9/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "image",
                  "kind": "LinkedField",
                  "name": "signature",
                  "plural": false,
                  "selections": [
                    (v9/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": [
                        {
                          "inspection_instance_checklist_item": {
                            "inspection_checklist_item_type": {
                              "text": {
                                "en": "asc_nulls_first"
                              }
                            }
                          }
                        },
                        {
                          "inspection_instance_checklist_item": {
                            "sort_index": "asc"
                          }
                        }
                      ]
                    }
                  ],
                  "concreteType": "inspection_answer",
                  "kind": "LinkedField",
                  "name": "inspection_answers",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "option_response",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "text_translation",
                      "kind": "LinkedField",
                      "name": "text_response",
                      "plural": false,
                      "selections": (v8/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "correction_inspection_answer",
                      "kind": "LinkedField",
                      "name": "correction_inspection_answer",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "inspection_answer",
                          "kind": "LinkedField",
                          "name": "corrected_inspection_answer",
                          "plural": false,
                          "selections": [
                            (v2/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "inspection_result",
                              "kind": "LinkedField",
                              "name": "inspection_result",
                              "plural": false,
                              "selections": [
                                (v3/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "user",
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": (v7/*: any*/),
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_instance_checklist_item",
                      "kind": "LinkedField",
                      "name": "inspection_instance_checklist_item",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "correct_answer",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "inspection_checklist_item_type",
                          "kind": "LinkedField",
                          "name": "inspection_checklist_item_type",
                          "plural": false,
                          "selections": [
                            (v10/*: any*/)
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "text_translation",
                          "kind": "LinkedField",
                          "name": "description",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "image",
                      "kind": "LinkedField",
                      "name": "images",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "blurhash",
                          "storageKey": null
                        },
                        (v9/*: any*/)
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "inspection_checklist_item_deficient_type",
                      "kind": "LinkedField",
                      "name": "inspection_checklist_item_deficient_type",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "is_immediate_fix",
                          "storageKey": null
                        },
                        (v10/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "inspection_answers(order_by:[{\"inspection_instance_checklist_item\":{\"inspection_checklist_item_type\":{\"text\":{\"en\":\"asc_nulls_first\"}}}},{\"inspection_instance_checklist_item\":{\"sort_index\":\"asc\"}}])"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "0a7d11522eb71dae8327febe5bdcfbab";

export default node;

import { FC, useState, useEffect } from "react";
import { Select, Card, Input } from "antd";
import {
  useGetIncidentAddressLazyQuery,
  Address_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";

interface IncidentAddressProps {
  type: "home" | "employer";
  title: string;
  updateAddress: (
    object: Address_Insert_Input,
    type: "home" | "employer",
  ) => Promise<void>;
  addressLine1?: string;
  zipCode?: string;
  cityZipCode?: string;
  cityName?: string;
}

const IncidentAddress: FC<IncidentAddressProps> = ({
  addressLine1,
  type,
  zipCode,
  title,
  updateAddress,
  ...props
}) => {
  const [line1, setLine1] = useState<string>(addressLine1 ?? "");
  const [zip, setZip] = useState<string>(zipCode ?? "");
  const [cityZipCode, setCityZipCode] = useState<string | undefined>(
    props.cityZipCode,
  );
  const [cityName, setCityName] = useState<string | undefined>(props.cityName);

  const [query, { data: incidentAddress }] = useGetIncidentAddressLazyQuery({
    variables: { zipCode: zipCode ?? "" },
  });

  useEffect(() => {
    if (zipCode) query({ variables: { zipCode: zipCode } });
  }, [zipCode]);

  const updateAddressInfo = () => {
    const state = incidentAddress?.city_zip_code.find(
      (cityZip) => cityZip.id === cityZipCode,
    )?.city.state_code;
    if (cityZipCode && zip && state) {
      updateAddress(
        {
          line_1: line1,
          city: cityName,
          city_zip_code_id: cityZipCode,
          zip_code: zip,
          state_code: state,
        },
        type,
      );
    }
  };
  const cityZips = incidentAddress?.city_zip_code.map((cityZip) => ({
    label: cityZip.city.name,
    value: cityZip.id,
  }));

  const state = incidentAddress?.city_zip_code.find(
    (cityZip) => cityZip.id === cityZipCode,
  )?.city.state?.name;

  return (
    <Card className="w-4/5">
      <div className="flex flex-row gap-x-2">{title}</div>

      <div className="flex gap-1 items-center">
        <div className="w-10">Address Line 1</div>
        <Input
          className="w-full"
          value={line1}
          onChange={(e) => {
            setLine1(e.target.value);
          }}
          onBlur={updateAddressInfo}
        />
      </div>

      <div className="flex gap-1 items-center mt-0.5">
        <div className="w-10">Zip code</div>
        <Input
          className="w-full"
          value={zip}
          pattern="[0-9]+"
          onChange={(e) => {
            const input = e.currentTarget.value;
            setZip(input);
            if (input.length === 5 && !isNaN(Number(input))) {
              query({ variables: { zipCode: input } });
              setCityZipCode(undefined);
            }
          }}
          onBlur={updateAddressInfo}
        />
      </div>

      {cityZips && cityZips.length > 0 && (
        <>
          <div className="flex gap-1 items-center mt-0.5">
            <div className="w-10">City</div>

            <Select
              className="w-full"
              value={cityZipCode}
              options={cityZips}
              onChange={(selectedCityZip) => {
                const selectedCity = cityZips.find(
                  (city) => city.value === selectedCityZip,
                );

                setCityZipCode(selectedCityZip);
                setCityName(selectedCity ? selectedCity.label : "");
              }}
              onBlur={updateAddressInfo}
            />
          </div>

          <div className="flex gap-1 items-center mt-0.5">
            <div className="w-10">State</div>
            <Input className="w-full" value={state} />
          </div>
        </>
      )}
    </Card>
  );
};

export default IncidentAddress;

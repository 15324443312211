import React, { useEffect, useRef, useState } from "react";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import Button from "src/common/components/general/Button";
import {
  useInsertInspectionInstanceAndTemplateMutation$variables,
} from "src/common/types/generated/relay/useInsertInspectionInstanceAndTemplateMutation.graphql";

import {
  inspection_instance_insert_input,
  inspection_template_insert_input,
  inspection_instance_checklist_item_insert_input,
  inspection_instance_qr_code_insert_input,
  inspection_template_checklist_item_insert_input,  
} from "src/common/types/generated/relay/types";

import AddInspectionChecklistItemModal from "./AddInspectionChecklistItemModal";
import * as uuid from "uuid";
import { message, notification } from "antd";
import noop from "src/common/functions/noop";
import { auth } from "src/common/functions/firebase";
import { reorderArrayInt } from "src/common/functions/reorderRecords";
import InspectionChecklistItemTypeTable from "../tables/InspectionChecklistItemTypeTable";
import {
  InspectionChecklistItemProps,
  InspectionInsertValues,
  NotifyUserType,
  OptionType,
} from "../../utils/siteInspectionTypes";
import { generalChecklistType } from "../../utils/generalChecklistType";
import EditInspectionFields from "../EditInspectionFields";
import { getCommomChecklistItemObject } from "../../utils/getCommonChecklistItemObject";
import useInsertInspectionInstanceAndTemplate from "../../utils/useInsertInspectionInstanceAndTemplate";
import { ConnectionHandler } from "relay-runtime";
import newQRCodeInsertionUpdater from "../../utils/newQRCodeInsertionUpdater";

interface CreateInspectionModalProps {
  projectId: string;
  onFinish: () => void;
  onCancel: () => void;
  generalContractorId?: string;
  visible: boolean;
  templateType?: "company" | "community";
  labelOptions: Array<OptionType>;
  inspectionQROptions: Array<OptionType>;
  projectEmployees: Array<NotifyUserType>;
}

const CreateInspectionModal: React.FC<CreateInspectionModalProps> = ({
  projectId,
  visible,
  onCancel,
  onFinish,
  ...props
}) => {
  const [createdChecklistItems, setCreatedChecklistItems] = useState<{
    [type: string]: { [key: string]: InspectionChecklistItemProps };
  }>({});
  const [newChecklistItem, setNewChecklistItem] = useState(false);

  const [insertInspection, isInserting] =
    useInsertInspectionInstanceAndTemplate();
  const modalRef = useRef<FModalRef<InspectionInsertValues>>();

  const getInstanceChecklistItem = (
    checklistItem: InspectionChecklistItemProps,
    eitherCompanyOrCommunityUploaded: boolean | undefined,
  ) => ({
    ...getCommomChecklistItemObject(checklistItem),
    ...(eitherCompanyOrCommunityUploaded
      ? {
          inspection_template_checklist_item_id: checklistItem.template_item_id,
        }
      : {}),
  });
  const resetForm = () => {
    modalRef.current?.form.resetFields();
    setCreatedChecklistItems({});
  };
  const [allowChecklistItemPhoto, setAllowChecklistItemPhoto] = useState(false);
  return (
    <FModal
      zIndex={10}
      width={1000}
      okText="Save"
      title={
        props.templateType === "community"
          ? "Create an Inspection for community"
          : props.templateType === "company"
          ? "Create a Company Inspection"
          : "Create Project Inspection"
      }
      confirmLoading={isInserting}
      open={visible}
      ref={modalRef}
      onCancel={() => {
        modalRef.current?.form.setFieldsValue({
          company: false,
          community: false,
        });
        onCancel();
      }}
      onOk={async () => {
        try {
          const values = await modalRef.current?.form.validateFields();
          if (!values?.name) {
            throw new Error("name cannot be empty");
          }
          if (values.model_number && values.model_number_required)
            throw new Error(
              "You can only either enter a defauly model number or require inspector to enter,not both, please try again",
            );
          const templateId = uuid.v4();
          const generalTypeChecklistItems: Array<{
            [key: string]: InspectionChecklistItemProps;
          }> = [];
          const nonGeneralTypeItems = Object.entries(
            createdChecklistItems,
          ).filter((obj) => {
            if (obj[0] === generalChecklistType) {
              generalTypeChecklistItems.push(obj[1]);
              return false;
            } else {
              return true;
            }
          });
          const newQRId = uuid.v4();
          const inspectionInstanceQR: Array<inspection_instance_qr_code_insert_input> =
            (values.inspection_qr_codes || []).map((inspQR) => ({
              inspection_qr_code_id: inspQR,
            }));
          if (values.new_inspection_qr_code) {
            inspectionInstanceQR.push({
              inspection_qr_code: {
                data: {
                  id: newQRId,
                  created_by_uid: auth.currentUser?.uid,
                  project_id: projectId,
                  name: values.new_inspection_qr_code,
                },
              },
            });
          }
          const buildTemplateObj = (
            includeCheckListItemId: boolean,
          ): inspection_template_insert_input => {
            const nonGeneralTypeChecklistItems = nonGeneralTypeItems.flatMap(
              ([itemType, checklistItemObj]) => {
                const typeId = uuid.v4();
                return Object.values(checklistItemObj).map(
                  (
                    checklistItem,
                    i,
                  ): inspection_template_checklist_item_insert_input => {
                    return {
                      ...getCommomChecklistItemObject(checklistItem, true),
                      ...(includeCheckListItemId
                        ? { id: checklistItem.template_item_id }
                        : {}),
                      ...(i === 0
                        ? {
                            inspection_checklist_item_type: {
                              data: {
                                id: typeId,
                                text: {
                                  data: { original: itemType, en: itemType },
                                },
                              },
                            },
                          }
                        : { inspection_checklist_item_type_id: typeId }),
                    };
                  },
                );
              },
            );

            return {
              name: { data: { original: values.name, en: values.name } },
              model_number:
                values.model_number && values.model_number.trim()
                  ? values.model_number.trim()
                  : null,
              model_number_required: values.model_number_required,
              image_required: values.image_required,
              is_multi_inspection: values.is_multi_inspection,
              allow_photo_to_checklist_item:
                values.allow_photo_to_checklist_item,
              created_by_uid: auth.currentUser?.uid,
              inspection_labels: {
                data: (values.labels || []).map((l) => ({ label_id: l })),
              },
              inspection_template_checklist_items: {
                data: [
                  ...nonGeneralTypeChecklistItems,
                  ...generalTypeChecklistItems
                    .flatMap((checklistItemObj) =>
                      Object.values(checklistItemObj),
                    )
                    .map((checklistItem) => ({
                      ...getCommomChecklistItemObject(checklistItem, true),

                      ...(includeCheckListItemId
                        ? { id: checklistItem.template_item_id }
                        : {}),
                    })),
                ],
              },
            };
          };
          const nonGeneralInstanceTypeChecklistItems: inspection_instance_checklist_item_insert_input[] =
            [];
          nonGeneralTypeItems.forEach(([itemType, checklistItemObj]) => {
            const typeId = uuid.v4();
            Object.values(checklistItemObj).map((checklistItem, i) => {
              nonGeneralInstanceTypeChecklistItems.push({
                ...getInstanceChecklistItem(
                  checklistItem,
                  values.community || values.company,
                ),
                ...(i === 0
                  ? {
                      inspection_checklist_item_type: {
                        data: {
                          id: typeId,
                          text: { data: { original: itemType, en: itemType } },
                        },
                      },
                    }
                  : { inspection_checklist_item_type_id: typeId }),
              });
            });
          });
          const instanceObjects: inspection_instance_insert_input[] =
            props.templateType
              ? []
              : [
                  {
                    name: { data: { original: values.name, en: values.name } },
                    model_number:
                      values.model_number && values.model_number.trim()
                        ? values.model_number.trim()
                        : null,
                    project_id: projectId,
                    ...(values.community || values.company
                      ? { inspection_template_id: templateId }
                      : {}),
                    model_number_required: values.model_number_required,
                    image_required: values.image_required,
                    is_multi_inspection: values.is_multi_inspection,
                    allow_photo_to_checklist_item:
                      values.allow_photo_to_checklist_item,
                    created_by_uid: auth.currentUser?.uid,
                    inspection_labels: {
                      data: (values.labels || []).map((l) => ({ label_id: l })),
                    },
                    inspection_instance_qr_codes: {
                      data: inspectionInstanceQR,
                    },
                    inspection_instance_checklist_items: {
                      data: [
                        ...nonGeneralInstanceTypeChecklistItems,
                        ...generalTypeChecklistItems
                          .flatMap((checklistItemObj) =>
                            Object.values(checklistItemObj),
                          )
                          .map((checklistItem) =>
                            getInstanceChecklistItem(
                              checklistItem,
                              values.community || values.company,
                            ),
                          ),
                      ],
                    },

                    // },
                  },
                ];
          const variables: useInsertInspectionInstanceAndTemplateMutation$variables =
            {
              instanceObjects,
              templateObjects:
                values.company && values.community
                  ? [
                      {
                        ...buildTemplateObj(true),
                        general_contractor_id: props.generalContractorId,
                        id: templateId,
                      },
                      buildTemplateObj(false),
                    ]
                  : values.company
                  ? [
                      {
                        ...buildTemplateObj(true),
                        general_contractor_id: props.generalContractorId,
                        id: templateId,
                      },
                    ]
                  : values.community
                  ? [{ ...buildTemplateObj(true), id: templateId }]
                  : [],
            };

          await insertInspection({
            variables,
            updater: (store) => {
              const conn = ConnectionHandler.getConnection(
                store.getRoot(),
                "InspectionInstanceTable_inspection_instance_connection",
              );
              const optionsConn = ConnectionHandler.getConnection(
                store.getRoot(),
                "GCInspectionsQuery_inspection_instance_connection",
              );
              const templateConn = ConnectionHandler.getConnection(
                store.getRoot(),
                "InspectionTemplateTable_inspection_template_connection",
              );
              const returningTemplates = store
                .getRootField("insert_inspection_template")
                .getLinkedRecords("returning");
              const returningInstances = store
                .getRootField("insert_inspection_instance")
                .getLinkedRecords("returning");
              if (values.new_inspection_qr_code) {
                newQRCodeInsertionUpdater(
                  store,
                  props.inspectionQROptions,
                  returningInstances[0].getLinkedRecords(
                    "inspection_instance_qr_codes",
                    {
                      where: {
                        inspection_qr_code: { deleted_at: { _is_null: true } },
                      },
                    },
                  ),
                );
              }
              if (templateConn) {
                if (returningTemplates[0] && props.templateType) {
                  const edge = ConnectionHandler.createEdge(
                    store,
                    templateConn,
                    returningTemplates[0],
                    "edge",
                  );
                  ConnectionHandler.insertEdgeBefore(templateConn, edge);
                }
              }

              returningInstances.forEach((insp) => {
                if (conn) {
                  const edge = ConnectionHandler.createEdge(
                    store,
                    conn,
                    insp,
                    "edge",
                  );
                  ConnectionHandler.insertEdgeBefore(conn, edge);
                }
                if (optionsConn) {
                  const edge = ConnectionHandler.createEdge(
                    store,
                    optionsConn,
                    insp,
                    "edge",
                  );
                  ConnectionHandler.insertEdgeBefore(optionsConn, edge);
                }
              });
            },
          });
          resetForm();
          onFinish();
          message.success("Successfully created Inspection");
        } catch (err) {
          console.error(err);
          notification.error({
            description: "Inspection could not be created",
            message: err instanceof Error ? err.message : JSON.stringify(err),
          });
        }
      }}
    >
      <EditInspectionFields
        form={modalRef.current?.form}
        onAllowItemPhotoChange={(checked) =>
          setAllowChecklistItemPhoto(checked)
        }
        showAddToCommunity={props.templateType !== "community"}
        showAddToCompany={props.templateType !== "company"}
        labelOptions={props.labelOptions}
        inspectionQROptions={props.inspectionQROptions}
        initialValues={{
          community: props.templateType === "community",
          company: props.templateType === "company",
        }}
        template={!!props.templateType}
      />
      {Object.entries(createdChecklistItems).map(
        ([type, checklistItemTypeObject]) => {
          return (
            <InspectionChecklistItemTypeTable
              allowChecklistItemPhoto={allowChecklistItemPhoto}
              dataSource={Object.values(checklistItemTypeObject)}
              type={type}
              excludedKeys={props.templateType ? ["notify_on_deficient"] : []}
              projectEmployees={props.projectEmployees}
              editing={true}
              onDeleteItem={(item) => {
                item.id
                  ? setCreatedChecklistItems((prev) => {
                      const newObj = { ...prev };
                      if (Object.entries(newObj[type]).length <= 1)
                        delete newObj[type];
                      else {
                        delete newObj[type][item.id!];
                      }
                      return newObj;
                    })
                  : noop();
              }}
              onEditOrEditDoneItem={(newItem, prevItem) => {
                if (newItem?.id === prevItem.id) {
                  setCreatedChecklistItems((prev) => ({
                    ...prev,
                    [type]: {
                      ...prev[type],
                      [prevItem.id!]: { ...newItem },
                    },
                  }));
                }
              }}
              onMoveRow={(dragIndex, hoverIndex) =>
                setCreatedChecklistItems((prev) => {
                  const list = Object.values(prev[type]);
                  const newList = reorderArrayInt<InspectionChecklistItemProps>(
                    list,
                    dragIndex,
                    hoverIndex,
                  );
                  return {
                    ...prev,
                    [type]: Object.fromEntries(
                      newList.map((val) => [val.id, val]),
                    ),
                  };
                })
              }
            />
          );
        },
      )}
      <div className="mt-1">
        <Button
          label="Add checklist items"
          onClick={() => setNewChecklistItem(true)}
        />
      </div>
      <AddInspectionChecklistItemModal
        allowChecklistItemPhoto={allowChecklistItemPhoto}
        projectEmployees={props.projectEmployees}
        hideNotifyees={!!props.templateType}
        checklistItemTypes={Object.keys(createdChecklistItems).filter(
          (p) => p != generalChecklistType,
        )}
        visible={newChecklistItem}
        onCancel={() => setNewChecklistItem(false)}
        onCreate={(values, creatingAnother) => {
          const typeKey = values.item_type_label
            ? values.item_type_label
            : generalChecklistType;
          const checklistItemId = uuid.v4();
          setCreatedChecklistItems((prev) => ({
            ...prev,
            [typeKey]: {
              ...(prev[typeKey] ?? {}),
              [checklistItemId]: {
                ...values,
                id: checklistItemId,
                template_item_id: uuid.v4(),
                sort_index: Object.keys(prev[typeKey] ?? {}).length + 1,
              },
            },
          }));
          if (!creatingAnother) {
            setNewChecklistItem(false);
          }
        }}
      />
    </FModal>
  );
};
export default CreateInspectionModal;

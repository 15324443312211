import { Button } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  FC,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import { Navigate, useNavigate } from "react-router-dom";
import { fetchQuery, SelectorStoreUpdater } from "relay-runtime";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BSpace from "src/common/components/layouts/BSpace";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import SCProjectSubcontractorsTable from "src/common/components/tables/SubAdminProjectSubcontractorsTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import useCustomLazyLoadQuery from "src/common/hooks/useCustomLazyLoadQuery";
import getConnectionNodes from "src/common/functions/getConnectionNodes";
import { SCProjectsCreateProjectQuery } from "src/common/types/generated/relay/SCProjectsCreateProjectQuery.graphql";
import { SCProjectsInsertProjectSubMutation } from "src/common/types/generated/relay/SCProjectsInsertProjectSubMutation.graphql";
import { SCProjectsInsertProjectsMutation } from "src/common/types/generated/relay/SCProjectsInsertProjectsMutation.graphql";
import { project_insert_input } from "src/common/types/generated/relay/types";

import { SCProjectsQuery } from "src/common/types/generated/relay/SCProjectsQuery.graphql";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";
import * as uuid from "uuid";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import dayjs from "dayjs";
import useAuthUser from "src/common/hooks/useAuthUser";

interface CreateProjectValues {
  gcId: string;
  union: boolean;
  gcName?: string;
  projectId: string;
  projectName?: string;
  addressLine1?: string;
  city?: string;
  zip?: string;
  stateCode?: string;
  startDate?: string;
  endDate?: string;
}

const createProjectModalQuery = graphql`
  query SCProjectsCreateProjectQuery($subId: uuid!) {
    state_connection(order_by: { name: asc }) {
      edges {
        node {
          name
          code
        }
      }
    }
    general_contractor_connection(order_by: { name: asc }) {
      edges {
        node {
          name
          require_dob
          require_phone
          agc_universal_orientation
          pk: id @__clientField(handle: "pk")
          gc_orientations(where: { type: { _eq: "corporate" } }) {
            pk: id @__clientField(handle: "pk")
          }
          gc_projects(
            where: {
              _not: {
                project: {
                  project_subcontractors: { subcontractor_id: { _eq: $subId } }
                }
              }
            }
            order_by: { project: { name: asc } }
          ) {
            project {
              name
              pk: id @__clientField(handle: "pk")
            }
          }
        }
      }
    }
  }
`;

type CreateProjectModalRef = FModalRef<CreateProjectValues> | null;
const CreateProjectModal = forwardRef<
  CreateProjectModalRef,
  {
    subcontractorId: string;
    onCancel: () => void;
    onFinish: () => void;
  }
>(({ onFinish, onCancel, subcontractorId }, ref) => {
  const modal = useRef<CreateProjectModalRef>(null);
  const [gcId, setGcId] = useState<string | null>(null);
  const [projectId, setProjectId] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [insertProjectSub] =
    useAsyncMutation<SCProjectsInsertProjectSubMutation>(
      graphql`
        mutation SCProjectsInsertProjectSubMutation(
          $objects: [project_subcontractor_insert_input!]!
        ) {
          insert_project_subcontractor(objects: $objects) {
            affected_rows
          }
        }
      `,
    );
  const environment = useRelayEnvironment();
  const [insertProject] = useAsyncMutation<SCProjectsInsertProjectsMutation>(
    graphql`
      mutation SCProjectsInsertProjectsMutation(
        $objects: [project_insert_input!]!
      ) {
        insert_project(objects: $objects) {
          affected_rows
        }
      }
    `,
  );

  useImperativeHandle<CreateProjectModalRef, CreateProjectModalRef>(
    ref,
    () => modal.current,
  );

  const reset = () => {
    setGcId(null);
    setProjectId(null);
    setLoading(false);
    modal.current?.form.resetFields();
  };

  const formContentdata = useCustomLazyLoadQuery<SCProjectsCreateProjectQuery>(
    createProjectModalQuery,
    {
      subId: subcontractorId,
    },
  );
  const FormContent = withCustomSuspense(() => {
    const data = formContentdata;

    const gcs = getConnectionNodes(data.general_contractor_connection);
    const states = getConnectionNodes(data.state_connection);

    const selectedGCProjects =
      gcs.find((gc) => gc.pk === gcId)?.gc_projects.map((p) => p.project) ?? [];

    return (
      <>
        <FModal.Select
          required
          name="gcId"
          label="General Contractor"
          props={{
            // TODO: replace any
            onChange: (id: any) => {
              setGcId(id);
              setProjectId(null);
            },
            showSearch: true,
            optionFilterProp: "key",
            options: [
              {
                value: "custom",
                key: "",
                label: "Add Another GC",
              },
              ...gcs.map((gc, i) => ({
                value: gc.pk,
                key: gc.name + i,
                label: gc.name,
              })),
            ],
          }}
        />
        {gcId === "custom" && <FModal.Text name="gcName" label="GC Name" />}
        {!!gcId && gcId !== "custom" && (
          <FModal.Select
            required
            name="projectId"
            label="Project"
            props={{
              onChange: (id: any) => setProjectId(id),
              optionFilterProp: "label",
              options: [
                {
                  value: "custom",
                  key: "custom",
                  label: "Add Another Project",
                },
                ...selectedGCProjects.map((p) => ({
                  value: p.pk,
                  label: p.name,
                })),
              ],
            }}
          />
        )}
        {(gcId === "custom" || projectId === "custom") && (
          <>
            <FModal.Text name="projectName" label="Project Name" />
            Address
            <FModal.Text required name="addressLine1" label="Address Line 1" />
            <FModal.Text required name="city" label="City" />
            <FModal.Text required name="zip" label="Zip Code" />
            <FModal.Select
              required
              name="stateCode"
              label="State"
              props={{
                showSearch: true,
                optionFilterProp: "key",
                options: states.map((p) => ({
                  value: p.code,
                  key: p.name,
                  label: p.name,
                })),
              }}
            />
            <FModal.Date
              required
              name="startDate"
              label="Start Date"
              props={{
                className: "w-full",
                format: "YYYY-MM-DD",
              }}
            />
            <FModal.Date
              required
              name="endDate"
              label="End Date"
              props={{
                className: "w-full",
                format: "YYYY-MM-DD",
                disabledDate: (current) =>
                  current.isBefore(dayjs().subtract(1, "day")),
              }}
            />
            <div className="flex">
              <FModal.RadioGroup
                label="Will this project mainly use Union labor?"
                name="union"
                initialValue={false}
              >
                <FModal.Radio props={{ value: true }}>Yes</FModal.Radio>
                <FModal.Radio props={{ value: false }}>No</FModal.Radio>
              </FModal.RadioGroup>
            </div>
          </>
        )}
      </>
    );
  });

  return (
    <FModal
      ref={modal}
      title="Create Project"
      okText="Done"
      confirmLoading={loading}
      onCancel={() => {
        onCancel();
        reset();
      }}
      onOk={async () => {
        setLoading(true);
        const selectedGC =
          formContentdata.general_contractor_connection.edges.filter(
            (gc) => gc.node.pk === gcId,
          )?.[0]?.node;
        try {
          const form = modal.current?.form;
          if (!form) return;
          let v = await form.validateFields();
          const updater: SelectorStoreUpdater = (store) => {
            const record = store
              .getRoot()
              .getLinkedRecord("project_subcontractor_connection", {
                where: {
                  subcontractor: { id: { _eq: subcontractorId } },
                },
              });
            record?.invalidateRecord();
          };

          if (v.projectId === "custom" || !v.projectId) {
            //  console.log(v);
            const id = uuid.v4();
            const projectInsert: project_insert_input = {
              name: v.projectName!,
              address: {
                data: {
                  line_1: v.addressLine1,
                  zip_code: v.zip,
                  city: v.city,
                  state_code: v.stateCode,
                },
              },
              start_date: v.startDate,
              end_date: v.endDate,
              project_orientation_settings: {
                data: selectedGC.gc_orientations.map((gco) => ({
                  orientation_id: gco.pk,
                })),
              },
              id,
              union_labor: v.union,
              require_dob: selectedGC.require_dob,
              require_phone: selectedGC.require_phone,
              agc_universal_orientation: selectedGC.agc_universal_orientation,
              orientation_project_id: id,
              project_subcontractors: {
                data: [
                  {
                    subcontractor_id: subcontractorId,
                  },
                ],
              },
            };

            if (v.gcId === "custom") {
              const gcId = uuid.v4();
              projectInsert.general_contractor = {
                data: {
                  id: gcId,
                  name: v.gcName!,
                },
              };
              projectInsert.general_contractors = {
                data: [
                  {
                    general_contractor_id: gcId,
                  },
                ],
              };
            } else {
              projectInsert.project_orientation_settings = {
                data: selectedGC.gc_orientations.map((gco) => ({
                  orientation_id: gco.pk,
                })),
              };
              projectInsert.require_dob = selectedGC.require_dob;
              projectInsert.require_phone = selectedGC.require_phone;
              projectInsert.general_contractor_id = v.gcId;
              projectInsert.general_contractors = {
                data: [
                  {
                    general_contractor_id: v.gcId,
                  },
                ],
              };
            }

            await insertProject({
              variables: {
                objects: [projectInsert],
              },
              updater,
            });
          } else {
            await insertProjectSub({
              variables: {
                objects: [
                  {
                    subcontractor_id: subcontractorId,
                    project_id: v.projectId!,
                  },
                ],
              },
              updater,
            });
          }

          fetchQuery<SCProjectsCreateProjectQuery>(
            environment,
            createProjectModalQuery,
            { subId: subcontractorId },
            { networkCacheConfig: { force: true } },
          );

          onFinish();
          reset();
        } finally {
          setLoading(false);
        }
      }}
    >
      <div style={{ width: "80%" }}>
        <FormContent />
      </div>
    </FModal>
  );
});

const query = graphql`
  query SCProjectsQuery($subId: uuid!, $userId: uuid!) {
    project_subcontractor_connection(
      where: { subcontractor: { id: { _eq: $subId } } }
      order_by: { project: { name: asc } }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          project_workers_aggregate {
            aggregate {
              count
            }
          }
          project_subcontractor_employees(
            where: { subcontractor_employee_id: { _eq: $userId } }
          ) {
            id
          }
          project {
            pk: id @__clientField(handle: "pk")
            name
            completed
            general_contractors {
              general_contractor {
                name
              }
            }

            general_contractor {
              name
            }
            project_subcontractors {
              tasks_aggregate(where: { subcontractor_id: { _eq: $subId } }) {
                aggregate {
                  count
                }
              }
              tasks(where: { subcontractor_id: { _eq: $subId } }) {
                id
              }
            }
          }
        }
      }
    }
    worker_connection(
      where: { subcontractor_id: { _eq: $subId } }
      order_by: { user: { name: asc } }
    ) {
      edges {
        node {
          user {
            name
            email
            phone_number
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }
  }
`;

const SCProjects: FC<SubcontractorProps> = ({ subcontractorId }) => {
  const createProjectModal = useRef<CreateProjectModalRef>(null);
  // const projectsTable = useRef<DataScrollTableRef>(null);
  const [fetchKey, setFetchKey] = useState(0);
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const data = useLazyLoadQuery<SCProjectsQuery>(
    query,
    { subId: subcontractorId, userId: authUser.uid },
    { fetchKey, fetchPolicy: "network-only" },
  );
  const refetch = () => {
    setFetchKey((i) => i + 1);
  };
  const handleProjectClick = (projectId: string) => {
    navigate(`/sub/projects/${projectId}`);
  };

  if (!subcontractorId) {
    return <Navigate to={`/sub`} replace />;
  }
  console.log("renger projects", data);
  return (
    <BasicWrapper scrollable>
      <CreateProjectModal
        ref={createProjectModal}
        subcontractorId={subcontractorId}
        onFinish={() => {
          createProjectModal.current?.close();
          refetch();
          //projectsTable.current?.refetch();
        }}
        onCancel={() => {
          createProjectModal.current?.close();
        }}
      />
      <BSpace>
        <Button
          type="primary"
          size="large"
          className="rounded-2 font-accent"
          onClick={() => {
            createProjectModal.current?.open();
          }}
        >
          New Project
        </Button>
        <SCProjectSubcontractorsTable
          subcontractorId={subcontractorId}
          title="Your Projects"
          type="yours"
          data={data}
          onProjectClick={handleProjectClick}
        />

        <SCProjectSubcontractorsTable
          subcontractorId={subcontractorId}
          type="current"
          title="Current Projects"
          data={data}
          onProjectClick={handleProjectClick}
        />

        <SCProjectSubcontractorsTable
          onProjectClick={handleProjectClick}
          data={data}
          subcontractorId={subcontractorId}
          type="completed"
          title="Completed projects"
        />
      </BSpace>
    </BasicWrapper>
  );
};
const SCProjectsWrapper: FC = () => {
  const subcontractorId = useSubcontractorId();
  return (
    <BasicWrapper scrollable>
      <SCProjects subcontractorId={subcontractorId} />
    </BasicWrapper>
  );
};
export default SCProjectsWrapper;

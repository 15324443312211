import type {
  inspection_instance_checklist_item_insert_input,
  inspection_instance_insert_input,
} from "src/common/types/generated/relay/types";
import { auth } from "src/common/functions/firebase";
import useInsertInspectionInstanceAndTemplate from "./useInsertInspectionInstanceAndTemplate";
import { ConnectionHandler } from "relay-runtime";
import * as uuid from "uuid";
import { InspectionTemplateType } from "../components/tables/InspectionTemplateTable";

const useCreateInspectionInstanceFromTemplate = (projectId: string) => {
  const [add, isAdding] = useInsertInspectionInstanceAndTemplate();
  const create = async (data: InspectionTemplateType) => {
    const typeMap: { [type: string]: string } = {};
    const object: inspection_instance_insert_input = {
      name: {
        data: {
          original: data.name.en + " _copy",
          en: data.name.en + " _copy",
        },
      },
      created_by_uid: auth.currentUser?.uid,
      image_required: data.image_required,
      model_number:
        data.model_number && data.model_number.trim()
          ? data.model_number.trim()
          : null,
      is_multi_inspection: data.is_multi_inspection,
      allow_photo_to_checklist_item: data.allow_photo_to_checklist_item,
      model_number_required: data.model_number_required,
      project_id: projectId,
      inspection_template_id: data.pk,
      inspection_labels: {
        data: data.inspection_labels.map((lb) => ({ label_id: lb.label_id })),
      },
      inspection_instance_checklist_items: {
        data: data.inspection_template_checklist_items.map((item) => {
          let typeToInpsert: inspection_instance_checklist_item_insert_input =
            {};
          if (item.inspection_checklist_item_type) {
            if (typeMap[item.inspection_checklist_item_type.text.en]) {
              typeToInpsert = {
                inspection_checklist_item_type_id:
                  typeMap[item.inspection_checklist_item_type.text.en],
              };
            } else {
              typeMap[item.inspection_checklist_item_type.text.en] = uuid.v4();
              typeToInpsert = {
                inspection_checklist_item_type: {
                  data: {
                    id: typeMap[item.inspection_checklist_item_type.text.en],
                    text: {
                      data: {
                        original: item.inspection_checklist_item_type.text.en,
                        en: item.inspection_checklist_item_type.text.en,
                      },
                    },
                  },
                },
              };
            }
          }
          return {
            correct_answer: item.correct_answer,
            description: {
              data: { original: item.description.en, en: item.description.en },
            },
            require_photo: item.require_photo,
            item_id: uuid.v4(),
            notify_on_deficient: item.notify_on_deficient,
            sort_index: item.sort_index,
            show_na: item.show_na,
            inspection_template_checklist_item_id: item.pk,
            ...typeToInpsert,
          };
        }),
      },
    };
    await add({
      variables: { instanceObjects: [object], templateObjects: [] },
      updater: (store) => {
        const conn = ConnectionHandler.getConnection(
          store.getRoot(),
          "InspectionInstanceTable_inspection_instance_connection",
        );
        const optionsConn = ConnectionHandler.getConnection(
          store.getRoot(),
          "GCInspectionsQuery_inspection_instance_connection",
        );
        const returning = store
          .getRootField("insert_inspection_instance")
          .getLinkedRecords("returning");
        returning.map((insp) => {
          if (conn) {
            const edge = ConnectionHandler.createEdge(
              store,
              conn,
              insp,
              "edge",
            );
            ConnectionHandler.insertEdgeBefore(conn, edge);
          }
          if (optionsConn) {
            const edge = ConnectionHandler.createEdge(
              store,
              optionsConn,
              insp,
              "edge",
            );
            ConnectionHandler.insertEdgeBefore(optionsConn, edge);
          }
        });
      },
    });
  };
  return [create, isAdding] as const;
};
export default useCreateInspectionInstanceFromTemplate;

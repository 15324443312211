/**
 * @generated SignedSource<<6c71cdc9ec6adb3754f5054590459c46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_worker_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectWorkerTable_ArchiveWorkerMutation$variables = {
  _set?: project_worker_set_input | null | undefined;
  projectWorkerId: string;
};
export type ProjectWorkerTable_ArchiveWorkerMutation$data = {
  readonly update_project_worker_by_pk: {
    readonly archived_at: string | null | undefined;
    readonly archived_by_uid: string | null | undefined;
    readonly archived_by_user: {
      readonly name: string;
      readonly role: string;
    } | null | undefined;
    readonly pk: string;
  } | null | undefined;
};
export type ProjectWorkerTable_ArchiveWorkerMutation = {
  response: ProjectWorkerTable_ArchiveWorkerMutation$data;
  variables: ProjectWorkerTable_ArchiveWorkerMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectWorkerId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "projectWorkerId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived_at",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "archived_by_uid",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectWorkerTable_ArchiveWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_worker",
        "kind": "LinkedField",
        "name": "update_project_worker_by_pk",
        "plural": false,
        "selections": [
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "archived_by_user",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ProjectWorkerTable_ArchiveWorkerMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_worker",
        "kind": "LinkedField",
        "name": "update_project_worker_by_pk",
        "plural": false,
        "selections": [
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "archived_by_user",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "46697953096a9e89d192e5681cad9adb",
    "id": null,
    "metadata": {},
    "name": "ProjectWorkerTable_ArchiveWorkerMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectWorkerTable_ArchiveWorkerMutation(\n  $projectWorkerId: uuid!\n  $_set: project_worker_set_input\n) {\n  update_project_worker_by_pk(pk_columns: {id: $projectWorkerId}, _set: $_set) {\n    pk: id\n    archived_at\n    archived_by_uid\n    archived_by_user {\n      name\n      role\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b4cd3909e879033bfc4be1a372ada59";

export default node;

/**
 * @generated SignedSource<<0347217f8774da5f8ceade9d2209655a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SCWorkers_Update_Project_Worker_Mutation$variables = {
  projectIds: ReadonlyArray<string>;
  subId: string;
  workerIds: ReadonlyArray<string>;
};
export type SCWorkers_Update_Project_Worker_Mutation$data = {
  readonly u1: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly u2: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type SCWorkers_Update_Project_Worker_Mutation = {
  response: SCWorkers_Update_Project_Worker_Mutation$data;
  variables: SCWorkers_Update_Project_Worker_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerIds"
},
v3 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_in",
      "variableName": "projectIds"
    }
  ],
  "kind": "ObjectValue",
  "name": "project_id"
},
v4 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_in",
      "variableName": "workerIds"
    }
  ],
  "kind": "ObjectValue",
  "name": "worker_id"
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "affected_rows",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": "u1",
    "args": [
      {
        "kind": "Literal",
        "name": "_set",
        "value": {
          "is_last": false
        }
      },
      {
        "fields": [
          (v3/*: any*/),
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_neq",
                "variableName": "subId"
              }
            ],
            "kind": "ObjectValue",
            "name": "subcontractor_id"
          },
          (v4/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_worker_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_worker",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  },
  {
    "alias": "u2",
    "args": [
      {
        "kind": "Literal",
        "name": "_set",
        "value": {
          "is_last": true
        }
      },
      {
        "fields": [
          (v3/*: any*/),
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_eq",
                "variableName": "subId"
              }
            ],
            "kind": "ObjectValue",
            "name": "subcontractor_id"
          },
          (v4/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "where"
      }
    ],
    "concreteType": "project_worker_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_worker",
    "plural": false,
    "selections": (v5/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SCWorkers_Update_Project_Worker_Mutation",
    "selections": (v6/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SCWorkers_Update_Project_Worker_Mutation",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "8121935a29b17fd81af37a18a9d0b701",
    "id": null,
    "metadata": {},
    "name": "SCWorkers_Update_Project_Worker_Mutation",
    "operationKind": "mutation",
    "text": "mutation SCWorkers_Update_Project_Worker_Mutation(\n  $projectIds: [uuid!]!\n  $workerIds: [uuid!]!\n  $subId: uuid!\n) {\n  u1: update_project_worker(where: {worker_id: {_in: $workerIds}, project_id: {_in: $projectIds}, subcontractor_id: {_neq: $subId}}, _set: {is_last: false}) {\n    affected_rows\n  }\n  u2: update_project_worker(where: {project_id: {_in: $projectIds}, worker_id: {_in: $workerIds}, subcontractor_id: {_eq: $subId}}, _set: {is_last: true}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "75c2028b3d79a9ec0142c79554841acf";

export default node;

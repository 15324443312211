import {
  GetObservationByPkDocument,
  GetObservationByPkQuery,
  GetObservationByPkQueryVariables,
  Lang_Code_Enum,
  Observation_Set_Input,
  useUpdateObservationByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import handleRequestError from "src/utility-features/error-handling/handleRequestError";
import useAuthUser from "src/common/hooks/useAuthUser";
import { produce } from "immer";
import createObservationPatch from "./createObservationPatch";

export interface UpdateFunctionInputProps {
  observation: NonNullable<GetObservationByPkQuery["observation_by_pk"]>;
  set: Omit<
    Observation_Set_Input,
    "id" | "created_at" | "location_id" | "description_id" | "created_by_uid"
  >;
  editComment?: string;
}
const useUpdateObservationWithCache = () => {
  const [updateObservation, { loading: updating, client }] =
    useUpdateObservationByPkMutation();

  const authUser = useAuthUser();
  const update = async ({
    observation,
    set,
    editComment,
  }: UpdateFunctionInputProps) => {
    const isSubmitted =
      observation.status !== "draft" &&
      (!observation.observation_inspection ||
        !!observation.observation_inspection.submitted_on);
    try {
      // @ts-ignore TODO FIX THIS TYPE
      const newObs: typeof observation = { ...observation, ...set };
      const patch = createObservationPatch(newObs, observation);

      await updateObservation({
        variables: {
          id: observation.id,
          _set: set,
          editHistoryObjects:
            isSubmitted && editComment
              ? [
                  {
                    observation_id: observation.id,
                    edit_type: "edited",
                    edited_by_uid: authUser.uid,
                    patch,
                    comment: {
                      data: {
                        original: editComment,
                        en: editComment,
                        lang: Lang_Code_Enum.En,
                      },
                    },
                  },
                ]
              : [],
        },
        optimisticResponse: {
          update_observation_by_pk: newObs,
          insert_observation_edit_history: { affected_rows: 1, returning: [] },
        },
        update: (cache, returningData) => {
          const returningEdits =
            returningData.data?.insert_observation_edit_history?.returning;
          if (!returningEdits) throw new Error("No returning edits");
          cache.modify<typeof observation>({
            id: cache.identify(observation),
            fields: {
              observation_edits(existingEdits, { toReference }) {
                return [
                  ...existingEdits,
                  ...returningEdits.map((edit) => toReference(edit)!),
                ];
              },
            },
          });
        },
      });
      return true;
    } catch (err) {
      handleRequestError(err, {
        errorTitle: `Observation could not be updated`,
      });
      // throw err;
    }
  };
  return [update, updating] as const;
};
export default useUpdateObservationWithCache;

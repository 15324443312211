import React, { useRef, useState } from "react";
import InspectionInstanceTable, {
  InspectionInstanceType,
} from "./tables/InspectionInstanceTable";
import { inspection_instance_bool_exp } from "src/common/types/generated/relay/types";
import {
  IconBackhoe,
  IconBooks,
  IconBuilding,
  IconHeartHandshake,
  IconPlus,
} from "@tabler/icons";
import CreateInspectionModal from "./modals/CreateInspectionModal";
import { OptionType, NotifyUserType } from "../utils/siteInspectionTypes";
import InspectionInstance, {
  setInstanceChecklistItems,
} from "./item-modals/InspectionInstance";
import InspectionTemplateTable, {
  InspectionTemplateType,
} from "./tables/InspectionTemplateTable";
import Button from "src/common/components/general/Button";
import InspectionTemplate from "./item-modals/InspectionTemplate";
import { useSearchParams } from "react-router-dom";
import Icon from "src/common/components/general/Icon";
import { Modal } from "antd";
import useInsertInspectionInstanceAndTemplate from "../utils/useInsertInspectionInstanceAndTemplate";
import { inspection_template_insert_input } from "src/common/types/generated/relay/types";
import getToInsertTemplateObjectFromInstance from "../utils/getToInsertTemplateObjectFromInstance";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import InspectionChecklistItemTypeTable from "./tables/InspectionChecklistItemTypeTable";
import noop from "src/common/functions/noop";
interface ManageInspectionsProps {
  where: inspection_instance_bool_exp;
  projectId: string;
  gcId?: string;
  labelOptions: Array<OptionType>;
  generalContractorId?: string;
  inspectionQROptions: Array<OptionType>;
  projectEmployees: Array<NotifyUserType>;
}
const ManageInspections: React.FC<ManageInspectionsProps> = ({
  where,
  projectId,
  inspectionQROptions,
  labelOptions,
  gcId,
  generalContractorId,
  projectEmployees,
}) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAddFromProjectModal, setOpenAddFromProjectModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [insertInspections, isInserting] =
    useInsertInspectionInstanceAndTemplate();
  const tableType = searchParams.get("table-type");
  const [inspectionsType, setInspectionsType] = useState<
    "project" | "company" | "community"
  >(
    tableType === "company" || tableType === "community"
      ? tableType
      : "project",
  );
  const templateRef = useRef<DataScrollTableRef>(null);
  const [selectedInspection, setSelectedInspection] = useState<
    InspectionInstanceType | undefined
  >();
  const [selectedTemplate, setSelectedTemplate] = useState<
    InspectionTemplateType | undefined
  >();
  return (
    <>
      {selectedTemplate && (
        <InspectionTemplate
          data={selectedTemplate}
          visible={!!selectedTemplate}
          projectId={projectId}
          onClose={() => setSelectedTemplate(undefined)}
          labelOptions={labelOptions}
        />
      )}
      {selectedInspection && (
        <InspectionInstance
          generalContractorId={generalContractorId}
          onOpenNew={(insp) => setSelectedInspection(insp)}
          projectId={projectId}
          inspectionQROptions={inspectionQROptions}
          data={selectedInspection}
          projectEmployees={projectEmployees}
          visible={!!selectedInspection}
          labelOptions={labelOptions}
          onClose={() => setSelectedInspection(undefined)}
        />
      )}
      <CreateInspectionModal
        projectId={projectId}
        labelOptions={labelOptions}
        inspectionQROptions={inspectionQROptions}
        generalContractorId={generalContractorId}
        projectEmployees={projectEmployees}
        visible={openAddModal}
        templateType={
          inspectionsType === "project" ? undefined : inspectionsType
        }
        onCancel={() => setOpenAddModal(false)}
        onFinish={() => setOpenAddModal(false)}
      />
      <Modal
        open={openAddFromProjectModal}
        footer={<></>}
        width={700}
        destroyOnClose
        onCancel={() => setOpenAddFromProjectModal(false)}
      >
        <InspectionInstanceTable
          where={{
            project: {
              general_contractor: { projects: { id: { _eq: projectId } } },
            },
            deleted_at: { _is_null: true },
          }}
          hideFiltersAndSort
          title="All Project’s Inspections"
          projectId={projectId}
          labelOptions={labelOptions}
          qrOptions={inspectionQROptions}
          excludedKeys={[
            "action",
            "completed",
            "created_by",
            "date",
            "model",
            "labels",
            "last_completed",
            "qrs",
          ]}
          expandedRowRender={(row) => {
            const currentChecklistItems = setInstanceChecklistItems(row);
            return (
              <>
                <div className="font-accent my-1">
                  Checklist Items (
                  {row.inspection_instance_checklist_items.length})
                </div>
                {Object.entries(currentChecklistItems).map(
                  ([type, checklistItemTypeObject]) => {
                    return (
                      <div key={type}>
                        <InspectionChecklistItemTypeTable
                          dataSource={Object.values(checklistItemTypeObject)}
                          type={type}
                          allowChecklistItemPhoto={
                            row.allow_photo_to_checklist_item
                          }
                          projectEmployees={projectEmployees}
                          editing={false}
                          onDeleteItem={() => {}}
                          onEditOrEditDoneItem={() => {}}
                          onAddNewItem={() => {}}
                          onMoveRow={() => {}}
                        />
                      </div>
                    );
                  },
                )}
              </>
            );
          }}
          onInspectionsSelected={{
            label: "Add to Company Library",
            onClick: async (selectedInspections) => {
              const objects: inspection_template_insert_input[] = [];
              selectedInspections.forEach((insp) => {
                objects.push({
                  ...getToInsertTemplateObjectFromInstance(
                    {
                      ...insp,
                      name: insp.name.en,
                      labels: insp.inspection_labels.map((p) => p.label_id),
                    },
                    setInstanceChecklistItems(insp),
                  ),
                  general_contractor_id: generalContractorId,
                });
              });
              await insertInspections({
                variables: { instanceObjects: [], templateObjects: objects },
              });

              templateRef.current && templateRef.current.refetch();
              setOpenAddFromProjectModal(false);
            },
            loading: isInserting,
          }}
        />
      </Modal>
      {inspectionsType === "project" ? (
        <InspectionInstanceTable
          where={where}
          projectId={projectId}
          excludedKeys={["project"]}
          qrOptions={inspectionQROptions}
          labelOptions={labelOptions}
          OnRowItemClick={(item) => {
            setSelectedInspection(item);
          }}
          headerComponent={
            <Button
              label={"Inspection Library"}
              onClick={() => {
                setSearchParams({ ["table-type"]: "company" });
                setInspectionsType((i) => "company");
              }}
              icon={IconBooks}
            />
          }
          topBarButtons={[
            {
              label: "Create",
              icon: IconPlus,
              onClick: () => setOpenAddModal(true),
            },
          ]}
        />
      ) : (
        <InspectionTemplateTable
          projectId={projectId}
          ref={templateRef}
          labelOptions={labelOptions}
          title={
            inspectionsType === "company"
              ? "Available Company Inspections"
              : " Available Community Inspections "
          }
          where={{
            deleted_at: { _is_null: true },
            ...(inspectionsType === "company"
              ? { general_contractor_id: { _eq: gcId } }
              : { general_contractor_id: { _is_null: true } }),
          }}
          excludedKeys={
            inspectionsType === "community" ? ["action"] : undefined
          }
          titleIcon={
            inspectionsType === "company"
              ? { icon: IconBuilding }
              : { icon: IconHeartHandshake }
          }
          headerComponent={
            <div className="flex gap-1">
              <Button
                label={
                  inspectionsType === "community"
                    ? "Company Library"
                    : "Community Library"
                }
                icon={
                  inspectionsType === "community"
                    ? IconBuilding
                    : IconHeartHandshake
                }
                secondary
                onClick={() =>
                  setInspectionsType((i) => {
                    const toReturn =
                      i === "community" ? "company" : "community";
                    setSearchParams({ ["table-type"]: toReturn });

                    return toReturn;
                  })
                }
              ></Button>
              <Button
                label={"Back to Project"}
                onClick={() => {
                  setSearchParams({ ["table-type"]: "project" });
                  setInspectionsType((i) => "project");
                }}
                icon={IconBackhoe}
              />
            </div>
          }
          topBarButtons={[
            {
              label: "Add New",
              icon: IconPlus,
              onClick: () => setOpenAddModal(true),
            },
            ...(inspectionsType === "company"
              ? [
                  {
                    label: "Add from Project",
                    icon: IconPlus,
                    secondary: true,
                    onClick: () => setOpenAddFromProjectModal(true),
                  },
                ]
              : []),
          ]}
          OnRowItemClick={(insp) => {
            setSelectedTemplate(insp);
          }}
        />
      )}
    </>
  );
};
export default ManageInspections;

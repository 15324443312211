import { useGetInspectionReportPdfMutation } from "src/common/types/generated/apollo/graphQLTypes";
import * as React from "react";
import { message, Menu } from "antd";
import downloadFromUrl from "src/common/functions/downloadFromUrl";

interface InspectionReportPdfDownloadProps {
  inspectionId: string;
}
const InspectionReportPdfDownload = (
  props: InspectionReportPdfDownloadProps,
) => {
  const [generatePdf, { loading: generatingPdf }] =
    useGetInspectionReportPdfMutation();

  return (
    <div
      className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
      onClick={async (e) => {
        if (typeof props.inspectionId !== "string") return;
        e.stopPropagation();
        message.loading({
          content: "Download in progress",
          duration: 0,
        });

        try {
          const { data: download } = await generatePdf({
            variables: {
              input: {
                inspectionId: props.inspectionId,
              },
            },
          });

          if (download?.generateInspectionReportPdf) {
            downloadFromUrl(download.generateInspectionReportPdf);
          }
          message.destroy();
        } catch (e) {
          if (e instanceof Error) {
            message.error(e.message);
          }
        }
      }}
    >
      Download Inspection as PDF
    </div>
  );
};

export default InspectionReportPdfDownload;

/**
 * @generated SignedSource<<e20a51eba98f7eda8b63b1fa70ea378b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { user_set_input, worker_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateWorkerAndUserMutation$variables = {
  user_set: user_set_input;
  workerId: string;
  worker_set: worker_set_input;
};
export type UpdateWorkerAndUserMutation$data = {
  readonly update_user: {
    readonly affected_rows: number;
  } | null | undefined;
  readonly update_worker_by_pk: {
    readonly uid: string;
  } | null | undefined;
};
export type UpdateWorkerAndUserMutation = {
  response: UpdateWorkerAndUserMutation$data;
  variables: UpdateWorkerAndUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "user_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "worker_set"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "_set",
      "variableName": "user_set"
    },
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_eq",
              "variableName": "workerId"
            }
          ],
          "kind": "ObjectValue",
          "name": "id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "user_mutation_response",
  "kind": "LinkedField",
  "name": "update_user",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affected_rows",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "worker_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "uid",
        "variableName": "workerId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateWorkerAndUserMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "worker",
        "kind": "LinkedField",
        "name": "update_worker_by_pk",
        "plural": false,
        "selections": [
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateWorkerAndUserMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "worker",
        "kind": "LinkedField",
        "name": "update_worker_by_pk",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d3eb6f27ad42562568f7aab7e530eb3",
    "id": null,
    "metadata": {},
    "name": "UpdateWorkerAndUserMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateWorkerAndUserMutation(\n  $worker_set: worker_set_input!\n  $workerId: uuid!\n  $user_set: user_set_input!\n) {\n  update_user(where: {id: {_eq: $workerId}}, _set: $user_set) {\n    affected_rows\n  }\n  update_worker_by_pk(pk_columns: {uid: $workerId}, _set: $worker_set) {\n    uid\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1aef5064097454a075b4a5687afc716d";

export default node;

import { Card, Divider, Empty } from "antd";

export interface ChartWrapperCardProps {
  title?: React.ReactNode;
  extraTop?: React.ReactNode;
  extraBottom?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  loading?: boolean;
}

const ChartWrapperCard: React.FC<ChartWrapperCardProps> = (props) => {
  return (
    <Card
      title={props.title}
      className={props.className}
      loading={props.loading}
    >
      {!!props.extraTop && (
        <>
          {props.extraTop}
          <Divider />
        </>
      )}

      {props.children ?? <Empty />}
      {!!props.extraBottom && (
        <>
          <Divider />
          {props.extraBottom}
        </>
      )}
    </Card>
  );
};

export default ChartWrapperCard;

/**
 * @generated SignedSource<<94e7aa75e666c66e4e612dbd65550ad2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_certification_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyMultipleCertsModal_InsertCertsMutation$variables = {
  objects: ReadonlyArray<worker_certification_insert_input>;
};
export type VerifyMultipleCertsModal_InsertCertsMutation$data = {
  readonly insert_worker_certification: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type VerifyMultipleCertsModal_InsertCertsMutation = {
  response: VerifyMultipleCertsModal_InsertCertsMutation$data;
  variables: VerifyMultipleCertsModal_InsertCertsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyMultipleCertsModal_InsertCertsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "worker_certification_mutation_response",
        "kind": "LinkedField",
        "name": "insert_worker_certification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyMultipleCertsModal_InsertCertsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "worker_certification_mutation_response",
        "kind": "LinkedField",
        "name": "insert_worker_certification",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a917f9a751faa145352e83405576bfa7",
    "id": null,
    "metadata": {},
    "name": "VerifyMultipleCertsModal_InsertCertsMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyMultipleCertsModal_InsertCertsMutation(\n  $objects: [worker_certification_insert_input!]!\n) {\n  insert_worker_certification(objects: $objects) {\n    affected_rows\n    returning {\n      id\n      pk: id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a08d23ca103c08350b99eda3ac156230";

export default node;

import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import GCProjectObservations from "./GCProjectObservations";
import GCObservation from "./GCObservation";
import GCObservationNavigation from "./GCObservationNavigation";
import GCProjectAudits from "./GCProjectAudits";
import ObservationInspectionDetail from "../../components/ObservationInspectionDetail";
import GCProjObsDashboard from "./dashboard/GCProjObsDashboard";

const gcObservationRoute = {
  element: <GCObservationNavigation />,
  children: [
    { path: "", element: <Navigate to={"observations"} /> },
    {
      path: "observations",
      element: <GCProjectObservations />,
    },
    {
      path: "observations/:observationId",
      element: <GCObservation />,
    },
    {
      path: "audits",
      element: <GCProjectAudits />,
    },
    {
      path: "audits/:auditId",
      element: <ObservationInspectionDetail />,
    },
    {
      path: "charts",
      element: <GCProjObsDashboard />,
    },
  ],
};
export default gcObservationRoute;

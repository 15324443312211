/**
 * @generated SignedSource<<fce05a113124537c528a933bff93fb1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { certificates_to_verify_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type VerifyMultipleCertsModal_UpdateMutation$variables = {
  _set?: certificates_to_verify_set_input | null | undefined;
  certId: string;
};
export type VerifyMultipleCertsModal_UpdateMutation$data = {
  readonly update_certificates_to_verify_by_pk: {
    readonly id: string;
    readonly pk: string;
    readonly status: string;
    readonly verified_at: string | null | undefined;
  } | null | undefined;
};
export type VerifyMultipleCertsModal_UpdateMutation = {
  response: VerifyMultipleCertsModal_UpdateMutation$data;
  variables: VerifyMultipleCertsModal_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "certId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "certId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "verified_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyMultipleCertsModal_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "certificates_to_verify",
        "kind": "LinkedField",
        "name": "update_certificates_to_verify_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "VerifyMultipleCertsModal_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "certificates_to_verify",
        "kind": "LinkedField",
        "name": "update_certificates_to_verify_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "438db13a780fa72595ccdef096f0c1f2",
    "id": null,
    "metadata": {},
    "name": "VerifyMultipleCertsModal_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation VerifyMultipleCertsModal_UpdateMutation(\n  $certId: uuid!\n  $_set: certificates_to_verify_set_input\n) {\n  update_certificates_to_verify_by_pk(pk_columns: {id: $certId}, _set: $_set) {\n    id\n    pk: id\n    verified_at\n    status\n  }\n}\n"
  }
};
})();

(node as any).hash = "caf0acbe0d938f767e9cb20c330d1553";

export default node;

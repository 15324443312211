import React from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { useUpdateGcBuEmployeesMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCHierarchyTeam from "../../component/GCHierarchyTeam";
import useAuthUser from "src/common/hooks/useAuthUser";
import withBusinessUnitId, {
  BusinessUnitProps,
} from "../../utils/withBusinessUnitId";

const GCBusinessUnitTeam: React.FC<BusinessUnitProps> = ({
  businessUnitId,
}) => {
  const { userData } = useUserData();
  const employee = userData.employee;
  if (!employee) throw new Error("Loggedā in user is not employee");

  const [updateBusinessUnitEmp] = useUpdateGcBuEmployeesMutation();
  const authUser = useAuthUser();

  return (
    <GCHierarchyTeam
      {...{
        getRemainingGcTeamVariables: {
          projectsToInviteWhere: {
            gc_business_unit_id: { _eq: businessUnitId },
          },
          gcEmployeeWhere: {
            gc_business_units: {
              gc_business_unit_id: { _eq: businessUnitId },
            },
            general_contractor_id: { _eq: employee.general_contractor_id },
          },
        },
        title: "BusinessUnit",
        onInviteSuccess: async (userIds) => {
          await updateBusinessUnitEmp({
            variables: {
              deleteProjEmpWhere: { id: { _is_null: true } },
              deleteDivEmpWhere: { id: { _is_null: true } },
              deleteOfficeEmpWhere: {
                gc_office: { gc_business_unit_id: { _eq: businessUnitId } },
                user_id: { _in: userIds },
              },
              deleteBuEmpWhere: { id: { _is_null: true } },
              insertBuEmpObjs: userIds.map((u) => ({
                gc_business_unit_id: businessUnitId,
                created_by_uid: authUser.uid,
                user_id: u,
              })),
            },
          });
        },
        onRemoveFromTeam: async (userId, projects) => {
          await updateBusinessUnitEmp({
            variables: {
              insertBuEmpObjs: [],
              deleteBuEmpWhere: {
                gc_business_unit_id: { _eq: businessUnitId },
                user_id: { _eq: userId },
              },
              deleteDivEmpWhere: { id: { _is_null: true } },
              deleteOfficeEmpWhere: { id: { _is_null: true } },
              deleteProjEmpWhere: {
                project_id: { _in: projects },
                employee_id: { _eq: userId },
                direct_project_assign: { _eq: false },
              },
            },
          });
        },
        excludeEmployeeWhere: {
          _or: [
            { is_corporate_admin: { _eq: true } },
            {
              gc_business_units: {
                gc_business_unit_id: { _eq: businessUnitId },
              },
            },
            {
              gc_divisions: {
                gc_division: {
                  gc_business_units: { id: { _eq: businessUnitId } },
                },
              },
            },
          ],
        },
      }}
    />
  );
};

export default withCustomSuspense(withBusinessUnitId(GCBusinessUnitTeam));

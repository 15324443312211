import React, { useState } from "react";
import SiteFeatureStepsUIImages from "src/common/components/general/images/SiteFeatureStepsUIImages";
import { SiteInspectionStepProps } from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";

import uploadImage from "src/common/functions/upload-utility/uploadImage";

export interface SiteInspectionWorkerStepsImagesProps
  extends SiteInspectionStepProps {}

const SiteInspectionWorkerStepsImages: React.FC<
  SiteInspectionWorkerStepsImagesProps
> = (props) => {
  const [loading, setLoading] = useState(false);
  const workerContext = useSiteInspectionUser();
  const userImages = workerContext.user?.images;
  const onNext = async (images: typeof userImages) => {
    const uploadedImages = workerContext.user?.uploadedImages || [];
    const uploadPromises = (images || [])
      .filter(
        (img) => !uploadedImages.find((u) => u.id === img.id) || img.edited,
      )
      .map(async (image) => {
        try {
          if (image.url) {
            const uploadedImg = await uploadImage(image.url);
            if (uploadedImg) {
              const replacingIndex = uploadedImages.findIndex(
                (img) => img.id === image.id,
              );
              if (replacingIndex === -1) {
                uploadedImages.push({ ...uploadedImg, id: image.id });
              } else {
                uploadedImages[replacingIndex] = {
                  ...uploadedImg,
                  id: image.id,
                };
              }
            }
          }
        } catch (err) {
          console.log(err);
        }
      });
    setLoading(true);
    await Promise.allSettled(uploadPromises)
      .then((results) => {
        results.map((result) => {
          console.log(result.status);
        });
      })
      .catch(console.error);
    workerContext.updateUser((u) =>
      u
        ? {
            ...u,
            uploadedImages,
            images: (images || []).map((img) => ({
              ...img,
              edited: false,
            })),
          }
        : u,
    );
    setLoading(false);
    props.onNext();
  };
  return (
    <SiteFeatureStepsUIImages
      loading={loading}
      required={workerContext.user?.inspection?.image_required || false}
      onBack={props.onBack}
      images={workerContext.user?.images ?? []}
      onNext={onNext}
    />
  );
};

export default SiteInspectionWorkerStepsImages;

/**
 * @generated SignedSource<<ef41e905a0cc7663c0e79adfa5aec6c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { work_above_elevation_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WorkAboveElevationTable_ElevationUpdate_Mutation$variables = {
  _set: work_above_elevation_set_input;
  id: string;
};
export type WorkAboveElevationTable_ElevationUpdate_Mutation$data = {
  readonly update_work_above_elevation_by_pk: {
    readonly completed_at: string | null | undefined;
    readonly created_at: string;
    readonly created_by_user: {
      readonly employee: {
        readonly employee_title: {
          readonly name: {
            readonly en: string;
          };
        } | null | undefined;
      } | null | undefined;
      readonly name: string;
    };
    readonly id: string;
    readonly name: {
      readonly en: string;
      readonly id: string;
    };
    readonly name_id: string;
    readonly pk: string;
    readonly type_id: string;
    readonly work_above_elevation_type: {
      readonly name: {
        readonly en: string;
      };
    };
    readonly work_above_floors: ReadonlyArray<{
      readonly completed_at: string | null | undefined;
      readonly has_netting: boolean;
      readonly id: string;
      readonly name: {
        readonly en: string;
        readonly id: string;
      };
      readonly name_id: string;
      readonly order: number;
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type WorkAboveElevationTable_ElevationUpdate_Mutation = {
  response: WorkAboveElevationTable_ElevationUpdate_Mutation$data;
  variables: WorkAboveElevationTable_ElevationUpdate_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v5 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v7/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v7/*: any*/)
    ],
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_at",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "has_netting",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v18 = [
  (v8/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkAboveElevationTable_ElevationUpdate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "work_above_elevation",
        "kind": "LinkedField",
        "name": "update_work_above_elevation_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "created_by_user",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "employee",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "employee_title",
                    "kind": "LinkedField",
                    "name": "employee_title",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "work_above_elevation_type",
            "kind": "LinkedField",
            "name": "work_above_elevation_type",
            "plural": false,
            "selections": (v11/*: any*/),
            "storageKey": null
          },
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "work_above_floor",
            "kind": "LinkedField",
            "name": "work_above_floors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v13/*: any*/),
              (v6/*: any*/),
              (v5/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WorkAboveElevationTable_ElevationUpdate_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "work_above_elevation",
        "kind": "LinkedField",
        "name": "update_work_above_elevation_by_pk",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v6/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "created_by_user",
            "plural": false,
            "selections": [
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "general_contractor_employee",
                "kind": "LinkedField",
                "name": "employee",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "employee_title",
                    "kind": "LinkedField",
                    "name": "employee_title",
                    "plural": false,
                    "selections": (v18/*: any*/),
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "work_above_elevation_type",
            "kind": "LinkedField",
            "name": "work_above_elevation_type",
            "plural": false,
            "selections": (v18/*: any*/),
            "storageKey": null
          },
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "work_above_floor",
            "kind": "LinkedField",
            "name": "work_above_floors",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v13/*: any*/),
              (v6/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "42d36026b8b87ecd90818b9d61870cf0",
    "id": null,
    "metadata": {},
    "name": "WorkAboveElevationTable_ElevationUpdate_Mutation",
    "operationKind": "mutation",
    "text": "mutation WorkAboveElevationTable_ElevationUpdate_Mutation(\n  $id: uuid!\n  $_set: work_above_elevation_set_input!\n) {\n  update_work_above_elevation_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n    created_at\n    pk: id\n    name_id\n    name {\n      en\n      id\n    }\n    type_id\n    created_by_user {\n      name\n      employee {\n        employee_title {\n          name {\n            en\n            id\n          }\n          id\n        }\n        id\n      }\n      id\n    }\n    work_above_elevation_type {\n      name {\n        en\n        id\n      }\n      id\n    }\n    completed_at\n    work_above_floors {\n      id\n      order\n      name_id\n      pk: id\n      completed_at\n      has_netting\n      name {\n        id\n        en\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "249ffdaf51a0dee1ace1059d15878843";

export default node;

/**
 * @generated SignedSource<<83d569eae88acaffec3bd4d9ad3f2382>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkerCertificationTable_worker_certifications$data = {
  readonly worker_certification_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly certification: {
          readonly name: string;
        };
        readonly created_at: string;
        readonly expires_on: string | null | undefined;
        readonly images: ReadonlyArray<{
          readonly url: string;
        }>;
        readonly issued_on: string | null | undefined;
        readonly pk: string;
        readonly privacy_setting?: {
          readonly employer: boolean;
          readonly id: string;
          readonly manager: boolean;
          readonly supervisor: boolean;
        } | null | undefined;
        readonly privacy_setting_id: string | null | undefined;
      };
    }>;
  };
  readonly " $fragmentType": "WorkerCertificationTable_worker_certifications";
};
export type WorkerCertificationTable_worker_certifications$key = {
  readonly " $data"?: WorkerCertificationTable_worker_certifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkerCertificationTable_worker_certifications">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "worker_certification_connection"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeSettings"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./WorkerCertificationTableRefetchableQuery.graphql')
    }
  },
  "name": "WorkerCertificationTable_worker_certifications",
  "selections": [
    {
      "alias": "worker_certification_connection",
      "args": [
        {
          "kind": "Variable",
          "name": "order_by",
          "variableName": "order_by"
        },
        {
          "kind": "Variable",
          "name": "where",
          "variableName": "where"
        }
      ],
      "concreteType": "worker_certificationConnection",
      "kind": "LinkedField",
      "name": "__WorkerCertificationTable_worker_certification_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "worker_certificationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "worker_certification",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": "pk",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id_pk",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expires_on",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "issued_on",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "privacy_setting_id",
                  "storageKey": null
                },
                {
                  "condition": "includeSettings",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "privacy_setting",
                      "kind": "LinkedField",
                      "name": "privacy_setting",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "manager",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "employer",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "supervisor",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ]
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "certification",
                  "kind": "LinkedField",
                  "name": "certification",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "order_by",
                      "value": {
                        "description": "desc_nulls_last"
                      }
                    }
                  ],
                  "concreteType": "image",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "storageKey": "images(order_by:{\"description\":\"desc_nulls_last\"})"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "02fb2bb7403a1a6d555aee43e049253e";

export default node;

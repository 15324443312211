import { message } from "antd";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import GetFullID from "src/common/functions/GetFullId";
import {
  UpdatePrivacySettingsByPkMutation,
} from "src/common/types/generated/relay/UpdatePrivacySettingsByPkMutation.graphql";
import type { privacy_setting_set_input } from "src/common/types/generated/relay/types";
import updatePrivacySettingsByPkMutation from "../mutations/UpdatePrivacySettingsByPk";

const useUpdatePermission = () => {
  const [updatePrivacySetting, isUpdating] =
    useAsyncMutation<UpdatePrivacySettingsByPkMutation>(
      updatePrivacySettingsByPkMutation,
    );
  const updatePermission = async (
    val: boolean,
    set: privacy_setting_set_input,
    settingId: string | null | undefined,
    setting:
      | { manager: boolean; employer: boolean; supervisor: boolean }
      | null
      | undefined,
  ) => {
    if (setting && settingId)
      await updatePrivacySetting({
        variables: {
          id: settingId,
          set,
        },
        optimisticResponse: {
          update_privacy_setting_by_pk: {
            id: GetFullID("privacy_setting", settingId),
            manager: setting.manager,
            employer: setting.employer,
            supervisor: setting.supervisor,
            ...set,
          },
        },
      })
        .then(() => {
          if (val) message.success("ON");
          else message.warning("OFF");
        })
        .catch((e) => {
          console.log(e);
          message.error("Error: Please try again or refresh page");
        });
    else {
      message.error("ERROR: No permission found to be set");
    }
  };
  return [updatePermission, isUpdating] as const;
};
export default useUpdatePermission;

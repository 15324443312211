// Utility function to convert file to Base64
export const readFileAsBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // Read the file as Data URL (Base64)
    reader.readAsDataURL(file);

    reader.onload = () => {
      // Resolve with the Base64 string
      resolve(reader.result as string);
    };

    reader.onerror = (error) => {
      // Reject with the error if something goes wrong
      reject(error);
    };
  });
};

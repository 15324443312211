import React, { useContext, useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import {
  NewDrugTest,
  OldDrugTest,
  SiteOrientationStepProps,
} from "../../../../../../../utils/siteOrientationTypes";
import siteOrientationInPersonWorkerContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import SiteOrientationDocumentCameraNewDocument from "../../../../../../../components/documentCamera/SiteOrientationDocumentCameraNewDocument";
import { Alert, message, notification } from "antd";
import Button from "src/common/components/general/Button";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import insertCertificatesToVerifyMutate from "src/common/api/relay/mutations/InsertCertificatesToVerify";
import uploadImage from "src/common/functions/upload-utility/uploadImage";
import { InsertCertificatesToVerifyMutation } from "src/common/types/generated/relay/InsertCertificatesToVerifyMutation.graphql";
import { useAcceptPrevDT } from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/drugTest/utils/useInsertDTResult";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";

import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerStepsDrugTestsProps
  extends SiteOrientationStepProps {}

const SiteOrientationInPersonWorkerStepsDrugTests: React.FC<
  SiteOrientationInPersonWorkerStepsDrugTestsProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const user = workerContext.orientee;
  const langStrings = useCurrentLangStrings();

  const [acceptPrevDT] = useAcceptPrevDT();

  const [insertDrugTest] = useAsyncMutation<InsertCertificatesToVerifyMutation>(
    insertCertificatesToVerifyMutate,
  );

  const [newDrugTest, setNewDrugTest] = useState<NewDrugTest>(
    user?.userType === "worker" && user?.drugTests?.new?.length
      ? user.drugTests.new[0]
      : {
          backImage: null,
          frontImage: null,
        },
  );

  const validOldDrugTests: OldDrugTest[] =
    (user && user.userType === "worker" && user.drugTests?.old) || [];

  const onEnter = async () => {
    // TODO update user info
    if (
      !newDrugTest.frontImage &&
      workerContext.registrationOptions?.drugTest?.completionRequired
    ) {
      message.error("add photos");
      return;
    }
    try {
      props.onNext();
      if (newDrugTest.frontImage) {
        const uploadFront = await uploadImage(newDrugTest.frontImage);
        const uploadBack = newDrugTest.backImage
          ? await uploadImage(newDrugTest.backImage)
          : undefined;
        await insertDrugTest({
          variables: {
            objects: [
              {
                project_id: workerContext.projectId,
                document: "drugtest",
                worker_id: user?.id,
                uploaded_by_uid: user?.id,
                front_image: {
                  data: {
                    ...uploadFront,
                    description: "Front Image",
                    created_by_user_id: user?.id,
                  },
                },
                back_image: !uploadBack
                  ? null
                  : {
                      data: {
                        ...uploadBack,
                        description: "Back Image",
                        created_by_user_id: user?.id,
                      },
                    },
              },
            ],
          },
        })
          .then(() => console.log("uploaded Drugtest"))
          .catch((e) => {
            console.log(e);
            message.error(e);
          });
        workerContext.updateOrientee((prevUserState) =>
          prevUserState?.userType === "worker"
            ? {
                ...prevUserState,
                drugTests: {
                  dtPassedProjects:
                    prevUserState.drugTests?.dtPassedProjects ?? {},
                  old: prevUserState.drugTests?.old || [],
                  new: [newDrugTest],
                },
              }
            : prevUserState,
        );
      }
    } catch (e) {
      notification.error({
        message: "Couldn't upload drugtest",
        description: e instanceof Error ? e.message : JSON.stringify(e),
      });
    }
  };
  if (!workerContext) throw new Error("context not found to be used");
  if (!user) throw new Error("User not found, please try again");
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      onBackButtonClick={props.onBack}
      nextButtonText={
        validOldDrugTests[0]
          ? langStrings.strings.uploadANewerDrugtest
          : langStrings.strings.next
      }
      nextButtonDisabled={
        (workerContext.registrationOptions.drugTest.completionRequired &&
          !newDrugTest.frontImage) ||
        (!!newDrugTest.frontImage && !newDrugTest.backImage)
      }
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.addPhotoOfYourDrugtest}
      >
        <div className={`flex flex-col gap-1`}>
          {/* {!!validOldDrugTests.length && (
            <div className={`flex flex-col gap-0.5`}>
              <p>Past drug tests:</p>
              {validOldDrugTests.map(
                (oldDrugTest, index) =>
                  dayjs.isDayjs(oldDrugTest.date) && (
                    <p key={oldDrugTest.id}>{getDateStr(oldDrugTest.date)}</p>
                  )
              )}
            </div>
          )} */}
          {validOldDrugTests[0] && (
            <div>
              <br />
              <br />
              <div className="flex items-center justify-center m-0">
                <Alert
                  message={
                    <div className="font-accent">
                      {langStrings.strings.youAlreadyHaveAValidDrugtest}
                      <br /> {langStrings.strings.dateOfValidTest}
                      {": "}
                      {validOldDrugTests[0].date.format("MMMM DD, YYYY")};
                      <div>
                        {langStrings.strings.ifYouDoNotWantToUploadNewDrugtest}
                      </div>
                    </div>
                  }
                  showIcon
                />
              </div>
              <br />
              <div className="flex items-center justify-center m-0">
                <Button
                  label={langStrings.strings.useExistingDrugtest}
                  onClick={() => {
                    if (user.id) {
                      const projectIdsToInsert = Object.keys(
                        user.projectsToRegister ?? {},
                      );
                      if (
                        !projectIdsToInsert.includes(workerContext.projectId)
                      ) {
                        projectIdsToInsert.push(workerContext.projectId);
                      }
                      acceptPrevDT(
                        projectIdsToInsert,
                        user.id,
                        validOldDrugTests[0].id,
                      );
                    }
                    props.onNext();
                  }}
                ></Button>
              </div>
              <br />
              <br />
            </div>
          )}
          <div className={`w-20 max-w-full`}>
            <SiteOrientationDocumentCameraNewDocument
              onBackImageChange={(image) => {
                setNewDrugTest((prevState) => ({
                  ...prevState,
                  backImage: image,
                }));
              }}
              ifFronRequireBack
              onFrontImageChange={(image) => {
                setNewDrugTest((prevState) => ({
                  ...prevState,
                  frontImage: image,
                }));
              }}
              backImageSrc={newDrugTest.backImage}
              frontImageSrc={newDrugTest.frontImage}
            />
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerStepsDrugTests;

import dayjs from "dayjs";
import { useState } from "react";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import { GetAdminObservationOptionsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { GCAdminSelectFilterProps } from "../../filter-inputs/GCAdminSelectFilter";
const useGetObservationFilters = (
  options: GetAdminObservationOptionsQuery | undefined,
) => {
  const [riskLevelFilter, handleRiskLevelChange, setRiskLevelFilter] =
    useSelectedIndicies<string>();
  const [categoriesFilter, handleCategoryChange, setCategoriesFilter] =
    useSelectedIndicies<string>();
  const [subCategoriesFilter, handleSubCategoryChange, setSubCategoriesFilter] =
    useSelectedIndicies<string>();
  const [companiesFilter, handleCompaniesChange, setCompaniesFilter] =
    useSelectedIndicies<string>();
  const [dateFilter, setDateFilter] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >(null);

  const selectFilters: GCAdminSelectFilterProps[] = [
    {
      options: (options?.subcontractor || []).map((p) => ({
        value: p.id,
        label: p.name,
      })),
      title: "Companies",
      placeholder: "All Companies Included",
      selectedItems: companiesFilter,
      handleItemChange: handleCompaniesChange,
      setSelectedItems: setCompaniesFilter,
    },
    {
      options: (options?.risk_level || []).map((c) => ({
        value: c.value,
        label: c.name,
        color: c.color_hex,
      })),
      selectedItems: riskLevelFilter,
      title: "Risk Level",
      placeholder: "All Risk Levels Included",
      handleItemChange: handleRiskLevelChange,
      setSelectedItems: setRiskLevelFilter,
    },
    {
      options: (options?.observation_category_type || []).map((c) => ({
        value: c.id,
        label: c.name.en,
      })),
      selectedItems: categoriesFilter,
      handleItemChange: handleCategoryChange,
      title: "Observation Category",
      placeholder: "All Categories Included",
      setSelectedItems: setCategoriesFilter,
    },

    {
      options: (options?.observation_category_type || [])
        .filter(
          (type) =>
            categoriesFilter.size === 0 || categoriesFilter.has(type.id),
        )
        .flatMap((type) =>
          type.observation_categories.map((c) => ({
            value: c.id,
            label:
              categoriesFilter.size !== 1
                ? type.name.en + " - " + c.name.en
                : c.name.en,
          })),
        ),
      selectedItems: subCategoriesFilter,
      title: "Observation Sub-Category",
      placeholder: "All Sub Cateogry for selected Category are included",
      handleItemChange: handleSubCategoryChange,
      setSelectedItems: setSubCategoriesFilter,
    },
  ];

  return {
    selectFilters,
    subCategoriesFilter,
    categoriesFilter,
    companiesFilter,
    riskLevelFilter,
    dateFilter,
    setDateFilter,
  };
};
export default useGetObservationFilters;

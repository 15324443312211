import { SelectProps } from "antd/lib";

/**
 * Filters options in a Select component based on the input value.
 *
 * Usage:
 * This function is used as the `filterOption` prop in an Ant Design Select component.
 * It filters the options based on the input value, making the Select component searchable.
 *
 * Example:
 * <Select
 *   showSearch
 *   filterOption={getNormalOptionsFilter}
 *   options={[{ label: 'Option1', value: '1' }, { label: 'Option2', value: '2' }]}
 * />
 */

const getNormalSelectOptionsFilter: SelectProps["filterOption"] = (
  input,
  option,
) =>
  option?.label?.toString().toLowerCase().includes(input.toLowerCase()) ??
  false;

export default getNormalSelectOptionsFilter;

import { FC, useState } from "react";
import { Button, Card, Image } from "antd";
import CustomSignatureCanvas from "src/common/components/general/signature-canvas/CustomSignatureCanvas";
import useSignaturePad from "src/common/components/general/signature-canvas/useSignaturePad";

interface SignatureCardProps {
  label: string;
  signatureUrl?: string;
  insertSignImage: (signImageId: string) => Promise<void>;
}

const SignatureCard: FC<SignatureCardProps> = ({
  label,
  signatureUrl,
  insertSignImage,
}) => {
  const [isEditable, setIsEditable] = useState(!signatureUrl);
  const { signaturePadProps, isEmpty, uploadSignature } = useSignaturePad();

  return (
    <Card className="w-4/5">
      <div className="flex gap-1 items-center">
        <div className="w-10">{label}</div>
        {signatureUrl ? (
          <Image src={signatureUrl} />
        ) : (
          <CustomSignatureCanvas {...signaturePadProps} />
        )}
      </div>
      <Button
        className="mt-1"
        disabled={isEmpty && isEditable}
        size="middle"
        type="primary"
        onClick={async () => {
          if (!isEditable) {
            setIsEditable(true);
          } else {
            const imgUrl = await uploadSignature();
            if (imgUrl) {
              await insertSignImage(imgUrl);

              setIsEditable(false);
            }
          }
        }}
      >
        {isEditable ? "Save" : "Edit"}
      </Button>
    </Card>
  );
};

export default SignatureCard;

import { Checkbox } from "antd";
import React from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import { useUpdatePermitProjectByPkMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitGcProjectSettingCheckbox from "./basic/PermitGcProjectSettingCheckbox";
import ShowPermitChecklistsTables from "./basic/ShowPermitChecklistsTables";

const HotWorkSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  data,
  checklistGc,
  mainGc,
  project,
}) => {
  const [updateProject] = useUpdatePermitProjectByPkMutation();

  return (
    <>
      <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: project?.project_setting,
          field: "only_submitter_sign_the_hot_work_permit",
        }}
      />
      {project && (
        <>
          <StyledContent>
            <Checkbox
              checked={project.enable_third_party_firewatch}
              onChange={(e) => {
                const set = { enable_third_party_firewatch: e.target.checked };
                updateProject({
                  variables: { _set: set, id: project.id },
                  optimisticResponse: {
                    update_project_by_pk: { ...project, ...set },
                  },
                });
              }}
            >
              Turn on if the Fire Watch is from a 3rd Party
            </Checkbox>
          </StyledContent>
          <PermitGcProjectSettingCheckbox
            {...{
              mainGc,
              project_setting: project?.project_setting,
              field: "require_photo_for_hot_work_permit",
              label: "Photo:  require a photo when submitting permit",
            }}
          />
          <StyledContent>
            <Checkbox
              checked={project.prevent_hot_work_submission}
              onChange={(e) => {
                const set = { prevent_hot_work_submission: e.target.checked };
                updateProject({
                  variables: { _set: set, id: project.id },
                  optimisticResponse: {
                    update_project_by_pk: { ...project, ...set },
                  },
                });
              }}
            >
              Turn On Prevent Hot Work Submssion if expired Permit is not closed
            </Checkbox>
          </StyledContent>
        </>
      )}
      <ShowPermitChecklistsTables
        {...{
          checklistGc,
          data,
          project,
          gcPermitCheckListItems,
          queryVariables,
          checklistItemsType: "hot_work",
        }}
      />
    </>
  );
};
export default HotWorkSettings;

import { FC } from "react";
import {
  GetIncidentByPkDocument,
  useUpdateRootCauseAnalysisMutation,
  Root_Cause_Analysis_Set_Input,
  Document_Insert_Input,
  useInsertDocumentMutation,
  useDeleteDocumentByPkMutation,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Card } from "antd";
import IncidentTextField from "./basic/IncidentTextField";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import IncidentDocViewAndUpload from "./basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "./basic/IncidentBottomButtons";
import IncidentDatepicker from "./basic/IncidentDatepicker";
import { deletedDocument } from "./basic/IncidentDocViewer";
import createIncidentPatch from "../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../utils/IncidentTypeProps";

const RootCauseAnalysis: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const [deleteDocumentMutation] = useDeleteDocumentByPkMutation();
  const [updateRootCause] = useUpdateRootCauseAnalysisMutation();
  const rootCauseAnalysis = incident.root_cause_analysis;
  if (!rootCauseAnalysis) {
    throw new Error("root_cause_analysis id missing");
  }
  const [insertDocument] = useInsertDocumentMutation();
  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    const insertedDocs: Array<{ url: string; name: string }> = [];
    const docsToInsert: {
      __typename?: "document";
      url: string;
      id: string;
      group_id?: string | null;
      document_type?: string | null;
      name?: string | null;
      created_at: string;
      image?: {
        __typename?: "image";
        url: string;
        id: string;
      } | null;
    }[] = [];

    objects.forEach((doc) => {
      if (doc.url && doc.id) {
        docsToInsert.push({
          __typename: "document" as const,
          ...doc,
          created_at: dayjs().format(),
          url: doc.url,
          id: doc.id,
          image:
            doc.document_type === "image" ? { url: doc.url, id: doc.id } : null,
        });

        insertedDocs.push({
          url: doc.url,
          name: doc.name ?? "document",
        });
      }
    });
    const updatedIncident = {
      ...incident,
      attached_files: [...incident.attached_files, ...docsToInsert],
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = `Documents added for Root Cause Analysis`;
    await insertDocument({
      variables: {
        objects: objects,
        editObjects: {
          comment: comment,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          patch: patch,
          edit_type: "document-upload",
          document_edit: [
            {
              field: "Root Cause Analysis",
              action: "added",
              documents: insertedDocs,
            },
          ],
        },
      },
      update(cache, result) {
        const insertedDocuments = result.data?.insert_document?.returning;
        if (!insertedDocuments) return;

        const updatedIncident = {
          ...incident,
          root_cause_analysis: {
            ...rootCauseAnalysis,
            document: [...rootCauseAnalysis.document, ...insertedDocuments],
          },
        };

        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
          query: GetIncidentByPkDocument,
        });
      },
    });
  };

  const deleteDocument = async (doc: deletedDocument) => {
    const rem = rootCauseAnalysis.document.filter((file) => file.id !== doc.id);
    if (!rem) return;

    const updatedIncident = {
      ...incident,
      root_cause_analysis: {
        ...rootCauseAnalysis,
        document: rem,
      },
    };

    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = "Document deleted for Root Cause Analysis";

    await deleteDocumentMutation({
      variables: {
        id: doc.id,
        editObjects: {
          comment: comment,
          patch: patch,
          document_edit: [
            {
              action: "deleted",
              field: "Root Cause Analysis",
              documents: [{ url: doc.url, name: doc.name }],
            },
          ],
          edited_by_uid: authUser.uid,
          edit_type: "document-delete",
          incident_id: incident.id,
        },
      },
      update(cache, result) {
        const docId = result.data?.delete_document_by_pk?.id;
        const rem = rootCauseAnalysis.document.filter(
          (file) => file.id !== docId,
        );
        if (!docId || !rem) return;

        const updatedIncident = {
          ...incident,
          root_cause_analysis: {
            ...rootCauseAnalysis,
            document: rem,
          },
        };

        if (docId && rem) {
          cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>(
            {
              data: {
                __typename: "query_root",
                incident_by_pk: updatedIncident,
              },
              query: GetIncidentByPkDocument,
            },
          );
        }
      },
    });
  };

  const updateRootCauseAnalysis = async (
    _set: Omit<Root_Cause_Analysis_Set_Input, "incident_id" | "id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      root_cause_analysis: {
        ...rootCauseAnalysis,
        ..._set,
      },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await updateRootCause({
      variables: {
        id: rootCauseAnalysis.id,
        _set: _set,
        objects: {
          patch: patch,
          incident_id: incident.id,
          edit_type: "root_cause_analysis_update",
          edited_by_uid: authUser.uid,
          comment: comment,
        },
      },
      optimisticResponse: {
        update_root_cause_analysis_by_pk: {
          ...rootCauseAnalysis,
          id: rootCauseAnalysis.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  return (
    <>
      <div className="absolute left-24 top-2 text-2">Root Cause Details</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <Card className="w-4/5">
          <IncidentTextField
            label="* Breif Root Cause of incident"
            text={rootCauseAnalysis.root_cause.en}
            textId={rootCauseAnalysis.root_cause.id}
            key={rootCauseAnalysis.root_cause.id}
            fieldTypeKey={"root_cause"}
            field="root_cause"
            incident={incident}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentTextField
            label="Who participated in this analysis"
            text={rootCauseAnalysis.analysis_participants.en}
            textId={rootCauseAnalysis.analysis_participants.id}
            key={rootCauseAnalysis.root_cause.id}
            fieldTypeKey={"root_cause"}
            field="analysis_participants"
            incident={incident}
          />
        </Card>
        <Card className="w-4/5">
          <IncidentDatepicker
            format="YYYY-MM-DD"
            label="Date of Root Cause Meeting"
            onChange={(date) => {
              updateRootCauseAnalysis(
                { date_of_meeting: date },
                `Updated Root Cause Analysis - "Date of Root Cause Meeting" to "${date}"`,
              );
            }}
            value={rootCauseAnalysis.date_of_meeting ?? undefined}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentTextField
            label="Additional Details"
            text={rootCauseAnalysis.additional_details.en}
            textId={rootCauseAnalysis.additional_details.id}
            key={rootCauseAnalysis.root_cause.id}
            fieldTypeKey={"root_cause"}
            field="additional_details"
            incident={incident}
          />
        </Card>

        <IncidentDocViewAndUpload
          deleteDocument={deleteDocument}
          documents={rootCauseAnalysis.document}
          groupId={rootCauseAnalysis.id}
          uploadDocument={uploadDocument}
          type="root_cause_analysis"
        />

        <IncidentBottomButtons
          saveDisabled={
            !rootCauseAnalysis.root_cause.en ||
            !rootCauseAnalysis.date_of_meeting
          }
          onNextClick={() => setSearchParams({ subview: "witness" })}
          onSave={() => {
            updateRootCauseAnalysis(
              { completed_at: dayjs().format() },
              `Root Cause Analysis section marked as completed`,
            );
          }}
        />
      </div>
    </>
  );
};

export default RootCauseAnalysis;

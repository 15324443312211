import { IconCalendar, IconMessage2 } from "@tabler/icons";
import { Popover } from "antd";
import dayjs from "dayjs";
import React from "react";
import Tag, { TagProps } from "../../general/Tag";
import Icon, { IconProps, IconType } from "../../general/Icon";
import Button, { ButtonProps } from "../../general/Button";
import CounterLabel from "../../general/CounterLabel";
import getDateStr, { GetDateStrOptions } from "src/common/functions/getDateStr";

interface CustomTableCellWrapperProps {
  children: React.ReactElement;
  cellPadding?: CustomTableCellPadding;
}

export const CustomTableCellWrapper: React.FC<CustomTableCellWrapperProps> = ({
  cellPadding = "md",
  children,
}) => {
  return (
    <div
      className={`${sizeToCellPadding(cellPadding)} flex flex-col items-start`}
    >
      {children}
    </div>
  );
};

export type CustomTaleCellDateType = {
  type: "date";
  renderOptions?: (value: any) => GetDateStrOptions;
};
export type CustomTaleCellCounterType = {
  type: "counter";
};

export type CustomTaleCellTagType = {
  type: "tag";
  renderOptions?: (
    value: any,
  ) => Omit<TagProps, "children"> & { label: string };
};

export type CustomTaleCellNoteType = {
  type: "note";
};
export type CustomTaleCellIconLabelType = {
  type: "with-icon";
  renderOptions: (value: any) => {
    icon: IconType;
    inactive?: boolean;
    label: string;
  };
};
export type CustomTableCellButtonType = {
  type: "button";
  renderOptions: (value: any) => ButtonProps;
};
export type CustomTableCellRowTitleType = {
  type: "row-title";
};
export type CustomTableCellWithoutWrapper = {
  type: "without-wrapper";
};

export type CustomTableCellPadding = "sm" | "md";

const sizeToCellPadding = (size: CustomTableCellPadding) => {
  switch (size) {
    case "sm":
      return "pt-0 pb-0 pl-1 pr-1";
    default:
      return "pt-1 pb-1 pl-1.5 pr-1.5";
  }
};
export type CustomTableCellContentType = (
  | CustomTaleCellDateType
  | CustomTaleCellCounterType
  | CustomTaleCellTagType
  | CustomTaleCellNoteType
  | CustomTaleCellIconLabelType
  | CustomTableCellButtonType
  | CustomTableCellWithoutWrapper
  | CustomTableCellRowTitleType
) & { icon?: IconProps };

export type CustomTableCellPopoverType = {
  content: React.ReactElement;
  title: string;
};

interface CustomTableCellProps {
  value: string | React.ReactNode;
  title?: string;
  contentType?: CustomTableCellContentType;
  dark?: boolean;
  popover?: CustomTableCellPopoverType;
  cellPadding?: CustomTableCellPadding;
}

const onlyDigits: (str: string) => boolean = (str) => /^[0-9]+$/.test(str);

const stringIsDate: (str: string) => boolean = (str) => {
  return !onlyDigits(str) && dayjs(str).isValid();
};

const CustomTableCell: React.FC<CustomTableCellProps> = ({
  cellPadding = "md",
  ...props
}) => {
  let element: React.ReactElement = (
    <CustomTableCellWrapper cellPadding={cellPadding}>
      <div className="">{props.value}</div>
    </CustomTableCellWrapper>
  );

  switch (props.contentType?.type) {
    case "without-wrapper":
      element = <div>{props.value}</div>;
      break;
    case "counter":
      var counterValue = 0;
      if (typeof props.value === "string" || typeof props.value === "number") {
        counterValue = parseInt(props.value + "");
      }
      if (isNaN(counterValue)) {
        counterValue = 0;
      }
      element = (
        <CustomTableCellWrapper cellPadding={cellPadding}>
          <div className="self-center">
            {props.title ? (
              <CounterLabel count={counterValue} label={props.title} />
            ) : (
              <span className="font-accent">{counterValue}</span>
            )}
          </div>
        </CustomTableCellWrapper>
      );
      break;
    case "date":
      let dateValue =
        typeof props.value === "string" && dayjs(props.value).isValid()
          ? getDateStr(
              dayjs(props.value),
              props.contentType.renderOptions?.(props.value),
            )
          : props.value === "undefined"
          ? "not complete"
          : props.value === "null"
          ? "not logged"
          : "";
      element = (
        <CustomTableCellWrapper cellPadding={cellPadding}>
          {dateValue ? (
            <div className="flex flex-row gap-0.5 items-center ">
              <Icon {...{ icon: IconCalendar, color: "static" }} />
              <span className="text-1">{dateValue}</span>{" "}
            </div>
          ) : (
            <></>
          )}
        </CustomTableCellWrapper>
      );
      break;
    case "tag":
      const renderOptions = props.contentType.renderOptions?.(props.value);
      if (!renderOptions && typeof props.value !== "string") {
        console.error(
          `${props.value} must be type of string, if you want to use "tag" as a content type`,
        );
      }
      element = (
        <CustomTableCellWrapper cellPadding={cellPadding}>
          <div className="self-center">
            <Tag {...(renderOptions ?? {})}>
              {renderOptions?.label ??
                (typeof props.value === "string" ? props.value : "")}
            </Tag>
          </div>
        </CustomTableCellWrapper>
      );
      break;
    case "note":
      let noteValue = "";
      if (typeof props.value === "string") {
        noteValue = props.value;
      } else {
        console.error(
          `${
            props.value
          } must be a string to be displayed as a note but it's a(n) ${typeof props.value}`,
        );
      }
      element = (
        <div className="flex flex-row px-1">
          <div
            className={`p-0.5 rounded-0.25 flex-1 flex flex-row items-center gap-0.5 ${
              props.dark ? "bg-suplementary-2" : "bg-suplementary-1"
            }`}
          >
            <Icon {...{ icon: IconMessage2, color: "static", size: "small" }} />
            <p className="text-0.75 text-left flex-1">{noteValue}</p>
          </div>
        </div>
      );
      break;
    case "with-icon":
      const options = props.contentType.renderOptions?.(props.value);
      element = (
        <CustomTableCellWrapper cellPadding={cellPadding}>
          <div
            className={`flex flex-row items-center gap-0.5 ${
              options?.inactive || !options?.label?.length
                ? "opacity-20"
                : "opacity-100"
            }`}
          >
            <Icon icon={options.icon} color={"static"}></Icon>
            <span className={``}>{options.label}</span>
          </div>
        </CustomTableCellWrapper>
      );
      break;
    case "button":
      const buttonValue = props.value;
      element = (
        <CustomTableCellWrapper cellPadding={cellPadding}>
          <Button {...props.contentType.renderOptions(buttonValue)} />
        </CustomTableCellWrapper>
      );
      break;
    case "row-title":
      const titleValue = props.value;
      element = (
        <CustomTableCellWrapper cellPadding={cellPadding}>
          <span className="font-accent w-full">{titleValue}</span>
        </CustomTableCellWrapper>
      );
      break;
    default:
      break;
  }

  return !props.popover ? (
    element
  ) : (
    <Popover
      placement="left"
      content={props.popover.content}
      title={props.popover.title}
      trigger="hover"
    >
      <div> {element}</div>
    </Popover>
  );
};

export default CustomTableCell;

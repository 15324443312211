import { FC } from "react";
import {
  useUpdatePropertyDamageIncidentMutation,
  Property_Damage_Incident_Set_Input,
  Document_Insert_Input,
  useGetMonetarySeverityLevelsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentCommonUser from "../incident-users/IncidentCommonUser";
import { Card } from "antd";
import { useSearchParams } from "react-router-dom";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import useUploadIncidentTypeDocument from "../../utils/useUploadIncidentTypeDocument";
import useDeleteIncidentTypeDocument from "../../utils/useDeleteIncidentTypeDocument";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import IncidentTextField from "../basic/IncidentTextField";
import IncidentInputField from "../basic/IncidentInputField";
import IncidentSelectField from "../basic/IncidentSelectField";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import dayjs from "dayjs";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";
import { deletedDocument } from "../basic/IncidentDocViewer";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import useUpdateIncidentCommonUser from "../../utils/useUpdateIncidentCommonUser";
import useUpdateIncidentGeneralPerson from "../../utils/useUpdateIncidentGeneralPerson";
import IncidentTypeProps from "../../utils/IncidentTypeProps";

const PropertyDamageIncident: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const [updateIncidentCommonUser] = useUpdateIncidentCommonUser();
  const [updateGeneralPerson] = useUpdateIncidentGeneralPerson();

  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery({
    fetchPolicy: "cache-first",
  });
  const monetarySeverityLevels = monetarySeverity?.incident_severity;

  const [updateMonetarySeverity] = useUpdateMonetarySeverity();
  const [uploadIncidentDocument] = useUploadIncidentTypeDocument();
  const [insertIncidentType] = useInsertIncidentType();
  const [updatePropertyDamage] = useUpdatePropertyDamageIncidentMutation();
  const [deleteIncidentDocument] = useDeleteIncidentTypeDocument();

  const propertyDamage = incident.property_damage_incident;
  const documents = getIncidentDocuments(incident, "property_damage");
  if (!propertyDamage) {
    throw new Error("property_damage id missing");
  }
  const deleteDocument = async (doc: deletedDocument) => {
    await deleteIncidentDocument(doc, incident, "property_damage");
  };
  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incident.id, "property_damage");
  };
  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocument(objects, incident, "property_damage");
  };
  const updatePropertyDamageIncident = async (
    _set: Omit<Property_Damage_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      property_damage_incident: { ...propertyDamage, ..._set },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await insertIncidentTypeLink();
    await updatePropertyDamage({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "property-damage-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_property_damage_incident_by_pk: {
          ...propertyDamage,
          incident_id: incident.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const propertyOwner = propertyDamage.property_owner;

  return (
    <>
      <div className="absolute left-24 top-2 text-2">Property Damage</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <IncidentCommonUser
          projectId={incident.project_id}
          title="Property Owner"
          user={propertyOwner ?? undefined}
          onUpdateUser={async (id, name) => {
            updatePropertyDamageIncident(
              { property_owner_user_id: id },
              `Updated Property Damage - "Property Owner" to ${name}`,
            );
          }}
          onUpdateUserInfo={async (id, key, val) => {
            if (!!propertyOwner) {
              const updatedIncident = {
                ...incident,
                property_damage_incident: {
                  ...propertyDamage,
                  property_owner: {
                    ...propertyOwner,
                    [key]: val,
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const label = key === "phone_number" ? "phone number" : "email";
              const comment = `Updated the ${label} of the "Property Owner" to ${val}`;
              updateIncidentCommonUser(
                id,
                { [key]: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
          onUpdateGeneralPerson={async (val) => {
            if (propertyOwner && propertyOwner.general_person) {
              const updatedIncident = {
                ...incident,
                property_damage_incident: {
                  ...propertyDamage,
                  property_owner: {
                    ...propertyOwner,
                    general_person: {
                      ...propertyOwner.general_person,
                      employer: val,
                    },
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const comment = `Updated employer of the "Property Owner" to ${val}`;
              updateGeneralPerson(
                propertyOwner.id,
                { employer: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
        />
        <Card className="w-4/5">
          <div className="w-10">Party responsible for damage</div>

          <IncidentInputField
            label="Employer Name"
            onSave={(val) => {
              if (typeof val === "string")
                updatePropertyDamageIncident(
                  {
                    party_responsible_employer_name: val,
                  },
                  `Updated Property Damage - "Party responsible for damage" to ${val}`,
                );
            }}
            text={propertyDamage.party_responsible_employer_name ?? undefined}
          />

          <div className="mt-1">
            <IncidentInputField
              label="Contact Information"
              onSave={(val) => {
                if (typeof val === "string")
                  updatePropertyDamageIncident(
                    {
                      party_responsible_employer_name: val,
                    },
                    `Updated Property Damage - "Contact number of Party responsible for damage " to ${val}`,
                  );
              }}
              text={
                propertyDamage.party_responsible_employer_phone_number ??
                undefined
              }
            />
          </div>
        </Card>

        <Card className="w-4/5">
          <IncidentSelectField
            title="Monetary Severity"
            options={
              monetarySeverityLevels?.map((severity) => ({
                label: severity.name.en,
                value: severity.id,
              })) ?? []
            }
            value={incident.monetary_severity_id ?? undefined}
            onChange={(option) => {
              if (typeof option.value === "string") {
                updateMonetarySeverity(incident, option.value, option.label);
              }
            }}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentTextField
            label="Description of Property Damage"
            text={propertyDamage.description.en}
            textId={propertyDamage.description.id}
            key={propertyDamage.description.id}
            saveIncidentField={insertIncidentTypeLink}
            fieldTypeKey={"property_damage"}
            field={"description"}
            incident={incident}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentTextField
            label="* Cause of Property Damage"
            text={propertyDamage.cause.en}
            textId={propertyDamage.cause.id}
            key={propertyDamage.cause.id}
            saveIncidentField={insertIncidentTypeLink}
            fieldTypeKey={"property_damage"}
            field={"cause"}
            incident={incident}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentInputField
            label="Estimated Cost of Damages (in USD)"
            validateAsNumber={true}
            onSave={(val) => {
              const damage = typeof val === "string" ? parseInt(val) : val;
              updatePropertyDamageIncident(
                { estimated_cost_of_damage: damage },
                `Updated Property Damage - "Estimated Cost of Damages" to ${val}`,
              );
            }}
            text={propertyDamage.estimated_cost_of_damage ?? undefined}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentInputField
            label="Actual Cost of Damages (in USD)"
            validateAsNumber={true}
            onSave={(val) => {
              const damage = typeof val === "string" ? parseInt(val) : val;
              updatePropertyDamageIncident(
                { actual_cost_of_damage: damage },
                `Updated Property Damage -"Actual Cost of Damages" to ${val}`,
              );
            }}
            text={propertyDamage.actual_cost_of_damage ?? undefined}
          />
        </Card>

        {incident.incident_types.findIndex(
          (indexType) => indexType.type_value === "property_damage",
        ) !== -1 && (
          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={documents}
            groupId={incident.id}
            uploadDocument={uploadDocument}
            type="property_damage"
          />
        )}

        <IncidentBottomButtons
          saveDisabled={!propertyDamage.cause.en}
          onNextClick={() => {
            const next = getNextPage(incident, "property_damage");
            setSearchParams({ subview: next });
          }}
          onSave={() => {
            updatePropertyDamageIncident(
              { completed_at: dayjs().format() },
              `Property Damage Section marked as completed`,
            );
          }}
        />
      </div>
    </>
  );
};

export default PropertyDamageIncident;

import dayjs from "dayjs";
import React, { useEffect, useMemo } from "react";
import ShowToggleTable, {
  ExtendedRow,
} from "src/common/components/tables/ShowToggleTable/ShowToggleTable";
import GCPerformanceTable from "src/common/components/tables/ShowToggleTable/GCPerformanceTable";
import {
  ColumnType,
  ReportType,
} from "src/common/components/tables/ShowToggleTable/ShowToggleTableColumn";
import LoadingContent from "src/common/components/general/LoadingContent";
import { useGetCompletionAuditV3Query } from "src/common/types/generated/apollo/graphQLTypes";

import useGetSwitchers from "./useGetSwitchers";

interface SubColumnType {
  title: string;
  startDate: string;
  endDate: string;
  reportData: ReportType;
}

interface SubcontractorDashReportData {
  id: string;
  name: string;
  columns: Array<SubColumnType>;
}

type ReportTypeType = "dailyReport" | "ptpReport";

// type CrewData = {
//   id: string;
//   name: string;
//   parentId: string;
//   data: RowData[];
// };

// type SubData = {
//   name: string;
//   title: string;
//   data: CrewData[];
// };

const initialSubData: (
  reportData: {
    id: string;
    name: string;
    date: string;
    onsite?: boolean;
    completed: number;
    parentId?: string;
  }[],
  subColumnData: Array<SubColumnType>,
) => Array<SubcontractorDashReportData> = (reportData, subColumnData) => {
  const subData: Array<SubcontractorDashReportData> = [];

  let tempSub: SubcontractorDashReportData | null = null;

  for (let i = 0; i < reportData.length; i++) {
    if (tempSub === null) {
      tempSub = {
        id: reportData[i].id,
        name: reportData[i].name,
        columns: JSON.parse(JSON.stringify(subColumnData)),
      };
    }

    if (tempSub.id !== reportData[i].id) {
      subData.push(tempSub);
      tempSub = {
        id: reportData[i].id,
        name: reportData[i].name,
        columns: JSON.parse(JSON.stringify(subColumnData)),
      };
    }

    tempSub.columns.forEach((c) => {
      if (
        dayjs(c.startDate).format("YYYY-MM-DD") <=
          dayjs(reportData[i].date).format("YYYY-MM-DD") &&
        dayjs(c.endDate).format("YYYY-MM-DD") >=
          dayjs(reportData[i].date).format("YYYY-MM-DD")
      ) {
        c.reportData.parentId = reportData[i].parentId;
        if (reportData[i].onsite) {
          if (reportData[i].completed) {
            c.reportData.submitted += reportData[i].completed;
            c.reportData.total += reportData[i].completed;
          } else {
            c.reportData.missingReportsDates.push(
              dayjs(reportData[i].date).toDate(),
            );
            c.reportData.total += 1;
          }
        }
      }
    });
  }
  if (tempSub) subData.push(tempSub);

  // subData.sort((s1, s2) => (s1.name < s2.name ? -1 : 1));
  return subData;
};

// const sortSubData = (
//   title: string,
//   subData: Array<SubcontractorDashReportData>
// ) => {
//   return subData.sort((s1, s2) => {
//     const t1 = s1.columns.find((c) => c.title === title);
//     const t2 = s2.columns.find((c) => c.title === title);
//     if (t1 && t2 && t1.reportData.total > 0 && t2.reportData.total > 0) {
//       return t1.reportData.submitted / t1.reportData.total >
//         t2.reportData.submitted / t2.reportData.total
//         ? -1
//         : 1;
//     }
//     if (t1 && t1.reportData.total > 0) return -1;
//     if (t2 && t2.reportData.total > 0) return 1;
//     return s1.subName < s2.subName ? -1 : 1;
//   });
// };

export interface SubOnsiteData {
  subId: string;
  subName: string;
  projectSubId: string;
  onsite: boolean;
  safety_reports_required: boolean;
  toolbox_talks_required: boolean;
  daily_reports_required: boolean;
  crews: Array<{
    crewName: string;
    crewLead?: string;
    crewId: string;
    onsite: boolean;
    safety_reports_required: boolean;
    toolbox_talks_required: boolean;
    daily_reports_required: boolean;
  }>;
}

export interface GCDashAppReportsTableProps {
  reportType: ReportTypeType;
  projectId: string;
  start: dayjs.Dayjs;
  projectTimezone: string;
  subOnsiteData?: SubOnsiteData[];
  onsiteSwitcher?: boolean;
  crewLeads: { [id: string]: string };
}

export type GCDashAppReportsColumnType = {
  title: string;
  rows: ReportType[];
  onSortingClick: () => void;
};
const GCDashAppReportsTable: React.FC<GCDashAppReportsTableProps> = ({
  projectId,
  subOnsiteData,
  onsiteSwitcher,
  reportType,
  projectTimezone,
  crewLeads,
  start,
}) => {
  const { data, loading } = useGetCompletionAuditV3Query({
    variables: {
      input: {
        startDate: dayjs(start)
          .subtract(1, "year")
          .tz(projectTimezone)
          .startOf("day")
          .format(),
        endDate: dayjs().tz(projectTimezone).endOf("day").format(),
        projectId,
        timezone: projectTimezone,
        includeDeletedCrews: true,
      },
    },
    fetchPolicy: "network-only",
  });
  const { getSubSwitchersFor, getCrewSwitchersFor } =
    useGetSwitchers(projectId);
  const subColumnData: Array<SubColumnType> = useMemo(
    () => [
      {
        title: "All time",
        startDate: dayjs(start).format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        reportData: {
          missingReportsDates: [],
          submitted: 0,
          total: 0,
        },
      },
      {
        title: "30D MA",
        startDate:
          dayjs(start).format("YYYY-MM-DD") >
          dayjs().subtract(29, "d").format("YYYY-MM-DD")
            ? dayjs(start).format("YYYY-MM-DD")
            : dayjs().subtract(29, "d").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        reportData: {
          missingReportsDates: [],
          submitted: 0,
          total: 0,
        },
      },
      {
        title: "Last week",
        startDate:
          dayjs(start).format("YYYY-MM-DD") >
          dayjs().startOf("week").subtract(7, "d").format("YYYY-MM-DD")
            ? dayjs(start).format("YYYY-MM-DD")
            : dayjs().startOf("week").subtract(7, "d").format("YYYY-MM-DD"),
        endDate:
          dayjs(start).format("YYYY-MM-DD") >
          dayjs().startOf("week").subtract(1, "d").format("YYYY-MM-DD")
            ? dayjs(start).format("YYYY-MM-DD")
            : dayjs().startOf("week").subtract(1, "d").format("YYYY-MM-DD"),
        reportData: {
          missingReportsDates: [],
          submitted: 0,
          total: 0,
        },
      },
      {
        title: "This week",
        startDate: dayjs().startOf("week").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        reportData: {
          missingReportsDates: [],
          submitted: 0,
          total: 0,
        },
      },
    ],
    [start],
  );

  if (loading) {
    return <LoadingContent />;
  }
  const rows: ExtendedRow[] = [];
  const columnsDataRowList: SubcontractorDashReportData[] = [];
  (data?.getCompletionAuditV3 || []).map((sub, i) => {
    const subPermanentData = subOnsiteData?.find((p) => p.subId === sub.id);
    const currentCrews = sub.crewData.filter((c) => !c.is_deleted);
    columnsDataRowList.push(
      ...initialSubData(
        sub.completion.map((report) => {
          const reportCompleted =
            reportType === "dailyReport"
              ? report.daily_reports_completion_count
              : report.safety_reports_completion_count;

          return {
            id: sub.id,
            name: sub.name,
            onsite:
              reportCompleted > 0 ||
              (report.onsite_required &&
                (reportType === "dailyReport"
                  ? report.daily_reports_required
                  : report.safety_reports_required)),
            completed: reportCompleted,
            date: report.date,
          };
        }),
        subColumnData,
      ),
    );
    rows.push({
      id: sub.id,
      title:
        sub.name +
        (currentCrews.length > 1 ? ` (${currentCrews.length} Crews) ` : ""),
      switchers:
        onsiteSwitcher && subPermanentData
          ? [getSubSwitchersFor(subPermanentData).OnsiteSwitcher]
          : [],
      haveChilds: currentCrews.length > 1,
      parentItem: true,
    });
    if (currentCrews.length > 1) {
      currentCrews.forEach((crew) => {
        const crewPermanentData = subPermanentData
          ? subPermanentData.crews.find((c) => c.crewId === crew.id)
          : undefined;
        rows.push({
          id: crew.id,
          title:
            crew.name + (crewLeads[crew.id] ? `, ${crewLeads[crew.id]}` : ""),
          switchers:
            onsiteSwitcher && subPermanentData && crewPermanentData
              ? [
                  getCrewSwitchersFor(crewPermanentData, subPermanentData)
                    .OnsiteSwitcher,
                ]
              : [],
          parentId: sub.id,
        });
      });
      columnsDataRowList.push(
        ...initialSubData(
          currentCrews.flatMap((crew) => {
            return crew.completion.map((report) => {
              const reportCompleted =
                reportType === "dailyReport"
                  ? report.daily_reports_completion_count
                  : report.safety_reports_completion_count;
              return {
                id: crew.id,
                name: crew.name,
                parentId: sub.id,
                onsite:
                  reportCompleted > 0 ||
                  (report.onsite_required &&
                    (reportType === "dailyReport"
                      ? report.daily_reports_required
                      : report.safety_reports_required)),
                completed: reportCompleted,
                date: report.date,
              };
            });
          }),
          subColumnData,
        ),
      );
    }
  });
  console.log(columnsDataRowList, "  ;IST");
  const columnsData = subColumnData.map((col): ColumnType => {
    return {
      title: col.title,
      rows: columnsDataRowList.map((crew) => {
        let crewColData = crew.columns.filter(
          (crewCol) => crewCol.title === col.title,
        );
        if (crewColData.length > 0) return crewColData[0].reportData;
        return {
          submitted: 0,
          total: 0,
          missingReportsDates: [],
        };
      }),
    };
  });

  // const rowDataMap = {}; // Create an object to store rows based on subId

  // (data?.getCompletionAuditV3 || []).forEach((sub) => {
  //   const currentCrews = sub.crewData.filter((c) => !c.is_deleted);

  //   // Process each sub and its crews
  //   // processSub(sub, currentCrews);
  //       const subPermanentData = subOnsiteData?.find((p) => p.subId === sub.id);

  //       rows.push({
  //         id: sub.id,
  //         title:
  //           sub.name +
  //           (currentCrews.length > 1 ? ` (${currentCrews.length} Crews) ` : ""),
  //         switchers:
  //           onsiteSwitcher && subPermanentData
  //             ? [getSubSwitchersFor(subPermanentData).OnsiteSwitcher]
  //             : [],
  //         haveChilds: currentCrews.length > 1,
  //         parentItem: true,
  //       });

  //       // Store sub data in rowDataMap
  //       rowDataMap[sub.id] = {
  //         name: sub.name,
  //         title: sub.name,
  //         data: [],
  //       };

  //   // Process crews for each sub
  //   currentCrews.forEach((crew) => processCrew(crew, sub.id));
  // });

  // Function to process sub and add rows
  // const processSub = (sub, crews) => {
  //   const subPermanentData = subOnsiteData?.find((p) => p.subId === sub.id);

  //   rows.push({
  //     id: sub.id,
  //     title: sub.name + (crews.length > 1 ? ` (${crews.length} Crews) ` : ""),
  //     switchers:
  //       onsiteSwitcher && subPermanentData
  //         ? [getSubSwitchersFor(subPermanentData).OnsiteSwitcher]
  //         : [],
  //     haveChilds: crews.length > 1,
  //     parentItem: true,
  //   });

  //   // Store sub data in rowDataMap
  //   rowDataMap[sub.id] = {
  //     name: sub.name,
  //     title: sub.name,
  //     data: [],
  //   };
  // };

  // Function to process crew and add rows
  // const processCrew = (crew, subId) => {
  //   const crewPermanentData = subPermanentData
  //     ? subPermanentData.crews.find((c) => c.crewId === crew.id)
  //     : undefined;

  //   rows.push({
  //     id: crew.id,
  //     title: crew.name + (crewLeads[crew.id] ? `, ${crewLeads[crew.id]}` : ""),
  //     switchers:
  //       onsiteSwitcher && subPermanentData && crewPermanentData
  //         ? [
  //             getCrewSwitchersFor(crewPermanentData, subPermanentData)
  //               .OnsiteSwitcher,
  //           ]
  //         : [],
  //     parentId: sub.id,
  //   });

  //   // Store crew data in rowDataMap
  //   rowDataMap[subId].data.push({
  //     id: crew.id,
  //     name: crew.name,
  //     parentId: sub.id,
  //     data: [],
  //   });
  // };

  // Process completion data and add to rowDataMap
  // (data?.getCompletionAuditV3 || []).forEach((sub) => {
  //   const currentCrews = sub.crewData.filter((c) => !c.is_deleted);

  //   currentCrews.forEach((crew) => {
  //     crew.completion.forEach((report) => {
  //       const reportCompleted =
  //         reportType === "dailyReport"
  //           ? report.daily_reports_completion_count
  //           : report.safety_reports_completion_count;

  //       const rowData = {
  //         id: crew.id,
  //         name: crew.name,
  //         parentId: sub.id,
  //         onsite:
  //           reportCompleted > 0 ||
  //           (report.onsite_required &&
  //             (reportType === "dailyReport"
  //               ? report.daily_reports_required
  //               : report.safety_reports_required)),
  //         completed: reportCompleted,
  //         date: report.date,
  //       };

  //       // Add row data to corresponding sub and crew in rowDataMap
  //       rowDataMap[sub.id].data.forEach((crewData) => {
  //         if (crewData.id === crew.id) {
  //           crewData.data.push(rowData);
  //         }
  //       });
  //     });
  //   });
  // });

  // // Create columns data based on rowDataMap
  // const columnsData = subColumnData.map((col) => {
  //   return {
  //     title: col.title,
  //     rows: Object.values(rowDataMap).map((rowData) => {
  //       const crewData = rowData.data.find(
  //         (crewData) => crewData.name === col.title,
  //       );
  //       if (crewData) {
  //         return crewData.data;
  //       } else {
  //         return [];
  //       }
  //     }),
  //   };
  // });
  return (
    // <ShowToggleTable
    //   {...{
    //     columns: columnsData,
    //     mainColumn: {
    //       id: "sub",
    //       rows: rows,
    //       switchTitles: [{ title: "Onsite" }],
    //       initiallyHideChilds: true,
    //       title: "Subcontractors",
    //     },
    //   }}
    // />
    <GCPerformanceTable
      {...{
        columns: columnsData,
        mainColumn: {
          id: "sub",
          rows: rows,
          switchTitles: [{ title: "Onsite" }],
          initiallyHideChilds: true,
          title: "Subcontractors",
        },
      }}
    />
  );
};

export default GCDashAppReportsTable;

import React, { FC, useRef, useState } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { auth } from "src/common/functions/firebase";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import useColumnOrderBy from "src/common/hooks/useColumnOrderBy";
import { Button, message, Popconfirm, Space } from "antd";
import noPropagation from "src/common/functions/noPropagation";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import {
  SCProjectSafetyPlan_InsertSafetyPlanMutation,
  safety_plan_insert_input,
} from "src/common/types/generated/relay/SCProjectSafetyPlan_InsertSafetyPlanMutation.graphql";
import { Task_Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import * as uuid from "uuid";
import { useLazyLoadQuery } from "react-relay/hooks";
import { SCProjectSafetyPlan_CompanySafetyPlanQuery } from "src/common/types/generated/relay/SCProjectSafetyPlan_CompanySafetyPlanQuery.graphql";
import {
  SCProjectSafetyPlan_UpdateSafetyPlanMutation,
  SCProjectSafetyPlan_UpdateSafetyPlanMutation$data,
} from "src/common/types/generated/relay/SCProjectSafetyPlan_UpdateSafetyPlanMutation.graphql";
import dayjs from "dayjs";
import {
  SCProjectSafetyPlan_updateProjectSubcontractorEmployeeMutation,
  SCProjectSafetyPlan_updateProjectSubcontractorEmployeeMutation$data,
} from "src/common/types/generated/relay/SCProjectSafetyPlan_updateProjectSubcontractorEmployeeMutation.graphql";
import PdfViewer from "../../../../common/components/pdf/PdfViewer";
import { SafetyPlanTable_safetyPlan$data } from "src/common/types/generated/relay/SafetyPlanTable_safetyPlan.graphql";
import SafetyPlanTable, { safetyPlan } from "./SafetyPlanTable";
import ProjectSubcontractorEmployeeTable, {
  ProjectSubcontractorEmployee,
} from "./ProjectSubcontractorEmployeeTable";
import UploadSafetyPlanModal from "./UploadSafetyPlanModal";
import AddSafetyPlanFromCompanyLibraryModal from "./AddSafetyPlanFromCompanyLibraryModal";
import AddSubcontractorProjectTeamModal from "./AddSubcontractorProjectTeamModal";
import ProjectWorkerPocTable from "./ProjectWorkerPocTable";
import AddProjectWorkerPocModal from "./AddProjectWorkerPocModal";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";

interface SCProjectSafetyPlanProps {
  projectId: string;
  subcontractorId: string;
  refresh: () => void;
}

const SCProjectSafetyPlan: FC<SCProjectSafetyPlanProps> = ({
  projectId,
  refresh,
  subcontractorId,
}) => {
  const loggedInUserId = auth.currentUser?.uid;
  const [pdfModal, setPdfModal] = useState(false);
  const [
    openAddSubcontractorProjectTeamModal,
    setOpenAddSubcontractorProjectTeamModal,
  ] = useState(false);
  const [addProjectWorkerPocModal, setAddProjectWorkerPocModal] =
    useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [fetchKey, setFetchKey] = useState(0);
  const [visible, setVisible] = useState<boolean>(false);
  const { orderBy, setOrderBy } = useColumnOrderBy<Task_Order_By>();
  const safetyPlanTableRef = useRef<DataScrollTableRef>(null);
  const projectWorkerPocTableRef = useRef<DataScrollTableRef>(null);
  const subcontractorProjectTeamTableRef = useRef<DataScrollTableRef>(null);

  const [
    addSafetyPlanFromCompanyLibraryModal,
    setAddSafetyPlanFromCompanyLibraryModal,
  ] = useState(false);
  const [openSiteFormUploadModal, setOpenSiteFormUploadModal] = useState(false);

  const [selectedSafetyPlan, setSelectedSafetyPlan] = useState<
    safetyPlan | undefined
  >();

  const [searchString, setSearchString] = useState("");

  const onInputKeyDown = (event: any): any => {
    if (event.key == "Enter") {
      setSearchString(event.target.value);
    }
  };

  const query = graphql`
    query SCProjectSafetyPlan_CompanySafetyPlanQuery($subId: uuid!) {
      safety_plan_connection(
        first: 10000
        where: {
          subcontractor_id: { _eq: $subId }
          deleted_at: { _is_null: true }
        }
        order_by: { title: asc }
      )
        @connection(
          key: "SCProjectSafetyPlan_CompanySafetyPlanQuery_safety_plan_connection"
          filters: []
        ) {
        edges {
          node {
            ...SafetyPlanFrag @relay(mask: false)
          }
        }
      }
    }
  `;

  const [insertSafetyPlan, loading] =
    useAsyncMutation<SCProjectSafetyPlan_InsertSafetyPlanMutation>(
      graphql`
        mutation SCProjectSafetyPlan_InsertSafetyPlanMutation(
          $object: safety_plan_insert_input!
        ) {
          insert_safety_plan_one(object: $object) {
            ...SafetyPlanFrag @relay(mask: false)
          }
        }
      `,
    );

  const [updateSafetyPlan, updating] =
    useAsyncMutation<SCProjectSafetyPlan_UpdateSafetyPlanMutation>(
      graphql`
        mutation SCProjectSafetyPlan_UpdateSafetyPlanMutation(
          $_set: safety_plan_set_input!
          $id: uuid!
        ) {
          update_safety_plan_by_pk(pk_columns: { id: $id }, _set: $_set) {
            id
            deleted_at
            company_safety_plan_id
          }
        }
      `,
    );

  const [updateProjectSubcontractorEmployee, inserting] =
    useAsyncMutation<SCProjectSafetyPlan_updateProjectSubcontractorEmployeeMutation>(
      graphql`
        mutation SCProjectSafetyPlan_updateProjectSubcontractorEmployeeMutation(
          $_set: project_subcontractor_employee_set_input
          $where: project_subcontractor_employee_bool_exp!
        ) {
          update_project_subcontractor_employee(_set: $_set, where: $where) {
            affected_rows
          }
        }
      `,
    );

  const deleteUpdater: (
    store: RecordSourceSelectorProxy<SCProjectSafetyPlan_UpdateSafetyPlanMutation$data>,
    id: string,
  ) => void = (store, id) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "SafetyPlanTable_safety_plan_connection",
    );
    if (conn) {
      const edges = (conn.getLinkedRecords("edges") || []).filter(
        (r) => r.getLinkedRecord("node")?.getValue("id") != id,
      );
      conn.setLinkedRecords(edges, "edges");
    }
  };

  const updateProjectSubcontractorEmployeeUpdater: (
    store: RecordSourceSelectorProxy<SCProjectSafetyPlan_updateProjectSubcontractorEmployeeMutation$data>,
    id: string,
    markAsValid: boolean,
  ) => void = (store, id, markAsValid) => {
    const conn = ConnectionHandler.getConnection(
      store.getRoot(),
      "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection",
    );
    if (conn) {
      const edges = conn.getLinkedRecords("edges") || [];
      edges.forEach((edge) => {
        const node = edge.getLinkedRecord("node");
        if (!node) {
          return;
        }

        if (node.getValue("id") == id && markAsValid) {
          node.setValue(true, "emergency_contact");
        } else if (!markAsValid) {
          node.setValue(false, "emergency_contact");
        }
      });
      conn.setLinkedRecords(edges, "edges");
    }
  };

  const safetyPlanData =
    useLazyLoadQuery<SCProjectSafetyPlan_CompanySafetyPlanQuery>(query, {
      subId: subcontractorId,
    }).safety_plan_connection.edges.map((item) => item.node);

  const companySafetyPlanData = safetyPlanData.filter((safetyPlan) => {
    if (safetyPlan.project_id != null) {
      return false;
    }
    const matchingSafetyPlan = safetyPlanData.find(
      (sp) =>
        sp.project_id &&
        sp.project_id == projectId &&
        sp.company_safety_plan_id == safetyPlan.pk,
    );
    return !matchingSafetyPlan;
  });

  const onDelete = async (
    safetyPlan: SafetyPlanTable_safetyPlan$data["safety_plan_connection"]["edges"][number]["node"],
  ) => {
    await updateSafetyPlan({
      variables: {
        _set: { deleted_at: dayjs().toISOString() },
        id: safetyPlan.pk,
      },
      updater: (store) => {
        deleteUpdater(store, safetyPlan.id);
      },
    })
      .then(async (d) => {
        message.success("Safety Plan Deleted");
      })
      .catch((e) => console.log(e));
  };

  const makeEmergencyContact = async (
    projectSubcontractorEmployee: ProjectSubcontractorEmployee,
  ) => {
    Promise.all([
      updateProjectSubcontractorEmployee({
        variables: {
          _set: { emergency_contact: false },
          where: { emergency_contact: { _eq: true } },
        },
        updater: (store) => {
          updateProjectSubcontractorEmployeeUpdater(
            store,
            projectSubcontractorEmployee.id,
            false,
          );
        },
      }),
      updateProjectSubcontractorEmployee({
        variables: {
          _set: { emergency_contact: true },
          where: { id: { _eq: projectSubcontractorEmployee.pk } },
        },
        updater: (store) => {
          updateProjectSubcontractorEmployeeUpdater(
            store,
            projectSubcontractorEmployee.id,
            true,
          );
        },
      }),
    ])
      .then((d) => {
        message.success("Emergency Contact Updated");
      })
      .catch((error) => {
        message.error("Unable to update Emergency Contact");
      });
  };

  const addToCompany = async (safetyPlan: safetyPlan) => {
    if (!safetyPlan) {
      return;
    }
    const companyId = uuid.v4();
    const safetyPlanInsertInput: safety_plan_insert_input = {
      created_by_uid: auth.currentUser?.uid,
      project_id: null,
      subcontractor_id: subcontractorId,
      title: safetyPlan.title,
      url: safetyPlan.url,
      id: companyId,
      revision_date: safetyPlan.revision_date,
      revision_number: safetyPlan.revision_number,
    };
    await insertSafetyPlan({
      variables: {
        object: { ...safetyPlanInsertInput },
      },
    });
    await updateSafetyPlan({
      variables: {
        _set: { company_safety_plan_id: companyId },
        id: safetyPlan.pk,
      },
      optimisticResponse: {
        update_safety_plan_by_pk: {
          id: safetyPlan.id,
          company_safety_plan_id: companyId,
          deleted_at: null,
        },
      },
    })
      .then(async (d) => {
        message.success("Added to Company Library");
      })
      .catch((e) => console.log(e));
  };
  return loggedInUserId ? (
    <BasicWrapper scrollable>
      <UploadSafetyPlanModal
        visible={openSiteFormUploadModal}
        subcontractorId={subcontractorId}
        projectId={projectId}
        safetyPlan={selectedSafetyPlan}
        onClose={() => setOpenSiteFormUploadModal(false)}
        onSubmit={() => {
          setOpenSiteFormUploadModal(false);
        }}
      />
      <PdfViewer
        visible={pdfModal}
        onClose={() => {
          setPdfModal(false);
        }}
        pdfUrl={pdfUrl}
      />
      <AddSafetyPlanFromCompanyLibraryModal
        {...{
          projectId: projectId ?? "",
          visible: addSafetyPlanFromCompanyLibraryModal,
          data: companySafetyPlanData,
          closeModal: () => setAddSafetyPlanFromCompanyLibraryModal(false),
          subcontractorId,
          refresh: () => {
            safetyPlanTableRef.current?.refetch();
          },
        }}
      />
      <AddSubcontractorProjectTeamModal
        modalClose={() => {
          setOpenAddSubcontractorProjectTeamModal(false);
        }}
        modalVisible={openAddSubcontractorProjectTeamModal}
        fetchKey={fetchKey}
        subcontractorId={subcontractorId}
        projectId={projectId}
        onSubmit={() => {
          setOpenAddSubcontractorProjectTeamModal(false);
          subcontractorProjectTeamTableRef.current?.refetch();
        }}
      />
      <AddProjectWorkerPocModal
        visible={addProjectWorkerPocModal}
        projectId={projectId}
        onClose={() => setAddProjectWorkerPocModal(false)}
        onSubmit={() => {
          setAddProjectWorkerPocModal(false);
          projectWorkerPocTableRef.current?.refetch();
        }}
      />

      <SafetyPlanTable
        title="Corporate Safety Manual"
        ref={safetyPlanTableRef}
        where={{
          subcontractor_id: { _eq: subcontractorId },
          project_id: { _eq: projectId },
          deleted_at: { _is_null: true },
        }}
        onSafetyPlanRowClick={(safetyPlan) => {
          setPdfUrl(safetyPlan.url);
          setPdfModal(true);
        }}
        loading={loading || updating}
        topBarButtons={[
          {
            onClick: () => {
              setSelectedSafetyPlan(undefined);
              setOpenSiteFormUploadModal(true);
            },
            label: "Upload New",
          },
          {
            onClick: () => {
              setAddSafetyPlanFromCompanyLibraryModal(true);
            },
            label: "Add from Library",
          },
        ]}
        extraColumns={[
          {
            title: "Action",
            dataIndex: [""],
            size: "lg",
            key: "action",
            render: (_, safetyPlan) => {
              return (
                <>
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={noPropagation(() => {
                      addToCompany(safetyPlan);
                    })}
                    onCancel={noPropagation()}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    {!safetyPlan.company_safety_plan_id && (
                      <Button type="link" onClick={noPropagation()}>
                        Add to Company Library
                      </Button>
                    )}
                  </Popconfirm>
                </>
              );
            },
            onCellClick: (record: any, event: any) => {
              console.log(record);
            },
          },
          {
            title: "Update",
            dataIndex: [""],
            size: "ml",
            key: "replace",
            render: (_, safetyplan) => {
              return (
                <>
                  <Button
                    type="link"
                    onClick={noPropagation(() => {
                      setSelectedSafetyPlan(safetyplan);
                      setOpenSiteFormUploadModal(true);
                    })}
                  >
                    Replace
                  </Button>
                </>
              );
            },
            onCellClick: (record: any, event: any) => {
              console.log(record);
            },
          },
          {
            title: "",
            dataIndex: [""],
            size: "md",
            key: "delete",
            render: (_: any, safetyPlan: safetyPlan) => (
              <>
                <Space>
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={noPropagation(() => {
                      onDelete(safetyPlan);
                    })}
                    onCancel={noPropagation()}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Button danger type="link" onClick={noPropagation()}>
                      Delete
                    </Button>
                  </Popconfirm>
                </Space>
              </>
            ),
          },
        ]}
      />

      <div className="mt-5 mb-5">
        <ProjectSubcontractorEmployeeTable
          ref={subcontractorProjectTeamTableRef}
          headerComponent={
            <>
              <br />
              Add your project’s Point of Contacts (POC)
            </>
          }
          where={{
            project_id: { _eq: projectId },
            subcontractor_employee: {
              subcontractor_id: { _eq: subcontractorId },
            },
          }}
          loading={loading || updating}
          topBarButtons={[
            {
              onClick: () => {
                setOpenAddSubcontractorProjectTeamModal(true);
              },
              label: "+ Add",
            },
          ]}
          excludedKeys={["status"]}
        />
      </div>

      <ProjectWorkerPocTable
        title="Crew Leads (Foremen and Women that will be onsite every day)"
        ref={projectWorkerPocTableRef}
        where={{
          project_id: { _eq: projectId },
        }}
        loading={loading || updating}
        topBarButtons={[
          {
            onClick: () => {
              setAddProjectWorkerPocModal(true);
            },
            label: "+ Add",
          },
        ]}
      />
    </BasicWrapper>
  ) : (
    <div className="flex flex-col items-center justify-center mt-3">
      Authentication Failed
    </div>
  );
};
export default SCProjectSafetyPlan;

import { useState } from "react";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { SiteInspectionStepProps } from "src/domain-features/siteinspection/utils/siteInspectionTypes";
import useSiteInspectionUser from "src/domain-features/siteinspection/utils/useSiteInspectionUser";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import {
  useCurrentDBLangSelector,
  useCurrentLangStrings,
} from "src/utility-features/i18n/context/languageHooks";
import { Select } from "antd";

export interface SiteInspectionJobLocationProps
  extends SiteInspectionStepProps {
  locationsOptions: Array<{ label: string; value: string }>;
}

const SiteInspectionJobLocation: React.FC<SiteInspectionJobLocationProps> = (
  props,
) => {
  const workerContext = useSiteInspectionUser();
  const [selectedLocation, setSelectedLocation] = useState<string>();
  const onEnter = () => {
    workerContext.updateUser((u) =>
      u
        ? { ...u, projectLocationId: selectedLocation }
        : { projectLocationId: selectedLocation },
    );
    props.onNext();
  };
  const langStrings = useCurrentLangStrings();
  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => onEnter()}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!selectedLocation}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.selectJobLocation}
      >
        <Select
          onChange={(val) => setSelectedLocation(val)}
          value={selectedLocation}
          options={props.locationsOptions}
        />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};
export default SiteInspectionJobLocation;

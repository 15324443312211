import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  Upload,
  AutoComplete,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { FormInstance, FormItemProps, FormProps } from "antd/lib/form";
import TextArea from "antd/lib/input/TextArea";
import { ModalProps } from "antd/lib/modal";
import React, {
  ComponentProps,
  forwardRef,
  JSXElementConstructor,
  useImperativeHandle,
  useRef,
} from "react";
import CustomSuspense from "src/common/components/general/CustomSuspense";
import noop from "src/common/functions/noop";
import normalizeUploadFile from "src/common/functions/normalizeUploadFile";
import BaseModal, { BaseModalRef } from "./BaseModal";
import Checkbox from "antd/es/checkbox/Checkbox";

export type FModalRef<V extends object> = BaseModalRef & {
  form: FormInstance<V>;
};

interface Props<V extends object> extends ModalProps {
  form?: FormProps<V> & { key?: string | number };
  children: any;
  ref?: any;
}

type FModalItemProps<CProps = any, V = any> = {
  props?: CProps;
  requiredMessage?: string;
} & FormItemProps<V>;
const FModalItemGen =
  <C extends JSXElementConstructor<any>>(
    Component: C,
  ): ((p: FModalItemProps<ComponentProps<C>>) => JSX.Element) =>
  ({ props, children, requiredMessage, ...item }) => {
    const C = Component as any;
    return (
      <Form.Item
        {...item}
        rules={
          requiredMessage
            ? [
                { required: true, message: requiredMessage },
                ...(item.rules ?? []),
              ]
            : item.rules
        }
      >
        <C {...props}>{children}</C>
      </Form.Item>
    );
  };

const UploadDragger = FModalItemGen(Upload.Dragger);
const DragUpload = ({
  props,
  ...item
}: Parameters<typeof UploadDragger>[0]) => {
  return (
    <UploadDragger
      {...item}
      valuePropName="fileList"
      getValueFromEvent={normalizeUploadFile}
      props={{
        multiple: true,
        iconRender: () => <AntCloudOutlined />,
        customRequest: () => true,
        ...props,
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p>Click or drag images here to upload</p>
    </UploadDragger>
  );
};
const DragUploadFile = ({
  props,
  ...item
}: Parameters<typeof UploadDragger>[0]) => {
  return (
    <UploadDragger
      {...item}
      valuePropName="fileList"
      getValueFromEvent={normalizeUploadFile}
      props={{
        multiple: true,
        iconRender: () => <AntCloudOutlined />,
        customRequest: () => true,
        ...props,
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p>Click or drag file here to upload</p>
    </UploadDragger>
  );
};
const SingleImgDragUpload = ({
  props,
  lang,
  ...item
}: { lang?: string } & Parameters<typeof UploadDragger>[0]) => {
  return (
    <UploadDragger
      {...item}
      valuePropName="fileList"
      getValueFromEvent={(v) => v.fileList}
      props={{
        maxCount: 1,
        accept: ".png,.jpg,.jpeg",
        iconRender: () => <AntCloudOutlined />,
        customRequest: () => true,
        ...props,
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p>
        {lang === "es"
          ? "Haga clic o arrastre las imágenes aquí para cargarlas (.jpg, .png, .jpeg)"
          : "Click or drag images here to upload (.jpg,.png,.jpeg)"}
      </p>
    </UploadDragger>
  );
};
const CheckboxItem = FModalItemGen(Checkbox);
const CheckboxWithValuePropName = ({
  props,
  ...item
}: Parameters<typeof CheckboxItem>[0]) => {
  return <CheckboxItem {...item} valuePropName="checked" props={props} />;
};

const ImgDragUpload = ({
  props,
  required = true,
  ...item
}: Parameters<typeof DragUpload>[0] & {
  required?: boolean;
}) => {
  return (
    <DragUpload
      {...item}
      requiredMessage={required ? "Choose images" : undefined}
      required={required}
      props={{
        accept: ".png,.jpg,.jpeg",
        ...props,
      }}
    />
  );
};

const FModal = Object.assign(
  forwardRef(
    <V extends object>({ form, children, ...modal }: Props<V>, ref: any) => {
      const [formRef] = useForm<V>();
      const modalRef = useRef<BaseModalRef>(null);

      useImperativeHandle<FModalRef<V>, FModalRef<V>>(ref, () => ({
        form: formRef,
        open: noop,
        close: noop,
        ...modalRef.current,
      }));

      return (
        <BaseModal
          ref={modalRef}
          onCancel={(e) => {
            formRef.resetFields();
            modalRef.current?.close();
            modal?.onCancel && modal.onCancel(e);
          }}
          {...modal}
        >
          <CustomSuspense>
            <Form key="form" form={formRef} layout="vertical" {...form}>
              {children}
            </Form>
          </CustomSuspense>
        </BaseModal>
      );
    },
  ),
  {
    RadioGroup: FModalItemGen(Radio.Group),
    Checkbox: CheckboxWithValuePropName,
    Radio: FModalItemGen(Radio),
    AutoComplete: FModalItemGen(AutoComplete),
    Text: FModalItemGen(Input),
    Password: FModalItemGen(Input.Password),
    TextArea: FModalItemGen(TextArea),
    Date: FModalItemGen(DatePicker),
    Select: FModalItemGen(Select),
    DragUploadFile,
    SelOpt: FModalItemGen(Select.Option),
    DragUpload,
    SingleImgDragUpload,
    ImgDragUpload,
  },
);

export default FModal;

import { FC } from "react";
import {
  useInsertIncidentUserMutation,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
  useUpdateIncidentUserMutation,
  useUpdateIncidentEditByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentUser from "./incident-users/IncidentUser";
import * as uuid from "uuid";
import { Button } from "antd";
import { useSearchParams } from "react-router-dom";
import InjuryDetail from "./incident-users/InjuryDetail";
import InsuranceDetail from "./incident-users/InsuranceDetail";
import createIncidentPatch from "./../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../utils/IncidentTypeProps";

const IncidentManagementInjury: FC<IncidentTypeProps> = ({
  incident,
  refetch,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const injuredUserId = searchParams.get("id");
  const [insertIncidentUser] = useInsertIncidentUserMutation();
  const [updateIncidentUser] = useUpdateIncidentUserMutation();
  const [updateIncidentEdit] = useUpdateIncidentEditByPkMutation();
  const insertInjuredUser = async (
    id: string,
    name: string,
    subcontractorId?: string,
  ) => {
    if (
      incident.injured_users.findIndex(
        (injuredUser) => injuredUser.user.id === id && !injuredUser.deleted_at,
      ) !== -1
    ) {
      return;
    }
    if (
      incident.injured_users.findIndex(
        (injuredUser) => injuredUser.user.id === id && !!injuredUser.deleted_at,
      ) !== -1
    ) {
      const injuredUserId = incident.injured_users.find(
        (witness) => witness.user.id === id && !!witness.deleted_at,
      )?.id;
      const updatedIncident = {
        ...incident,
        injured_users: incident.injured_users.map((injuredUser) => {
          if (injuredUser.id === injuredUserId)
            return {
              ...injuredUser,
              deleted_at: null,
            };
          else return injuredUser;
        }),
      };
      const patch = createIncidentPatch(updatedIncident, incident);
      const comment = `Added injured user "${name}"`;
      if (injuredUserId) {
        await updateIncidentUser({
          variables: {
            id: injuredUserId,
            set: { deleted_at: null },
            objects: {
              patch: patch,
              comment: comment,
              edit_type: "injured-user-edit",
              incident_id: incident.id,
            },
          },
        });
        refetch();
        setSearchParams({ ...searchParams, id: injuredUserId });
      }
    }
    if (injuredUserId) {
      const prevInjuredUser = incident.injured_users.find(
        (injuredUser) => injuredUser.id === injuredUserId,
      )?.user.name;
      const comment = `Updated the injured user ${prevInjuredUser} to ${name}`;
      const { data: updatedIncidentUser } = await updateIncidentUser({
        variables: {
          id: injuredUserId,
          set: { user_id: id },
          objects: {
            comment: comment,
            edit_type: "incident-user-update",
            edited_by_uid: authUser.uid,
            incident_id: incident.id,
          },
        },
      });

      const updatedIncident = {
        ...incident,
        injured_users: incident.injured_users.map((injuredUser) => {
          if (
            injuredUser.user.id === id &&
            updatedIncidentUser?.update_incident_user_by_pk
          ) {
            return updatedIncidentUser.update_incident_user_by_pk;
          }
          return injuredUser;
        }),
      };
      const patch = createIncidentPatch(updatedIncident, incident);
      const incidentEditId =
        updatedIncidentUser?.insert_incident_edit?.returning.at(0)?.id;
      if (incidentEditId) {
        await updateIncidentEdit({
          variables: { id: incidentEditId, _set: { patch: patch } },
        });
      }
      refetch();
    } else {
      const injuredUserId = uuid.v4();
      let updatedIncident = incident;
      const comment = `Added injured user "${name}"`;
      const { data: insertedIncidentUserData } = await insertIncidentUser({
        variables: {
          object: {
            id: injuredUserId,
            user_id: id,
            project_id: incident.project_id,
            subcontractor_id: subcontractorId,
            type: "injured",
            incident_id: incident.id,
            injury_detail: {
              data: {
                insurance_claim: {
                  data: {},
                },
              },
            },
          },
          editObjects: {
            comment: comment,
            edited_by_uid: authUser.uid,
            incident_id: incident.id,
            edit_type: "injured-user-added",
          },
        },
        update(cache, result) {
          const addedUser = result.data?.insert_incident_user_one;
          if (!incident.injured_users || !addedUser) return;
          updatedIncident = {
            ...incident,
            injured_users: [...incident.injured_users, addedUser],
          };
          cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>(
            {
              data: {
                __typename: "query_root",
                incident_by_pk: updatedIncident,
              },
              query: GetIncidentByPkDocument,
            },
          );
        },
      });
      const addedIncidentUserId =
        insertedIncidentUserData?.insert_incident_user_one?.id;

      if (addedIncidentUserId) {
        setSearchParams({
          subview: "injured_user",
          id: addedIncidentUserId,
        });
      }
      const incidentEditId =
        insertedIncidentUserData?.insert_incident_user_one?.id;
      const patch = createIncidentPatch(updatedIncident, incident);
      if (incidentEditId) {
        await updateIncidentEdit({
          variables: {
            id: incidentEditId,
            _set: {
              patch: patch,
            },
          },
        });
      }
    }
  };

  return (
    <>
      <div className="absolute left-24 top-2 text-2">
        Injured Person Details
      </div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <IncidentUser
          key={injuredUserId}
          title=" Injured Person"
          incident={incident}
          incidentUserId={injuredUserId ?? undefined}
          onUpdateUser={async (
            id: string,
            name: string,
            subcontractorId?: string,
          ) => {
            insertInjuredUser(id, name, subcontractorId);
          }}
          userType="injured-user"
        />

        {injuredUserId && (
          <>
            <InjuryDetail
              key={`injury_detail ${injuredUserId}`}
              incident={incident}
              injuredUserId={injuredUserId}
            />
            <InsuranceDetail
              key={`insurance_detail ${injuredUserId}`}
              incident={incident}
              injuredUserId={injuredUserId}
            />
            <div className="flex w-4/5 justify-between mt-2">
              <Button
                className="w-6"
                type="primary"
                htmlType="submit"
                onClick={() => {
                  const index =
                    incident.injured_users.findIndex(
                      (injuredUser) => injuredUser.id === injuredUserId,
                    ) ?? 0;
                  if (index + 1 < (incident.injured_users.length ?? 0)) {
                    const nextInjuredUserId =
                      incident.injured_users[index + 1].id;
                    if (nextInjuredUserId) {
                      setSearchParams({
                        subview: "injured_user",
                        id: nextInjuredUserId,
                      });
                    }
                  } else {
                    setSearchParams({ subview: "root_cause" });
                  }
                }}
              >
                Save
              </Button>

              <Button
                className="w-6"
                type="primary"
                htmlType="submit"
                onClick={() => setSearchParams({ subview: "injured_user" })}
              >
                Next
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default IncidentManagementInjury;

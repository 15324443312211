import {
  InsertTasksMutation,
  InsertTasksMutation$data,
} from "src/common/types/generated/relay/InsertTasksMutation.graphql";
import type {
  task_insert_input,
} from "src/common/types/generated/relay/types";
import insertTasksMutation from "src/common/api/relay/mutations/InsertTasks";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { AddJHAToProjectOrCompanyQuery$data } from "src/common/types/generated/relay/AddJHAToProjectOrCompanyQuery.graphql";
import * as uuid from "uuid";
import { notification, message } from "antd";
import { auth } from "src/common/functions/firebase";
import dayjs from "dayjs";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";

type TaskToAdd =
  AddJHAToProjectOrCompanyQuery$data["task_connection"]["edges"][number]["node"];

const useAddTaskToProjectOrCompany = () => {
  const [insertTasks, loading] =
    useAsyncMutation<InsertTasksMutation>(insertTasksMutation);

  const addTaskToProjectOrCompany = async (
    tasks: Array<TaskToAdd>,
    subcontractorId: string,
    projectId: string | null,
    type: "addToProject" | "addToCompany",
  ) => {
    const taskInsertInput = tasks.map(
      (task): task_insert_input => ({
        company_task_id: task.pk,
        id: uuid.v4(),
        description: {
          data: {
            en: task.description.en,
            original: task.description.en,
          },
        },
        created_by_uid: task.created_by_uid,
        project_id: type == "addToProject" ? projectId : null,
        subcontractor_id: subcontractorId,
        creation_request_id: task.creation_request_id,
        accepted_at_from_creation_request:
          task.accepted_at_from_creation_request,
        accepted_by_uid_from_creation_request:
          task.accepted_by_uid_from_creation_request,
        document_id: task.document_id,
        task_steps: {
          data: task.task_steps.map((s, i) => ({
            sort_index: 10000 * (i + 1),
            description: {
              data: {
                en: s.description.en,
                original: s.description.en,
              },
            },
            task_step_hazards: {
              data: s.task_step_hazards.map((h, x) => ({
                sort_index: (x + 1) * 1000,
                description: {
                  data: {
                    en: h.description.en,
                    original: h.description.en,
                  },
                },
                control: {
                  data: {
                    en: h.control.en,
                    original: h.control.en,
                  },
                },
                task_hazard_ecm_types: {
                  data: h.task_hazard_ecm_types.map((e) => ({
                    ecm_type_id: e.ecm_type?.pk,
                  })),
                },
              })),
            },
          })),
        },
        task_permit_types: {
          data: task.task_permit_types.map((permit) => ({
            permit_type_id: permit.permit_type.pk,
          })),
        },
        task_ppe_types: {
          data: task.task_ppe_types.map((ppe) => ({
            ppe_type_id: ppe.ppe_type.pk,
          })),
        },
        task_edit: {
          data:
            type === "addToProject"
              ? [
                  {
                    edit_type: "Added to the Project",
                    edited_by_uid: auth.currentUser?.uid,
                    edited_at: dayjs().format(),
                  },
                ]
              : [],
        },
      }),
    );

    await insertTasks({
      variables: {
        objects: taskInsertInput,
      },
      updater: (store) => {
        const newVals = store.getRootField("insert_task");
        const conn1 = ConnectionHandler.getConnection(
          store.getRoot(),
          "SubProjectMobilizationView_task_connection",
        );

        if (conn1 && newVals) {
          newVals.getLinkedRecords("returning").forEach((val) => {
            const edge = store.create(uuid.v4(), "edge");
            edge.setLinkedRecord(val, "node");
            ConnectionHandler.insertEdgeAfter(conn1, edge);
          });
        }
      },
    })
      .then(() =>
        message.success(
          type == "addToCompany"
            ? `${
                tasks.length > 1 ? tasks.length + " Tasks" : "Task"
              } added to Company JHA Library`
            : `${
                tasks.length > 1 ? tasks.length + " Tasks" : "Task"
              } added to Project JHA Library`,
          5,
        ),
      )
      .catch((error: any) => {
        notification.error({
          message: "Error: ",
          description:
            error instanceof Error ? error.message : JSON.stringify(error),
          duration: null,
        });
      });
  };

  return [addTaskToProjectOrCompany, loading] as const;
};

export default useAddTaskToProjectOrCompany;

import React, { useState } from "react";
import FullScreenCamera from "../../camera/FullScreenCamera";
import { Button } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { NewCertificationImage } from "src/domain-features/siteorientation/components/documentCamera/SiteOrientationDocumentCameraNewDocument";
import * as uuid from "uuid";
import SiteFeatureStepsInputLayout from "../../layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "../../layouts/steps-navigation-layout/SiteFeatureStepLayout";
import CustomButton from "src/common/components/general/Button";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";
type ImageType = {
  id: string;
  url?: string;
  edited?: boolean;
};
export interface SiteFeatureStepsUIImagesProps {
  images: Array<ImageType>;
  nextButtonText?: string;
  onBack?: () => void;
  onNext: (newImages: Array<ImageType>) => Promise<void>;
  initiallyCameraOpen?: boolean;
  required: boolean;
  initiallyDirectlyAdd?: boolean;
  loading?: boolean;
  label?: string;
}

const SiteFeatureStepsUIImages: React.FC<SiteFeatureStepsUIImagesProps> = ({
  images,
  initiallyDirectlyAdd,
  onNext,
  onBack,
  initiallyCameraOpen,
  required,
  nextButtonText,
  loading,
  label,
}) => {
  const langStrings = useCurrentLangStrings();
  const [newImages, setNewImages] = useState<{
    [id: string]: Omit<ImageType, "id">;
  }>(
    Object.fromEntries(
      images.map((p) => [p.id, { url: p.url, edited: p.edited }]),
    ) ?? [],
  );
  const [editCameraOn, setEditCameraOn] = useState<string | undefined>();
  const [cameraOn, setCameraOn] = useState(!!initiallyCameraOpen);

  const onEnter = () =>
    onNext(
      Object.entries(newImages).map(([id, { url, edited }]) => ({
        id,
        url,
        edited,
      })),
    );

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={onEnter}
      loading={loading}
      nextButtonText={nextButtonText}
      onBackButtonClick={onBack}
      nextButtonDisabled={
        required
          ? Object.values(newImages).filter((url) => url).length === 0
          : false
      }
    >
      <SiteFeatureStepsInputLayout
        headline={
          <div className="flex gap-0.5">{langStrings.strings.addImages} </div>
        }
      >
        <div className={`flex flex-col gap-1`}>
          {label || langStrings.strings.takePhotosOfYouAreInspecting}
          <div className="text-1.5 flex items-center justify-center">
            <CustomButton
              label={"📷➕"}
              small
              secondary
              onClick={() => setCameraOn(true)}
            />
          </div>{" "}
          <div className={`flex flex-col gap-0.5 mt-1`}>
            <div className={`grid grid-cols-2 gap-1`}>
              {Object.entries(newImages).map(([id, { url }], i) =>
                url ? (
                  <div className={``} key={id}>
                    {editCameraOn === id && (
                      <FullScreenCamera
                        onCapture={([image]) => {
                          setNewImages((prev) => {
                            const newState = { ...prev };
                            newState[id] = { url: image, edited: true };
                            return newState;
                          });
                          setEditCameraOn(undefined);
                        }}
                        photoDimensions={{
                          width: 1080,
                          height: 1920,
                        }}
                        facingMode={"environment"}
                        onClose={() => {
                          setEditCameraOn(undefined);
                        }}
                      />
                    )}
                    <div className="grid relative z-0 grid-cols-[2rem,auto,2rem] grid-rows-[2rem,auto,2rem]">
                      <div className={`absolute top-0 right-0 z-20`}>
                        {url && (
                          <div className="flex justify-between flex-row">
                            <div>
                              <Button
                                icon={<EditOutlined />}
                                onClick={() => {
                                  setEditCameraOn(id);
                                }}
                                type={`link`}
                                className={`w-2 h-2`}
                              />
                              <div>
                                <Button
                                  icon={<DeleteOutlined />}
                                  onClick={() => {
                                    setNewImages((prev) => {
                                      const newState = { ...prev };
                                      newState[id] = { url: undefined };
                                      return newState;
                                    });
                                  }}
                                  type={`link`}
                                  className={`w-2 h-2 text-semantic-negative hover:text-semantic-negative`}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className={`col-start-1 col-end-3 row-start-1 row-end-3 z-10`}
                      >
                        <div className={`w-full h-full`}>
                          <div className={`w-full h-0 pb-[150%] relative`}>
                            <div
                              className={`h-full bg-interactive-primary w-full absolute shadow overflow-hidden border-interactive-secondary group-hover:border-interactive-primary inset-0 border rounded-0.5`}
                            >
                              {url ? <NewCertificationImage img={url} /> : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null,
              )}
            </div>
            {cameraOn && (
              <FullScreenCamera
                multiple
                onCapture={async (images) => {
                  const promises: Array<Promise<any>> = [];
                  setCameraOn(false);
                  setNewImages((prev) => {
                    const newState = { ...prev };
                    images.forEach((image) => {
                      newState[uuid.v4()] = { url: image, edited: true };
                    });
                    if (
                      initiallyDirectlyAdd &&
                      Object.values(prev).filter((p) => p.url).length === 0
                    ) {
                      promises.push(
                        onNext(
                          Object.entries(newState).map(
                            ([id, { url, edited }]) => ({
                              id,
                              url,
                              edited,
                            }),
                          ),
                        ),
                      );
                    }

                    return newState;
                  });
                  await Promise.all(promises);
                }}
                photoDimensions={{
                  width: 1080,
                  height: 1920,
                }}
                facingMode={"environment"}
                onClose={() => {
                  setCameraOn(false);
                }}
              />
            )}
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default SiteFeatureStepsUIImages;

import { Form, Input, notification, Select } from "antd";

import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { GCAddNewSubModal_Mutation } from "src/common/types/generated/relay/GCAddNewSubModal_Mutation.graphql";
import { GCAddNewSubModal_sub_Query } from "src/common/types/generated/relay/GCAddNewSubModal_sub_Query.graphql";
import { NotificationPlacement } from "antd/es/notification/interface";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

interface FormValues {
  businessName: string;
  ein: string;
  subcontractorTrade: string;
  pocName: string;
  pocPhoneNumber: string;
  pocEmail: string;
  pocTitleId: string;
}

interface SelectSubcontractorModalValues {
  subcontractorIds: Array<string>;
}

interface GCAddNewSubModalProps {
  projectId?: string;
  // replaced setFetchKey with onSubcontractorInserted because
  // we should  decouple interal logic of modal from outside logic
  // name of event should indetify action inside of modal
  // and should not depend on possible logic that parent component may do
  // it may refetch data or may do something else...
  // we should give them freedom to decide...
  onSubcontractorInserted?: () => void;
  onSubmit: () => void;
}

const tradeDataQuery = graphql`
  query GCAddNewSubModal_sub_Query {
    subcontractor_trade_connection(order_by: { name: asc }, where: {}) {
      edges {
        node {
          name
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    subcontractor_employee_title_connection(
      order_by: { name_text: asc }
      where: {}
    ) {
      edges {
        node {
          name_text
          pk: id @__clientField(handle: "pk")
        }
      }
    }
  }
`;

const successNotification = (placement: NotificationPlacement) => {
  notification.success({
    message: `Thanks for adding to the SiteForm network!`,
    description:
      "To avoid adding duplicate companies, a SiteForm representative will check our database to make sure this company doesn’t already exist. This should happen fast, but depends on our current load. Thanks for your patience.",
    placement,
    duration: 0,
  });
};

const failureNotification = (placement: NotificationPlacement) => {
  notification.error({
    message: `Unable to add this Subcontractor to SiteForm because of some error.`,
    description: (
      <>
        <div>
          Please email at{" "}
          <a href="mailto:support@siteform.io">support@siteform.io</a> for
          assistance.
        </div>
      </>
    ),
    placement,
    duration: 0,
  });
};

export type GCAddNewSubModalRef = FModalRef<FormValues> | null;
const GCAddNewSubModal = forwardRef<GCAddNewSubModalRef, GCAddNewSubModalProps>(
  ({ projectId, onSubcontractorInserted, onSubmit }, ref) => {
    const modal = useRef<FModalRef<FormValues>>(null);
    const [loading, setLoading] = useState(false);
    const tradeData = useLazyLoadQuery<GCAddNewSubModal_sub_Query>(
      tradeDataQuery,
      {},
    );

    const [requestToAdd, isInserting] =
      useAsyncMutation<GCAddNewSubModal_Mutation>(graphql`
        mutation GCAddNewSubModal_Mutation(
          $object: add_subcontractor_request_insert_input!
        ) {
          insert_add_subcontractor_request_one(object: $object) {
            id
          }
        }
      `);

    useImperativeHandle<GCAddNewSubModalRef, GCAddNewSubModalRef>(
      ref,
      () => modal.current,
    );
    const reset = () => {
      setLoading(false);
      modal.current?.form.resetFields();
      modal.current?.close();
    };
    return (
      <FModal
        width={"45%"}
        style={{ marginTop: "-40px" }}
        ref={modal}
        title="Add New Subcontractor or Trade Partner to SiteForm"
        okText="Add"
        cancelText="Cancel"
        confirmLoading={loading || isInserting}
        onCancel={() => {
          reset();
        }}
        onOk={async () => {
          onSubmit();
          const form = modal.current?.form;
          if (!form) return;
          setLoading(true);
          form
            .validateFields()
            .then(async (values) => {
              // console.log(values);
              await requestToAdd({
                variables: {
                  object: {
                    project_id: projectId,
                    request_by_uid: auth.currentUser?.uid,
                    subcontractor_business_name: values.businessName,
                    requested_trade_id: values.subcontractorTrade,
                    poc_name: values.pocName,
                    poc_email_address: values.pocEmail,
                    poc_phone_number: values.pocPhoneNumber,
                    poc_title_id: values.pocTitleId,
                    subcontractor_ein: values.ein,
                  },
                },
              });
            })
            .then(() => {
              reset();
              successNotification("topRight");
            })
            .catch((e) => {
              if (e?.errorFields && e.errorFields.length > 0) {
                console.log(e);
                setLoading(false);
              } else {
                reset();
                console.log(e);
                failureNotification("topRight");
              }
            });
        }}
      >
        <Form.Item
          name="businessName"
          label="Complete Business Name (as written on their contract)"
          rules={[
            {
              required: true,
              message: "Complete Business Name required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="ein"
          label="EIN (Employer Identification Number)"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="subcontractorTrade"
          label="Trade"
          rules={[{ required: true, message: `Choose the trade` }]}
        >
          <Select
            showSearch
            placeholder={"Trade"}
            filterOption={getNormalSelectOptionsFilter}
            options={tradeData.subcontractor_trade_connection.edges.map((c) => {
              return {
                label: c.node.name,
                value: c.node.pk,
              };
            })}
          />
        </Form.Item>
        <div className="flex content-center justify-center items-center text-semantic-negative">
          <div>
            Don’t see their trade? Email us at{"  "}
            <a href="mailto:support@siteform.io">support@siteform.io</a>
          </div>
        </div>
        <br></br>
        <hr className="bg-semantic-inactive-dark" />
        <div>
          <b>Point of Contact</b>: Your Administrator/POC that works for the
          Trade Partner/Subcontractor (Administrator, Project Manager, Safety
          Manager, etc.)
        </div>
        <br></br>
        <Form.Item
          name="pocName"
          label="First and last name"
          rules={[{ required: true, message: "POC name required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pocPhoneNumber"
          label="Phone number"
          rules={[{ required: true, message: "POC phone number required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pocEmail"
          label="Email address"
          rules={[{ required: true, message: "POC email required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="pocTitleId"
          label="Title"
          rules={[{ required: true, message: "POC title required" }]}
        >
          <Select
            showSearch
            placeholder={"Select title"}
            filterOption={getNormalSelectOptionsFilter}
            options={tradeData.subcontractor_employee_title_connection.edges.map(
              (c) => {
                return {
                  label: c.node.name_text,
                  value: c.node.pk,
                };
              },
            )}
          />
        </Form.Item>
        <div className="text-semantic-negative text-0.75">
          These users will have access to their company’s SiteForm website and
          worker profiles.
          <br></br>
          ** DO NOT add foremen or workers here.
        </div>
      </FModal>
    );
  },
);

export default withCustomSuspense(GCAddNewSubModal);

import {
  InspectionChecklistItemProps,
  InspectionInsertValues,
} from "./siteInspectionTypes";
import {
  inspection_template_checklist_item_insert_input,
  inspection_template_insert_input,
} from "src/common/types/generated/relay/types";
import { auth } from "src/common/functions/firebase";
import { generalChecklistType } from "./generalChecklistType";
import * as uuid from "uuid";

const getToInsertTemplateObjectFromInstance = (
  newValues: InspectionInsertValues,
  currentChecklistItems: {
    [type: string]: { [id: string]: InspectionChecklistItemProps };
  },
) => {
  const typeMap: { [type: string]: string } = {};
  const object: inspection_template_insert_input = {
    name: {
      data: {
        original: newValues.name,
        en: newValues.name,
      },
    },
    created_by_uid: auth.currentUser?.uid,
    is_multi_inspection: newValues.is_multi_inspection,
    allow_photo_to_checklist_item: newValues.allow_photo_to_checklist_item,
    image_required: newValues.image_required,
    model_number:
      newValues.model_number && newValues.model_number.trim()
        ? newValues.model_number.trim()
        : null,
    model_number_required: newValues.model_number_required,
    inspection_labels: {
      data: (newValues.labels || []).map((lb) => ({ label_id: lb })),
    },
    inspection_template_checklist_items: {
      data: Object.entries(currentChecklistItems).flatMap(([type, items]) => {
        return Object.values(items).map((item) => {
          let typeToInpsert: inspection_template_checklist_item_insert_input =
            {};
          if (type !== generalChecklistType) {
            if (typeMap[type]) {
              typeToInpsert = {
                inspection_checklist_item_type_id: typeMap[type],
              };
            } else {
              typeMap[type] = uuid.v4();
              typeToInpsert = {
                inspection_checklist_item_type: {
                  data: {
                    id: typeMap[type],
                    text: { data: { original: type, en: type } },
                  },
                },
              };
            }
          }
          return {
            correct_answer: item.correct_answer,
            description: {
              data: { original: item.description, en: item.description },
            },
            item_id: uuid.v4(),
            sort_index: item.sort_index,
            show_na: item.show_na,
            require_photo: item.require_photo,
            // ...(insertItemsId && item.template_item_id
            //   ? { id: item.template_item_id }
            //   : {}),
            ...typeToInpsert,
          };
        });
      }),
    },
  };

  return object;
};
export default getToInsertTemplateObjectFromInstance;

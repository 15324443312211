import { useState } from "react";
import { Button, Drawer } from "antd";
import IncidentSeverityMapping, {
  IncidentSeverityMappingProps,
} from "../IncidentSeverityMapping";
import { SettingOutlined } from "@ant-design/icons";

interface GCAdminIncidentsSettingsButtonProps
  extends IncidentSeverityMappingProps {}

const GCAdminIncidentsSettingsButton: React.FC<
  GCAdminIncidentsSettingsButtonProps
> = (props) => {
  const [showSettings, setShowSettings] = useState(false);
  return (
    <>
      <Button icon={<SettingOutlined />} onClick={() => setShowSettings(true)}>
        Settings
      </Button>
      <Drawer
        width={`85%`}
        onClose={() => setShowSettings(false)}
        open={showSettings}
      >
        <IncidentSeverityMapping {...props} />
      </Drawer>
    </>
  );
};

export default GCAdminIncidentsSettingsButton;

import {
  Button,
  DatePicker,
  Form,
  Input,
  message,
  Select,
  Switch,
  Upload,
} from "antd";
import React, { FC, useState } from "react";
import CustomButton from "src/common/components/general/Button";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import { IconCheck, IconInfoSquare, IconPencil, IconPlus } from "@tabler/icons";
import { useLazyLoadQuery } from "react-relay/hooks";
import dayjs from "dayjs";
import Image from "src/common/components/general/images/Image";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import * as uuid from "uuid";
import noop from "src/common/functions/noop";
import Icon from "src/common/components/general/Icon";
import TextArea from "antd/lib/input/TextArea";
import normalizeUploadFile from "src/common/functions/normalizeUploadFile";
import uploadFiles from "src/common/functions/upload-utility/uploadFiles";
import { insertUploadedTBTMutation } from "./ManageToolboxTalks";
import { useNavigate } from "react-router-dom";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import { auth } from "src/common/functions/firebase";
import { ManageToolboxTalksMutation } from "src/common/types/generated/relay/ManageToolboxTalksMutation.graphql";
import { UploadedToolboxTalkMutation } from "src/common/types/generated/relay/UploadedToolboxTalkMutation.graphql";
import { UploadedToolboxTalkQuery } from "src/common/types/generated/relay/UploadedToolboxTalkQuery.graphql";
import {
  image_insert_input,
  UploadedToolboxTalk_DeleteImage_Mutation,
} from "src/common/types/generated/relay/UploadedToolboxTalk_DeleteImage_Mutation.graphql";
import { UploadedToolboxTalk_UpdateTextTranslation_Mutation } from "src/common/types/generated/relay/UploadedToolboxTalk_UpdateTextTranslation_Mutation.graphql";

const UploadedToolboxTalk: FC<{
  community: boolean;
  uploaded_toolbox_talk_id: string;
  projectId?: string;
  gcId?: string;
  subId?: string;
  onFinish?: () => void;
  subs?: Array<{ id: string; name: string }>;
  projects?: Array<{ id: string; name: string }>;
}> = ({
  uploaded_toolbox_talk_id,
  projectId,
  subId,
  gcId,
  community,
  onFinish,
  subs,
  projects,
}) => {
  const userId = auth.currentUser?.uid;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [fetchKey, setFetchKey] = useState(0);
  const data = useLazyLoadQuery<UploadedToolboxTalkQuery>(
    graphql`
      query UploadedToolboxTalkQuery($id: uuid!) {
        uploaded_toolbox_talk_connection(where: { id: { _eq: $id } }, first: 1)
          @connection(
            key: "UploadedToolboxTalk_uploaded_toolbox_talk_connection"
            filters: []
          ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              images(order_by: { sort_index: asc }) {
                url
                id
                pk: id @__clientField(handle: "pk")
              }
              general_contractor_id
              subcontractor_id
              uploaded_toolbox_talk_projects(
                order_by: { project: { name: asc } }
              ) {
                project_id
                project {
                  name
                }
              }
              uploaded_toolbox_talk_subcontractors(
                order_by: { subcontractor: { name: asc } }
              ) {
                subcontractor_id
                subcontractor {
                  name
                }
              }
              assign_to
              added_to_community
              content_id
              content {
                en
                id
              }
              due_time
              name
            }
          }
        }
      }
    `,
    { id: uploaded_toolbox_talk_id },
    { fetchKey, fetchPolicy: "network-only" },
    //using null Id here to handle cases for showing TBT to sub when a project is not selected
  ).uploaded_toolbox_talk_connection.edges[0].node;

  const [edit, setEdit] = useState(false);
  const [insertUploadedTBT, addingToLib] =
    useAsyncMutation<ManageToolboxTalksMutation>(insertUploadedTBTMutation);
  const [updateUploadedTBT] = useAsyncMutation<UploadedToolboxTalkMutation>(
    graphql`
      mutation UploadedToolboxTalkMutation(
        $_set: uploaded_toolbox_talk_set_input!
        $projectsRelationsToDelete: uploaded_toolbox_talk_project_bool_exp!
        $newProjectsRelationsToInsert: [uploaded_toolbox_talk_project_insert_input!]!
        $subcontractorsRelationsToDelete: uploaded_toolbox_talk_subcontractor_bool_exp!
        $newSubcontractorsRelationsToInsert: [uploaded_toolbox_talk_subcontractor_insert_input!]!
        $id: uuid!
      ) {
        insert_uploaded_toolbox_talk_project(
          objects: $newProjectsRelationsToInsert
        ) {
          affected_rows
        }
        delete_uploaded_toolbox_talk_project(
          where: $projectsRelationsToDelete
        ) {
          affected_rows
        }
        insert_uploaded_toolbox_talk_subcontractor(
          objects: $newSubcontractorsRelationsToInsert
        ) {
          affected_rows
        }
        delete_uploaded_toolbox_talk_subcontractor(
          where: $subcontractorsRelationsToDelete
        ) {
          affected_rows
        }
        update_uploaded_toolbox_talk_by_pk(
          pk_columns: { id: $id }
          _set: $_set
        ) {
          id
          name
          due_time
          added_to_community
          subcontractor_id
          assign_to
          uploaded_toolbox_talk_projects {
            project_id
          }
          content {
            en
          }
        }
      }
    `,
  );
  const [insertAndDeleteImages] =
    useAsyncMutation<UploadedToolboxTalk_DeleteImage_Mutation>(graphql`
      mutation UploadedToolboxTalk_DeleteImage_Mutation(
        $idsToDelete: [uuid!]!
        $objectsToInsert: [image_insert_input!]!
      ) {
        delete_image(where: { id: { _in: $idsToDelete } }) {
          affected_rows
        }
        insert_image(objects: $objectsToInsert) {
          affected_rows
        }
      }
    `);
  const [updateTextTranslation] =
    useAsyncMutation<UploadedToolboxTalk_UpdateTextTranslation_Mutation>(graphql`
      mutation UploadedToolboxTalk_UpdateTextTranslation_Mutation(
        $object: text_translation_insert_input!
      ) {
        insert_text_translation_one(
          object: $object
          on_conflict: {
            constraint: text_translation_pkey
            update_columns: [original]
          }
        ) {
          id
          original
          en
        }
      }
    `);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [imageIdsToDelete, setImageIdsToDelete] = useState<{
    [imageId: string]: boolean;
  }>({});

  return (
    <>
      {community ? (
        <div className="float-right">
          <BPopconfirm
            onConfirm={async () => {
              await insertUploadedTBT({
                variables: {
                  object: {
                    added_to_community: false,
                    name: data.name,
                    created_by_uid: userId,
                    ...(data.content
                      ? { content: { data: { original: data.content.en } } }
                      : {}),
                    images: {
                      data: data.images.map((p, i) => ({
                        url: p.url,
                        sort_index: i,
                        description: "Toolbox Talk",
                      })),
                    },
                    ...(gcId
                      ? {
                          general_contractor_id: gcId,
                          assign_to: "all-subcontractors",
                          uploaded_toolbox_talk_projects: {
                            data: [{ project_id: projectId }],
                          },
                        }
                      : subId && projectId
                      ? {
                          subcontractor_id: subId,
                          uploaded_toolbox_talk_projects: {
                            data: [{ project_id: projectId }],
                          },
                        }
                      : subId
                      ? { subcontractor_id: subId, assign_to: "all-projects" }
                      : {}),
                  },
                },
              });
              onFinish && onFinish();
            }}
          >
            <CustomButton
              loading={addingToLib}
              icon={IconPlus}
              label={projectId ? "Add to Project" : "Add to Your Library"}
              onClick={() => {}}
            />
          </BPopconfirm>
        </div>
      ) : (
        <div className="float-right">
          <CustomButton
            loading={loading}
            icon={edit ? IconCheck : IconPencil}
            onClick={async () => {
              if (edit) {
                try {
                  setLoading(true);
                  await form.validateFields().then(async (values) => {
                    const content_id = data.content_id ?? uuid.v4();
                    const content = values.content
                      ? values.content.trim().replace(/\s(?=\s)/g, "")
                      : null;
                    const name = values.name
                      ? values.name.trim().replace(/\s(?=\s)/g, "")
                      : null;
                    if (!name)
                      throw new Error("Topic of Toolbox Talk is required");
                    content
                      ? await updateTextTranslation({
                          variables: {
                            object: { original: content, id: content_id },
                          },
                        })
                      : noop();
                    const [subsToInsert, subsToDelete] = compareTwoLists(
                      values.subsSelected ?? [],
                      data.uploaded_toolbox_talk_subcontractors.map(
                        (s) => s.subcontractor_id,
                      ),
                    );
                    const [projectsToInsert, projectsToDelete] =
                      compareTwoLists(
                        values.projectsSelected ?? [],
                        data.uploaded_toolbox_talk_projects.map(
                          (s) => s.project_id,
                        ),
                      );
                    await updateUploadedTBT({
                      variables: {
                        newSubcontractorsRelationsToInsert: subId
                          ? []
                          : subsToInsert.map((s) => ({
                              subcontractor_id: s,
                              uploaded_toolbox_talk_id,
                            })),
                        subcontractorsRelationsToDelete: {
                          uploaded_toolbox_talk_id: {
                            _eq: uploaded_toolbox_talk_id,
                          },
                          subcontractor_id: { _in: subId ? [] : subsToDelete },
                        },
                        newProjectsRelationsToInsert: gcId
                          ? []
                          : projectsToInsert.map((s) => ({
                              project_id: s,
                              uploaded_toolbox_talk_id,
                            })),
                        projectsRelationsToDelete: {
                          uploaded_toolbox_talk_id: {
                            _eq: uploaded_toolbox_talk_id,
                          },
                          project_id: { _in: gcId ? [] : projectsToDelete },
                        },
                        id: uploaded_toolbox_talk_id,
                        _set: {
                          name: name,
                          added_to_community: values.addedToCommunityLib,
                          assign_to:
                            (gcId &&
                              values.subsSelected &&
                              values.subsSelected.length > 0) ||
                            (subId &&
                              values.projectsSelected &&
                              values.projectsSelected.length > 0)
                              ? null
                              : gcId
                              ? "all-subcontractors"
                              : "all-projects",
                          ...(values.dueDate
                            ? {
                                due_time: dayjs(values.dueDate)
                                  .endOf("day")
                                  .toISOString(),
                              }
                            : { due_time: null }),
                          ...(content ? { content_id } : {}),
                        },
                      },
                    });
                  });

                  const imagesToDelete = Object.entries(imageIdsToDelete)
                    .filter(([_, toDelete]) => toDelete)
                    .map(([imageId, _]) => imageId);
                  const imagesToInsert: Array<image_insert_input> = [];

                  await form2
                    .validateFields()
                    .then(
                      async (values2: {
                        images: Array<{ originFileObj: File }>;
                      }) => {
                        console.log(values2);
                        await uploadFiles(
                          (values2.images || []).map((o) => o.originFileObj),
                        ).then((d) => {
                          d.forEach((p, i) => {
                            if (p?.url)
                              imagesToInsert.push({
                                url: p.url,
                                parent_id: uploaded_toolbox_talk_id,
                                description: "Toolbox Talk",
                                sort_index: data.images.length + i + 1,
                              });
                          });
                        });
                      },
                    );
                  await insertAndDeleteImages({
                    variables: {
                      idsToDelete: imagesToDelete,
                      objectsToInsert: imagesToInsert,
                    },
                  });
                  setLoading(false);
                  form.resetFields();
                  form2.resetFields();
                  setFetchKey((i) => i + 1);
                } catch (error) {
                  message.error(
                    `ERROR: ${
                      error instanceof Error
                        ? error.message
                        : JSON.stringify(error)
                    }`,
                  );
                  setLoading(false);
                }
              }
              setEdit((i) => !i);
            }}
            label={edit ? "Save" : "Edit"}
          ></CustomButton>
        </div>
      )}
      <div className={`mt-0.5 mb-1 text-1 font-accent`}>
        {edit ? (
          <Form
            form={form}
            initialValues={{
              addedToCommunityLib: data.added_to_community,
              name: data.name,
              content: data.content?.en,
              projectsSelected: data.uploaded_toolbox_talk_projects.map(
                (p) => p.project_id,
              ),
              subsSelected: data.uploaded_toolbox_talk_subcontractors.map(
                (s) => s.subcontractor_id,
              ),
              dueDate: data.due_time ? dayjs(data.due_time) : undefined,
            }}
          >
            <Form.Item
              name="addedToCommunityLib"
              label={
                <div className="text-1">
                  <div className="flex">
                    <span className="mr-0.5">
                      {" Add Toolbox Talk to Community Library"}
                    </span>
                    &nbsp; &nbsp;
                    <Icon
                      icon={IconInfoSquare}
                      hoverContent={{
                        content: (
                          <div className="w-24">
                            <div className="font-accent">
                              Toolbox Talk Community Library
                            </div>
                            <br />
                            <div>
                              When it comes to safety, everyone is on the same
                              team. Help build a community toolbox talk library.
                            </div>
                            <div>
                              By adding a TBT to the community library you are
                              sharing and helping workers across the country.
                              And other users are helping your workers.
                            </div>
                            <div>
                              We do not disclose who added the toolbox talk or
                              from what company. But we will show you how many
                              workers have used it.
                            </div>
                            <div>
                              You can remove a toolbox talk from the community
                              at any time.
                            </div>
                            <div>
                              Disclaimer: any community database within SiteForm
                              is provided for informational purposes only and is
                              not monitored for the accuracy or reliability of
                              the information contained within it. Email us at{" "}
                              <a
                                href="mailto:support@siteform.io"
                                className="text-interactive-primary underline"
                              >
                                support@siteform.io
                              </a>{" "}
                              about any issues with community content.
                            </div>
                          </div>
                        ),
                      }}
                    />
                  </div>
                </div>
              }
            >
              <Switch
                defaultChecked={data.added_to_community}
                className="ml-1"
              />
            </Form.Item>
            <Form.Item label={<div className="text-1">Topic</div>} name="name">
              <Input size="large" />
            </Form.Item>
            <Form.Item
              label={<div className="text-1 mr-1">Toolbox Talk Content</div>}
              name="content"
            >
              <TextArea
                rows={20}
                style={{ width: 1000 }}
                placeholder={
                  data.content
                    ? undefined
                    : "Add your toolbox talk text and information here"
                }
              />
            </Form.Item>
            <Form.Item
              label={<div className="text-1 flex">Due Date</div>}
              name="dueDate"
            >
              <DatePicker
                disabledDate={(date) =>
                  date.isSameOrBefore(dayjs().subtract(1, "day").endOf("day"))
                }
              />
            </Form.Item>
            {subs && (
              <Form.Item
                name="subsSelected"
                label={
                  <div className="text-1">
                    Select Subscontractors to assign this TBT to
                  </div>
                }
              >
                <Select
                  placeholder="All Subcontractors are Selected by Default"
                  mode="multiple"
                >
                  {subs.map((s) => (
                    <Select.Option key={s.id} value={s.id}>
                      {s.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            {projects && (
              <Form.Item
                name="projectsSelected"
                label={
                  <div className="text-1">
                    Select Projects to assign this TBT to
                  </div>
                }
              >
                <Select
                  placeholder="All Projects are Selected by Default"
                  mode="multiple"
                >
                  {projects.map((p) => (
                    <Select.Option key={p.id} value={p.id}>
                      {p.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form>
        ) : (
          <div>
            <div className="mt-2">
              <span className="font-normal">Topic</span>: {data.name}
            </div>
            {data.content && (
              <div className="m-0.5 p-1 bg-suplementary-2 pb-2 font-normal">
                {data.content.en}
              </div>
            )}

            {!community && data.due_time && (
              <p className={`pt-1.5 mb-1 text-1 font-accent`}>
                Due Date: {dayjs(data.due_time).format("MM/DD/YYYY")}
              </p>
            )}
            {!community && (
              <div>
                {data.assign_to === "all-projects" ? (
                  "This TBT is assigned to all projects under your company."
                ) : data.assign_to === "all-subcontractors" ? (
                  "This TBT is assigned to all subcontractors under this project."
                ) : subId ? (
                  <div>
                    This Toolbox talk is assigned to:{" "}
                    {data.uploaded_toolbox_talk_projects.map((cur) => (
                      <span
                        className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
                        key={cur.project_id}
                      >
                        {cur.project.name}
                      </span>
                    ))}
                  </div>
                ) : gcId ? (
                  <div>
                    This Toolbox talk is assigned to:{" "}
                    {data.uploaded_toolbox_talk_subcontractors.map((cur) => (
                      <span
                        className="mx-0.25 text-0.75 px-0.5 py-0.25 rounded-2 bg-suplementary-1"
                        key={cur.subcontractor_id}
                      >
                        {cur.subcontractor.name}
                      </span>
                    ))}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {data.images.length > 0 && (
        <div className="text-1 font-accent mn-1 flex">
          Images ({data.images.length}){" "}
        </div>
      )}
      <div className={data.images.length <= 3 ? "flex" : ""}>
        <div className="mr-0.5 ml-0.25 flex flex-row flex-wrap w-full">
          {data.images.map((image, i) => {
            return (
              <div
                key={i}
                className={
                  edit && imageIdsToDelete[image.pk]
                    ? "border-1 border-semantic-negative border-solid max-w-24 min-w-20"
                    : "max-w-24 min-w-20"
                }
              >
                {edit && (
                  <BPopconfirm
                    title={
                      imageIdsToDelete[image.pk]
                        ? " Do not delete this image"
                        : "Are you sure you want to delete this image?"
                    }
                    onConfirm={async (e) => {
                      setImageIdsToDelete((prev) => ({
                        ...prev,
                        [image.pk]: !prev[image.pk],
                      }));
                    }}
                  >
                    <div className="relative">
                      <Button
                        type="link"
                        className=" absolute opacity-80 text-white text-1.5 float-right p-1"
                      >
                        {imageIdsToDelete[image.pk] ? "✔️" : "X"}
                      </Button>
                    </div>
                  </BPopconfirm>
                )}
                <Image src={image.url} preview={!edit} />
              </div>
            );
          })}
        </div>
        <div>
          {edit && (
            <Form form={form2}>
              <Form.Item
                name="images"
                valuePropName="fileList"
                getValueFromEvent={normalizeUploadFile}
              >
                <Upload
                  // customRequest={() => true}
                  accept=".png,.jpg,.jpeg"
                  listType="picture-card"
                >
                  <Icon icon={IconPlus} color="interactive" />
                </Upload>
              </Form.Item>
            </Form>
          )}
        </div>
      </div>
    </>
  );
};

export default withCustomSuspense(UploadedToolboxTalk);

/**
 * @generated SignedSource<<95884c0cc7046d8fff61ec03be52a986>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddLogoModalGCInsertLogoMutation$variables = {
  GCId: string;
  logoUrl?: string | null | undefined;
};
export type AddLogoModalGCInsertLogoMutation$data = {
  readonly update_general_contractor: {
    readonly returning: ReadonlyArray<{
      readonly logo_url: string | null | undefined;
    }>;
  } | null | undefined;
};
export type AddLogoModalGCInsertLogoMutation = {
  response: AddLogoModalGCInsertLogoMutation$data;
  variables: AddLogoModalGCInsertLogoMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "GCId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "logoUrl"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "logo_url",
        "variableName": "logoUrl"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "GCId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo_url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddLogoModalGCInsertLogoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "general_contractor_mutation_response",
        "kind": "LinkedField",
        "name": "update_general_contractor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddLogoModalGCInsertLogoMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "general_contractor_mutation_response",
        "kind": "LinkedField",
        "name": "update_general_contractor",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "general_contractor",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6bd52150a5f5bb571caa8c28cc49bab3",
    "id": null,
    "metadata": {},
    "name": "AddLogoModalGCInsertLogoMutation",
    "operationKind": "mutation",
    "text": "mutation AddLogoModalGCInsertLogoMutation(\n  $GCId: uuid!\n  $logoUrl: String\n) {\n  update_general_contractor(where: {id: {_eq: $GCId}}, _set: {logo_url: $logoUrl}) {\n    returning {\n      logo_url\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a367bc176dee05a61d722bce1ff729b";

export default node;

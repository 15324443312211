/**
 * @generated SignedSource<<313de126abb5ce00122305f2b38258ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type GCNewProjectStepSubcontractorsSubadminWorkersQuery$variables = {
  subIds: ReadonlyArray<string>;
};
export type GCNewProjectStepSubcontractorsSubadminWorkersQuery$data = {
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly pk: string;
        readonly subcontractor_employee: {
          readonly subcontractor_id: string;
        } | null | undefined;
      };
    }>;
  };
};
export type GCNewProjectStepSubcontractorsSubadminWorkersQuery = {
  response: GCNewProjectStepSubcontractorsSubadminWorkersQuery$data;
  variables: GCNewProjectStepSubcontractorsSubadminWorkersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subIds"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "_in",
                "variableName": "subIds"
              }
            ],
            "kind": "ObjectValue",
            "name": "subcontractor_id"
          }
        ],
        "kind": "ObjectValue",
        "name": "subcontractor_employee"
      },
      {
        "kind": "Literal",
        "name": "worker",
        "value": {}
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCNewProjectStepSubcontractorsSubadminWorkersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id_pk",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor_employee",
                    "kind": "LinkedField",
                    "name": "subcontractor_employee",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCNewProjectStepSubcontractorsSubadminWorkersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": "pk",
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": "pk",
                    "args": null,
                    "filters": null,
                    "handle": "pk",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor_employee",
                    "kind": "LinkedField",
                    "name": "subcontractor_employee",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7fb70b6cde2482e906d21a6821f9a57c",
    "id": null,
    "metadata": {},
    "name": "GCNewProjectStepSubcontractorsSubadminWorkersQuery",
    "operationKind": "query",
    "text": "query GCNewProjectStepSubcontractorsSubadminWorkersQuery(\n  $subIds: [uuid!]!\n) {\n  user_connection(where: {subcontractor_employee: {subcontractor_id: {_in: $subIds}}, worker: {}}) {\n    edges {\n      node {\n        pk: id\n        subcontractor_employee {\n          subcontractor_id\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "61ddd29cfc5a47488759e5fbf69c000f";

export default node;

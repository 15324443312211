import { Checkbox, Space } from "antd";
import React, { useState } from "react";
import StyledContent from "src/common/components/layouts/StyledContent";
import {
  useUpdatePermitProjectByPkMutation,
  useUpdateProjectSettingByPkForPermitMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { work_above_elevation_bool_exp } from "src/common/types/generated/relay/WorkAboveElevationTableQuery.graphql";
import WorkAboveElevationTable from "../../table/WorkAboveElevationTable";
import { PermitSettingsComponentProps } from "../../utils/PermitSettingsTypes";
import PermitGcProjectSettingCheckbox from "./basic/PermitGcProjectSettingCheckbox";
import ShowPermitChecklistsTables from "./basic/ShowPermitChecklistsTables";

const WorkAboveSettings: React.FC<PermitSettingsComponentProps> = ({
  gcPermitCheckListItems,
  queryVariables,
  data,
  project,
  checklistGc,
  mainGc,
}) => {
  const [elevationFilter, setElevationFilter] =
    useState<work_above_elevation_bool_exp>({});
  const [updateProject] = useUpdatePermitProjectByPkMutation();
  const [updateProjectSetting] = useUpdateProjectSettingByPkForPermitMutation();
  const projSettings = project?.project_setting;
  return (
    <>
      <PermitGcProjectSettingCheckbox
        {...{
          mainGc,
          project_setting: project?.project_setting,
          field: 'only_submitter_sign_the_work_above_permit'
        }}
      />
      {project && (
        <>
          <StyledContent>
            <Checkbox
              checked={project.prevent_work_above_submission}
              onChange={(e) => {
                const set = {
                  prevent_work_above_submission: e.target.checked,
                };
                updateProject({
                  variables: { id: project.id, _set: set },
                  optimisticResponse: {
                    update_project_by_pk: { ...project, ...set },
                  },
                });
              }}
            >
              Turn On Prevent Work Above Submssion if expired Permit is not
              closed
            </Checkbox>
          </StyledContent>
          {projSettings && (
            <StyledContent>
              <Checkbox
                checked={project.project_setting?.is_work_above_elevation}
                onChange={(e) => {
                  const set = { is_work_above_elevation: e.target.checked };
                  updateProjectSetting({
                    variables: { _set: set, project_id: project.id },
                    optimisticResponse: {
                      update_project_setting_by_pk: { ...projSettings, ...set },
                    },
                  });
                }}
              >
                Turn On to Add Protection
              </Checkbox>
              <Space />
              {projSettings.is_work_above_elevation && (
                <WorkAboveElevationTable
                  where={{
                    ...elevationFilter,
                    project_id: { _eq: project.id },
                  }}
                  typeOptions={data.work_above_elevation_type}
                  projectId={project.id}
                  customFilters={[
                    {
                      type: "radio",
                      title: "Completed",
                      removeFilter: () => setElevationFilter({}),
                      options: [
                        {
                          option: "Completed",
                          onClick: () => {
                            setElevationFilter({
                              completed_at: { _is_null: false },
                            });
                          },
                        },
                        {
                          option: "Not Completed",
                          onClick: () => {
                            setElevationFilter({
                              completed_at: { _is_null: true },
                            });
                          },
                        },
                      ],
                    },
                  ]}
                />
              )}
            </StyledContent>
          )}
        </>
      )}
      <ShowPermitChecklistsTables
        {...{
          checklistGc,
          data,
          project,
          gcPermitCheckListItems,
          queryVariables,
          checklistItemsType: "work_above",
        }}
      />
    </>
  );
};
export default WorkAboveSettings;

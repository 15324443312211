/**
 * @generated SignedSource<<62d30ffb08cd2001c5ffeb6a3e4c7612>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type CsrProjectOrientationsQuery$variables = {
  projectId: string;
};
export type CsrProjectOrientationsQuery$data = {
  readonly orientation_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created_at: string;
        readonly id: string;
        readonly name: string;
        readonly pk: string;
        readonly project_id: string | null | undefined;
        readonly project_orientations: ReadonlyArray<{
          readonly hide_but_give_credit: boolean;
          readonly id: string;
          readonly orientation_id: string;
          readonly pk: string;
          readonly play_during_in_person: boolean;
          readonly play_during_registration: boolean;
          readonly project_id: string | null | undefined;
          readonly required_by_all_workers: boolean;
        }>;
        readonly type: string;
        readonly user: {
          readonly employee: {
            readonly general_contractor: {
              readonly name: string;
            };
          } | null | undefined;
          readonly name: string;
        };
      };
    }>;
  };
};
export type CsrProjectOrientationsQuery = {
  response: CsrProjectOrientationsQuery$data;
  variables: CsrProjectOrientationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v7 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "_eq",
        "variableName": "projectId"
      }
    ],
    "kind": "ObjectValue",
    "name": "id"
  }
],
v8 = [
  {
    "fields": [
      {
        "fields": (v7/*: any*/),
        "kind": "ObjectValue",
        "name": "linked_orientation_projects"
      }
    ],
    "kind": "ObjectValue",
    "name": "project"
  }
],
v9 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "created_at": "asc"
    }
  },
  {
    "fields": (v8/*: any*/),
    "kind": "ObjectValue",
    "name": "where"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orientation_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hide_but_give_credit",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "play_during_in_person",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "play_during_registration",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "required_by_all_workers",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v18 = {
  "_is_null": true
},
v19 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": [
      {
        "order": "asc"
      },
      {
        "name": "asc"
      }
    ]
  },
  {
    "fields": [
      {
        "items": [
          {
            "fields": [
              {
                "items": [
                  {
                    "kind": "Literal",
                    "name": "_or.0",
                    "value": {
                      "general_contractor_id": (v18/*: any*/),
                      "project_orientations": {
                        "project_id": (v18/*: any*/),
                        "required_by_all_workers": {
                          "_eq": true
                        }
                      }
                    }
                  },
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "fields": (v7/*: any*/),
                            "kind": "ObjectValue",
                            "name": "projects"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "general_contractor"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "_or.1"
                  }
                ],
                "kind": "ListValue",
                "name": "_or"
              }
            ],
            "kind": "ObjectValue",
            "name": "_and.0"
          },
          {
            "fields": [
              {
                "items": [
                  {
                    "kind": "Literal",
                    "name": "_or.0",
                    "value": {
                      "project_id": (v18/*: any*/)
                    }
                  },
                  {
                    "fields": (v8/*: any*/),
                    "kind": "ObjectValue",
                    "name": "_or.1"
                  }
                ],
                "kind": "ListValue",
                "name": "_or"
              }
            ],
            "kind": "ObjectValue",
            "name": "_and.1"
          }
        ],
        "kind": "ListValue",
        "name": "_and"
      },
      {
        "kind": "Literal",
        "name": "deleted_at",
        "value": (v18/*: any*/)
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v20 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v21 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CsrProjectOrientationsQuery",
    "selections": [
      {
        "alias": "orientation_connection",
        "args": null,
        "concreteType": "orientationConnection",
        "kind": "LinkedField",
        "name": "__CsrProjectOrientationsQuery_orientation_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "project_orientation",
                    "kind": "LinkedField",
                    "name": "project_orientations",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v10/*: any*/),
                      (v6/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CsrProjectOrientationsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v19/*: any*/),
        "concreteType": "orientationConnection",
        "kind": "LinkedField",
        "name": "orientation_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "orientationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "orientation",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_employee",
                        "kind": "LinkedField",
                        "name": "employee",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "project_orientation",
                    "kind": "LinkedField",
                    "name": "project_orientations",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v10/*: any*/),
                      (v6/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              },
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v19/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "CsrProjectOrientationsQuery_orientation_connection",
        "kind": "LinkedHandle",
        "name": "orientation_connection"
      }
    ]
  },
  "params": {
    "cacheID": "76ee816af098ed0a96b728ffeb4896b3",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "orientation_connection"
          ]
        }
      ]
    },
    "name": "CsrProjectOrientationsQuery",
    "operationKind": "query",
    "text": "query CsrProjectOrientationsQuery(\n  $projectId: uuid!\n) {\n  orientation_connection(where: {deleted_at: {_is_null: true}, _and: [{_or: [{general_contractor_id: {_is_null: true}, project_orientations: {project_id: {_is_null: true}, required_by_all_workers: {_eq: true}}}, {general_contractor: {projects: {id: {_eq: $projectId}}}}]}, {_or: [{project_id: {_is_null: true}}, {project: {linked_orientation_projects: {id: {_eq: $projectId}}}}]}]}, order_by: [{order: asc}, {name: asc}], first: 10000) {\n    edges {\n      node {\n        id\n        pk: id\n        name\n        type\n        created_at\n        project_id\n        user {\n          name\n          employee {\n            general_contractor {\n              name\n              id\n            }\n            id\n          }\n          id\n        }\n        project_orientations(where: {project: {linked_orientation_projects: {id: {_eq: $projectId}}}}, order_by: {created_at: asc}) {\n          id\n          pk: id\n          orientation_id\n          project_id\n          hide_but_give_credit\n          play_during_in_person\n          play_during_registration\n          required_by_all_workers\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cbb32ac0ba601332ac27eacffcf68314";

export default node;

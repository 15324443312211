/**
 * @generated SignedSource<<8dfb8fb36e2fbd4e7b789319b5dadc31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VisitorsTable_project_visitor_certification$data = {
  readonly project_visitor_certification_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly comment: string | null | undefined;
        readonly created_at: string | null | undefined;
        readonly email: string | null | undefined;
        readonly general_contractor: {
          readonly id: string;
          readonly name: string;
        } | null | undefined;
        readonly hours_planning_to_stay: number;
        readonly name: string;
        readonly oac_company: {
          readonly id: string;
          readonly name: string;
        } | null | undefined;
        readonly pk: string;
        readonly project: {
          readonly timezone: string;
        };
        readonly subcontractor: {
          readonly id: string;
          readonly name: string;
        } | null | undefined;
        readonly visitor_company: {
          readonly id: string;
          readonly name: string;
        } | null | undefined;
      };
    }>;
  };
  readonly " $fragmentType": "VisitorsTable_project_visitor_certification";
};
export type VisitorsTable_project_visitor_certification$key = {
  readonly " $data"?: VisitorsTable_project_visitor_certification$data;
  readonly " $fragmentSpreads": FragmentRefs<"VisitorsTable_project_visitor_certification">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "project_visitor_certification_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./VisitorsTableRefetchableQuery.graphql')
    }
  },
  "name": "VisitorsTable_project_visitor_certification",
  "selections": [
    {
      "alias": "project_visitor_certification_connection",
      "args": null,
      "concreteType": "project_visitor_certificationConnection",
      "kind": "LinkedField",
      "name": "__VisitorsTable_project_visitor_certification_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project_visitor_certificationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "project_visitor_certification",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": "pk",
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__id_pk",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hours_planning_to_stay",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "project",
                  "kind": "LinkedField",
                  "name": "project",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "timezone",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "comment",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "general_contractor",
                  "kind": "LinkedField",
                  "name": "general_contractor",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "subcontractor",
                  "kind": "LinkedField",
                  "name": "subcontractor",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "oac_company",
                  "kind": "LinkedField",
                  "name": "oac_company",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "visitor_company",
                  "kind": "LinkedField",
                  "name": "visitor_company",
                  "plural": false,
                  "selections": (v2/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "5566428e7d186e25bb086bf660da3bd5";

export default node;

import React, { FC } from "react";
import { Image as ImageType } from "src/common/types/generated/apollo/graphQLTypes";
import Image from "../general/images/Image";

const ShowMultipleImageLayout: FC<{
  images: Array<Omit<ImageType, "__typename" | "created_at">>;
  showSmaller?: boolean;
}> = ({ images, showSmaller }) => {
  return (
    <div className="flex flex-row flex-wrap">
      {images.map((img, x) => (
        <div
          className={showSmaller ? "w-1/12 font-accent" : "w-3/12 font-accent"}
          key={img.id}
        >
          <Image
            src={showSmaller ? img.sm_url || img.url : img.url}
            alt={"Image " + (x + 1)}
            preview
            previewSrc={img.url}
            // blurhash={img.blurhash}
          />
        </div>
      ))}
    </div>
  );
};
export default ShowMultipleImageLayout;

import { AutoComplete } from "antd";
import { RefSelectProps } from "antd/lib/select";
import React, { FC, useEffect, useRef, useState } from "react";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

const AutoCompleteCustom: FC<{
  options: { label: string; value: string }[];
  initialSelectedOptionValue: string | undefined;
  initialText?: string;
  placeholder?: string;
  minTextLengthToShowOption?: number;
  onSelect: (val: string | undefined) => void;
  onTextChange: (text: string) => void;
}> = ({
  initialSelectedOptionValue,
  options,
  onSelect,
  onTextChange,
  initialText,
  placeholder = "Type here",
  minTextLengthToShowOption = 0,
}) => {
  const [selectOption, setSelectOption] = useState<
    | {
        label: string;
        value: string;
      }
    | undefined
  >(options.find((w) => w.value === initialSelectedOptionValue));
  const onSelectOption = (
    option: { label: string; value: string } | undefined,
  ) => {
    setSelectOption(option);
    onSelect(option?.value);
  };
  const selectRef = useRef<RefSelectProps>(null);
  const [openDropDown, setOpenDropDown] = useState(false);
  const handleFoucusOut = (e: any) => setOpenDropDown(false);
  useEffect(() => {
    document.addEventListener("focusout", handleFoucusOut);
    return () => document.removeEventListener("focusout", handleFoucusOut);
  }, []);

  const [text, setText] = useState(initialText || "");
  return (
    <AutoComplete
      placeholder={placeholder}
      value={selectOption?.label || text}
      ref={selectRef}
      options={options}
      className="w-full"
      showSearch
      dropdownStyle={{
        display: `${openDropDown ? "block" : "none"}`,
      }}
      onClick={() => {
        if (!minTextLengthToShowOption) {
          setOpenDropDown(true);
        }
      }}
      onKeyDown={(e) => {
        if (e.key === "Enter") setOpenDropDown(false);
      }}
      onSearch={(input) =>
        setOpenDropDown(input.length >= minTextLengthToShowOption)
      }
      onFocus={(e) => {
        window.scrollTo({
          top: e.target.getBoundingClientRect().top - window.innerHeight / 2,
          behavior: "smooth",
        });
      }}
      onSelect={(val, option) => {
        selectRef.current?.blur();
        if (val && option.label)
          onSelectOption(
            option.value
              ? {
                  label: option.label.toString(),
                  value: `${option.value}`,
                }
              : undefined,
          );
      }}
      onChange={(e) => {
        onSelectOption(undefined);
        setText(e);
        onTextChange(e);
      }}
      filterOption={getNormalSelectOptionsFilter}
    />
  );
};
export default AutoCompleteCustom;

import { Button, message, Popconfirm, Space } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { FC, useRef, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { useNavigate } from "react-router-dom";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import SubcontractorProps from "src/common/types/manual/SubcontractorProps";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import SdsTable, { SDS } from "./SdsTable";
import noPropagation from "src/common/functions/noPropagation";
import SelectProjectForSdsModal from "./SelectProjectForSdsModal";
import { SdsTable_sds$data } from "src/common/types/generated/relay/SdsTable_sds.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import UploadSdsModal from "./UploadSdsModal";
import UploadSDSToSiteFormModal from "../../job-hazard-analysis/components/modals/UploadSdsToSiteformModal";
import { SCSds_ProjectQuery } from "src/common/types/generated/relay/SCSds_ProjectQuery.graphql";
import * as uuid from "uuid";
import { auth } from "src/common/functions/firebase";
import dayjs from "dayjs";
import { SCSds_UpdateSdsMutation } from "src/common/types/generated/relay/SCSds_UpdateSdsMutation.graphql";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import PdfViewer from "src/common/components/pdf/PdfViewer";
import CustomButton from "src/common/components/general/Button";
import type { safety_data_sheet_insert_input, safety_data_sheet_bool_exp } from "src/common/types/generated/relay/types";
import {
  SCSds_InsertSdsMutation,
} from "src/common/types/generated/relay/SCSds_InsertSdsMutation.graphql";
import AddSdsFromCompanyLibraryModal from "src/domain-features/sitesafety/safety-data-sheets/components/AddSdsFromCompanyLibraryModal";

const query = graphql`
  query SCSds_ProjectQuery($subcontractorId: uuid!) {
    project_subcontractor_connection(
      where: { subcontractor_id: { _eq: $subcontractorId } }
      order_by: { project: { name: asc } }
    ) {
      edges {
        node {
          project {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
        }
      }
    }

    safety_data_sheet_connection(
      where: { project_id: { _is_null: false }, deleted_at: { _is_null: true } }
      order_by: { product_name: asc }
    ) {
      edges {
        node {
          ...SdsFrag @relay(mask: false)
        }
      }
    }
  }
`;

const SubAdminSds: FC<SubcontractorProps> = ({ subcontractorId }) => {
  const [hintVisible, setHintVisible] = useState(true);
  const [openSiteFormUploadModal, setOpenSiteFormUploadModal] = useState(false);
  const [openSdsUploadToSiteformModal, setOpenSdsUploadToSiteformModal] =
    useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [openUploadSdsFromOtherProjectsModal, setOpenSDSFromOtherProjectModal] =
    useState(false);
  const [selectedSds, setSelectedSds] = useState<SDS>();
  const [selectProjectModal, setSelectProjectModal] = useState(false);
  const [tableWhere, setTableWhere] = useState<safety_data_sheet_bool_exp>({
    subcontractor_id: { _eq: subcontractorId },
    project_id: { _is_null: true },
    deleted_at: { _is_null: true },
  });
  const [allSdsVisible, setAllSdsVisible] = useState(false);
  const sdsTableRef = useRef<DataScrollTableRef>(null);

  const data = useLazyLoadQuery<SCSds_ProjectQuery>(query, {
    subcontractorId: subcontractorId,
  });
  const projectList = data.project_subcontractor_connection.edges.map((itr) => {
    return { id: itr.node.project.pk, name: itr.node.project.name };
  });

  const [updateSds, updating] = useAsyncMutation<SCSds_UpdateSdsMutation>(
    graphql`
      mutation SCSds_UpdateSdsMutation(
        $_set: safety_data_sheet_set_input
        $where: safety_data_sheet_bool_exp!
      ) {
        update_safety_data_sheet(_set: $_set, where: $where) {
          affected_rows
        }
      }
    `,
  );

  const [insertSds, loading] = useAsyncMutation<SCSds_InsertSdsMutation>(
    graphql`
      mutation SCSds_InsertSdsMutation(
        $object: safety_data_sheet_insert_input!
      ) {
        insert_safety_data_sheet_one(object: $object) {
          ...SdsFrag @relay(mask: false)
        }
      }
    `,
  );

  const onDelete = async (
    sds: SdsTable_sds$data["safety_data_sheet_connection"]["edges"][number]["node"],
  ) => {
    await updateSds({
      variables: {
        _set: { deleted_at: dayjs().toISOString() },
        where: { id: { _eq: sds.pk } },
      },
    })
      .then(async (d) => {
        sdsTableRef.current?.refetch();
        message.success("Sds Deleted");
      })
      .catch((e) => console.log(e));
  };

  const addToCompany = async (sds: SDS) => {
    if (!sds) {
      return;
    }
    const sdsInsertInput: safety_data_sheet_insert_input = {
      created_by_uid: auth.currentUser?.uid,
      project_id: null,
      subcontractor_id: subcontractorId,
      company_name: sds.company_name,
      product_name: sds.product_name,
      reference_number: sds.reference_number,
      issue_date: sds.issue_date,
      url: sds.url,
      file_type: sds.file_type,
      id: uuid.v4(),
      company_safety_data_sheet_id: sds.pk,
    };
    await insertSds({
      variables: {
        object: { ...sdsInsertInput },
      },
    })
      .then(async (d) => {
        message.success("Added to Company Library");
      })
      .catch((e) => console.log(e));
  };

  const otherProjectsSdsData = data.safety_data_sheet_connection.edges.map(
    (sds) => sds.node,
  );

  return (
    <>
      <SelectProjectForSdsModal
        visible={selectProjectModal}
        subcontractorId={subcontractorId}
        data={projectList}
        sds={selectedSds}
        onClose={() => {
          setSelectProjectModal(false);
          setHintVisible(true);
        }}
        onSubmit={() => {
          setSelectProjectModal(false);
          setHintVisible(true);
        }}
      />
      <PdfViewer
        visible={pdfModal}
        onClose={() => {
          setPdfModal(false);
        }}
        pdfUrl={pdfUrl}
      />

      <UploadSDSToSiteFormModal
        visible={openSdsUploadToSiteformModal}
        subcontractorId={subcontractorId}
        onClose={() => {
          setOpenSdsUploadToSiteformModal(false);
          setHintVisible(true);
        }}
        onSubmit={() => {
          setOpenSdsUploadToSiteformModal(false);
          setHintVisible(true);
        }}
      />

      <UploadSdsModal
        visible={openSiteFormUploadModal}
        subcontractorId={subcontractorId}
        onClose={() => setOpenSiteFormUploadModal(false)}
        onSubmit={() => {
          setOpenSiteFormUploadModal(false);
          sdsTableRef.current?.refetch();
        }}
      />

      <AddSdsFromCompanyLibraryModal
        closeModal={() => setOpenSDSFromOtherProjectModal(false)}
        subcontractorId={subcontractorId}
        visible={openUploadSdsFromOtherProjectsModal}
        title="Add SDS from Projects"
        refresh={() => sdsTableRef.current?.refetch()}
        tableWhere={{
          deleted_at: { _is_null: true },
          project_id: { _is_null: false },
        }}
        showProjectColumn
      />
      {/* <BSpace direction="vertical">
        <h3 className="text-1.25">Company SDS</h3>
      </BSpace> */}
      <SdsTable
        loadAll
        title={allSdsVisible ? "SDS Database" : "Company SDS Library"}
        ref={sdsTableRef}
        where={tableWhere}
        excludedKeys={
          allSdsVisible
            ? [
                "projectName",
                "addedBy",
                "addedDate",
                "delete",
                "select",
                "subcontractorName",
                "addToProjects",
              ]
            : ["projectName", "addToProjects", "select", "subcontractorName"]
        }
        onSdsRowClick={(sds) => {
          setPdfUrl(sds.url);
          setPdfModal(true);
        }}
        loading={updating}
        topBarButtons={[
          {
            label: "+ Upload New",
            secondary: true,
            onClick: () => setHintVisible(true),
            hint: hintVisible ? (
              <div>
                {" "}
                <div
                  className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                  onClick={() => {
                    setOpenSdsUploadToSiteformModal(true);
                    setHintVisible(false);
                  }}
                >
                  Easy Upload (not instant)
                </div>
                <div
                  className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                  onClick={() => {
                    setOpenSiteFormUploadModal(true);
                    setHintVisible(false);
                  }}
                >
                  Manual Upload (instant)
                </div>
                <div
                  className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                  onClick={() => {
                    setOpenSDSFromOtherProjectModal(true);
                    setHintVisible(false);
                  }}
                >
                  SDS from projects (instant)
                </div>
              </div>
            ) : undefined,
          },
        ]}
        headerComponent={
          <CustomButton
            secondary
            large
            label={allSdsVisible ? "Company SDS Library" : "SDS Database"}
            onClick={() => {
              allSdsVisible
                ? setTableWhere({
                    subcontractor_id: { _eq: subcontractorId },
                    project_id: { _is_null: true },
                    deleted_at: { _is_null: true },
                  })
                : setTableWhere({
                    deleted_at: { _is_null: true },
                    company_safety_data_sheet_id: { _is_null: true },
                  });
              allSdsVisible ? setAllSdsVisible(false) : setAllSdsVisible(true);
            }}
          />
        }
        extraColumns={[
          allSdsVisible
            ? {
                title: "Action",
                dataIndex: [""],
                size: "ml",
                key: "action",
                render: (_, sds) => {
                  return (
                    <>
                      <Popconfirm
                        title="Are you sure?"
                        onConfirm={noPropagation(() => {
                          addToCompany(sds);
                        })}
                        onCancel={noPropagation()}
                        okText="Yes"
                        cancelText="Cancel"
                      >
                        <Button type="link" onClick={noPropagation()}>
                          Add to Company SDS Library
                        </Button>
                      </Popconfirm>
                    </>
                  );
                },
                onCellClick: (record: any, event: any) => {
                  console.log(record);
                },
              }
            : {
                title: "Action",
                dataIndex: [""],
                size: "ml",
                key: "action",
                render: (_, sds) => {
                  return (
                    <>
                      <Button
                        type="link"
                        onClick={noPropagation(() => {
                          setSelectedSds(sds);
                          setSelectProjectModal(true);
                        })}
                      >
                        Add to Project
                      </Button>
                    </>
                  );
                },
                onCellClick: (record: any, event: any) => {},
              },
          {
            title: "",
            dataIndex: [""],
            size: "md",
            key: "delete",
            render: (_: any, sds: SDS) => (
              <>
                <Space>
                  <Popconfirm
                    title="Are you sure?"
                    onConfirm={noPropagation(() => {
                      onDelete(sds);
                    })}
                    onCancel={noPropagation()}
                    okText="Yes"
                    cancelText="Cancel"
                  >
                    <Button danger type="link" onClick={noPropagation()}>
                      Delete
                    </Button>
                  </Popconfirm>
                </Space>
              </>
            ),
          },
        ]}
      />
    </>
  );
};
const SCSdsWrapper: FC = () => {
  const subcontractorId = useSubcontractorId();
  return (
    <BasicWrapper scrollable>
      <SubAdminSds subcontractorId={subcontractorId} />
    </BasicWrapper>
  );
};
export default withCustomSuspense(SCSdsWrapper);

import { Button, DatePicker, Form, message, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { FC, useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { VerifyCertsQuery } from "src/common/types/generated/relay/VerifyCertsQuery.graphql";
import { VerifyMultipleCertsModal_InsertCertsMutation } from "src/common/types/generated/relay/VerifyMultipleCertsModal_InsertCertsMutation.graphql";
import { VerifyMultipleCertsModal_UpdateMutation } from "src/common/types/generated/relay/VerifyMultipleCertsModal_UpdateMutation.graphql";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

export const updateCertsToVerifyMutation = graphql`
  mutation VerifyMultipleCertsModal_UpdateMutation(
    $certId: uuid!
    $_set: certificates_to_verify_set_input
  ) {
    update_certificates_to_verify_by_pk(
      pk_columns: { id: $certId }
      _set: $_set
    ) {
      id
      pk: id @__clientField(handle: "pk")
      verified_at
      status
    }
  }
`;
export const insertCertsMutation = graphql`
  mutation VerifyMultipleCertsModal_InsertCertsMutation(
    $objects: [worker_certification_insert_input!]!
  ) {
    insert_worker_certification(objects: $objects) {
      affected_rows
      returning {
        id
        pk: id @__clientField(handle: "pk")
      }
    }
  }
`;

interface VerifyMultipleCertsModalProps {
  Modal: { visible: boolean; onCancel: () => void };
  onSuccess: () => void;
  loggedInUserId: string;
  workerId: string;
  certId: string;
  frontImage: VerifyCertsQuery["response"]["certificates_to_verify_connection"]["edges"][0]["node"]["front_image"];
  certifications: VerifyCertsQuery["response"]["certification_connection"]["edges"];
  backImage?: VerifyCertsQuery["response"]["certificates_to_verify_connection"]["edges"][0]["node"]["back_image"];
}

const VerifyMultipleCertsModal: FC<VerifyMultipleCertsModalProps> = (props) => {
  const [form] = Form.useForm();
  const [maxCerts, setMaxCerts] = useState(2);
  const [updateMutation, updatingStatus] =
    useAsyncMutation<VerifyMultipleCertsModal_UpdateMutation>(
      updateCertsToVerifyMutation,
    );
  const [insertCerts, isInserting] =
    useAsyncMutation<VerifyMultipleCertsModal_InsertCertsMutation>(
      insertCertsMutation,
    );

  const list = [];
  for (let i = 0; i < 100; i++) {
    list.push(i);
  }
  return (
    <Modal
      {...props.Modal}
      confirmLoading={isInserting || updatingStatus}
      onOk={async () => {
        await updateMutation({
          variables: {
            certId: props.certId,
            _set: {
              verified_at: dayjs().format(),
              verified_by_uid: props.loggedInUserId,
            },
          },
        });
        let insertedCerts = 0;
        for (let i = 1; i <= maxCerts; i++) {
          const values = form.getFieldsValue([
            "type " + i,
            "issueDate " + i,
            "expireDate " + i,
          ]);

          if (values["type " + i]) {
            insertedCerts++;
            insertCerts({
              variables: {
                objects: [
                  {
                    certification_id: values["type " + i],
                    expires_on: values["expireDate " + i]
                      ? dayjs(values["expireDate " + i]).format()
                      : null,
                    issued_on: values["issueDate " + i]
                      ? dayjs(values["issueDate " + i]).format()
                      : null,
                    verification_id: props.certId,
                    worker_id: props.workerId,
                    privacy_setting: { data: {} },
                    images: {
                      data: props.backImage?.url
                        ? [
                            {
                              ...props.frontImage,
                              created_by_user_id: props.workerId,
                            },
                            {
                              ...props.backImage,
                              created_by_user_id: props.workerId,
                            },
                          ]
                        : [
                            {
                              ...props.frontImage,
                              created_by_user_id: props.workerId,
                            },
                          ],
                    },
                  },
                ],
              },
            }).catch((e) => console.error(e));
          }
        }
        message.success("Inserted " + insertedCerts + " Certificates");
        form.resetFields();
        props.onSuccess();
      }}
    >
      <Form
        layout="horizontal"
        name="form_in_modal"
        form={form}
        className="mt-2"
        // onFinish={(values) => onSubmit(values, c)}
      >
        {list.map(
          (_, i) =>
            i + 1 <= maxCerts && (
              <div key={i}>
                {i > 0 && <hr className="mb-1" />}
                <h1 className="text-1 font-accent">
                  Certification {i + 1}{" "}
                  {i + 1 > 2 && (
                    <Button
                      className="font-accent"
                      type="primary"
                      onClick={() => setMaxCerts((i) => Math.max(2, i - 1))}
                    >
                      Remove -
                    </Button>
                  )}
                </h1>
                <br />
                <Form.Item
                  name={`type ${i + 1}`}
                  label={<p className="text1">Type</p>}
                  rules={[
                    {
                      required: true,
                      message: `Select the type of Certificate to Continue`,
                    },
                  ]}
                >
                  {props.certifications.length && (
                    <Select
                      showSearch
                      className="w-full"
                      size="large"
                      placeholder={"Select type of certificate"}
                      filterOption={getNormalSelectOptionsFilter}
                      options={props.certifications.map((c) => {
                        return {
                          label: c.node.name,
                          value: c.node.pk,
                        };
                      })}
                    />
                  )}
                </Form.Item>
                <br />
                <Form.Item
                  name={`issueDate ${i + 1}`}
                  label={<div style={{ fontSize: "15px" }}>Issue Date</div>}
                >
                  <DatePicker size="large" />
                </Form.Item>

                <Form.Item
                  name={`expireDate ${i + 1}`}
                  label={<div style={{ fontSize: "15px" }}>Expire date</div>}
                >
                  <DatePicker size="large" />
                </Form.Item>
              </div>
            ),
        )}
      </Form>
      <Button type="primary" onClick={() => setMaxCerts((i) => i + 1)}>
        Add More +
      </Button>
    </Modal>
  );
};
export default VerifyMultipleCertsModal;

import { Button, Card, Spin } from "antd";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import PieChart from "src/common/components/charts/PieChart";
import { titleCase } from "src/common/functions/stringFormatters";
import {
  GetObservationOptionsQueryVariables,
  useEmailObservationInspectionMutation,
  useGenerateObservationInspectionPdfMutation,
  useGetObservationInspectionDataQuery,
  useGetObservationOptionsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import { graphColors } from "../../../common/constants/graphColors";
import ObservationTable from "./ObservationTable";
import {
  ArrowLeftOutlined,
  DownloadOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import getGroupOptionsFromObservationOptionsData from "../utils/getGroupOptionsFromObservationOptionsData";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import { useRef, useState } from "react";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import ObservationDistributedUserTable from "./ObservationDistributedUserTable";
import useAuthUser from "src/common/hooks/useAuthUser";
import ColumnChart from "../../../common/components/charts/ColumnChart";
import ChartWrapperCard from "../../../common/components/charts/ChartWrapperCard";

const ObservationInspectionDetail: React.FunctionComponent = () => {
  const { auditId, projectId } = useParams();
  if (!auditId || !projectId)
    throw new Error(
      `Missing ${projectId ? "projectId" : "Observation Inspection Id"} param`,
    );
  const { data, loading, error } = useGetObservationInspectionDataQuery({
    variables: { id: auditId },
  });
  const [openDistributeModal, setOpenDistributeModal] = useState(false);
  const navigate = useNavigate();
  const fmodalRef = useRef<
    FModalRef<{
      distributeTo: Array<string>;
      safe: boolean;
      open: boolean;
      corrected: boolean;
    }>
  >();
  const authUser = useAuthUser();
  const [emailAudit, { loading: emailing }] =
    useEmailObservationInspectionMutation();
  const [generatePdf, { loading: downloading }] =
    useGenerateObservationInspectionPdfMutation();
  const optionsVariables: GetObservationOptionsQueryVariables = {
    projectWorkerWhere: { project_id: { _eq: projectId } },
    projectId,
    userId: authUser.uid,
    subWhere: {
      subcontractor_projects: { project_id: { _eq: projectId } },
    },
  };
  const {
    data: optionsData,
    error: err2,
    loading: loading2,
    client,
  } = useGetObservationOptionsQuery({
    fetchPolicy: "cache-first",
    variables: optionsVariables,
  });
  if (loading || loading2) return <Spin />;
  if (error || err2) throw error || err2;
  if (!optionsData) throw new Error("Options data not found");
  const options = getGroupOptionsFromObservationOptionsData(optionsData);
  const obsIns = data?.observation_inspection_by_pk;
  if (!obsIns) throw new Error("Observation Inspection not found");
  const safetyObsMap: {
    [key: string]: { name: string; value: number; color: string };
  } = {};
  const subObsMap: {
    [key: string]: {
      name: string;
      safe: number;
      unsafe: number;
    };
  } = {};

  obsIns.observations.forEach((ob) => {
    if (ob.risk_level_value && safetyObsMap[ob.risk_level_value]) {
      safetyObsMap[ob.risk_level_value].value += ob.number_of_findings;
    } else if (ob.risk_level)
      safetyObsMap[ob.risk_level_value!] = {
        value: ob.number_of_findings,
        name: ob.risk_level?.name,
        color:
          ob.risk_level_value === "safe"
            ? graphColors.green
            : ob.risk_level_value === "low"
            ? graphColors.yellow
            : ob.risk_level_value === "medium"
            ? graphColors.orange
            : ob.risk_level_value === "high"
            ? graphColors.red
            : graphColors.darkRed,
      };
    if (ob.subcontractor_id) {
      if (subObsMap[ob.subcontractor_id]) {
        ob.risk_level_value === "safe"
          ? (subObsMap[ob.subcontractor_id].safe += ob.number_of_findings)
          : (subObsMap[ob.subcontractor_id].unsafe += ob.number_of_findings);
      } else {
        subObsMap[ob.subcontractor_id] = {
          name: ob.subcontractor!.name,
          ...(ob.risk_level_value === "safe"
            ? { safe: ob.number_of_findings, unsafe: 0 }
            : { safe: 0, unsafe: ob.number_of_findings }),
        };
      }
    }
  });
  const subsData: Array<{
    value: number;
    xField: string;
    valueTitle: string;
    color: string;
  }> = [];
  Object.values(subObsMap).forEach((v) => {
    if (v.unsafe)
      subsData.push({
        xField: v.name,
        value: v.unsafe,
        valueTitle: "unsafe",
        color: graphColors.red,
      });
    if (v.safe)
      subsData.push({
        xField: v.name,
        value: v.safe,
        valueTitle: "safe",
        color: graphColors.green,
      });
  });
  const safetyObsList = Object.values(safetyObsMap).filter((v) => v.value);
  return (
    <div className="w-full flex flex-col gap-1 pt-1">
      <div className="flex justify-between">
        <div>
          <Button onClick={() => navigate(-1)} icon={<ArrowLeftOutlined />}>
            Back
          </Button>
        </div>
        <div className="flex-row">
          <Button
            className="mr-1"
            onClick={async () => {
              const { data } = await generatePdf({
                variables: {
                  input: { observationInspectionId: auditId, projectId },
                },
              });
              if (data) {
                downloadFromUrl(data.generateObservationInspectionPdf);
              }
            }}
            loading={downloading}
            icon={<DownloadOutlined />}
            type="primary"
          >
            Download
          </Button>
          <Button
            onClick={() => {
              setOpenDistributeModal(true);
            }}
            loading={emailing}
            icon={<ShareAltOutlined />}
            type="primary"
          >
            Distribute
          </Button>{" "}
        </div>
      </div>
      <FModal
        open={openDistributeModal}
        onCancel={() => setOpenDistributeModal(false)}
        ref={fmodalRef}
        okText="Send"
        onOk={async () => {
          const vals = await fmodalRef.current?.form.validateFields();
          console.log(vals);
          if (!vals) return;
          setOpenDistributeModal(false);
          emailAudit({
            variables: {
              input: {
                observationInspectionId: auditId,
                projectId,
                open: vals.open,
                corrected: vals.corrected,
                safe: vals.safe,
                distributeToIds: vals.distributeTo,
              },
            },
          });
        }}
      >
        <FModal.Select
          required
          requiredMessage="Select atleast one user"
          name={"distributeTo"}
          label={"Select users to distribute to"}
          props={{
            mode: "multiple",
            options: options,
            filterOption: (input, option) => {
              if (option?.label && input.length > 0) {
                return (
                  option.label
                    .toLocaleLowerCase()
                    .indexOf(input.toLocaleLowerCase()) !== -1
                );
              }
              return false;
            },
          }}
        />
        <div className="flex">
          <FModal.Checkbox name={"open"} />
          <span className="mt-0.5 ml-1">Open</span>
        </div>
        <div className="flex">
          <FModal.Checkbox name={"corrected"} />
          <span className="mt-0.5 ml-1">Corrected</span>
        </div>
        <div className="flex">
          <FModal.Checkbox name={"safe"} />
          <span className="mt-0.5 ml-1">Safe</span>
        </div>
      </FModal>
      <Card>
        <div className="text-1.25">
          {" "}
          Inspection:{" "}
          {obsIns.observation_inspection_option
            ? obsIns.observation_inspection_option.name
            : "General"}
        </div>
        <div className="text-1.2">
          {" "}
          Started On: {dayjs(obsIns.start_at).format("MMMM Do, YYYY")}
        </div>
        {obsIns.submitted_on && (
          <div className="text-1.2">
            {" "}
            Submitted On: {dayjs(obsIns.submitted_on).format("MMMM Do, YYYY")}
          </div>
        )}
      </Card>
      <div className="grid grid-cols-2 gap-1">
        <ChartWrapperCard title={`Risk Level  VS Observation`}>
          <PieChart
            label={[
              "name",
              (name: string) => ({
                content: (record: any) =>
                  `${titleCase(name)} - ${Math.round(100 * record.percent)}%`,
              }),
            ]}
            chartCfg={{
              autoFit: true,
              height: 300,
              // width: 350,
              padding: [10, 110, 10, 30],
            }}
            tooltip={{
              config: {
                showTitle: false,
                showMarkers: false,
              },
              displayAs: [
                "name*value",
                (name, value) => ({
                  name: titleCase(name),
                  value: `${value} Observation`,
                }),
              ],
            }}
            legend={{
              show: true,
              options: {
                position: "right",
                itemName: {
                  formatter(text: string) {
                    return titleCase(text);
                  },
                },
              },
            }}
            interactions={["tooltip", "element-active"]}
            color={{
              field: "name",
              colors: safetyObsList.map((o) => o.color),
            }}
            dataSource={safetyObsList}
          />
        </ChartWrapperCard>
        <ChartWrapperCard>
          <ColumnChart dataSource={subsData} />
        </ChartWrapperCard>
      </div>
      <ObservationTable
        where={{
          observation_inspection_id: { _eq: auditId },
          status: { _neq: "draft" },
        }}
        onRowClick={(row) =>
          navigate(
            `/gce/projects/${projectId}/observations/observations/${row.id}`,
          )
        }
        excludedKeys={["project_name"]}
        projectId={projectId}
      />
      {obsIns.submitted_on ? (
        <ObservationDistributedUserTable
          where={{ observation_inspection_id: { _eq: auditId } }}
        />
      ) : null}
    </div>
  );
};
export default ObservationInspectionDetail;

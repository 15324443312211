/**
 * @generated SignedSource<<e939b49610f0feeae621b113d423dc71>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SCProjectsQuery$variables = {
  subId: string;
  userId: string;
};
export type SCProjectsQuery$data = {
  readonly project_subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly pk: string;
        readonly project: {
          readonly completed: boolean;
          readonly general_contractor: {
            readonly name: string;
          };
          readonly general_contractors: ReadonlyArray<{
            readonly general_contractor: {
              readonly name: string;
            };
          }>;
          readonly name: string;
          readonly pk: string;
          readonly project_subcontractors: ReadonlyArray<{
            readonly tasks: ReadonlyArray<{
              readonly id: string;
            }>;
            readonly tasks_aggregate: {
              readonly aggregate: {
                readonly count: number;
              } | null | undefined;
            };
          }>;
        };
        readonly project_subcontractor_employees: ReadonlyArray<{
          readonly id: string;
        }>;
        readonly project_workers_aggregate: {
          readonly aggregate: {
            readonly count: number;
          } | null | undefined;
        };
      };
    }>;
  };
  readonly worker_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly user: {
          readonly email: string | null | undefined;
          readonly name: string;
          readonly phone_number: string | null | undefined;
          readonly pk: string;
        };
      };
    }>;
  };
};
export type SCProjectsQuery = {
  response: SCProjectsQuery$data;
  variables: SCProjectsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "name": "asc"
},
v2 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "subId"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "project": (v1/*: any*/)
    }
  },
  {
    "fields": [
      {
        "fields": [
          {
            "fields": (v2/*: any*/),
            "kind": "ObjectValue",
            "name": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "subcontractor"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "project_worker_aggregate",
  "kind": "LinkedField",
  "name": "project_workers_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "project_worker_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v7/*: any*/)
],
v9 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "_eq",
              "variableName": "userId"
            }
          ],
          "kind": "ObjectValue",
          "name": "subcontractor_employee_id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "project_subcontractor_employee",
  "kind": "LinkedField",
  "name": "project_subcontractor_employees",
  "plural": true,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "general_contractor",
  "kind": "LinkedField",
  "name": "general_contractor",
  "plural": false,
  "selections": [
    (v10/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "fields": [
    {
      "fields": (v2/*: any*/),
      "kind": "ObjectValue",
      "name": "subcontractor_id"
    }
  ],
  "kind": "ObjectValue",
  "name": "where"
},
v14 = [
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": (v14/*: any*/),
  "concreteType": "task_aggregate",
  "kind": "LinkedField",
  "name": "tasks_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "task_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": (v14/*: any*/),
  "concreteType": "task",
  "kind": "LinkedField",
  "name": "tasks",
  "plural": true,
  "selections": (v8/*: any*/),
  "storageKey": null
},
v17 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "user": (v1/*: any*/)
    }
  },
  (v13/*: any*/)
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
},
v20 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v21 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "general_contractor",
  "kind": "LinkedField",
  "name": "general_contractor",
  "plural": false,
  "selections": [
    (v10/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v6/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_project",
                        "kind": "LinkedField",
                        "name": "general_contractors",
                        "plural": true,
                        "selections": [
                          (v12/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v12/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_subcontractor",
                        "kind": "LinkedField",
                        "name": "project_subcontractors",
                        "plural": true,
                        "selections": [
                          (v15/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "workerConnection",
        "kind": "LinkedField",
        "name": "worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCProjectsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v20/*: any*/),
                  (v21/*: any*/),
                  (v6/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_project",
                        "kind": "LinkedField",
                        "name": "general_contractors",
                        "plural": true,
                        "selections": [
                          (v22/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v22/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_subcontractor",
                        "kind": "LinkedField",
                        "name": "project_subcontractors",
                        "plural": true,
                        "selections": [
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v17/*: any*/),
        "concreteType": "workerConnection",
        "kind": "LinkedField",
        "name": "worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v18/*: any*/),
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f19bafe290271cfe9cfb273138185b6e",
    "id": null,
    "metadata": {},
    "name": "SCProjectsQuery",
    "operationKind": "query",
    "text": "query SCProjectsQuery(\n  $subId: uuid!\n  $userId: uuid!\n) {\n  project_subcontractor_connection(where: {subcontractor: {id: {_eq: $subId}}}, order_by: {project: {name: asc}}) {\n    edges {\n      node {\n        pk: id\n        project_workers_aggregate {\n          aggregate {\n            count\n          }\n        }\n        project_subcontractor_employees(where: {subcontractor_employee_id: {_eq: $userId}}) {\n          id\n        }\n        project {\n          pk: id\n          name\n          completed\n          general_contractors {\n            general_contractor {\n              name\n              id\n            }\n            id\n          }\n          general_contractor {\n            name\n            id\n          }\n          project_subcontractors {\n            tasks_aggregate(where: {subcontractor_id: {_eq: $subId}}) {\n              aggregate {\n                count\n              }\n            }\n            tasks(where: {subcontractor_id: {_eq: $subId}}) {\n              id\n            }\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n  worker_connection(where: {subcontractor_id: {_eq: $subId}}, order_by: {user: {name: asc}}) {\n    edges {\n      node {\n        user {\n          name\n          email\n          phone_number\n          pk: id\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3b59d09a84fc33b5e7e8a5b6658824ce";

export default node;

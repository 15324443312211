import { message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useRef, useState } from "react";
import { ConnectionHandler } from "relay-runtime";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import { auth } from "src/common/functions/firebase";
import { uploadFilesWithPath } from "src/common/functions/upload-utility/uploadFiles";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { AddNewPlanDocModal_InsertLogisticPlan_Mutation } from "src/common/types/generated/relay/AddNewPlanDocModal_InsertLogisticPlan_Mutation.graphql";
import { AddNewPlanDocModal_InsertNewDoc_Mutation } from "src/common/types/generated/relay/AddNewPlanDocModal_InsertNewDoc_Mutation.graphql";
const AddNewPlanDocModal: React.FC<{
  projectId: string;
  planUpload: boolean;
  startSortIndex: number;
  logisticPlanId?: string;
  onCancel: () => void;
  onFinish: () => void;
}> = ({
  logisticPlanId,
  onCancel,
  onFinish,
  planUpload,
  projectId,
  startSortIndex,
}) => {
  const [insertLogisticPlan] =
    useAsyncMutation<AddNewPlanDocModal_InsertLogisticPlan_Mutation>(graphql`
      mutation AddNewPlanDocModal_InsertLogisticPlan_Mutation(
        $object: logistic_plan_insert_input!
      ) {
        insert_logistic_plan_one(object: $object) {
          ...LogisticPlanFrag @relay(mask: false)
        }
      }
    `);
  const fModalRef =
    useRef<
      FModalRef<{ name?: string; files: Array<{ originFileObj: File }> }>
    >();
  const [loading, setLoading] = useState(false);
  const [insertNewDoc] =
    useAsyncMutation<AddNewPlanDocModal_InsertNewDoc_Mutation>(graphql`
      mutation AddNewPlanDocModal_InsertNewDoc_Mutation(
        $objects: [document_insert_input!]!
      ) {
        insert_document(objects: $objects) {
          returning {
            ...DocumentFrag @relay(mask: false)
          }
        }
      }
    `);
  return (
    <FModal
      destroyOnClose
      ref={fModalRef}
      open={planUpload || !!logisticPlanId}
      title={planUpload ? "Upload Plan Documents" : "Add docs to current plan"}
      onCancel={onCancel}
      confirmLoading={loading}
      onOk={async () => {
        const vals = await fModalRef.current?.form.validateFields();
        if (!vals || (!vals.name && planUpload)) {
          throw new Error("Form Not filled completed");
        }
        if (vals.files.length < 1) {
          message.warning("Please select at least 1 document");
          return;
        }
        try {
          setLoading(true);
          const uploadedFiles = await uploadFilesWithPath(
            vals.files.map((file) => ({
              file: file.originFileObj,
              directoryName: "logistic-plan",
            })),
          );
          if (planUpload) {
            await insertLogisticPlan({
              variables: {
                object: {
                  name: vals.name,
                  created_by_uid: auth.currentUser?.uid,
                  project_id: projectId,
                  documents: {
                    data: uploadedFiles.map((upFile, i) => ({
                      url: upFile.url,
                      sort_index: (i + 1) * 1000,
                      author_id: auth.currentUser?.uid,
                      document_type: upFile.type,
                      name: upFile.name,
                      type: "logistic_plan",
                    })),
                  },
                },
              },
              updater: (store) => {
                const newRecord = store.getRootField(
                  "insert_logistic_plan_one",
                );

                const projConn = ConnectionHandler.getConnection(
                  store.getRoot(),
                  "GCProjectSettingsOldQuery_project_connection",
                );

                if (projConn) {
                  const edges = projConn.getLinkedRecords("edges") || [];
                  const edge = edges[0];

                  if (!edge) return;
                  const node = edge.getLinkedRecord("node");
                  if (!node) return;
                  node.setLinkedRecords([newRecord], "logistic_plans", {
                    order_by: { created_at: "desc" },
                    limit: 1,
                  });
                }
              },
            });
          } else if (logisticPlanId) {
            await insertNewDoc({
              variables: {
                objects: uploadedFiles.map((upFile, i) => ({
                  url: upFile.url,
                  author_id: auth.currentUser?.uid,
                  group_id: logisticPlanId,
                  name: upFile.name,
                  document_type: upFile.type,
                  sort_index: startSortIndex + (i + 1) * 1000,
                  type: "logistic_plan",
                })),
              },
              updater: (store) => {
                const projConn = ConnectionHandler.getConnection(
                  store.getRoot(),
                  "GCProjectSettingsOldQuery_project_connection",
                );

                if (projConn) {
                  const edges = projConn.getLinkedRecords("edges") || [];
                  const edge = edges[0];

                  if (!edge) return;
                  const node = edge.getLinkedRecord("node");
                  if (!node) return;
                  const logisticPlans =
                    node.getLinkedRecords("logistic_plans", {
                      order_by: { created_at: "desc" },
                      limit: 1,
                    }) || [];
                  const currentPlan = logisticPlans[0];
                  if (!currentPlan) return;
                  const currDocs =
                    currentPlan.getLinkedRecords("documents", {
                      order_by: { sort_index: "asc" },
                    }) || [];

                  currentPlan.setLinkedRecords(
                    [
                      ...currDocs,
                      ...store
                        .getRootField("insert_document")
                        .getLinkedRecords("returning"),
                    ],
                    "documents",
                    {
                      order_by: { sort_index: "asc" },
                    },
                  );
                }
              },
            });
          } else {
            throw new Error("Not Enough Fields");
          }
          fModalRef.current?.form.resetFields();
          setLoading(false);
          onFinish();
        } catch (error) {
          setLoading(false);
          message.error(
            `ERROR: ${
              error instanceof Error ? error.message : JSON.stringify(error)
            }`,
          );
        }
      }}
    >
      {planUpload && (
        <FModal.Text name={"name"} initialValue={"Logistic Plan"}></FModal.Text>
      )}
      <FModal.DragUploadFile
        name={"files"}
        props={{ accept: ".pdf" }}
      ></FModal.DragUploadFile>
    </FModal>
  );
};
export default AddNewPlanDocModal;

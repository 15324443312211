/**
 * @generated SignedSource<<1fbbd4f1eb243aeba52f60ec8fca48fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrientationProjectEmployeeTable_SaveHHMutation$variables = {
  hardHat: string;
  projectEmployeeId: string;
};
export type OrientationProjectEmployeeTable_SaveHHMutation$data = {
  readonly update_project_employee_by_pk: {
    readonly hard_hat_number: string | null | undefined;
    readonly pk: string;
  } | null | undefined;
};
export type OrientationProjectEmployeeTable_SaveHHMutation = {
  response: OrientationProjectEmployeeTable_SaveHHMutation$data;
  variables: OrientationProjectEmployeeTable_SaveHHMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hardHat"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectEmployeeId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "hard_hat_number",
        "variableName": "hardHat"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "projectEmployeeId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hard_hat_number",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "OrientationProjectEmployeeTable_SaveHHMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_employee",
        "kind": "LinkedField",
        "name": "update_project_employee_by_pk",
        "plural": false,
        "selections": [
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "OrientationProjectEmployeeTable_SaveHHMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "project_employee",
        "kind": "LinkedField",
        "name": "update_project_employee_by_pk",
        "plural": false,
        "selections": [
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "df69050bdae04178214558cc31a42171",
    "id": null,
    "metadata": {},
    "name": "OrientationProjectEmployeeTable_SaveHHMutation",
    "operationKind": "mutation",
    "text": "mutation OrientationProjectEmployeeTable_SaveHHMutation(\n  $projectEmployeeId: uuid!\n  $hardHat: String!\n) {\n  update_project_employee_by_pk(pk_columns: {id: $projectEmployeeId}, _set: {hard_hat_number: $hardHat}) {\n    pk: id\n    hard_hat_number\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e844c90dd2838ca54c712db53a1d3d4";

export default node;

import SignaturePad from "signature_pad";
import { useUploadBase64FileMutation } from "../../../types/generated/apollo/graphQLTypes";
import { useState } from "react";
import NotifyUserException from "../../../../utility-features/error-handling/NotifyUserException";
import uploadImage, {
  UploadResult,
} from "src/common/functions/upload-utility/uploadImage";

// Not for stand-alone use
// This hook is used in the `useSignaturePad` hook

export type UploadSignatureFuncType = () => Promise<string | null>;
export type UploadSignatureAsImageFuncType = () => Promise<UploadResult | null>;
export interface UploadSignatureReturnType {
  uploadSignature: UploadSignatureFuncType;
  uploading: boolean;
  uploadSignatureAsImage: UploadSignatureAsImageFuncType;
}
const useUploadSignature: (
  signaturePad: SignaturePad | null,
) => UploadSignatureReturnType = (signaturePad) => {
  const [uploadBase64] = useUploadBase64FileMutation();
  const [uploading, setUploading] = useState<boolean>(false);

  const uploadSignature = async () => {
    if (!signaturePad) {
      throw new NotifyUserException(
        "Cannot Upload Signature",
        "Try to refresh the page",
      );
    }
    setUploading(true);
    const data = signaturePad?.toDataURL();
    const uploadRes = await uploadBase64({
      variables: { input: { base64: data } },
    });
    const signatureUrl = uploadRes.data?.uploadBase64File;
    setUploading(false);
    return signatureUrl || null;
  };
  const uploadSignatureAsImage = async () => {
    if (!signaturePad) {
      throw new NotifyUserException(
        "Cannot Upload Signature",
        "Try to refresh the page",
      );
    }
    setUploading(true);
    const data = signaturePad?.toDataURL();
    const uploadRes = await uploadImage(data);
    setUploading(false);
    return uploadRes || null;
  };

  return { uploadSignature, uploadSignatureAsImage, uploading };
};

export default useUploadSignature;

import { graphql } from "babel-plugin-relay/macro";
import React, { useRef, useState, useMemo } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { ProjectJHALibraryQuery } from "src/common/types/generated/relay/ProjectJHALibraryQuery.graphql";
import withCustomSuspense from "./general/withCustomSuspense";
import { DataScrollTableRef } from "./tables/basic/DataScrollTable";
import { task_bool_exp } from "../types/generated/relay/types";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import AddTasksFromOtherJHALibraryModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/AddTasksFromOtherJHALibraryModal";
import TasksTable from "src/domain-features/sitesafety/job-hazard-analysis/components/tables/TasksTable";
import CustomButton from "src/common/components/general/Button";
import UploadTaskModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadTaskModal";
import UploadTaskToSiteFormModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadTaskToSiteformModal";
import SelectSubAdminsToUploadJHAsModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/SelectSubAdminsToUploadJHAsModal";
import topBarButtons from "src/domain-features/sitesafety/job-hazard-analysis/utils/topBarButtons";
import useAuthUser from "../hooks/useAuthUser";
import { Order_By } from "../types/generated/apollo/graphQLTypes";

const query = graphql`
  query ProjectJHALibraryQuery($subId: uuid!, $projectId: uuid!, $uid: uuid!) {
    subcontractor_employee_connection(
      where: { subcontractor_id: { _eq: $subId } }
    ) {
      edges {
        node {
          user_id
          user {
            name
          }
        }
      }
    }
    project_worker_connection(
      where: {
        subcontractor_worker: {}
        subcontractor_id: { _eq: $subId }
        project_id: { _eq: $projectId }
        user: { created_password: { _eq: true } }
      }
    ) {
      edges {
        node {
          user {
            name
          }
          worker_id
        }
      }
    }
    task_connection(
      where: {
        project_id: { _eq: $projectId }
        subcontractor_id: { _eq: $subId }
        deleted_at: { _is_null: true }
        report_id: { _is_null: true }
      }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          task_admins {
            general_contractor_employee {
              user {
                pk: id @__clientField(handle: "pk")
                name
              }
            }
          }
          task_signatures(
            order_by: { created_at: desc }
            where: { is_active: { _eq: true } }
          ) {
            created_at
            signature_image_id
            user {
              role
              id
              pk: id @__clientField(handle: "pk")
              name
            }
          }
        }
      }
    }
    ppe_type_connection(order_by: { name: { en: asc } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
        }
      }
    }
    permit_type_connection(order_by: { name: { en: asc } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          name
          general_contractor_id
          general_contractor {
            name
          }
          id
          pk: id @__clientField(handle: "pk")
        }
      }
    }
    project_subcontractor_connection(
      where: { subcontractor_id: { _eq: $subId } }
      order_by: { project: { name: asc } }
    ) {
      edges {
        node {
          project {
            name
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }

    general_contractor_employee_connection(where: { uid: { _eq: $uid } }) {
      edges {
        node {
          employee_projects {
            project {
              pk: id @__clientField(handle: "pk")
              id
              name
            }
          }
        }
      }
    }
  }
`;

const ProjectJHALibrary: React.FC<{
  projectId: string;
  onClick: () => void;
  order: Order_By;
  setOrder: React.Dispatch<React.SetStateAction<Order_By>>;
  subcontractorId: string;
}> = (props: any) => {
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const queryData = useLazyLoadQuery<ProjectJHALibraryQuery>(query, {
    subId: props.subcontractorId,
    projectId: props.projectId,
    uid: authUser.uid,
  });
  const [taskWhere, setTaskWhere] = useState<task_bool_exp | undefined>(
    undefined,
  );
  const [selectedGcUser, setSelectedGCUser] = useState<Array<string>>([]);
  const [selectedJhaAdmin, setSelectedJhaAdmin] = useState<Array<string>>([]);

  const [
    isOpenSelectSubadminsToUploadJhas,
    setIsOpenSelectSubadminsToUploadJhas,
  ] = useState(false);

  const [isOpenUploadTaskModal, setIsOpenUploadTaskModal] = useState(false);
  const [isOpenUploadTaskToSiteformModal, setIsOpenUploadTaskToSiteformModal] =
    useState(false);

  const jhaTableRef = useRef<DataScrollTableRef>(null);
  const [start, setStart] = useState<dayjs.Dayjs | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<task_bool_exp>({});
  const activeJhaWhere: task_bool_exp = {
    archived_at: { _is_null: true },
    is_pending_acceptance: { _eq: false },
    request_state: { _is_null: true },
  };
  const processingJhaWhere: task_bool_exp = {
    request_state: { _eq: "processing" },
  };
  const [selectedPpe, setSeletedPpe] = useState<Array<string>>([]);
  const { approvedJhas, signedJhas, gcReviews, jhaAdmins } = useMemo(() => {
    const approvedJhas = new Set<string>();
    const signedJhas = new Set<string>();
    const gcReviews: Array<{ id: string; name: string }> = [];
    const jhaAdmins: Array<{ id: string; name: string }> = [];

    queryData.task_connection.edges.forEach((task) => {
      task.node.task_signatures.forEach((s) => {
        if (
          s.user?.role === "employee" &&
          gcReviews.findIndex((gc) => gc.id === s.user?.pk) === -1
        ) {
          gcReviews.push({ id: s.user.pk, name: s.user.name });
        } else if (s.signature_image_id) {
          signedJhas.add(task.node.pk);
        } else {
          approvedJhas.add(task.node.pk);
        }
      });
      task.node.task_admins.forEach((admin) => {
        if (
          jhaAdmins.findIndex(
            (jhaAdmin) =>
              jhaAdmin.id === admin.general_contractor_employee.user.pk,
          ) === -1
        ) {
          jhaAdmins.push({
            id: admin.general_contractor_employee.user.pk,
            name: admin.general_contractor_employee.user.name,
          });
        }
      });
    });

    return { approvedJhas, signedJhas, gcReviews, jhaAdmins };
  }, [queryData.task_connection]);

  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const isNotTurnersProject =
    queryData.project_connection.edges[0]?.node.general_contractor_id !==
    turnerGCId;

  const projectOfSub =
    queryData.general_contractor_employee_connection.edges[0].node.employee_projects.map(
      (proj) => ({ id: proj.project.pk, name: proj.project.name }),
    );
  const projectData = queryData.project_connection.edges[0].node;
  const ppeData = queryData.ppe_type_connection.edges;
  const permitData = queryData.permit_type_connection.edges;
  const userIdMap: { [key: string]: boolean } = {};
  const subAdmins = queryData.subcontractor_employee_connection.edges.map(
    (sa) => {
      userIdMap[sa.node.user_id] = true;
      return {
        name: sa.node.user.name,
        id: sa.node.user_id,
      };
    },
  );
  const workers: Array<{ name: string; id: string }> = [];
  queryData.project_worker_connection.edges.forEach((sa) => {
    if (!userIdMap[sa.node.worker_id] && sa.node.user?.name)
      workers.push({
        name: sa.node.user.name,
        id: sa.node.worker_id,
      });
  });

  return (
    <>
      <UploadTaskModal
        visible={isOpenUploadTaskModal}
        isNotTurnersProject={isNotTurnersProject}
        onCancel={() => setIsOpenUploadTaskModal(false)}
        refetch={() => jhaTableRef.current?.refetch()}
        ppeData={ppeData.map((p) => ({
          name: p.node.name.en,
          id: p.node.pk,
        }))}
        permitData={permitData.map((p) => ({
          name: p.node.name.en,
          id: p.node.pk,
        }))}
        projectId={props.projectId}
        subcontractorId={props.subcontractorId}
        setIsOpenUploadModal={setIsOpenUploadTaskModal}
      />

      <UploadTaskToSiteFormModal
        visible={isOpenUploadTaskToSiteformModal}
        subcontractorId={props.subcontractorId}
        onClose={() => setIsOpenUploadTaskToSiteformModal(false)}
        onSubmit={() => setIsOpenUploadTaskToSiteformModal(false)}
        projectId={props.projectId}
        workers={workers}
        subAdmins={subAdmins}
      />

      <AddTasksFromOtherJHALibraryModal
        closeModal={() => setTaskWhere(undefined)}
        refresh={() => jhaTableRef.current?.refetch()}
        projectId={props.projectId}
        subcontractorId={props.subcontractorId}
        visible={!!taskWhere}
        taskWhere={taskWhere}
        addToProjectOrCompany="project"
      />

      <SelectSubAdminsToUploadJHAsModal
        type={"gc_project_jha"}
        iniviteText={false}
        subcontractorId={props.subcontractorId}
        subAdminUsersData={subAdmins}
        sentFromCompanyName={projectData.general_contractor.name}
        projectName={projectData.name}
        projectId={props.projectId}
        visible={isOpenSelectSubadminsToUploadJhas}
        onCancel={() => setIsOpenSelectSubadminsToUploadJhas(false)}
      />
      <TasksTable
        excludedKeys={[
          "subcontractor",
          "ppe",
          "permit",
          "addToProject",
          "addToMulipleProjects",
          "delete",
        ]}
        onRowItemClick={(row) => {
          if (row.is_pending_acceptance) {
            navigate(
              `requested-task?idFromGCProj=${row.pk}${
                row.task_creation_request &&
                row.task_creation_request.type === "images_for_one"
                  ? `&requestId=${row.task_creation_request.pk}`
                  : ""
              }`,
            );
          } else {
            navigate(
              `/gce/projects/${props.projectId}/subcontractors/${props.subcontractorId}/task/${row.pk}`,
            );
          }
        }}
        topBarButtons={topBarButtons({
          onInstantTaskUploadClick: () => setIsOpenUploadTaskModal(true),
          onNonInstantTaskUploadClick: () =>
            setIsOpenUploadTaskToSiteformModal(true),
          onAddJHAFromCompanyLibraryClick: () =>
            setTaskWhere({
              subcontractor_id: { _eq: props.subcontractorId },
              project_id: { _is_null: true },
            }),
          onAddJHAFromOtherProjectClick: () =>
            setTaskWhere({
              subcontractor_id: { _eq: props.subcontractorId },
              project_id: { _neq: props.projectId },
            }),
          onRequestJHAFromSubClick: () =>
            setIsOpenSelectSubadminsToUploadJhas(true),
        })}
        headerComponent={
          <CustomButton
            secondary
            label="Go to Company JHA Library"
            onClick={() => props.onClick()}
          />
        }
        actionsAllowed={[
          "acceptJHA",
          "archiveOrActivate",
          "delete",
          "excelDownload",
          "pdfDownload",
          "viewFile",
          "resendForAcceptance",
          "requestSubcontractorApproval",
          "unflagJHA",
        ]}
        isNotTurnersProject={isNotTurnersProject}
        ref={jhaTableRef}
        projectId={props.projectId}
        subcontractorId={props.subcontractorId}
        projectOfSub={projectOfSub}
        title="Project"
        where={{
          project_id: { _eq: props.projectId },
          subcontractor_id: { _eq: props.subcontractorId },
          report_id: { _is_null: true },
          deleted_at: { _is_null: true },
          ...(selectedGcUser.length > 0
            ? {
                task_signatures: {
                  user_id: { _in: selectedGcUser },
                  is_active: { _eq: true },
                },
              }
            : {}),

          ...(selectedJhaAdmin.length > 0
            ? {
                task_admins: {
                  empolyee_id: { _in: selectedJhaAdmin },
                },
              }
            : {}),
          ...statusFilter,
          ...(start
            ? {
                report_tasks: {
                  report: {
                    created_at: { _gte: start.format("YYYY-MM-DD") },
                  },
                },
              }
            : {}),

          ...(selectedPpe.length
            ? {
                task_ppe_types: { ppe_type_id: { _in: selectedPpe } },
              }
            : {}),
        }}
        extraColumns={[
          {
            title: "Created",
            dataIndex: ["created_at"],
            key: "created",
            defaultSortOrder: "asc",
            size: "md",
            sortable: true,
            searchDataIndex: ["created_at"],
            align: "right",
            render: (d: any, row) =>
              row.created_at ? (
                <div>
                  <span>{row.created_by_user?.name} </span> <br />
                  <span>{dayjs(row.created_at).format("MMM DD, YYYY")}</span>
                </div>
              ) : (
                ""
              ),
          },
        ]}
        customFilters={[
          {
            type: "checkbox",
            title: "GC Review",
            removeFilter: () => setSelectedGCUser([]),
            options: gcReviews.map((review) => ({
              option: review.name,
              optionType: "checkbox",
              onApply: () => setSelectedGCUser((prev) => [...prev, review.id]),
              onCancel: () =>
                setSelectedGCUser((prev) =>
                  prev.filter((id) => id !== review.id),
                ),
            })),
          },
          {
            type: "checkbox",
            title: "JHA Leads",
            removeFilter: () => setSelectedJhaAdmin([]),
            options: jhaAdmins.map((review) => ({
              option: review.name,
              optionType: "checkbox",
              onApply: () =>
                setSelectedJhaAdmin((prev) => [...prev, review.id]),
              onCancel: () =>
                setSelectedJhaAdmin(
                  selectedJhaAdmin.filter((id) => id !== review.id),
                ),
            })),
          },
          {
            type: "checkbox",
            title: "Status",
            removeFilter: () => setStatusFilter({}),
            options: [
              {
                option: "Active",
                onApply: () => {
                  setStatusFilter((prev) => {
                    const { _and, ...rest } = prev;
                    if (_and && _and.length > 0) {
                      return {
                        ...rest,
                        _and: [processingJhaWhere, activeJhaWhere],
                      };
                    } else {
                      return {
                        ...rest,
                        _and: [activeJhaWhere],
                      };
                    }
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { _and, ...rest } = prev;
                    if (_and?.length === 2) {
                      return {
                        ...rest,
                        _and: [processingJhaWhere],
                      };
                    } else {
                      return rest;
                    }
                  });
                },
              },
              {
                option: "Approved",
                onApply: () => {
                  setStatusFilter((prev) => {
                    const { id, ...rest } = prev;
                    let tempJhas = prev.id?._in;
                    if (tempJhas)
                      tempJhas = tempJhas.concat(Array.from(approvedJhas));
                    else tempJhas = Array.from(approvedJhas);
                    return {
                      id: { _in: tempJhas },
                      ...rest,
                    };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { id, ...rest } = prev;
                    let tempJhas = prev.id?._in;
                    tempJhas = tempJhas?.filter((id) => !approvedJhas.has(id));

                    if (tempJhas && tempJhas.length > 0) {
                      return { id: { _in: tempJhas }, ...rest };
                    } else {
                      return rest;
                    }
                  });
                },
              },
              {
                option: "Archived",
                onApply: () => {
                  setStatusFilter((prev) => {
                    return { ...prev, archived_at: { _is_null: false } };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { archived_at, ...rest } = prev;
                    return rest;
                  });
                },
              },
              {
                option: "Pending Acceptance",
                onApply: () => {
                  setStatusFilter((prev) => {
                    return { ...prev, subcontractor_id: { _is_null: true } };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { subcontractor_id, ...rest } = prev;
                    return rest;
                  });
                },
              },
              {
                option: "Processing",
                onApply: () => {
                  setStatusFilter((prev) => {
                    const { _and, ...rest } = prev;
                    if (_and && _and.length > 0) {
                      return {
                        ...rest,
                        _and: [processingJhaWhere, activeJhaWhere],
                      };
                    } else {
                      return {
                        ...rest,
                        _and: [processingJhaWhere],
                      };
                    }
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { _and, ...rest } = prev;
                    if (_and?.length === 2) {
                      return {
                        ...rest,
                        _and: [activeJhaWhere],
                      };
                    } else {
                      return rest;
                    }
                  });
                },
              },
              {
                option: "Requested",
                onApply: () => {
                  setStatusFilter((prev) => {
                    return {
                      ...prev,
                      request_state: { _eq: "requested" },
                    };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { request_state, ...rest } = prev;
                    return rest;
                  });
                },
              },
              {
                option: "Reviewed",
                onApply: () => {
                  setStatusFilter((prev) => {
                    return {
                      ...prev,
                      reviewed_at: { _is_null: false },
                    };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    const { reviewed_at, ...rest } = prev;
                    return rest;
                  });
                },
              },
              {
                option: "Signed",
                onApply: () => {
                  setStatusFilter((prev) => {
                    let tempJhas = prev.id?._in;
                    if (tempJhas)
                      tempJhas = tempJhas.concat(Array.from(signedJhas));
                    else tempJhas = Array.from(signedJhas);
                    return {
                      id: { _in: tempJhas },
                    };
                  });
                },
                onCancel: () => {
                  setStatusFilter((prev) => {
                    let tempJhas = prev.id?._in;
                    tempJhas = tempJhas?.filter((id) => !signedJhas.has(id));
                    if (tempJhas && tempJhas.length > 0)
                      return { id: { _in: tempJhas } };
                    else {
                      return {};
                    }
                  });
                },
              },
            ],
          },
          {
            type: "radio",
            title: "Last Used",
            removeFilter: () => setStart(undefined),
            options: [
              {
                option: "Used Today",
                onClick: () => {
                  setStart(dayjs().startOf("day"));
                },
              },
              {
                option: "Last 15 days",
                onClick: () =>
                  setStart(dayjs().startOf("day").subtract(15, "days")),
              },
              {
                option: "Last 30 days",
                onClick: () =>
                  setStart(dayjs().startOf("day").subtract(30, "days")),
              },
              {
                option: "Last 60 days",
                onClick: () =>
                  setStart(dayjs().startOf("day").subtract(60, "days")),
              },
              {
                option: "Last 90 days",
                onClick: () =>
                  setStart(dayjs().startOf("day").subtract(90, "days")),
              },
            ],
          },
          {
            type: "checkbox",
            title: "PPE",
            removeFilter: () => setSeletedPpe([]),
            options: ppeData.map((ppe) => ({
              option: ppe.node.name.en,
              optionType: "checkbox",
              onApply: () => setSeletedPpe((prev) => [...prev, ppe.node.pk]),
              onCancel: () =>
                setSeletedPpe(selectedPpe.filter((id) => id !== ppe.node.id)),
            })),
          },
        ]}
      />
    </>
  );
};

export default withCustomSuspense(ProjectJHALibrary);

/**
 * @generated SignedSource<<5e97d0b6471d460e5d1873eb48ad01aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { delivery_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SitedeliveryAddNewDeliveryModalMutation$variables = {
  object: delivery_insert_input;
};
export type SitedeliveryAddNewDeliveryModalMutation$data = {
  readonly insert_delivery_one: {
    readonly approved_at: string | null | undefined;
    readonly approved_by: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    } | null | undefined;
    readonly calendars: ReadonlyArray<{
      readonly calendar: {
        readonly color_hex: string;
        readonly id: string;
        readonly name: {
          readonly en: string;
        } | null | undefined;
        readonly pk: string;
      } | null | undefined;
    }>;
    readonly created_at: string;
    readonly detail: {
      readonly en: string;
    } | null | undefined;
    readonly duration: number | null | undefined;
    readonly id: string;
    readonly name: {
      readonly en: string;
    } | null | undefined;
    readonly pk: string;
    readonly remark: {
      readonly en: string;
    } | null | undefined;
    readonly start_at: string;
    readonly status: string | null | undefined;
    readonly storage_location: {
      readonly en: string;
    } | null | undefined;
    readonly subcontractor: {
      readonly id: string;
      readonly name: string;
    } | null | undefined;
    readonly user: {
      readonly id: string;
      readonly name: string;
      readonly pk: string;
    } | null | undefined;
  } | null | undefined;
};
export type SitedeliveryAddNewDeliveryModalMutation = {
  response: SitedeliveryAddNewDeliveryModalMutation$data;
  variables: SitedeliveryAddNewDeliveryModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "calendar_id": "asc"
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start_at",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "duration",
  "storageKey": null
},
v14 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v10/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "approved_at",
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v19 = [
  (v5/*: any*/),
  (v2/*: any*/)
],
v20 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v19/*: any*/),
  "storageKey": null
},
v21 = [
  (v2/*: any*/),
  (v17/*: any*/),
  (v18/*: any*/),
  (v10/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SitedeliveryAddNewDeliveryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "delivery",
        "kind": "LinkedField",
        "name": "insert_delivery_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "delivery_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "calendars(order_by:{\"calendar_id\":\"asc\"})"
          },
          (v9/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "detail",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          (v7/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "approved_by",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "remark",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "storage_location",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SitedeliveryAddNewDeliveryModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "delivery",
        "kind": "LinkedField",
        "name": "insert_delivery_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "delivery_calendar",
            "kind": "LinkedField",
            "name": "calendars",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "calendar",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v20/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": "calendars(order_by:{\"calendar_id\":\"asc\"})"
          },
          (v9/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "detail",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          (v20/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "user",
            "kind": "LinkedField",
            "name": "approved_by",
            "plural": false,
            "selections": (v21/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "remark",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "text_translation",
            "kind": "LinkedField",
            "name": "storage_location",
            "plural": false,
            "selections": (v19/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7692483b186da2eb1ec2e27beec8a300",
    "id": null,
    "metadata": {},
    "name": "SitedeliveryAddNewDeliveryModalMutation",
    "operationKind": "mutation",
    "text": "mutation SitedeliveryAddNewDeliveryModalMutation(\n  $object: delivery_insert_input!\n) {\n  insert_delivery_one(object: $object) {\n    id\n    pk: id\n    calendars(order_by: {calendar_id: asc}) {\n      calendar {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n        color_hex\n      }\n      id\n    }\n    status\n    subcontractor {\n      id\n      name\n    }\n    detail {\n      en\n      id\n    }\n    start_at\n    duration\n    user {\n      id\n      pk: id\n      name\n    }\n    name {\n      en\n      id\n    }\n    created_at\n    approved_at\n    approved_by {\n      id\n      pk: id\n      name\n    }\n    remark {\n      en\n      id\n    }\n    storage_location {\n      en\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6e81b3b74b168df42a4969af467be58b";

export default node;

import { RcFile } from "antd/es/upload";
import uploadImage from "./uploadImage";
import { readFileAsBase64 } from "./readFileAsBase64";
import filterFailedTasks from "./filterFailedTasks";

const uploadImageFiles = (files: Array<{ file: File }>) =>
  filterFailedTasks(
    files.map(async ({ file }) => {
      const base64 = await readFileAsBase64(file);
      return await uploadImage(base64);
    }),
  );

export default uploadImageFiles;

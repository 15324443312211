/**
 * @generated SignedSource<<8647a2b44a49214d0fc963a90dc369f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { orientation_viewed_slide_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type InsertOrientationViewedSlideMutation$variables = {
  objects: ReadonlyArray<orientation_viewed_slide_insert_input>;
};
export type InsertOrientationViewedSlideMutation$data = {
  readonly insert_orientation_viewed_slide: {
    readonly returning: ReadonlyArray<{
      readonly created_at: string;
      readonly id: string;
      readonly orientation_result_id: string | null | undefined;
      readonly slide_id: string;
      readonly user_id: string;
    }>;
  } | null | undefined;
};
export type InsertOrientationViewedSlideMutation = {
  response: InsertOrientationViewedSlideMutation$data;
  variables: InsertOrientationViewedSlideMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "orientation_viewed_slide_slide_id_user_id_key",
          "update_columns": [
            "created_at"
          ]
        }
      }
    ],
    "concreteType": "orientation_viewed_slide_mutation_response",
    "kind": "LinkedField",
    "name": "insert_orientation_viewed_slide",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "orientation_viewed_slide",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slide_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "user_id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created_at",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "orientation_result_id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InsertOrientationViewedSlideMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InsertOrientationViewedSlideMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b5f9460b46e02509385d3df725e02a78",
    "id": null,
    "metadata": {},
    "name": "InsertOrientationViewedSlideMutation",
    "operationKind": "mutation",
    "text": "mutation InsertOrientationViewedSlideMutation(\n  $objects: [orientation_viewed_slide_insert_input!]!\n) {\n  insert_orientation_viewed_slide(objects: $objects, on_conflict: {constraint: orientation_viewed_slide_slide_id_user_id_key, update_columns: [created_at]}) {\n    returning {\n      id\n      slide_id\n      user_id\n      created_at\n      orientation_result_id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8a829d4aa522ec2ca9d67480497f2843";

export default node;

/**
 * @generated SignedSource<<a986917502fb5b7912a440c42f15777a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LogisticPlan_UpdateDoc_Mutation$variables = {
  docId: string;
  newOrder: number;
};
export type LogisticPlan_UpdateDoc_Mutation$data = {
  readonly update_document_by_pk: {
    readonly id: string;
    readonly name: string | null | undefined;
    readonly pk: string;
    readonly sort_index: number;
    readonly url: string;
  } | null | undefined;
};
export type LogisticPlan_UpdateDoc_Mutation = {
  response: LogisticPlan_UpdateDoc_Mutation$data;
  variables: LogisticPlan_UpdateDoc_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "docId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "newOrder"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "sort_index",
        "variableName": "newOrder"
      }
    ],
    "kind": "ObjectValue",
    "name": "_set"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "docId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sort_index",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LogisticPlan_UpdateDoc_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "document",
        "kind": "LinkedField",
        "name": "update_document_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LogisticPlan_UpdateDoc_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "document",
        "kind": "LinkedField",
        "name": "update_document_by_pk",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b8bf76354c4b0b217d74702054b4e41b",
    "id": null,
    "metadata": {},
    "name": "LogisticPlan_UpdateDoc_Mutation",
    "operationKind": "mutation",
    "text": "mutation LogisticPlan_UpdateDoc_Mutation(\n  $docId: uuid!\n  $newOrder: Int!\n) {\n  update_document_by_pk(pk_columns: {id: $docId}, _set: {sort_index: $newOrder}) {\n    url\n    pk: id\n    id\n    sort_index\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee7f9711678501b0cb65bae871d12208";

export default node;

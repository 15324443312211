/**
 * @generated SignedSource<<61dafd1f140d3b562382ddbc79c91525>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type QrProjectSubcontractorTaskSignQuery$variables = {
  projectId: string;
  subcontractorId: string;
  taskId: string;
};
export type QrProjectSubcontractorTaskSignQuery$data = {
  readonly project_worker_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly worker: {
          readonly user: {
            readonly birth_date: string | null | undefined;
            readonly name: string;
            readonly username: string | null | undefined;
          };
        };
        readonly worker_id: string;
      };
    }>;
  };
  readonly task_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: {
          readonly en: string;
        };
        readonly subcontractor: {
          readonly name: string;
        } | null | undefined;
        readonly task_edit: ReadonlyArray<{
          readonly edited_at: string;
          readonly task_patch: any | null | undefined;
        }>;
        readonly task_signatures: ReadonlyArray<{
          readonly created_at: string;
          readonly user_id: string | null | undefined;
        }>;
      };
    }>;
  };
};
export type QrProjectSubcontractorTaskSignQuery = {
  response: QrProjectSubcontractorTaskSignQuery$data;
  variables: QrProjectSubcontractorTaskSignQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subcontractorId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "taskId"
},
v3 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "taskId"
          }
        ],
        "kind": "ObjectValue",
        "name": "id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "is_active": {
        "_eq": true
      }
    }
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "user_id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v9 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "edited_at": "desc"
    }
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "task_patch",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "edited_at",
  "storageKey": null
},
v12 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "project_id"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "subcontractorId"
          }
        ],
        "kind": "ObjectValue",
        "name": "subcontractor_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_id",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birth_date",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QrProjectSubcontractorTaskSignQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "task_signature",
                    "kind": "LinkedField",
                    "name": "task_signatures",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": "task_signatures(where:{\"is_active\":{\"_eq\":true}})"
                  },
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "task_edit",
                    "kind": "LinkedField",
                    "name": "task_edit",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": "task_edit(order_by:{\"edited_at\":\"desc\"})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "QrProjectSubcontractorTaskSignQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "task_signature",
                    "kind": "LinkedField",
                    "name": "task_signatures",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": "task_signatures(where:{\"is_active\":{\"_eq\":true}})"
                  },
                  {
                    "alias": null,
                    "args": (v9/*: any*/),
                    "concreteType": "task_edit",
                    "kind": "LinkedField",
                    "name": "task_edit",
                    "plural": true,
                    "selections": [
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": "task_edit(order_by:{\"edited_at\":\"desc\"})"
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v12/*: any*/),
        "concreteType": "project_workerConnection",
        "kind": "LinkedField",
        "name": "project_worker_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_workerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "204ca96bc7ca9c111c6b9c1f0b7dc1c4",
    "id": null,
    "metadata": {},
    "name": "QrProjectSubcontractorTaskSignQuery",
    "operationKind": "query",
    "text": "query QrProjectSubcontractorTaskSignQuery(\n  $taskId: uuid!\n  $projectId: uuid!\n  $subcontractorId: uuid!\n) {\n  task_connection(where: {id: {_eq: $taskId}}) {\n    edges {\n      node {\n        description {\n          en\n          id\n        }\n        subcontractor {\n          name\n          id\n        }\n        task_signatures(where: {is_active: {_eq: true}}) {\n          user_id\n          created_at\n          id\n        }\n        task_edit(order_by: {edited_at: desc}) {\n          task_patch\n          edited_at\n          id\n        }\n        id\n      }\n    }\n  }\n  project_worker_connection(where: {project_id: {_eq: $projectId}, subcontractor_id: {_eq: $subcontractorId}}) {\n    edges {\n      node {\n        worker_id\n        id\n        worker {\n          user {\n            name\n            username\n            birth_date\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "06ba949cd235fd98ec08cacabdace130";

export default node;

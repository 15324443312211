import React, { FC, useRef, useState } from "react";
import { Button, Popconfirm, Select } from "antd";
import TextareaAutosize from "react-textarea-autosize";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import CustomButton from "src/common/components/general/Button";
import { cloneDeep } from "@apollo/client/utilities";
import * as uuid from "uuid";
import TaskType from "src/common/types/manual/TaskType";
import { IconArrowBadgeDown, IconArrowBadgeUp, IconPlus } from "@tabler/icons";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

interface TaskEditPageProps {
  currentTaskObject: TaskType;
  isNotTurnersProject: boolean;
  dataForPpe: Array<{
    id: string;
    description: string;
    ppes: Array<{ id: string; description: string }>;
  }>;
  dataForPermit: Array<{ id: string; description: string }>;
  dataForEcm: Array<{ id: string; description: string | undefined }>;
  setCurrentTaskObject: (taskObject: TaskType) => void;
  stepsOrderChanged: () => void;
  onSave: () => void;
  onCancel: () => void;
  showCancel: boolean;
  isAnonymous: boolean;
  onSfeTaskSave?: (task: TaskType) => void;
}

const TaskEditPage: FC<TaskEditPageProps> = ({
  currentTaskObject,
  isNotTurnersProject,
  dataForPpe,
  dataForPermit,
  dataForEcm,
  setCurrentTaskObject,
  stepsOrderChanged,
  onSave,
  onCancel,
  showCancel,
  isAnonymous,
}) => {
  const addHazForm =
    useRef<FModalRef<{ newHazard: string; newControl: string }>>(null);

  const addStepForm = useRef<FModalRef<{ newStep: string }>>(null);

  const [addStepJHAId, setAddStepJHAId] = useState<string | undefined>(
    undefined,
  );

  const [addHazJHAId, setAddHazJHAId] = useState<string | undefined>(undefined);

  const mapEcms = (ecmId: string) => {
    const e = "39ab97a5-47ac-4e5d-b287-21c2cb016c16",
      c = "05660c6a-c370-4c82-86a9-e558772d7d56",
      m = "57774dd6-3e26-45aa-a7cd-c3cb518a75f9";
    if (ecmId === e) return "Environment";
    else if (ecmId === c) return "Capability";
    else if (ecmId === m) return "Motivation";
    else return "";
  };

  const ppeMap: Map<string, string> = new Map<string, string>();
  const permitMap: Map<string, string> = new Map<string, string>();

  dataForPpe.forEach((ppeCategory) => {
    ppeCategory.ppes.forEach((ppe) => {
      ppeMap.set(ppe.id, ppe.description);
    });
  });

  dataForPermit.forEach((permit) => {
    permitMap.set(permit.id, permit.description);
  });

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const currentTaskObjectDeepCopy = cloneDeep(currentTaskObject);
    currentTaskObjectDeepCopy.description = event.target.value;
    setCurrentTaskObject(currentTaskObjectDeepCopy);
  };

  const handleStepsChange = (
    stepId: string,
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const currentTaskObjectTestCopy = cloneDeep(currentTaskObject);
    const index = currentTaskObjectTestCopy.steps.findIndex(
      (step) => step.id === stepId,
    );
    currentTaskObjectTestCopy.steps[index].description = event.target.value;
    setCurrentTaskObject(currentTaskObjectTestCopy);
  };

  const handleHazardsChange = (
    hazardId: string,
    stepId: string,
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const currentTaskObjectTestCopy = cloneDeep(currentTaskObject);
    const stepIndex = currentTaskObjectTestCopy.steps.findIndex(
      (step) => step.id === stepId,
    );
    if (stepIndex === -1) return;
    const hazIndex = currentTaskObjectTestCopy.steps[
      stepIndex
    ].hazards.findIndex((h) => h.id === hazardId);
    if (hazIndex === -1) return;
    currentTaskObjectTestCopy.steps[stepIndex].hazards[hazIndex].description =
      event.target.value;
    setCurrentTaskObject(currentTaskObjectTestCopy);
  };

  const handleControlsChange = (
    hazardId: string,
    stepId: string,
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const currentTaskObjectTestCopy = cloneDeep(currentTaskObject);
    const stepIndex = currentTaskObjectTestCopy.steps.findIndex(
      (step) => step.id === stepId,
    );
    if (stepIndex === -1) return;
    const hazIndex = currentTaskObjectTestCopy.steps[
      stepIndex
    ].hazards.findIndex((h) => h.id === hazardId);
    if (hazIndex === -1) return;
    currentTaskObjectTestCopy.steps[stepIndex].hazards[hazIndex].control =
      event.target.value;
    setCurrentTaskObject(currentTaskObjectTestCopy);
  };

  const handleEcmsChange = (hazardId: string, stepId: string, event: any) => {
    const currentTaskObjectTestDeepCopy = cloneDeep(currentTaskObject);
    const stepIndex = currentTaskObjectTestDeepCopy.steps.findIndex(
      (step) => step.id === stepId,
    );
    const hazIndex = currentTaskObjectTestDeepCopy.steps[
      stepIndex
    ].hazards.findIndex((h) => h.id === hazardId);

    const ecmObject: Array<{ id: string; description: string }> = [];

    event.forEach((e: any) => {
      ecmObject.push({
        id: e,
        description: mapEcms(e),
      });
    });

    currentTaskObjectTestDeepCopy.steps[stepIndex].hazards[hazIndex].ecms =
      ecmObject;

    setCurrentTaskObject(currentTaskObjectTestDeepCopy);
  };

  const handlePpeChange = (event: any) => {
    const currentTaskObjectTestDeepCopy = cloneDeep(currentTaskObject);

    currentTaskObjectTestDeepCopy.ppes = event.map((e: any) => ({
      id: e,
      description: ppeMap.get(e),
    }));

    currentTaskObjectTestDeepCopy.ppes.sort((a, b) =>
      a.description < b.description ? -1 : 1,
    );
    setCurrentTaskObject(currentTaskObjectTestDeepCopy);
  };

  const handlePermitChange = (event: any) => {
    const currentTaskObjectTestDeepCopy = cloneDeep(currentTaskObject);

    currentTaskObjectTestDeepCopy.permits = event.map((e: any) => ({
      id: e,
      description: permitMap.get(e),
    }));

    currentTaskObjectTestDeepCopy.permits.sort((a, b) =>
      a.description < b.description ? -1 : 1,
    );
    setCurrentTaskObject(currentTaskObjectTestDeepCopy);
  };

  const onOrderChange = async (from: number, to: number) => {
    if (from === to) return;

    const currentTaskObjectDeepCopy = cloneDeep(currentTaskObject);
    currentTaskObjectDeepCopy.steps[from] = currentTaskObject.steps[to];
    currentTaskObjectDeepCopy.steps[to] = currentTaskObject.steps[from];
    setCurrentTaskObject(currentTaskObjectDeepCopy);
    stepsOrderChanged();
  };

  return (
    <div className="flex-col mb-4">
      <div className="bg-suplementary-1 ml-2 pl-2 mr-2 pt-1 pb-1 text-0.9 rounded-2">
        <>
          <br />
          {
            <div className="flex items-center">
              <span>Description: </span>
              <span className="w-full flex mr-1">
                <TextareaAutosize
                  value={currentTaskObject.description}
                  className={`w-full rounded-0.5 border-b-px border-suplementary-3 font-accent flex-1 resize-none outline-none h-5 p-1 ml-1 whitespace-pre-wrap break-word`}
                  onChange={(event) => handleDescriptionChange(event)}
                />
              </span>
            </div>
          }

          {
            <div className="mt-4">
              <span className="min-w-24 max-w-24">
                <span className="font-accent text-1">PPE</span>
                <span>
                  {" (Personal Protective Equipment)" +
                    " (" +
                    currentTaskObject.ppes.length +
                    ")"}
                </span>
              </span>

              <div className="flex items-center">
                <Select
                  className="w-full ml-5 mr-1 p-1"
                  value={currentTaskObject.ppes.map((x) => x.id)}
                  mode="multiple"
                  size="large"
                  //TODO fix and uncomment
                  // showSearch
                  // filterOption={(input, option) => {
                  //   if (option?.label) {
                  //     return (
                  //       option.label
                  //         .toLowerCase()
                  //         .indexOf(input.toLowerCase()) >= 0
                  //     );
                  //   } else {
                  //     return false;
                  //   }
                  // }}
                  onChange={(event) => {
                    handlePpeChange(event);
                  }}
                  options={dataForPpe.map((c) => ({
                    label: <span>{c.description}</span>,
                    title: c.description,
                    options: c.ppes.map((p) => ({
                      label: p.description,
                      value: p.id,
                    })),
                  }))}
                />
              </div>

              <div className="min-w-24 max-w-24 mt-2">
                <span className="font-accent mt-2 text-1">Permits or Tags</span>
                <span>
                  {" Required to Perform this JHA" +
                    " (" +
                    currentTaskObject.permits.length +
                    ")"}
                </span>
              </div>
              <div className="flex">
                <Select
                  className="w-full ml-5 mr-1 p-1"
                  value={currentTaskObject.permits.map((x) => x.id)}
                  mode="multiple"
                  size="large"
                  showSearch
                  filterOption={getNormalSelectOptionsFilter}
                  onChange={(event) => {
                    handlePermitChange(event);
                  }}
                  options={dataForPermit.map((p) => ({
                    label: p.description,
                    value: p.id,
                  }))}
                />
              </div>

              <div className="mt-5 flex align-middle">
                <span className="mt-0.5">
                  Steps ({currentTaskObject.steps.length})
                </span>

                <FModal
                  ref={addStepForm}
                  open={addStepJHAId === "step"}
                  onCancel={() => setAddStepJHAId(undefined)}
                  onOk={async () => {
                    const newStep =
                      addStepForm.current?.form.getFieldValue("newStep");

                    if (newStep) {
                      const newStepObj: (typeof currentTaskObject)["steps"][number] =
                        {
                          id: uuid.v1(),
                          description: newStep,
                          hazards: [],
                        };
                      const taskObjectDeepCopy = cloneDeep(currentTaskObject);
                      taskObjectDeepCopy.steps.push(newStepObj);
                      setCurrentTaskObject(taskObjectDeepCopy);
                    }
                    setAddStepJHAId(undefined);
                  }}
                >
                  <FModal.TextArea
                    name="newStep"
                    label={"New Step Description"}
                    requiredMessage="Enter Step"
                    required={true}
                  />
                </FModal>

                <span className="ml-1">
                  <CustomButton
                    small
                    icon={IconPlus}
                    label="Add New Step"
                    onClick={() => {
                      addStepJHAId
                        ? setAddStepJHAId(undefined)
                        : setAddStepJHAId("step");
                    }}
                  />
                </span>
              </div>
            </div>
          }
        </>

        <div className="App">
          {currentTaskObject.steps.map((step, i) => {
            return (
              <>
                <div key={step.id} className={i == 0 ? "mt-2" : "mt-6"}>
                  <p className="pl-1">
                    {
                      <>
                        <span className="flex items-center mr-0.5">
                          <span className="min-w-5 max-w-5 flex">
                            {i !== 0 && (
                              <span className="flex-1">
                                <CustomButton
                                  icon={IconArrowBadgeUp}
                                  small
                                  onClick={() => onOrderChange(i, i - 1)}
                                  hint="Shift step up"
                                />
                              </span>
                            )}
                            {i !== currentTaskObject.steps.length - 1 && (
                              <span className="ml-0.5">
                                <CustomButton
                                  icon={IconArrowBadgeDown}
                                  small
                                  onClick={() => onOrderChange(i, i + 1)}
                                  hint="Shift step down"
                                />
                              </span>
                            )}
                          </span>

                          <span className=" font-accent text-0.5 ml-2 mr-0.5 min-w-4 max-w-4">
                            Step {i + 1}:{" "}
                          </span>
                          <span className="w-full flex">
                            <TextareaAutosize
                              value={step.description}
                              className={`rounded-0.5 border-b-px border-suplementary-3 flex-1 resize-none outline-none p-1 ml-2 whitespace-pre-wrap break-word`}
                              onChange={(event) =>
                                handleStepsChange(step.id, event)
                              }
                            />
                          </span>
                          <Popconfirm
                            title="Are you sure you want to delete this Step? This cannot be undone"
                            onConfirm={async () => {
                              const currentTaskObjectCopy =
                                cloneDeep(currentTaskObject);

                              currentTaskObjectCopy.steps =
                                currentTaskObjectCopy.steps.filter(
                                  (s) => s.id !== step.id,
                                );

                              setCurrentTaskObject(currentTaskObjectCopy);
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              className="rounded-0.5 mx-2 font-light border-semantic-pending"
                              size="small"
                              onClick={() => {}}
                            >
                              Delete Step
                            </Button>
                          </Popconfirm>
                        </span>
                      </>
                    }

                    {addHazJHAId === "hazard" + i + "th Step" && (
                      <>
                        <FModal
                          ref={addHazForm}
                          open={addHazJHAId === "hazard" + i + "th Step"}
                          onCancel={() => setAddHazJHAId(undefined)}
                          onOk={async () => {
                            const newHazard =
                              addHazForm.current?.form.getFieldValue(
                                "newHazard",
                              );
                            const newControl =
                              addHazForm.current?.form.getFieldValue(
                                "newControl",
                              );

                            const newEcms =
                              addHazForm.current?.form.getFieldValue("ecms");

                            const ecmData = newEcms?.map((e: string) => ({
                              ecm_type_id: e,
                            }));

                            const ecmObject = ecmData?.map((ecm: any) => {
                              return {
                                id: ecm.ecm_type_id,
                                description: mapEcms(ecm.ecm_type_id),
                              };
                            });

                            if (newHazard && newControl) {
                              const currentTaskObjectDeepCopy =
                                cloneDeep(currentTaskObject);

                              const newHazardObj = {
                                control: newControl,
                                description: newHazard,
                                descriptionId: "",
                                controlId: "",
                                ecms: ecmObject ?? [],
                                id: uuid.v1(),
                              };

                              currentTaskObjectDeepCopy.steps[i].hazards.push(
                                newHazardObj,
                              );

                              setCurrentTaskObject(currentTaskObjectDeepCopy);
                            }
                            setAddHazJHAId(undefined);
                          }}
                        >
                          <FModal.TextArea
                            name="newHazard"
                            label={"New Hazard Description"}
                            requiredMessage="Enter Hazard"
                            required={true}
                          />{" "}
                          <FModal.TextArea
                            name="newControl"
                            label={"New Control Description"}
                            requiredMessage="Enter Control"
                            required={true}
                          />
                          {!isNotTurnersProject && (
                            <FModal.Select
                              name="ecms"
                              label="E,C,M"
                              className="w-full"
                              props={{
                                mode: "tags",
                                placeholder: "Select ECM",
                                options: dataForEcm.map((c) => ({
                                  key: c.id,
                                  value: c.id,
                                  label: c.description,
                                })),
                              }}
                            />
                          )}
                        </FModal>
                      </>
                    )}
                  </p>{" "}
                  <div className="flex mt-1 mb-2 ml-20">
                    <CustomButton
                      small
                      icon={IconPlus}
                      label="Add New Hazard"
                      onClick={() => {
                        addStepJHAId === "hazard" + i + "th Step"
                          ? setAddHazJHAId(undefined)
                          : setAddHazJHAId("hazard" + i + "th Step");
                      }}
                    />
                  </div>
                  {step.hazards.map((h, i2: number) => {
                    const ecms = h.ecms || [];

                    return (
                      <div key={h.id} className="ml-10 flex">
                        <span className="flex-auto">
                          {
                            <span
                              className={
                                i2 == 0
                                  ? "flex items-center"
                                  : "mt-3 flex items-center"
                              }
                            >
                              <div className="font-accent ml-3 text-0.5 max-w-5 min-w-5">
                                Hazard {i2 + 1}:
                              </div>
                              <span className="flex-1">
                                <div className="flex items-center">
                                  <TextareaAutosize
                                    value={h.description}
                                    className={`rounded-0.5 border-b-px border-suplementary-3 flex-1 resize-none outline-none p-1 ml-2 whitespace-pre-wrap break-word`}
                                    onChange={(event) =>
                                      handleHazardsChange(h.id, step.id, event)
                                    }
                                  />
                                </div>
                              </span>
                            </span>
                          }
                          {
                            <span className="flex items-baseline mt-1">
                              <span className="font-accent text-0.5 ml-3 max-w-5 min-w-5">
                                Control:
                              </span>
                              <span className="flex-1">
                                <div className="flex items-center">
                                  <TextareaAutosize
                                    value={h.control}
                                    className={`rounded-0.5 border-b-px border-suplementary-3 flex-1 resize-none outline-none p-1 ml-2 whitespace-pre-wrap break-word`}
                                    onChange={(event) =>
                                      handleControlsChange(h.id, step.id, event)
                                    }
                                  />
                                </div>
                              </span>
                            </span>
                          }

                          {!isNotTurnersProject && (
                            <span className="mt-1 flex items-baseline">
                              <span className="font-accent text-0.5 ml-3 mr-1.5 max-w-5 min-w-5">
                                E,C,M:{" "}
                              </span>
                              <Select
                                className="ml-0.5 w-full"
                                mode="multiple"
                                dropdownMatchSelectWidth
                                size="large"
                                placeholder="Select the ECM For this Hazard"
                                value={h.ecms.map((x) => x.id)}
                                onChange={(event) => {
                                  handleEcmsChange(h.id, step.id, event);
                                }}
                              >
                                {dataForEcm.map((p) => (
                                  <Select.Option key={p.id} value={p.id}>
                                    {p.description}
                                  </Select.Option>
                                ))}
                              </Select>
                            </span>
                          )}
                        </span>
                        <span className="mt-4">
                          <Popconfirm
                            title="Are you sure you want to delete this hazard? This cannot be undone"
                            onConfirm={async () => {
                              const currentTaskObjectTestCopy =
                                cloneDeep(currentTaskObject);

                              currentTaskObjectTestCopy.steps[i].hazards =
                                currentTaskObjectTestCopy.steps[
                                  i
                                ].hazards.filter(
                                  (hazard) => hazard.id !== h.id,
                                );

                              setCurrentTaskObject(currentTaskObjectTestCopy);
                            }}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                          >
                            <Button
                              className="rounded-0.5 mx-4 font-light border-semantic-pending"
                              size="small"
                              onClick={() => {}}
                            >
                              Delete Hazard
                            </Button>
                          </Popconfirm>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </>
            );
          })}
        </div>
        <span className="flex ml-5 mt-3 mb-3">
          <CustomButton
            small
            icon={IconPlus}
            label="Add New Step"
            onClick={() => {
              addStepJHAId
                ? setAddStepJHAId(undefined)
                : setAddStepJHAId("step");
            }}
          />
        </span>
      </div>
      <span className="flex mt-1 ml-2.5">
        <span>
          <CustomButton
            secondary={isAnonymous}
            label="Save"
            onClick={() => onSave()}
          />{" "}
        </span>
        {showCancel && (
          <span className="ml-1">
            <CustomButton
              secondary={isAnonymous}
              label={`Cancel`}
              onClick={() => onCancel()}
            />
          </span>
        )}
      </span>
    </div>
  );
};

export default TaskEditPage;

/**
 * @generated SignedSource<<771aeeff3b0ebe00f41a859a50723ff7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { checklist_item_config_type_enum, checklist_item_type_enum } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SCReportsDailySettingsQuery$variables = {
  subcontractorId: string;
};
export type SCReportsDailySettingsQuery$data = {
  readonly checklist_item_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly checklist_item_configs: ReadonlyArray<{
          readonly config: checklist_item_config_type_enum;
          readonly pk: string;
        }>;
        readonly checklist_item_notify_emails: ReadonlyArray<{
          readonly email: string;
          readonly pk: string;
        }>;
        readonly data_type: string;
        readonly description: {
          readonly clientText: string;
          readonly en: string;
          readonly es: string;
          readonly id: string;
          readonly original: string;
          readonly pk: string;
          readonly pt: string;
        };
        readonly did_notify: boolean;
        readonly id: string;
        readonly option_response: string | null | undefined;
        readonly parent_id: string | null | undefined;
        readonly pk: string;
        readonly project_id: string | null | undefined;
        readonly sort_index: number;
        readonly subcontractor_id: string | null | undefined;
        readonly text_response: {
          readonly clientText: string;
          readonly en: string;
          readonly es: string;
          readonly id: string;
          readonly original: string;
          readonly pk: string;
          readonly pt: string;
        };
        readonly type: checklist_item_type_enum;
      };
    }>;
  };
  readonly subcontractor_employee_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly user: {
          readonly email: string | null | undefined;
          readonly name: string;
        };
      };
    }>;
  };
};
export type SCReportsDailySettingsQuery = {
  response: SCReportsDailySettingsQuery$data;
  variables: SCReportsDailySettingsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subcontractorId"
  }
],
v1 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "subcontractorId"
    }
  ],
  "kind": "ObjectValue",
  "name": "subcontractor_id"
},
v2 = {
  "fields": [
    {
      "kind": "Literal",
      "name": "data_type",
      "value": {
        "_eq": "base"
      }
    },
    (v1/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "where"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "config",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "data_type",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parent_id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "option_response",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "es",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pt",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v17 = [
  (v3/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v4/*: any*/),
  {
    "alias": "clientText",
    "args": null,
    "kind": "ScalarField",
    "name": "__original_clientText",
    "storageKey": null
  }
],
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "did_notify",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sort_index",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v23 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "user": {
        "name": "asc"
      }
    }
  },
  {
    "fields": [
      (v1/*: any*/),
      {
        "kind": "Literal",
        "name": "user",
        "value": {
          "email": {
            "_is_null": false
          }
        }
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v25 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  (v2/*: any*/)
],
v26 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v27 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v28 = [
  (v3/*: any*/),
  (v13/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/),
  (v16/*: any*/),
  (v26/*: any*/),
  (v27/*: any*/),
  {
    "alias": "clientText",
    "args": null,
    "kind": "ScalarField",
    "name": "original",
    "storageKey": null
  },
  {
    "alias": "clientText",
    "args": null,
    "filters": null,
    "handle": "clientText",
    "key": "",
    "kind": "ScalarHandle",
    "name": "original"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCReportsDailySettingsQuery",
    "selections": [
      {
        "alias": "checklist_item_connection",
        "args": [
          (v2/*: any*/)
        ],
        "concreteType": "checklist_itemConnection",
        "kind": "LinkedField",
        "name": "__SCReportsDailySettings_checklist_item_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "checklist_itemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "checklist_item",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "checklist_item_config",
                    "kind": "LinkedField",
                    "name": "checklist_item_configs",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "checklist_item_notify_email",
                    "kind": "LinkedField",
                    "name": "checklist_item_notify_emails",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "text_response",
                    "plural": false,
                    "selections": (v17/*: any*/),
                    "storageKey": null
                  },
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": (v17/*: any*/),
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              },
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v23/*: any*/),
        "concreteType": "subcontractor_employeeConnection",
        "kind": "LinkedField",
        "name": "subcontractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCReportsDailySettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v25/*: any*/),
        "concreteType": "checklist_itemConnection",
        "kind": "LinkedField",
        "name": "checklist_item_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "checklist_itemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "checklist_item",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "checklist_item_config",
                    "kind": "LinkedField",
                    "name": "checklist_item_configs",
                    "plural": true,
                    "selections": [
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v5/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "checklist_item_notify_email",
                    "kind": "LinkedField",
                    "name": "checklist_item_notify_emails",
                    "plural": true,
                    "selections": [
                      (v26/*: any*/),
                      (v27/*: any*/),
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "text_response",
                    "plural": false,
                    "selections": (v28/*: any*/),
                    "storageKey": null
                  },
                  (v18/*: any*/),
                  (v19/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "description",
                    "plural": false,
                    "selections": (v28/*: any*/),
                    "storageKey": null
                  },
                  (v26/*: any*/),
                  (v27/*: any*/),
                  (v20/*: any*/)
                ],
                "storageKey": null
              },
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          (v22/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v25/*: any*/),
        "filters": [
          "where"
        ],
        "handle": "connection",
        "key": "SCReportsDailySettings_checklist_item_connection",
        "kind": "LinkedHandle",
        "name": "checklist_item_connection"
      },
      {
        "alias": null,
        "args": (v23/*: any*/),
        "concreteType": "subcontractor_employeeConnection",
        "kind": "LinkedField",
        "name": "subcontractor_employee_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor_employeeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_employee",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v24/*: any*/),
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "dd867ec2fc07a494d0187f25685017c0",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "checklist_item_connection"
          ]
        }
      ]
    },
    "name": "SCReportsDailySettingsQuery",
    "operationKind": "query",
    "text": "query SCReportsDailySettingsQuery(\n  $subcontractorId: uuid!\n) {\n  checklist_item_connection(first: 10000, where: {subcontractor_id: {_eq: $subcontractorId}, data_type: {_eq: \"base\"}}) {\n    edges {\n      node {\n        id\n        checklist_item_configs {\n          pk: id\n          config\n          id\n        }\n        checklist_item_notify_emails {\n          pk: id\n          email\n          id\n        }\n        type\n        data_type\n        project_id\n        subcontractor_id\n        parent_id\n        option_response\n        text_response {\n          id\n          en\n          es\n          pt\n          original\n          pk: id\n          clientText: original\n        }\n        did_notify\n        sort_index\n        description {\n          id\n          en\n          es\n          pt\n          original\n          pk: id\n          clientText: original\n        }\n        pk: id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  subcontractor_employee_connection(where: {user: {email: {_is_null: false}}, subcontractor_id: {_eq: $subcontractorId}}, order_by: {user: {name: asc}}) {\n    edges {\n      node {\n        user {\n          name\n          email\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "09fbb42e62b386478f825893d8419f2e";

export default node;

import React, { forwardRef } from "react";
import {
  GetObservationInspectionsQuery,
  GetObservationInspectionsQueryVariables,
  useDeleteObservationInspectionMutation,
  GetObservationInspectionsDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import DataApolloScrollTable, {
  DataApolloScrollTableImplementorProps,
  DataApolloScrollTableProps,
  DataApolloScrollTableRef,
} from "src/common/components/tables/basic/DataApolloScrollTable";
import { useNavigate } from "react-router-dom";
import useAuthUser from "src/common/hooks/useAuthUser";
import Tag from "src/common/components/general/Tag";
import dayjs from "dayjs";
import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
type ColumnKeys =
  | "date"
  | "sub_name"
  | "created_by"
  | "observations"
  | "status"
  | "name"
  | "location"
  | "project_name"
  | "extra";
type DataType =
  GetObservationInspectionsQuery["observation_inspection"][number];
type Props = DataApolloScrollTableImplementorProps<
  GetObservationInspectionsQueryVariables,
  ColumnKeys,
  DataType,
  GetObservationInspectionsQuery
> & {
  projectId?: string;

  searchString?: string;
  extraColumns?: DataApolloScrollTableProps<
    GetObservationInspectionsQueryVariables,
    ColumnKeys,
    DataType,
    GetObservationInspectionsQuery
  >["columns"];
  headerComponent?: React.ReactElement;
  datePickerDataTitle?: string;
  subcontractorId?: string;
  datePickerDataIndex?: string[];

  defaultSortColumnKey?: ColumnKeys;
};

const getStatusSortOrder = (r: DataType) => {
  if (!r.submitted_on) return 4;
  let isOpen = false;
  let isCorrected = false;
  for (const obs of r.observations) {
    if (isOpen && isCorrected) break;
    if (obs.status === "open") isOpen = true;
    if (obs.risk_level_value !== "safe") isCorrected = true;
  }
  if (isOpen) return 3;
  if (isCorrected) return 2;
  return 1;
};

const ObservationInspectionTable = forwardRef<DataApolloScrollTableRef, Props>(
  (
    {
      title = "Workers",
      onRowClick,
      searchString,
      subcontractorId,
      datePickerDataTitle,
      datePickerDataIndex,
      projectId,
      headerComponent,
      extraColumns,
      where,
      defaultSortColumnKey,
      ...props
    },
    ref,
  ) => {
    const [deleteObsInsp] = useDeleteObservationInspectionMutation();
    const navigate = useNavigate();
    const authUser = useAuthUser();
    return (
      <DataApolloScrollTable<
        GetObservationInspectionsQueryVariables,
        ColumnKeys,
        DataType,
        GetObservationInspectionsQuery
      >
        {...props}
        newCustomTableLook
        title="Audits"
        loadAll
        extraQueryVariables={{ includeProj: false }}
        filterNotVisibleByDefault
        onRowClick={(item) => {
          navigate(`${item.id}`);
        }}
        ref={ref}
        where={where}
        queryNode={GetObservationInspectionsDocument}
        aggregateCountIndex="observation_inspection_aggregate"
        queryDataIndex="observation_inspection"
        topBarButtons={[
          // {
          //   onClick: async () => {
          //     const { data } = await insertObservation({
          //       variables: {
          //         object: {
          //           project_id: projectId,
          //           created_by_uid: authUser.uid,
          //           subcontractor_id: subcontractorId,
          //           unsafe_observation: {
          //             data: { action: { data: { original: "" } } },
          //           },
          //         },
          //       },
          //     });
          //     if (data?.insert_observation_one?.id)
          //       navigate(`${data.insert_observation_one.id}`);
          //     else {
          //       throw new Error(
          //         "InsertObservation Failed: Inserted data not found",
          //       );
          //     }
          //   },
          //   loading: loading,
          //   label: "Create",
          //   icon: IconPlus,
          // },
          ...(props.topBarButtons || []),
        ]}
        columns={[
          {
            title: "Status",
            key: "status",
            defaultSortOrder: "asc",
            size: "sm",
            sortable: true,
            clientCompareFn: (a1, a2) => {
              let res = getStatusSortOrder(a1) - getStatusSortOrder(a2);
              if (res === 0)
                res =
                  dayjs(a2.start_at).valueOf() - dayjs(a1.start_at).valueOf();
              return res;
            },
            dataIndex: ["submitted_on"],
            filters: {
              dataIndex: ["status"],
              options: [
                { text: "Draft", value: "pending" },
                { text: "Open", value: "open" },
                { text: "Closed", value: "closed" },
              ],
            },
            render: (v, r) => {
              return r.submitted_on ? (
                r.observations.find((ob) => ob.status === "open") ? (
                  <Tag status="negative">Open</Tag>
                ) : (
                  <Tag
                    status={
                      r.observations.find(
                        (ob) => ob.risk_level_value !== "safe",
                      )
                        ? "corrected"
                        : "positive"
                    }
                  >
                    Closed
                  </Tag>
                )
              ) : (
                <Tag status="inactive">Draft</Tag>
              );
            },
          },

          {
            title: "Audit Name",
            key: "name",
            sortable: true,
            defaultSortOrder: "asc",
            dataIndex: ["observation_inspection_option", "name"],
            searchDataIndex: ["observation_inspection_option", "name"],
            render: (v) => v || "General",
          },
          {
            title: "Project Name",
            key: "project_name",
            sortable: true,
            defaultSortOrder: "asc",
            dataIndex: ["project", "name"],
            searchDataIndex: ["project", "name"],
          },
          //   {
          //     title: "Subcontractor Name",
          //     key: "sub_name",
          //     sortable: true,
          //     defaultSortOrder: "asc",
          //     dataIndex: ["subcontractor", "name"],
          //     searchDataIndex: ["subcontractor", "name"],
          //     render: (v) =>
          //       v ? (
          //         <div className="flex font-accent">
          //           <Icon icon={IconBriefcase} />
          //           {v}
          //         </div>
          //       ) : (
          //         ""
          //       ),
          //   },

          {
            title: "Date",
            key: "date",
            sortable: true,
            defaultSortOrder: "asc",
            dataIndex: ["start_at"],
            contentType: {
              type: "date",
              renderOptions: () => ({ format: "full" }),
            },
          },
          {
            title: "Created By",
            key: "created_by",
            dataIndex: ["created_by_user", "name"],
            searchDataIndex: ["created_by_user", "name"],
          },
          // {
          //   title: "Location",
          //   key: "location",
          //   dataIndex: ["location", "en"],
          //   searchDataIndex: ["location", "en"],
          // },
          {
            title: "Observations",
            key: "observations",
            dataIndex: ["observations"],
            render: (_, r) => {
              let corrected = 0,
                safe = 0,
                incomplete = 0,
                open = 0;
              r.observations.forEach((ob) =>
                ob.risk_level_value === "safe"
                  ? safe++
                  : ob.status === "open"
                  ? open++
                  : ob.status === "closed"
                  ? corrected++
                  : incomplete++,
              );
              return (
                <div className="flex  gap-0.5">
                  {!!safe && (
                    <div className="p-0.5 bg-semantic-positive-green min-w-2 text-center rounded-0.25 text-white">
                      {safe}
                    </div>
                  )}
                  {!!corrected && (
                    <div className="p-0.5 bg-purple min-w-2 text-center rounded-0.25 text-white">
                      {corrected}
                    </div>
                  )}

                  {!!open && (
                    <div className="p-0.5 mr-1 bg-semantic-negative min-w-2 text-center text-white rounded-0.25">
                      {open}
                    </div>
                  )}
                </div>
              );
            },
          },
          {
            key: "extra",
            title: "Action",
            size: "icon",
            dataIndex: ["id"],
            render: (id, r) => {
              if (r.submitted_on) return null;
              return (
                <div onClick={(e) => e.stopPropagation()}>
                  <Button
                    icon={<DeleteOutlined size={45} />}
                    danger
                    type="text"
                    color="red"
                    size="large"
                    onClick={async () => {
                      await deleteObsInsp({ variables: { id } });
                      if (ref && typeof ref !== "function") {
                        ref.current?.refetch();
                      }
                    }}
                  ></Button>
                </div>
              );
            },
          },
        ]}
      />
    );
  },
);
export default ObservationInspectionTable;

/**
 * @generated SignedSource<<b27d2d5777a440f47856a8e9a1bfbf58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { user_project_filter_calendar_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation$variables = {
  where: user_project_filter_calendar_bool_exp;
};
export type GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation$data = {
  readonly delete_user_project_filter_calendar: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation = {
  response: GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation$data;
  variables: GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "user_project_filter_calendar_mutation_response",
    "kind": "LinkedField",
    "name": "delete_user_project_filter_calendar",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "user_project_filter_calendar",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2eed6696a06e9996d2bdcf0f5fba2934",
    "id": null,
    "metadata": {},
    "name": "GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectCalendarSitedeliveryDeliveries_deleteFilterCalendar_Mutation(\n  $where: user_project_filter_calendar_bool_exp!\n) {\n  delete_user_project_filter_calendar(where: $where) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4706a52eed58ef453e8ddc82048932e4";

export default node;

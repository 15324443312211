/**
 * @generated SignedSource<<1df26bc3cde082b1334915e5e71f6190>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectSettingsOldUpdateProjectMutation$variables = {
  _set: project_set_input;
  projectId: string;
};
export type GCProjectSettingsOldUpdateProjectMutation$data = {
  readonly update_project_by_pk: {
    readonly completed: boolean;
    readonly id: string;
    readonly is_sitedelivery_approval_needed: boolean;
    readonly is_sitedelivery_on: boolean;
    readonly is_visitor_log_and_tracking_active: boolean;
    readonly is_visitor_log_procore_integration_enabled: boolean | null | undefined;
    readonly logo_url: string | null | undefined;
  } | null | undefined;
};
export type GCProjectSettingsOldUpdateProjectMutation = {
  response: GCProjectSettingsOldUpdateProjectMutation$data;
  variables: GCProjectSettingsOldUpdateProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_set"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "project",
    "kind": "LinkedField",
    "name": "update_project_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "completed",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_visitor_log_and_tracking_active",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_sitedelivery_on",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_visitor_log_procore_integration_enabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "logo_url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "is_sitedelivery_approval_needed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectSettingsOldUpdateProjectMutation",
    "selections": (v2/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "GCProjectSettingsOldUpdateProjectMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "25aede1700fd603f76a3529d212f6eb6",
    "id": null,
    "metadata": {},
    "name": "GCProjectSettingsOldUpdateProjectMutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectSettingsOldUpdateProjectMutation(\n  $projectId: uuid!\n  $_set: project_set_input!\n) {\n  update_project_by_pk(pk_columns: {id: $projectId}, _set: $_set) {\n    id\n    completed\n    is_visitor_log_and_tracking_active\n    is_sitedelivery_on\n    is_visitor_log_procore_integration_enabled\n    logo_url\n    is_sitedelivery_approval_needed\n  }\n}\n"
  }
};
})();

(node as any).hash = "761d0e78d7356c1a6c30bc9f40261201";

export default node;

/**
 * @generated SignedSource<<b36de38bff3540bd1d2e0a143d7920d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type QrProjectSubcontractorQuery$variables = {
  projectId: string;
  subcontractorId: string;
};
export type QrProjectSubcontractorQuery$data = {
  readonly project_subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly project: {
          readonly address: {
            readonly city: string;
            readonly line_1: string | null | undefined;
            readonly line_2: string | null | undefined;
            readonly state: {
              readonly name: string;
            };
            readonly state_code: string;
            readonly zip_code: string | null | undefined;
          };
          readonly general_contractors: ReadonlyArray<{
            readonly general_contractor: {
              readonly name: string;
            };
          }>;
          readonly name: string;
        };
        readonly subcontractor: {
          readonly name: string;
          readonly tasks: ReadonlyArray<{
            readonly description: {
              readonly en: string;
            };
            readonly id: string;
            readonly pk: string;
            readonly show_type: string;
          }>;
        };
      };
    }>;
  };
};
export type QrProjectSubcontractorQuery = {
  response: QrProjectSubcontractorQuery$data;
  variables: QrProjectSubcontractorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subcontractorId"
},
v2 = {
  "fields": [
    {
      "kind": "Variable",
      "name": "_eq",
      "variableName": "projectId"
    }
  ],
  "kind": "ObjectValue",
  "name": "project_id"
},
v3 = [
  {
    "fields": [
      (v2/*: any*/),
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "subcontractorId"
          }
        ],
        "kind": "ObjectValue",
        "name": "subcontractor_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "line_1",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "line_2",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state_code",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "zip_code",
  "storageKey": null
},
v11 = {
  "_is_null": false
},
v12 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "description": {
        "en": "asc"
      }
    }
  },
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "_or",
        "value": [
          {
            "creation_request_id": {
              "_is_null": true
            }
          },
          {
            "accepted_at_from_creation_request": (v11/*: any*/)
          },
          {
            "task_creation_request": {
              "rejected_at": (v11/*: any*/)
            }
          }
        ]
      },
      (v2/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_type",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = [
  (v4/*: any*/),
  (v15/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QrProjectSubcontractorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_project",
                        "kind": "LinkedField",
                        "name": "general_contractors",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "address",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "state",
                            "kind": "LinkedField",
                            "name": "state",
                            "plural": false,
                            "selections": (v5/*: any*/),
                            "storageKey": null
                          },
                          (v10/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": (v12/*: any*/),
                        "concreteType": "task",
                        "kind": "LinkedField",
                        "name": "tasks",
                        "plural": true,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "text_translation",
                            "kind": "LinkedField",
                            "name": "description",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v15/*: any*/),
                          {
                            "alias": "pk",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__id_pk",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QrProjectSubcontractorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_subcontractorConnection",
        "kind": "LinkedField",
        "name": "project_subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "general_contractor_project",
                        "kind": "LinkedField",
                        "name": "general_contractors",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "general_contractor",
                            "kind": "LinkedField",
                            "name": "general_contractor",
                            "plural": false,
                            "selections": (v16/*: any*/),
                            "storageKey": null
                          },
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "address",
                        "kind": "LinkedField",
                        "name": "address",
                        "plural": false,
                        "selections": [
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "state",
                            "kind": "LinkedField",
                            "name": "state",
                            "plural": false,
                            "selections": (v16/*: any*/),
                            "storageKey": null
                          },
                          (v10/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": (v12/*: any*/),
                        "concreteType": "task",
                        "kind": "LinkedField",
                        "name": "tasks",
                        "plural": true,
                        "selections": [
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "text_translation",
                            "kind": "LinkedField",
                            "name": "description",
                            "plural": false,
                            "selections": [
                              (v14/*: any*/),
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v15/*: any*/),
                          {
                            "alias": "pk",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": "pk",
                            "args": null,
                            "filters": null,
                            "handle": "pk",
                            "key": "",
                            "kind": "ScalarHandle",
                            "name": "id"
                          }
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ecab667b372757b4b7c912e765a5f9f",
    "id": null,
    "metadata": {},
    "name": "QrProjectSubcontractorQuery",
    "operationKind": "query",
    "text": "query QrProjectSubcontractorQuery(\n  $subcontractorId: uuid!\n  $projectId: uuid!\n) {\n  project_subcontractor_connection(where: {subcontractor_id: {_eq: $subcontractorId}, project_id: {_eq: $projectId}}) {\n    edges {\n      node {\n        project {\n          name\n          general_contractors {\n            general_contractor {\n              name\n              id\n            }\n            id\n          }\n          address {\n            line_1\n            line_2\n            city\n            state_code\n            state {\n              name\n              id\n            }\n            zip_code\n            id\n          }\n          id\n        }\n        subcontractor {\n          name\n          tasks(where: {project_id: {_eq: $projectId}, _or: [{creation_request_id: {_is_null: true}}, {accepted_at_from_creation_request: {_is_null: false}}, {task_creation_request: {rejected_at: {_is_null: false}}}]}, order_by: {description: {en: asc}}) {\n            show_type\n            description {\n              en\n              id\n            }\n            id\n            pk: id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e521e9fed0e7c46e201a2af7be3ffca7";

export default node;

/**
 * @generated SignedSource<<95816fb9502abcafee36ca0d03c187a2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type useInsertSecretCodeMutation$variables = {
  expire_at?: string | null | undefined;
  finalUrl: string;
  id: string;
  only_project_data: boolean;
  projectId: string;
  signInRole?: string | null | undefined;
  userId: string;
};
export type useInsertSecretCodeMutation$data = {
  readonly insert_secret_login_link_one: {
    readonly id: string;
    readonly pk: string;
  } | null | undefined;
};
export type useInsertSecretCodeMutation = {
  response: useInsertSecretCodeMutation$data;
  variables: useInsertSecretCodeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "expire_at"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "finalUrl"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "only_project_data"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "signInRole"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userId"
},
v7 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "expire_at",
        "variableName": "expire_at"
      },
      {
        "kind": "Variable",
        "name": "final_url",
        "variableName": "finalUrl"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "only_project_data",
        "variableName": "only_project_data"
      },
      {
        "kind": "Variable",
        "name": "project_id",
        "variableName": "projectId"
      },
      {
        "kind": "Variable",
        "name": "sign_in_role",
        "variableName": "signInRole"
      },
      {
        "kind": "Variable",
        "name": "user_id",
        "variableName": "userId"
      }
    ],
    "kind": "ObjectValue",
    "name": "object"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useInsertSecretCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "secret_login_link",
        "kind": "LinkedField",
        "name": "insert_secret_login_link_one",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v4/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "useInsertSecretCodeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v7/*: any*/),
        "concreteType": "secret_login_link",
        "kind": "LinkedField",
        "name": "insert_secret_login_link_one",
        "plural": false,
        "selections": [
          (v8/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e27deed1e78ddacbc47170363a98329",
    "id": null,
    "metadata": {},
    "name": "useInsertSecretCodeMutation",
    "operationKind": "mutation",
    "text": "mutation useInsertSecretCodeMutation(\n  $projectId: uuid!\n  $userId: uuid!\n  $id: uuid!\n  $signInRole: String\n  $expire_at: timestamptz\n  $only_project_data: Boolean!\n  $finalUrl: String!\n) {\n  insert_secret_login_link_one(object: {expire_at: $expire_at, project_id: $projectId, user_id: $userId, only_project_data: $only_project_data, id: $id, sign_in_role: $signInRole, final_url: $finalUrl}) {\n    id\n    pk: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "28019421f5e42dc8b708099f65ebb351";

export default node;

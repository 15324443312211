/**
 * @generated SignedSource<<986df62d41019955389e2c74a8b6b51a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { text_translation_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadedToolboxTalk_UpdateTextTranslation_Mutation$variables = {
  object: text_translation_insert_input;
};
export type UploadedToolboxTalk_UpdateTextTranslation_Mutation$data = {
  readonly insert_text_translation_one: {
    readonly en: string;
    readonly id: string;
    readonly original: string;
  } | null | undefined;
};
export type UploadedToolboxTalk_UpdateTextTranslation_Mutation = {
  response: UploadedToolboxTalk_UpdateTextTranslation_Mutation$data;
  variables: UploadedToolboxTalk_UpdateTextTranslation_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      },
      {
        "kind": "Literal",
        "name": "on_conflict",
        "value": {
          "constraint": "text_translation_pkey",
          "update_columns": [
            "original"
          ]
        }
      }
    ],
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "insert_text_translation_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "original",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "en",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadedToolboxTalk_UpdateTextTranslation_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadedToolboxTalk_UpdateTextTranslation_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "74fdb579da16f77ed791bdaccf980672",
    "id": null,
    "metadata": {},
    "name": "UploadedToolboxTalk_UpdateTextTranslation_Mutation",
    "operationKind": "mutation",
    "text": "mutation UploadedToolboxTalk_UpdateTextTranslation_Mutation(\n  $object: text_translation_insert_input!\n) {\n  insert_text_translation_one(object: $object, on_conflict: {constraint: text_translation_pkey, update_columns: [original]}) {\n    id\n    original\n    en\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5d185c05ecbaf10fa8f1e6e078ba353";

export default node;

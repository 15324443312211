/**
 * @generated SignedSource<<10efaa3334cae49b0d6b2a737f7d0ece>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { image_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WorkerProfileUpsertImageMutation$variables = {
  object: image_insert_input;
};
export type WorkerProfileUpsertImageMutation$data = {
  readonly insert_image_one: {
    readonly id: string;
    readonly pk: string;
  } | null | undefined;
};
export type WorkerProfileUpsertImageMutation = {
  response: WorkerProfileUpsertImageMutation$data;
  variables: WorkerProfileUpsertImageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "object",
    "variableName": "object"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "image_pkey",
      "update_columns": [
        "url",
        "description",
        "created_by_user_id"
      ]
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerProfileUpsertImageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "image",
        "kind": "LinkedField",
        "name": "insert_image_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "__id_pk",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkerProfileUpsertImageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "image",
        "kind": "LinkedField",
        "name": "insert_image_one",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": "pk",
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": "pk",
            "args": null,
            "filters": null,
            "handle": "pk",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e81332f967b398a1a8d506b9aa40d7cb",
    "id": null,
    "metadata": {},
    "name": "WorkerProfileUpsertImageMutation",
    "operationKind": "mutation",
    "text": "mutation WorkerProfileUpsertImageMutation(\n  $object: image_insert_input!\n) {\n  insert_image_one(object: $object, on_conflict: {constraint: image_pkey, update_columns: [url, description, created_by_user_id]}) {\n    id\n    pk: id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7aa9a166ee81550acd2613dfa646182";

export default node;

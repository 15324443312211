import { IconArrowLeft, IconSettings } from "@tabler/icons";
import { graphql } from "babel-plugin-relay/macro";
import { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { Navigate, useNavigate } from "react-router-dom";
import ProjectDescriptions from "src/common/components/ProjectDescriptions";
import ReportsTabsGroup, {
  ReportsTabType,
} from "src/common/components/ReportsTabsGroup";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import CustomButton from "src/common/components/general/Button";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import StyledContent from "src/common/components/layouts/StyledContent";
import { SCProjectQuery } from "src/common/types/generated/relay/SCProjectQuery.graphql";
import SCReportsDaily from "src/domain-features/sitesafety/entryRoutes/scReports/routes/daily/SCReportsDaily";
import SCReportsDailySettings from "src/domain-features/sitesafety/entryRoutes/scReports/routes/daily/routes/settings/SCReportsDailySettings";
import SCReportsPTP from "src/domain-features/sitesafety/entryRoutes/scReports/routes/ptp/SCReportsPTP";
import SCProjectSDS from "src/domain-features/sitesafety/safety-data-sheets/components/SCProjectSDS";
import SCProjectSafetyPlan from "src/domain-features/sitesafety/siteSafetyPlan/components/SCProjectSafetyPlan";
import SubProjectMobilizationViewWrapper from "src/domain-features/sitesafety/sub-project-mobilization/components/SubProjectMobilizationViewWrapper";
import SCProjectCrews from "src/domain-features/worker-crew/entry-routes/sub/SCProjectCrews";
import CSBillingInvoices from "../billing/SCBillingInvoices";
import SCProjectAutoEmailTables from "./SCProjectAutoEmailTables";
import SCProjectDeliveries from "./SCProjectDeliveries";
import SCProjectJHAs from "./SCProjectJHAs";
import SCProjectToolboxTalks from "./SCProjectToolboxTalks";
import SCProjectWorkers from "./SCProjectWorkers";
import SCProjectPocs from "./SCProjectPocs";

const query = graphql`
  query SCProjectQuery(
    $projectId: uuid!
    $subcontractorId: uuid!
    $projectWorkerWhere: project_worker_bool_exp!
  ) {
    ...CrewProjectWorkerOptions_project_worker_connection_frag
      @arguments(projectWorkerWhere: $projectWorkerWhere)
    worker_connection(
      where: {
        subcontractor_id: { _eq: $subcontractorId }
        _not: { worker_projects: { project_id: { _eq: $projectId } } }
      }
      order_by: { user: { name: asc } }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          current_worker_role
          worker_title {
            pk: id @__clientField(handle: "pk")
          }
          user {
            name
          }
        }
      }
    }

    ppe_type_connection(order_by: { name: { en: asc } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
        }
      }
    }
    permit_type_connection(order_by: { name: { en: asc } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
        }
      }
    }
    project_subcontractor_connection(
      where: { subcontractor_id: { _eq: $subcontractorId } }
      order_by: { project: { name: asc } }
    ) {
      edges {
        node {
          project {
            name
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }
    task_connection(
      where: {
        project_id: { _eq: $projectId }
        subcontractor_id: { _eq: $subcontractorId }
        deleted_at: { _is_null: true }
        report_id: { _is_null: true }
      }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          task_edit(order_by: { edited_at: desc }) {
            task_patch
            edited_at
          }
          task_signatures(
            where: { user: { role: { _neq: "employee" } } }
            order_by: { created_at: desc }
          ) {
            created_at
            signature_image_id
          }
        }
      }
    }
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          name
          external_id
          created_at
          agc_universal_orientation
          in_person_orientation
          general_contractors {
            general_contractor {
              id
              name
            }
          }
          general_contractor {
            id
            pk: id @__clientField(handle: "pk")
            name
          }
          address {
            line_1
            line_2
            city
            state {
              code
              name
            }
            zip_code
          }
          project_workers_aggregate(
            where: { subcontractor_id: { _eq: $subcontractorId } }
          ) {
            aggregate {
              count
            }
          }
          project_crews(
            where: {
              subcontractor_id: { _eq: $subcontractorId }
              deleted_at: { _is_null: true }
            }
            order_by: { name: asc }
          ) {
            pk: id @__clientField(handle: "pk")
            name
            created_at
            lead_foreman_project_worker {
              pk: id @__clientField(handle: "pk")
              user {
                id
                name
              }
            }
            project_workers_aggregate {
              aggregate {
                count
              }
            }
          }
        }
      }
    }
    universalRequired: orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        project_orientations: {
          required_by_all_workers: { _eq: true }
          project_id: { _is_null: true }
        }
        type: { _eq: "universal" }
      }
      first: 1
      order_by: [{ order: asc }, { name: asc }]
    ) {
      edges {
        node {
          id
        }
      }
    }
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        project_orientations: {
          play_during_registration: { _eq: true }
          required_by_all_workers: { _eq: true }
          project: { linked_orientation_projects: { id: { _eq: $projectId } } }
        }
        _or: [
          {
            project: {
              linked_orientation_projects: { id: { _eq: $projectId } }
            }
          }
          {
            project_id: { _is_null: true }
            general_contractor: { projects: { id: { _eq: $projectId } } }
          }
        ]
      }
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

interface SCProjectProps {
  projectId: string;
  subcontractorId: string;
  subview: string;
}

const SCProject: FC<SCProjectProps> = ({
  subcontractorId,
  projectId,
  subview,
}) => {
  const navigate = useNavigate();

  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  const [fetchKey, setFetchKey] = useState(0);
  const data = useLazyLoadQuery<SCProjectQuery>(
    query,
    {
      projectId,
      subcontractorId,
      projectWorkerWhere: {
        project_id: { _eq: projectId },
        subcontractor_id: { _eq: subcontractorId },
        subcontractor_worker: {},
      },
    },
    { fetchKey, fetchPolicy: "network-only" },
  );
  const refresh = () => setFetchKey((i) => i + 1);
  const tabs: ReportsTabType[] = [
    { id: "mobilization", label: "Mobilization" },
    { id: "poc", label: "POC(s)" },
    { id: "deliveries", label: "Deliveries" },
    { id: "sub-blocks", label: "Delivery Blocks" },
    { id: "workers-and-crews", label: "Workers and crews" },
    { id: "jhas", label: "JHAs" },
    { id: "sds", label: "SDS" },
    { id: "pre-task-plans", label: "Pre-Task Plans" },
    { id: "daily", label: "Daily Reports" },
    { id: "toolbox-talks-manage", label: "Manage TBTs" },
    { id: "toolbox-talks-completed", label: "Completed TBTs" },
    { id: "sssp", label: "Safety Manuals" },
    { id: "billing", label: "Billing" },
  ];
  return (
    <>
      <div className="flex flex-row-reverse items-start justify-between">
        <CustomButton
          secondary={subview === "auto-emails"}
          icon={IconSettings}
          label="Settings"
          onClick={() =>
            subview !== "auto-emails" && handleReportsTabClick("auto-emails")
          }
        />
        <CustomButton
          onClick={() => navigate("/sub/projects")}
          icon={IconArrowLeft}
          label="Back to projects"
        />
      </div>

      <BasicWrapper scrollable>
        <ProjectDescriptions project={data.project_connection.edges[0].node} />
      </BasicWrapper>
      <div className="h-0.25"></div>
      <ReportsTabsGroup
        onTabClick={handleReportsTabClick}
        tabs={tabs}
        tabsType="button"
        selectedTabId={subview}
      />

      <div className="mt-1">
        {subview === "deliveries" && (
          <SCProjectDeliveries
            type="delivery"
            subcontractorId={subcontractorId}
          />
        )}
        {subview === "sub-blocks" && (
          <SCProjectDeliveries
            type="sub-block"
            subcontractorId={subcontractorId}
          />
        )}
      </div>
      {subview === "jhas" && (
        <SCProjectJHAs {...{ projectId, subcontractorId, data, refresh }} />
      )}
      {subview === "poc" && (
        <SCProjectPocs {...{ projectId, subcontractorId, data, refresh }} />
      )}
      {subview === "sds" && (
        <SCProjectSDS {...{ projectId, subcontractorId, data }} />
      )}
      {subview === "sssp" && (
        <SCProjectSafetyPlan
          {...{ projectId, subcontractorId, data, refresh }}
        />
      )}
      {subview === "billing" && (
        <CSBillingInvoices {...{ subId: subcontractorId, projectId }} />
      )}
      {subview === "mobilization" && <SubProjectMobilizationViewWrapper />}
      {subview === "workers-and-crews" && (
        <BasicWrapper scrollable>
          <SCProjectWorkers
            {...{ subcontractorId, projectId, data, refresh }}
          />
          <div />
          <SCProjectCrews {...{ projectId, subcontractorId, data, refresh }} />
        </BasicWrapper>
      )}
      {subview === "pre-task-plans" && <SCReportsPTP />}
      {subview === "daily" && <SCReportsDaily />}
      {subview === "toolbox-talks-manage" && (
        <SCProjectToolboxTalks type="manage" />
      )}
      {subview === "toolbox-talks-completed" && (
        <SCProjectToolboxTalks type="completed" />
      )}
      {subview === "daily-settings" && <SCReportsDailySettings />}
      {subview === "auto-emails" && (
        <StyledContent>
          <h3 className="text-1.25">Automatic Emails</h3>
          <br />
          <SCProjectAutoEmailTables
            subcontractorId={subcontractorId}
            projectId={projectId}
          />
        </StyledContent>
      )}
    </>
    // </BasicWrapper>
  );
};

interface SCProjectWrapperProps {
  projectId: string;
  subview: string;
}

const SCProjectWrapper: FC<SCProjectWrapperProps> = ({
  projectId,
  subview,
}) => {
  const subcontractorId = useSubcontractorId();
  return projectId ? (
    <BasicWrapper scrollable>
      <SCProject {...{ projectId, subcontractorId, subview }} />
    </BasicWrapper>
  ) : (
    <Navigate to={"sub"} replace />
  );
};
export default SCProjectWrapper;

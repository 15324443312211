/**
 * @generated SignedSource<<4840eee349376d962a55cdd3654e1bbf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { safety_plan_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SCProjectSafetyPlan_UpdateSafetyPlanMutation$variables = {
  _set: safety_plan_set_input;
  id: string;
};
export type SCProjectSafetyPlan_UpdateSafetyPlanMutation$data = {
  readonly update_safety_plan_by_pk: {
    readonly company_safety_plan_id: string | null | undefined;
    readonly deleted_at: string | null | undefined;
    readonly id: string;
  } | null | undefined;
};
export type SCProjectSafetyPlan_UpdateSafetyPlanMutation = {
  response: SCProjectSafetyPlan_UpdateSafetyPlanMutation$data;
  variables: SCProjectSafetyPlan_UpdateSafetyPlanMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_set"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "pk_columns"
      }
    ],
    "concreteType": "safety_plan",
    "kind": "LinkedField",
    "name": "update_safety_plan_by_pk",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deleted_at",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "company_safety_plan_id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCProjectSafetyPlan_UpdateSafetyPlanMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCProjectSafetyPlan_UpdateSafetyPlanMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "39b237854cae9862ceeecdbee1158886",
    "id": null,
    "metadata": {},
    "name": "SCProjectSafetyPlan_UpdateSafetyPlanMutation",
    "operationKind": "mutation",
    "text": "mutation SCProjectSafetyPlan_UpdateSafetyPlanMutation(\n  $_set: safety_plan_set_input!\n  $id: uuid!\n) {\n  update_safety_plan_by_pk(pk_columns: {id: $id}, _set: $_set) {\n    id\n    deleted_at\n    company_safety_plan_id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1cb39d01ce9f3fb40ded66e205aa59f";

export default node;

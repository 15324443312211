import { message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useMutation } from "react-relay/hooks";
import uploadFiles from "src/common/functions/upload-utility/uploadFiles";
import { useGetUserRoleQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { AddLogoModalGCInsertLogoMutation } from "src/common/types/generated/relay/AddLogoModalGCInsertLogoMutation.graphql";
import { AddLogoModalSubInsertLogoMutation } from "src/common/types/generated/relay/AddLogoModalSubInsertLogoMutation.graphql";
import FModal, { FModalRef } from "./FModal";

type FormValues = {
  logoImageObjects: Array<{ originFileObj: File }>;
};
export type AddLogoModalProps = {
  isLogo: boolean;
  id: any;
  onSubmit: () => void;
};
export type AddLogoModalRef = FModalRef<FormValues> | null;
const AddLogoModal = forwardRef<AddLogoModalRef, AddLogoModalProps>(
  ({ onSubmit, isLogo, id }, ref) => {
    const modal = useRef<FModalRef<FormValues>>(null);
    const [loading, setLoading] = useState(false);
    const isGC = useGetUserRoleQuery().data?.role === "employee";
    const title = isLogo ? "Update Logo" : "Add Logo";
    const modalButtonTitle = isLogo ? "Update" : "Add";
    const successMessage = isLogo ? "Logo Updated" : "Logo Added";
    const [insertGCLogo] = useMutation<AddLogoModalGCInsertLogoMutation>(
      graphql`
        mutation AddLogoModalGCInsertLogoMutation(
          $GCId: uuid!
          $logoUrl: String
        ) {
          update_general_contractor(
            where: { id: { _eq: $GCId } }
            _set: { logo_url: $logoUrl }
          ) {
            returning {
              logo_url
            }
          }
        }
      `,
    );

    const [insertSubLogo] = useMutation<AddLogoModalSubInsertLogoMutation>(
      graphql`
        mutation AddLogoModalSubInsertLogoMutation(
          $Id: uuid!
          $logoUrl: String
        ) {
          update_subcontractor(
            where: { id: { _eq: $Id } }
            _set: { logo_url: $logoUrl }
          ) {
            returning {
              logo_url
            }
          }
        }
      `,
    );

    useImperativeHandle<AddLogoModalRef, AddLogoModalRef>(
      ref,
      () => modal.current,
    );

    const reset = () => {
      setLoading(false);
      modal.current?.form.resetFields();
      modal.current?.close();
    };
    return (
      <FModal
        ref={modal}
        title={title}
        okText={modalButtonTitle}
        onOk={() => {
          const form = modal.current?.form;
          if (!form) return;
          form.validateFields().then(async (v) => {
            setLoading(true);
            const files = await uploadFiles(
              v.logoImageObjects.map((o) => o.originFileObj),
            );
            const logourl = files[0]?.url;
            if (isGC == true) {
              insertGCLogo({
                variables: {
                  GCId: id,
                  logoUrl: logourl,
                },
                onCompleted: () => {
                  reset();
                  message.success(successMessage);
                },
                onError: (e) => {
                  notification.error({
                    message: "Error",
                    description: e.message,
                    duration: null,
                  });
                },
              });
            } else {
              insertSubLogo({
                variables: {
                  Id: id,
                  logoUrl: logourl,
                },
                onCompleted: () => {
                  reset();
                  message.success(successMessage);
                },
                onError: (e) => {
                  notification.error({
                    message: "Error",
                    description: e.message,
                    duration: null,
                  });
                },
              });
            }
          });
        }}
      >
        <FModal.DragUpload
          props={{
            maxCount: 1,
          }}
          required
          label="Logo"
          name="logoImageObjects"
        />
      </FModal>
    );
  },
);

export default AddLogoModal;

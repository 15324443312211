/**
 * @generated SignedSource<<d41117f948ae1ae817f8db0a27092a94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { subcontractor_employee_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangeSubcontractorEmployeeTitleModalMutation$variables = {
  id: string;
  set: subcontractor_employee_set_input;
};
export type ChangeSubcontractorEmployeeTitleModalMutation$data = {
  readonly update_subcontractor_employee: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly employee_title: {
        readonly id: string;
        readonly name: {
          readonly clientText: string;
          readonly en: string;
          readonly es: string;
          readonly id: string;
          readonly original: string;
          readonly pk: string;
          readonly pt: string;
        };
      } | null | undefined;
      readonly id: string;
      readonly subcontractor: {
        readonly id: string;
        readonly name: string;
      };
      readonly subcontractor_id: string;
      readonly title_id: string | null | undefined;
    }>;
  } | null | undefined;
};
export type ChangeSubcontractorEmployeeTitleModalMutation = {
  response: ChangeSubcontractorEmployeeTitleModalMutation$data;
  variables: ChangeSubcontractorEmployeeTitleModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "set"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "set"
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "user_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "affected_rows",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "subcontractor",
  "kind": "LinkedField",
  "name": "subcontractor",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title_id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "es",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeSubcontractorEmployeeTitleModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "subcontractor_employee_mutation_response",
        "kind": "LinkedField",
        "name": "update_subcontractor_employee",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor_employee",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_employee_title",
                "kind": "LinkedField",
                "name": "employee_title",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": "pk",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id_pk",
                        "storageKey": null
                      },
                      {
                        "alias": "clientText",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__original_clientText",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeSubcontractorEmployeeTitleModalMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "subcontractor_employee_mutation_response",
        "kind": "LinkedField",
        "name": "update_subcontractor_employee",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractor_employee",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_employee_title",
                "kind": "LinkedField",
                "name": "employee_title",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "text_translation",
                    "kind": "LinkedField",
                    "name": "name",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": "pk",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": "pk",
                        "args": null,
                        "filters": null,
                        "handle": "pk",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "id"
                      },
                      {
                        "alias": "clientText",
                        "args": null,
                        "kind": "ScalarField",
                        "name": "original",
                        "storageKey": null
                      },
                      {
                        "alias": "clientText",
                        "args": null,
                        "filters": null,
                        "handle": "clientText",
                        "key": "",
                        "kind": "ScalarHandle",
                        "name": "original"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5168627d563ed4479ed82b6892e187d5",
    "id": null,
    "metadata": {},
    "name": "ChangeSubcontractorEmployeeTitleModalMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeSubcontractorEmployeeTitleModalMutation(\n  $id: uuid!\n  $set: subcontractor_employee_set_input!\n) {\n  update_subcontractor_employee(where: {user_id: {_eq: $id}}, _set: $set) {\n    affected_rows\n    returning {\n      subcontractor_id\n      subcontractor {\n        id\n        name\n      }\n      id\n      title_id\n      employee_title {\n        id\n        name {\n          id\n          en\n          es\n          pt\n          original\n          pk: id\n          clientText: original\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c853b156511e7fa717eacf3b87eeafda";

export default node;

import { useUpdateIncidentProjectWorkerMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { useUpdateIncidentProjectEmployeeMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { Operation } from "rfc6902";
import useAuthUser from "src/common/hooks/useAuthUser";

const useUpdateIncidentHardHat = () => {
  const [updateIncidentProjectWorker] =
    useUpdateIncidentProjectWorkerMutation();
  const [updateIncidentProjectEmployee] =
    useUpdateIncidentProjectEmployeeMutation();
  const authUser = useAuthUser();

  const updateHardHat = async (
    id: string,
    hardHat: string,
    userType: "project-worker" | "project-employee",
    patch: Operation[],
    comment: string,
    incidentId: string,
  ) => {
    if (userType === "project-worker") {
      await updateIncidentProjectWorker({
        variables: {
          pk_columns: { id: id },
          _set: { hard_hat_number: hardHat },
          objects: {
            comment: comment,
            edited_by_uid: authUser.uid,
            incident_id: incidentId,
            edit_type: "project-worker-edit",
            patch: patch,
          },
        },
        optimisticResponse: {
          update_project_worker_by_pk: {
            id: id,
            hard_hat_number: hardHat,
          },
          insert_incident_edit: {
            affected_rows: 1,
          },
        },
      });
    } else {
      await updateIncidentProjectEmployee({
        variables: {
          pk_columns: { id: id },
          _set: { hard_hat_number: hardHat },
          objects: {
            comment: comment,
            edited_by_uid: authUser.uid,
            incident_id: incidentId,
            edit_type: "project-employee-edit",
            patch: patch,
          },
        },
        optimisticResponse: {
          update_project_employee_by_pk: {
            id: id,
            hard_hat_number: hardHat,
          },
          insert_incident_edit: {
            affected_rows: 1,
          },
        },
      });
    }
  };

  return [updateHardHat] as const;
};

export default useUpdateIncidentHardHat;

import { Form, Input, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { SubAdminSendEmailModalQuery } from "src/common/types/generated/relay/SubAdminSendEmailModalQuery.graphql";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";

const query = graphql`
  query SubAdminSendEmailModalQuery($subId: uuid!) {
    subcontractor_connection(where: { id: { _eq: $subId } }) {
      edges {
        node {
          subcontractor_projects(order_by: { project: { name: asc } }) {
            project_id
            project {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
          }
        }
      }
    }
  }
`;

interface EmailDownloadModalValues {
  projectId: string;
  email: string;
}

interface EmailDownloadModalProps {
  visible: boolean;
  onCreate: (values: EmailDownloadModalValues) => void;
  onCancel: () => void;
  isEmail: boolean;
  subId: string;
  projectId?: string;
}

const SubAdminSendEmailDownloadModal: React.FC<EmailDownloadModalProps> = ({
  visible,
  onCreate,
  onCancel,
  isEmail,
  subId,
  projectId,
}) => {
  const [form] = Form.useForm();
  const data = useLazyLoadQuery<SubAdminSendEmailModalQuery>(query, {
    subId: subId,
  });
  form.setFieldsValue({ projectId: projectId });
  const data1 = data.subcontractor_connection.edges[0]?.node;
  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };
  return (
    <Modal
      open={visible}
      title={
        isEmail ? "Enter the Email and Project Details" : "Select the Project"
      }
      okText={isEmail ? "Send" : "Download"}
      cancelText="Cancel"
      onCancel={handleCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values as EmailDownloadModalValues);
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        {isEmail && (
          <Form.Item
            name="email"
            label="Email to"
            rules={[
              { required: true, message: "Description must be specified" },
            ]}
          >
            <Input placeholder="example@company.com" />
          </Form.Item>
        )}

        <Form.Item
          name="projectId"
          label="Project Name"
          style={{ display: projectId ? "none" : undefined }}
          rules={[{ required: true, message: "Select a Project" }]}
        >
          {data1?.subcontractor_projects.length !== 0 && (
            <Select
              style={{ width: "100%" }}
              placeholder="Select the Project"
              showSearch
              filterOption={getNormalSelectOptionsFilter}
              options={data1?.subcontractor_projects.map((p: any) => ({
                label: p.project.name,
                value: p.project_id,
              }))}
            ></Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default SubAdminSendEmailDownloadModal;

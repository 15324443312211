/**
 * @generated SignedSource<<1e1bacb2aff2d8bf18e2ca9405897172>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkAboveElevationTable_work_above_elevation$data = {
  readonly work_above_elevation_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly completed_at: string | null | undefined;
        readonly created_at: string;
        readonly created_by_user: {
          readonly employee: {
            readonly employee_title: {
              readonly name: {
                readonly en: string;
              };
            } | null | undefined;
          } | null | undefined;
          readonly name: string;
        };
        readonly id: string;
        readonly name: {
          readonly en: string;
          readonly id: string;
        };
        readonly name_id: string;
        readonly pk: string;
        readonly type_id: string;
        readonly work_above_elevation_type: {
          readonly name: {
            readonly en: string;
          };
        };
        readonly work_above_floors: ReadonlyArray<{
          readonly completed_at: string | null | undefined;
          readonly has_netting: boolean;
          readonly id: string;
          readonly name: {
            readonly en: string;
            readonly id: string;
          };
          readonly name_id: string;
          readonly order: number;
          readonly pk: string;
        }>;
        readonly " $fragmentSpreads": FragmentRefs<"EditWorkAboveElevationModal_work_above_elevation_frag">;
      };
    }>;
  };
  readonly " $fragmentType": "WorkAboveElevationTable_work_above_elevation";
};
export type WorkAboveElevationTable_work_above_elevation$key = {
  readonly " $data"?: WorkAboveElevationTable_work_above_elevation$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkAboveElevationTable_work_above_elevation">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "work_above_elevation_connection"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v4/*: any*/)
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_at",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order_by"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "where"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./WorkAboveElevationTableRefetchableQuery.graphql')
    }
  },
  "name": "WorkAboveElevationTable_work_above_elevation",
  "selections": [
    {
      "alias": "work_above_elevation_connection",
      "args": null,
      "concreteType": "work_above_elevationConnection",
      "kind": "LinkedField",
      "name": "__WorkAboveElevationTable_work_above_elevation_connection_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "work_above_elevationEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "work_above_elevation",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created_at",
                  "storageKey": null
                },
                (v2/*: any*/),
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "text_translation",
                  "kind": "LinkedField",
                  "name": "name",
                  "plural": false,
                  "selections": [
                    (v4/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type_id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "user",
                  "kind": "LinkedField",
                  "name": "created_by_user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "general_contractor_employee",
                      "kind": "LinkedField",
                      "name": "employee",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "employee_title",
                          "kind": "LinkedField",
                          "name": "employee_title",
                          "plural": false,
                          "selections": (v5/*: any*/),
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "work_above_elevation_type",
                  "kind": "LinkedField",
                  "name": "work_above_elevation_type",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "work_above_floor",
                  "kind": "LinkedField",
                  "name": "work_above_floors",
                  "plural": true,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "order",
                      "storageKey": null
                    },
                    (v3/*: any*/),
                    (v2/*: any*/),
                    (v6/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "has_netting",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "text_translation",
                      "kind": "LinkedField",
                      "name": "name",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v4/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "EditWorkAboveElevationModal_work_above_elevation_frag"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "query_root",
  "abstractKey": null
};
})();

(node as any).hash = "f0d8d696f925acaac8b7dab48144b4ab";

export default node;

import { Navigate, Outlet } from "react-router-dom";
import RequireRoleWrapper from "src/common/components/ReqiureRoleWrapper";
import NestedRoute from "src/common/types/manual/NestedRoute";
import GCBusinessUnits from "../business-unit/GCBusinessUnits";
import GCBusinessUnitTeam from "./team/GCBusinessUnitTeam";
import GCBusinessUnit from "./GCBusinessUnit";
import GCBusinessUnitProjects from "./projects/GCBusinessUnitProjects";
import GCBusinessUnitDetail from "./detail/GCBusinessUnitDetail";
import GCBusinessUnitIncidents from "./incident/GCBusinessUnitIncidents";
import GCBusinessUnitWorkers from "./worker/GCBusinessUnitWorkers";
import GCBuOffices from "./sub-levels/GCBuOffices";
import GCBuObservations from "./observation/GCBuObservations";
const gcBusinessUnitsRoute: NestedRoute = {
  element: (
    <RequireRoleWrapper requiredRole="employee">
      <Outlet />
    </RequireRoleWrapper>
  ),
  children: [
    {
      path: "",
      element: <GCBusinessUnits />,
      children: [
        {
          path: ":businessUnitId",
          element: <GCBusinessUnit />,
          children: [
            { path: "", element: <Navigate to={"projects"} /> },
            // { path: "details", element:  },
            { path: "workers", element: <GCBusinessUnitWorkers /> },
            { path: "teams", element: <GCBusinessUnitTeam /> },
            { path: "projects", element: <GCBusinessUnitProjects /> },
            { path: "incidents", element: <GCBusinessUnitIncidents /> },
            {
              path: "observations",
              element: <GCBuObservations />,
            },
            { path: "settings", element: <GCBusinessUnitDetail /> },
            { path: "offices", element: <GCBuOffices /> },
          ],
        },
      ],
    },
  ],
};
export default gcBusinessUnitsRoute;

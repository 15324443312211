import React, { useRef, useState } from "react";
import { Button } from "antd";
import AddSubcontractorProjectTeamModal from "src/domain-features/sitesafety/siteSafetyPlan/components/AddSubcontractorProjectTeamModal";
import ProjectSubcontractorEmployeeTable from "src/domain-features/sitesafety/siteSafetyPlan/components/ProjectSubcontractorEmployeeTable";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { graphql } from "babel-plugin-relay/macro";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";

export interface SubProjectMobilizationStepContactListsProps {
  projectId: string;
  subcontractorId: string;
  onNext: (isUploaded: boolean) => void;
}

const SubProjectMobilizationStepContactLists: React.FC<
  SubProjectMobilizationStepContactListsProps
> = (props) => {
  const [
    openAddSubcontractorProjectTeamModal,
    setOpenAddSubcontractorProjectTeamModal,
  ] = useState(false);
  const projectId = props.projectId;
  const subcontractorId = props.subcontractorId;
  const subcontractorProjectTeamTableRef = useRef<DataScrollTableRef>(null);

  return (
    <div>
      <AddSubcontractorProjectTeamModal
        modalClose={() => {
          setOpenAddSubcontractorProjectTeamModal(false);
        }}
        modalVisible={openAddSubcontractorProjectTeamModal}
        subcontractorId={subcontractorId}
        projectId={projectId ?? ""}
        onSubmit={() => {
          setOpenAddSubcontractorProjectTeamModal(false);
          subcontractorProjectTeamTableRef.current?.refetch();
          props.onNext(true);
        }}
      />
      <div className="mt-1">
        <Button
          onClick={() => {
            props.onNext(false);
          }}
        >
          Next
        </Button>
      </div>
      <div className="mt-2">
        <ProjectSubcontractorEmployeeTable
          ref={subcontractorProjectTeamTableRef}
          where={{
            project_id: { _eq: projectId },
            subcontractor_employee: {
              subcontractor_id: { _eq: subcontractorId },
            },
          }}
          excludedKeys={["subcontractorEmployeeTitle", "email", "status"]}
          loading={false}
          topBarButtons={[
            {
              onClick: () => {
                setOpenAddSubcontractorProjectTeamModal(true);
              },
              label: "+ Add",
            },
          ]}
        />
      </div>
    </div>
  );
};

export default SubProjectMobilizationStepContactLists;

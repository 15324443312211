import React, { ComponentType, FC, forwardRef, PropsWithoutRef } from "react";
import CustomSuspense, {
  CustomSuspenseProps,
} from "src/common/components/general/CustomSuspense";

const withCustomSuspense = <Props,>(
  Component: ComponentType<PropsWithoutRef<Props>>,
  options?: CustomSuspenseProps
) =>
  forwardRef((props: PropsWithoutRef<Props>, ref) => {
    return (
      <CustomSuspense {...options}>
        <Component {...props} ref={ref} />
      </CustomSuspense>
    );
  });

export default withCustomSuspense;
